import React, { useState } from 'react'
import { RadioButtonsWithContent } from '../../../../../../simples/RadioButtonsWithContent'
import styles from './Calendar.module.scss'
import { Variant } from '../../../../../../simples/RadioButtonsWithContent/RadioButtonsWithContent'
import { useAppSelector } from '../../../../../../../hooks/store'





function optionToId(date: string, time: string, gate: string) {
  return `${date}-${time}-${gate}`
}
function splitKey(key: string): SelectedOption {
  const [date, time, gate] = key.split('-')
  return { date, time, gate }
}

export type SelectedOption = {
  date: string;
  time: string;
  gate: string;
}


// const config: CalendarConfig = {
//   items: [{
//     availableSlots: [
//       { time: '12:00' }, { time: '13:00' }, { time: '14:00' }, { time: '15:00', disabled: true },
//       { time: '16:00' }, { time: '17:00', disabled: true }, { time: '18:00' }, { time: '19:00' },
//       { time: '20:00' }],
//     date: '12 января',
//     gate: {
//       id: '1',
//       title: 'Гейт 17'
//     }
//   }, {
//     availableSlots: [
//       { time: '12:00' }, { time: '13:00', disabled: true }, { time: '14:00' }, { time: '15:00' },
//       { time: '16:00' }, { time: '17:00' }, { time: '18:00' }, { time: '19:00' },
//       { time: '20:00', disabled: true }],
//     date: '13 января',
//     gate: {
//       id: '1',
//       title: 'Гейт 17'
//     }
//   }, {
//     availableSlots: [
//       { time: '12:00' }, { time: '13:00', disabled: true }, { time: '14:00' }, { time: '15:00' },
//       { time: '16:00' }, { time: '17:00' }, { time: '18:00' }, { time: '19:00' },
//       { time: '20:00', disabled: true }],
//     date: '14 января',
//     gate: {
//       id: '2',
//       title: 'Гейт 15'
//     }
//   }]
// }

export default function Calendar() {
  const [selected, setSelected] = useState({
    date: '',
    time: '',
    gate: '',
  })
  const selectedOption = optionToId(selected.date, selected.time, selected.gate)

  const handleChange = (key: string) => {
    setSelected(splitKey(key))
  }

  const config = useAppSelector(state => state.fbo.calendarConfig)

  return (
    <div className={styles.items}>
      {config.items.map(item => {
        const options = item.availableSlots.map(slot => ({
          value: optionToId(item.date, slot.time, item.gate.id),
          content: <>{slot.time}</>,
          disabled: slot.disabled
        }))
        return <div key={`${item.gate}${item.date}`} className={styles.item}>
          <div className={styles.head}>
            <div className={styles.date}>{item.date}</div>
            <div className={styles.gate}>{item.gate.title}</div>
          </div>
          <div className={styles.btns}>
            <RadioButtonsWithContent value={selectedOption} options={options} onChange={handleChange} className={styles.grid} variant={Variant.v1} />
          </div>
        </div>
      })}
    </div>
  )
}
