import {useAppDispatch} from "../hooks/store";
import {
  chekedProductColorInput,
  createProductBarCode,
  changeProductSizeInput,
  removeProductColorSize,
  addProductColor,
  changeProductAdditionalColor,
  changeProductColor,
  removeColorField,
  loadProductFashionPhoto,
  removeProductPhoto,
  loadProductPhoto,
  addColorArticle,
  resetBarcodes,
} from "../store/slices/productEdit";
import {productColorsPageType} from "../types/product";

export const generateNum = () => {
  return Array.from({length: 13}, () => Math.round(Math.random() * 9)).join("");
};

export const validateOfSymbols = (count: number, value: string | number) => {
  if (typeof value === "string" && value.length >= count) {
    return "Максимальное количество символов 70";
  }

  if (typeof value === "number" && value > count) {
    return `Превышенно максимальное значение ${count}`;
  }
};

export function normalPrice(num: number) {
  return String(num).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
}

export function formatNumber(num: number) {
  return num < 9 ? `0${num}` : num;
}

export const getErrorsColorPage = (colorPage: productColorsPageType) => {
  const isEmtyArticle = colorPage.color_article.length > 0;
  const isEmptyPhotoList = colorPage.productsPhotos.length > 0;
  const isFashionPhoto = colorPage.productsFashion.file_id > 0;
  const isEmptySizes = colorPage.sizes.length > 0;
  const isEmtyManBarcode = colorPage.sizes.filter(s => !s.barcode).length === 0;
  const isEmptyManArticle = colorPage.man_article.length > 0;

  return (
    isEmtyArticle &&
    isEmptySizes &&
    isEmptyPhotoList &&
    isFashionPhoto &&
    isEmtyManBarcode &&
    isEmptyManArticle
  );
};

export const ProductEdditActions = (activePage: number) => {
  const dispatch = useAppDispatch();

  const chekedInput = (id: number) => {
    dispatch(chekedProductColorInput({id, index: activePage - 1}));
  };

  const createBarCode = () => {
    dispatch(createProductBarCode({index: activePage - 1}));
  };

  const changeSizeInput = (id: number, value: string) => {
    dispatch(changeProductSizeInput({id, index: activePage - 1, value}));
  };

  const delteProductColorSize = (id: number) => {
    dispatch(removeProductColorSize({id, index: activePage - 1}));
  };

  const changedProductColor = (id: number) => {
    dispatch(changeProductColor(id, activePage - 1));
    dispatch(resetBarcodes());
  };

  const changeAdditionalColor = (indexColor: number, color_id: number) => {
    dispatch(
      changeProductAdditionalColor({
        indexColor,
        color_id,
        indexPage: activePage - 1,
      }),
    );
  };

  const changeColorArticle = (article: string) => {
    dispatch(addColorArticle(activePage - 1, article));
  };

  const addProductColorField = (id: number) => {
    dispatch(addProductColor(activePage - 1, id));
  };

  const deletedColorField = (indexColor: number) => {
    dispatch(removeColorField({indexColor, indexPage: activePage - 1}));
  };

  const loadFahsionPhoto = (file_id: number) => {
    dispatch(loadProductFashionPhoto(activePage - 1, file_id));
  };

  const productPhotoRemove = (id: number) => {
    dispatch(removeProductPhoto(activePage - 1, id));
  };

  const loadProductsPhoto = (fotoList: {id: number}) => {
    dispatch(loadProductPhoto(activePage - 1, fotoList));
  };

  return {
    chekedInput,
    createBarCode,
    changeSizeInput,
    delteProductColorSize,
    changedProductColor,
    changeAdditionalColor,
    addProductColorField,
    deletedColorField,
    loadFahsionPhoto,
    productPhotoRemove,
    loadProductsPhoto,
    changeColorArticle,
  };
};
