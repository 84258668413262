import classNames from 'classnames'
import React from 'react'
import { Promotions } from '../../../../../types/swagger/llyApi'
import calendar_icon from "../../assets/calendar_icon.svg"

import calendarStyles from "../../ActionsCalendar.module.scss"
import styles from "./CalendarDay.module.scss"
import { formatPeriod } from '../../../../../utils/action'

type Props = {
  action: Promotions
  selectAction: (actionId: number) => void
}


export default function DayAction({ action, selectAction }: Props) {
  const handleClick = () => {
    action.id && selectAction(action.id)
  }
  return (<div onClick={handleClick} className={classNames(styles.day_action, calendarStyles.calendar_item)}>
    <div className={classNames(styles.title, calendarStyles.title)}>{action.title}</div>
    <div className={styles.content}>
      <div className={styles.period}>
        <img src={calendar_icon} alt="" /><span>Период:</span><span>{formatPeriod(action.start_date || "", action.finish_date || "")}</span>
      </div>
      <div className={styles.discount}>
        <span>{action.text}</span> <span>-{action.percent}%!</span>
      </div>
      <div className={styles.description}>
        <div className={styles.description_title}>Условия акции</div>
        <div className={styles.description_text}><span>{action.description}</span></div>
      </div>
    </div>
  </div>)
}
