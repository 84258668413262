import React from 'react'
import styles from '../../Addresses.module.scss'
import { PulseLoader } from 'react-spinners'

type Props = {
  title: string
}

export default function AddressesPannel({ title }: Props) {
  return (
    <div className={styles.address_pannel}>
      <h1>{title}</h1>
      <div className={styles.fulladdresses}>
        <div className={styles.label}>Аддрес</div>
        <div className={styles.input_wrap}>
          <input type="text" value='Россия' />
          <PulseLoader color='#A984D5' size={8} margin={4} />
        </div>
      </div>
      <div className={styles.inputs}>
        <div className={styles.inputs_group}>
          <div className={styles.input}>
            <div className={styles.label}>Дом *</div>
            <div className={styles.input_wrap}>
              <input type="text" />
            </div>
          </div>
          <div className={styles.input}>
            <div className={styles.label}>Корпус, литер</div>
            <div className={styles.input_wrap}>
              <input type="text" />
            </div>
          </div>
        </div>
        <div className={styles.inputs_group}>
          <div className={styles.input}>
            <div className={styles.label}>Офис</div>
            <div className={styles.input_wrap}>
              <input type="text" />
            </div>
          </div>
          <div className={styles.input}>
            <div className={styles.label}>Кабинет</div>
            <div className={styles.input_wrap}>
              <input type="text" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
