import React from 'react'

import styles from './VariantPhotos.module.scss'
import { ProductPhoto } from '../../../../../store/slices/product'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from "@dnd-kit/utilities";
import { Checkbox } from '../../../../simples/Checkbox';


type Props = {
  photo: ProductPhoto
  onRemove?: (id: number) => void
  // isMain?: boolean
  setMain?: () => void
  isChecked?: boolean
  onChange?: (checked: boolean) => void
}

export default function Photo({ photo, onRemove, setMain, onChange, isChecked }: Props) {

  // const handleRemove = () => {
  //   onRemove(photo.id)
  // }

  const {
    isSorting,
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: photo.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isSorting ? (transition || undefined) : undefined,
    zIndex: isDragging ? 10 : 0,
    opacity: isDragging ? 0.8 : 1,
    cursor: isDragging ? 'grabbing' : 'grab'
  };

  const handle = () => {
    console.log();
  }

  return (
    <div className={styles.photo}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <img src={photo.url} />
      {onChange && <div className={styles.remove_cb_wrap}>
        <div className={styles.remove_cb_bg}></div>
        <Checkbox className={styles.remove_cb} checked={isChecked} onChange={onChange} />
      </div>}
      {/* <div className={styles.mask}>
        <span onClick={handleRemove} className={styles.delete}>
          Удалить фото
        </span>
      </div> */}
      {photo.isMain && <span className={styles.main_photo_label}>Основное фото товара</span>}
      {(!photo.isMain && setMain !== undefined) && <span className={styles.set_as_main_photo_label} onClick={setMain}>Назначить основным фото</span>}
    </div>
  )
}
