import React, { useCallback, useImperativeHandle } from "react";
import styles from "./Upload.module.scss";
import { useDropzone } from "react-dropzone";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import classNames from "classnames";
import uploadIcon from "../../assets/arch.svg";
import { getUploadingError, uploadArch } from "../../../../../store/slices/massEdit";


type UploadHandle = {
  open_dialog: () => void,
}

const Upload = (_, ref: React.Ref<UploadHandle>) => {

  const dispatch = useAppDispatch();
  const uploadingError = useAppSelector(getUploadingError());

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 1) {
      dispatch(uploadArch({ formDataFile: acceptedFiles[0] }));
    }
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop, maxFiles: 1,
    multiple: false,
    accept: { "application/zip": [".zip"], "application/vnd.rar": [".rar"], "application/x-7z-compressed": [".7z"] },
  });

  useImperativeHandle(ref, () => ({
    open_dialog() {
      open()
    }
  }));


  return (
    <div  {...getRootProps()} className={styles.wrap} >
      <input {...getInputProps()} />
      <div className={classNames(styles.content)}>
        <img className={styles.icon} src={uploadIcon} alt="icon" />
        <span className={styles.text}>Загрузите, либо перетащите сюда заполненный архив в формате .rar, .7z, .zip</span>
      </div>
      {uploadingError && <span className={styles.error}>Ошибка: {uploadingError}</span>}
    </div >
  );
};

// export default Upload;
export default React.forwardRef(Upload);
