import React, { useEffect, useState } from "react";
import styles from "./MainSidebar.module.scss";
import classNames from "classnames";
import logo from "./../../../../../../src/assets/icons/appLogo.svg";
import Menu from "./parts/Menu";
import MenuConfig from "../../../../../constants/mainMenu";

import api from '../../../../../utils/api'
import { toast } from "react-toastify";

const setLinkClass = (navData: any) =>
  navData.isActive
    ? classNames(styles.active, styles.navLink)
    : classNames(styles.navLink);



type FetchResponse = {
  success: boolean,
  data: {
    company_info: boolean,
    company_documents: boolean,
    work_times: boolean
  },
  errors?: string[]
}

const MainSidebar = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [notifications, setNotifications] = useState({})

  useEffect(() => {
    setIsLoading(true)
    api.get<FetchResponse>('/companies/notifications').then(resp => {
      if (resp.data.success) {
        setNotifications(resp.data.data)
      } else {
        toast.error("Ошибка", { theme: "light" });
      }
      setIsLoading(false)
    }).catch(e => {
      toast.error("Ошибка", { theme: "light" });
      console.log(e);
    })

  }, [])


  return (
    <aside className={styles.sidebar}>
      <header className={styles.logoBlock}>
        <div className={styles.img_wrap}>
          <img src={logo} alt="Логотип сайта" />
        </div>
      </header>

      <nav className={styles.navigation}>
        <Menu
          menuItems={MenuConfig}
          className={styles.navBlock}
          isOpen={true}
          notifications={notifications}
        />
      </nav>

    </aside>
  );
};

export default MainSidebar;
