import React from 'react'
import { Layout } from '../../complexes/Layout'

import styles from './Brands.module.scss'
import { ITab, Tabs } from '../../complexes/Tabs'
import BrandsTab from './parts/BrandsTab'
import BrandsTabInfo from './parts/BrandsTabInfo'
import CertificatesTab from './parts/CertificatesTab'
import CertificatesInfo from './parts/CertificatesInfo'
import { useAppSelector } from '../../../hooks/store'
import CertCard from './parts/CertCard'
import { useParams } from 'react-router-dom'
import { BrandsTabConfig } from './const'

export default function Brands() {

  const selectedCertCard = useAppSelector(state => state.certificates.selectedCertCardId)
  const isCertCardSelected = selectedCertCard !== undefined

  const params = useParams();
  const tabId = params.tabId

  const tabs: ITab[] = [{
    // label: 'Бренды',
    ...BrandsTabConfig.brands,
    content: <BrandsTab />,
    rightSideBar: <BrandsTabInfo />
  }, {
    // label: 'Сертификаты',
    ...BrandsTabConfig.certificates,
    content: isCertCardSelected ? <CertCard /> : <CertificatesTab />,
    rightSideBar: <CertificatesInfo />
  }]

  const activeTabIndex = tabs.findIndex(tab => tab.id === tabId)

  return (
    <Layout title='Бренды и сертификаты'>
      <div className={styles.wrap}>
        <Tabs tabs={tabs} activeTabIndex={activeTabIndex} />
      </div>
    </Layout>
  )
}
