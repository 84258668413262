import React from 'react'

import styles from './PanelMini.module.scss'
import classNames from 'classnames'

type Props = {
  title?: JSX.Element | JSX.Element[] | string
  children?: JSX.Element | JSX.Element[] | string
  className?: string
  onClick?: () => void
  isSelected?: boolean
}

export default function PanelMini({ title, children, className, onClick, isSelected }: Props) {

  return (<div className={classNames(styles.panel, className, { [styles.clickable]: onClick !== undefined, [styles.selected]: isSelected })} onClick={onClick}>
    <div className={styles.head}>{title}</div >
    <div className={styles.body}>{children}</div>
  </div>)
}
