import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { deselectStarFilter, selectStarFilter } from '../../../../../store/slices/feedback';
import { StarFilterLabels } from '../../consts';
import FilterLabel from './FilterLabel';

import styles from "./Filters.module.scss";

export default function StarFilter() {
  const dispatch = useAppDispatch()

  const selectedStars = useAppSelector(state => state.feedback.selectedStarsFilter);

  const handleSelectOption = (v: number) => () => {
    if (selectedStars.indexOf(v) === -1) {
      dispatch(selectStarFilter(v))
    } else {
      dispatch(deselectStarFilter(v))
    }
  }
  const handleDeselectOption = (v: number) => () => {
    dispatch(deselectStarFilter(v))
  }
  const labels = Array(5).fill("").map((_, i) =>
    <FilterLabel
      key={i}
      text={StarFilterLabels[i]}
      starsCount={i + 1}
      select={handleSelectOption(i + 1)}
      selected={selectedStars.indexOf(i + 1) !== -1}
      deselect={handleDeselectOption(i + 1)}
    />
  )

  return <div className={styles.col}>
    {labels}
  </div>;
}