import React, { useState, useEffect } from 'react'
import styles from './ProductSelectorSlider.module.scss'
import { SearchInput } from '../SearchInput'
import { ProductsSlider } from '../ProductsSlider'
import { GoodsAnswer } from '../../../types/productListTypes'
import api from '../../../utils/api'
import { ProductsDTO } from '../../../types/swagger/llyApi_new'
import { Option, OptionSelector } from '../../simples/OptionSelector'
import { BlueButton, ButtonStyle } from '../../simples/BlueButton'
import { CustomDatePicker } from '../CustomDatePicker'
import { DatePickerDate } from '../CustomDatePicker'
import { datePickerDateToDate } from '../CustomDatePicker/CustomDatePicker'
import { dateToDatePickerDate } from '../CustomDatePicker/CustomDatePicker'
import { ModalProductSearch } from '../ModalProductSearch'
import { sub } from 'date-fns'
import { CalendarPeriod, CalendarPeriodSelector } from '../CalendarPeriodSelector'

// enum CalendarPeriod {
//   year = "year",
//   month = "month",
//   week = "week",
//   day = "day",
// }

// const CalendarPeriodOptions: Option<CalendarPeriod>[] = [{
//   label: "День",
//   value: CalendarPeriod.day
// }, {
//   label: "Неделя",
//   value: CalendarPeriod.week
// }, {
//   label: "Месяц",
//   value: CalendarPeriod.month
// }, {
//   label: "Год",
//   value: CalendarPeriod.year
// }]

const DefaultLocalStorageKey = 'ProductSelectorSlider-lastProducts';



function saveHistory(ids: string[], localStorageKey?: string) {
  localStorage.setItem(localStorageKey || DefaultLocalStorageKey, JSON.stringify(ids))
}

export type ProductSelectorSliderConfig = {
  colorId?: number
  dateFrom: DatePickerDate
  dateTo: DatePickerDate
  productTitle: string
}

type Props = {
  defaultConfig?: ProductSelectorSliderConfig
  localStorageKey?: string
  onChange: (config: ProductSelectorSliderConfig) => void
}

export default function ProductSelectorSlider({ onChange, localStorageKey, defaultConfig }: Props) {

  const [history, setHistory] = useState<ProductsDTO[]>([])
  const [search, setSearch] = useState('')
  const [selectedProduct, setSelectedProduct] = useState<ProductsDTO>()
  const [isLoading, setIsLoading] = useState(true)
  const [isProductsSelection, setIsProductsSelection] = useState(false)

  const [dateTo, setDateTo] = useState<DatePickerDate>(
    (defaultConfig !== undefined && defaultConfig.dateTo !== undefined)
      ? defaultConfig.dateTo
      : dateToDatePickerDate(new Date())
  )
  const [dateFrom, setDateFrom] = useState<DatePickerDate>(
    (defaultConfig !== undefined && defaultConfig.dateFrom !== undefined)
      ? defaultConfig.dateFrom
      : dateToDatePickerDate(sub(new Date(), {
        months: 1
      }))
  )

  const [period, setPeriod] = useState<CalendarPeriod | undefined>(CalendarPeriod.month)

  useEffect(() => {
    switch (period) {
      case CalendarPeriod.day: {
        const date = sub(datePickerDateToDate(dateTo), {
          days: 1
        })
        setDateFrom(dateToDatePickerDate(date))
        break;
      }
      case CalendarPeriod.week: {
        const date = sub(datePickerDateToDate(dateTo), {
          days: 7
        })
        setDateFrom(dateToDatePickerDate(date))
        break;
      }
      case CalendarPeriod.month: {
        const date = sub(datePickerDateToDate(dateTo), {
          months: 1
        })
        setDateFrom(dateToDatePickerDate(date))
        break;
      }
      case CalendarPeriod.year: {
        const date = sub(datePickerDateToDate(dateTo), {
          months: 12
        })
        setDateFrom(dateToDatePickerDate(date))
        break;
      }

      default:
        break;
    }
  }, [period, dateTo])


  const loadHistory = () => {
    let ids: string[] = []
    const _data = localStorage.getItem(DefaultLocalStorageKey)
    if (_data) {
      ids = JSON.parse(_data)
    }

    if (ids.length === 0) {
      // если история пуста берём 50 первых
      const req = '/products?status_id=0&limit=50'
      setIsLoading(true)
      api.get<GoodsAnswer>(req)
        .then((res) => {
          const products = res.data.data || []
          saveHistory(products.map(p => p.id), localStorageKey)
          setHistory(products)
          setIsLoading(false)
        })
        .catch((err) => console.log(err));
    } else {
      const req = `/products/get-by-ids`
      setIsLoading(true)
      api.post<GoodsAnswer>(req, {
        products: ids
      }).then((res) => {
        setHistory(res.data.data || [])
        setIsLoading(false)
      }).catch((err) => console.log(err));
    }
  }


  useEffect(loadHistory, [])

  const handleSetDateFrom = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    const to = datePickerDateToDate(dateTo);
    if (d.getTime() < to.getTime()) {
      setPeriod(undefined)
      setDateFrom(date)
    }
  }
  const handleSetDateTo = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    const from = datePickerDateToDate(dateFrom)
    const now = new Date()

    if (d.getTime() > from.getTime() && d.getTime() < now.getTime()) {
      setPeriod(undefined)
      setDateTo(date)
    }
  }


  const handleBuildReport = () => {
    onChange({
      dateFrom,
      dateTo,
      colorId: selectedProduct?.product_color_id,
      productTitle: selectedProduct?.name || ''
    })
  }
  const handleAddProducts = (list: string[]) => {
    if (list.length > 0) {
      const newHistory = [...list, ...history.map(item => item.id).filter(id => list.indexOf(id) === -1)].splice(0, 100)
      saveHistory(newHistory, localStorageKey)
      loadHistory()
    }
    setIsProductsSelection(false)
  }
  const handleOpenSearch = () => {
    setIsProductsSelection(true)
  }


  let filteredHistory: ProductsDTO[] = []
  // фильтруем дубликаты размеров
  for (let i = 0; i < history.length; i++) {
    const item = history[i];
    if (filteredHistory.find(p => p.product_id === item.product_id && p.product_color_id === item.product_color_id) === undefined) {
      filteredHistory.push(item)
    }
  }

  //  = history
  filteredHistory = filteredHistory.filter(item =>
    item.article?.toLocaleLowerCase()?.indexOf(search.toLocaleLowerCase()) !== -1
    || item.main_color?.toLocaleLowerCase()?.indexOf(search.toLocaleLowerCase()) !== -1
    || item.name?.toLocaleLowerCase()?.indexOf(search.toLocaleLowerCase()) !== -1
  )

  const btnIsDisabled = selectedProduct === undefined

  return (
    <div className={styles.product_selector_slider}>
      <ModalProductSearch isVisible={isProductsSelection} onClose={handleAddProducts} />
      <div className={styles.search}>
        <SearchInput onChange={setSearch} className={styles.search_input} />
        <div className={styles.btn} onClick={handleOpenSearch}>Смотреть все</div>
      </div>
      <div className={styles.history}>
        <ProductsSlider products={filteredHistory} selectedId={selectedProduct?.id} onSelect={setSelectedProduct} isLoading={isLoading} />
      </div>
      <div className={styles.date_selector}>
        <div className={styles.period_selector}>
          <div>Сформировать статистику за</div>
          {/* <OptionSelector<CalendarPeriod> onSelect={setPeriod} options={CalendarPeriodOptions} selectedOption={period} /> */}
          <CalendarPeriodSelector period={period} onChange={setPeriod} />
        </div>
        <div className={styles.dates}>
          <CustomDatePicker date={dateFrom} onChange={handleSetDateFrom} label="с" />
          <CustomDatePicker date={dateTo} onChange={handleSetDateTo} label="по" />
        </div>
        <BlueButton buttonStyle={ButtonStyle.Style1} title='Применить' onClick={handleBuildReport} disabled={btnIsDisabled} />
      </div>
      <BlueButton className={styles.build_report} title='Сформировать отчет' onClick={handleBuildReport} disabled={btnIsDisabled} />
    </div>
  )
}
