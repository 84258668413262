import React from 'react'
import styles from "./RadioButtons.module.scss"
import o from "./assets/o.svg"
import x from "./assets/x.svg"

type Props = {
  checked: boolean
  label: string
  onChange: (value: boolean) => void
  icon?: JSX.Element
  disabled?: boolean
}

export default function RadioButton({ checked, label, icon, onChange }: Props) {
  const handleClick = () => {
    onChange(true);
  }
  return (
    <div className={styles.rbtn} onClick={handleClick}>
      {checked ? <img src={x} alt="" /> : <img src={o} alt="" />}
      <span className={styles.label}>{label}</span>
      {icon}
    </div>
  )
}
