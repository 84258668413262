import React from 'react'
import styles from '../MainPage.module.scss'
import { News, NotificationTrait } from '../../../../types/swagger/llyApi_new'
import { Panel } from '../../../simples/Panel';
import NewsList from './NewsList';
import NotificationsList from './NotificationsList';

type Props = {
  isLoading: boolean
  news: News[]
  notifications: NotificationTrait[]
}

export default function NewsPanel({isLoading, news, notifications}: Props) {
  return (
    <Panel className={styles.news_panel}>
      <NotificationsList notifications={notifications} isLoading={isLoading} />
      <NewsList news={news} isLoading={isLoading} />
    </Panel>
  )
}
