import React from 'react'
import styles from './Passes.module.scss'
import { IconBtn, IconType } from '../../../../../../simples/IconBtn'
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/store'
import { Checkbox } from '../../../../../../simples/Checkbox'
import { BlueButton, ButtonStyle } from '../../../../../../simples/BlueButton'
import { addPass, removePass, saveNewPass, setPassValue, togglePass } from '../../../../../../../store/slices/fbo'
import { CustomSelect_v2 } from '../../../../../../simples/CustomSelect_v2'
import { CustomTextInput_v2, IOnChange } from '../../../../../../simples/CustomTextInput_v2'

export default function Passes() {

  const dispatch = useAppDispatch()
  const { passes, selectedPasses, newPass } = useAppSelector(state => state.fbo)

  const handleChangeSelected = (id?: string) => () => {
    if (id) { dispatch(togglePass(id)) }
  }
  const handleChange = (passId?: string) => ({ name, value }: IOnChange) => {
    dispatch(setPassValue({ passId, key: name.toString(), value: value.toString() }))
  }
  const handleAddPass = () => {
    dispatch(addPass())
  }
  const handleSavePass = () => {
    dispatch(saveNewPass())
  }
  const handleRemovePass = (id?: string) => () => {
    if (id) { dispatch(removePass(id)) }
  }

  const sortedPasses = [...passes].sort((a, b) => a.fio.toLowerCase().localeCompare(b.fio.toLowerCase(), "ru"))

  const newPassHasErrors = !newPass?.car || !newPass?.carNumber || !newPass?.fio || !newPass?.phone

  return (
    <div className={styles.passes}>
      <table>
        <thead><tr>
          <th className={styles.v}></th>
          <th className={styles.fio}>ФИО</th>
          <th className={styles.car}>Автомобиль</th>
          <th className={styles.car_n}>Номер автомобиля</th>
          <th className={styles.phone}>Телефон</th>
          <th></th>
        </tr></thead>
        <tbody>
          {sortedPasses.map(p => <tr key={p.id}>
            <td><Checkbox checked={!!p.id && selectedPasses.indexOf(p.id) !== -1} onChange={handleChangeSelected(p.id)} /></td>
            <td>{p.fio}</td>
            <td>{p.car}</td>
            <td>{p.carNumber}</td>
            <td>{p.phone}</td>
            <td><IconBtn icon={IconType.Trash} onClick={handleRemovePass(p.id)} /></td>
          </tr>
          )}
          {newPass && <tr>
            <td></td>
            <td><CustomTextInput_v2 value={newPass.fio} name='fio' onChange={handleChange()} placeholder='Введите ФИО' hideError /></td>
            <td><CustomTextInput_v2 value={newPass.car} name='car' onChange={handleChange()} placeholder='Укажите марку автомобиля' hideError /></td>
            <td><CustomTextInput_v2 value={newPass.carNumber} name='carNumber' onChange={handleChange()} placeholder='Укажите номер' hideError /></td>
            <td><CustomTextInput_v2 value={newPass.phone} name='phone' onChange={handleChange()} placeholder='Укажите номер телефона' hideError /></td>
            <td><IconBtn icon={IconType.Save} onClick={handleSavePass} disabled={newPassHasErrors} /></td>
          </tr>
          }
        </tbody>
      </table>
      <BlueButton buttonStyle={ButtonStyle.Style1} title='Добавить водителя' onClick={handleAddPass} disabled={newPass !== undefined} />
    </div>
  )
}
