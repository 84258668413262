import classNames from "classnames";
import React from "react";
import styles from "./DisabledMultilineInput.module.scss";

type Props = {
  header: string;
  body: string;
  className?: string;
};

const DisabledMultilineInput = ({className, header, body}: Props) => (
  <div className={className}>
    <h2 className={styles.header}>{header}</h2>
    <p className={classNames(styles.body, !body && styles.disabledTest)}>
      {body || ""}
    </p>
  </div>
);

export default DisabledMultilineInput;
