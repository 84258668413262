import React, { useEffect, useState } from 'react'
import styles from '../MainPage.module.scss'
import { Panel } from '../../../simples/Panel'
import Logo from '../../../complexes/Logo/Logo'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import api from "../../../../utils/api";
import { ShopInfoDTO } from '../../../../types/swagger/llyApi_new'
import { BlueButton } from '../../../simples/BlueButton'
import star_icon from '../assets/star.svg'
import ShopLvlHint from '../../../simples/ShopLvlHint'
import { CustomHintDirection } from '../../../simples/CustomHint/CustomHint'
import Actions from './Actions'


type FetchResponse = {
  success?: boolean
  data?: ShopInfoDTO
  errors?: string[]
}

export default function Sidebar() {

  const [shopInfo, setShopInfo] = useState<ShopInfoDTO>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    api.get<FetchResponse>('/companies/info').then(resp => {
      if (resp.data.success) {
        setShopInfo(resp.data.data)
      } else {
        toast.error("Ошибка", { theme: "light" });
      }
      setIsLoading(false)
    }).catch(e => {
      toast.error("Ошибка", { theme: "light" });
      console.log(e);
    })
  }, [])

  const handle = () => { return }

  let logoUrl: string | undefined
  if (shopInfo?.avatars && shopInfo?.avatars.length > 0) {
    logoUrl = shopInfo?.avatars[0].url
  }

  return (
    <div className={classNames(styles.sidebar, styles.panels_column)}>
      <Panel className={styles.sidebar_content} isLoading={isLoading}>
        <Logo progress={shopInfo?.progress || 0} logoUrl={logoUrl} />
        <div>Уровень магазина №<span>{shopInfo?.level}</span></div>
        <div className={styles.level_name}><span>{shopInfo?.level_name}</span><ShopLvlHint direction={CustomHintDirection.Right} /></div>
        <div className={styles.lv}>
          <span className={styles.label}>Комиссия</span>
          <span className={styles.value}>{shopInfo?.commission}%</span>
        </div>
        <div className={styles.lv}>
          <span className={styles.label}>Рейтинг магазина</span>
          <span className={styles.value}>{shopInfo?.level}<img className={styles.star_icon} src={star_icon} /></span>
        </div>
        <div className={styles.lv}>
          <span className={styles.label}>Баланс:</span>
          <span className={styles.value}>{shopInfo?.balance?.toLocaleString()} ₽</span>
        </div>
        <div className={styles.sidebar_btns}>
          <BlueButton onClick={handle} title='Вывести деньги' />
        </div>
      </Panel>
      <Panel className={classNames(styles.sidebar_content, styles.fbo_fbs)} isLoading={isLoading}>
        <div className={styles.warning_text}>
          <span>Эти позиции вот-вот закончатся на складе</span>
        </div>
        <div className={styles.panel}>
          <div className={styles.panel_title}>FBO</div>
          <table>
            <tbody>
              <tr>
                <td>4609372<br />Пальто длинное красное Mango</td>
                <td>S (42)</td>
                <td>10шт</td>
              </tr>
              <tr>
                <td>4609372<br />Пальто длинное красное Mango</td>
                <td>XS (40)</td>
                <td>10шт</td>
              </tr>
              <tr>
                <td>4609372<br />Пальто длинное красное Mango</td>
                <td>M (44)</td>
                <td>10шт</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.panel}>
          <div className={styles.panel_title}>FBS</div>
          <table>
            <tbody>
              <tr>
                <td>4609372<br />Пальто длинное красное Mango</td>
                <td>S (42)</td>
                <td>10шт</td>
              </tr>
              <tr>
                <td>4609372<br />Пальто длинное красное Mango</td>
                <td>XS (40)</td>
                <td>10шт</td>
              </tr>
              <tr>
                <td>4609372<br />Пальто длинное красное Mango</td>
                <td>M (44)</td>
                <td>10шт</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Panel>
      <Actions />
    </div >
  )
}
