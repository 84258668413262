import React from 'react'
import Skeleton from 'react-loading-skeleton'

import styles from '../MainPage.module.scss'
import classNames from 'classnames'
type Props = {
  value?: number
}

export default function GrowthValue({ value }: Props) {
  if (value === undefined) { return <Skeleton /> }
  const siGrowth = value > 0
  return (
    <div className={classNames(styles.indicator_growth, { [styles.down]: !siGrowth })}>{siGrowth?'+':''}{(value + '%')}</div>
  )
}
