import React from 'react'
import styles from '../MainPage.module.scss'
import { NotificationTrait } from '../../../../types/swagger/llyApi_new'
import Warning from './Warning';

type Props = {
  warnings: NotificationTrait[]
}

export default function Warnings({warnings}: Props) {
  return (
    <div className={styles.warnings}>
      {warnings.map(w => <Warning key={w.itemName} notification={w} />)}
    </div>
  )
}
