import React from 'react'
import { IOrder, addItemTo1stBox, addItemToBox, setActiveItem, startBoxCreation } from '../../../../../../../../../store/slices/fbs'
import styles from './Order.module.scss'
import { DndContext, DragEndEvent, closestCenter, DragOverlay, DragStartEvent, useSensors, useSensor, MouseSensor, TouchSensor, KeyboardSensor } from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import DraggableItem from './DraggableItem'
import DroppableBox from './DroppableBox'
import { useAppDispatch, useAppSelector } from '../../../../../../../../../hooks/store';
import OrderItems from './OrderItems';
import arrowIcon from './assets/arrow.svg'
import plusIcon from './assets/plus.svg'

type Props = {
  order: IOrder
}

export default function Order({ order }: Props) {

  const dispatch = useAppDispatch()
  const { activeItem } = useAppSelector(state => state.fbs)

  // const [activeItem, setActiveItem] = useState<IItem | null>(null);

  const handleDragEnd = (event: DragEndEvent) => {
    dispatch(setActiveItem(null));
    if (event.over) {
      dispatch(addItemToBox({
        orderId: order.id,
        boxId: parseInt(event.over.id.toString()),
        itemId: event.active.id.toString()
      }))
    }
  }
  function handleDragStart(event: DragStartEvent) {
    const id = event.active.id.toString()
    const activeItem = order.items.find(i => i.id === id)
    dispatch(setActiveItem(activeItem || null));
  }

  const itemsInBoxes: string[] = []
  for (let i = 0; i < order.boxes.length; i++) {
    const box = order.boxes[i];
    itemsInBoxes.push(...box.itemIds)
  }

  const handleCreateBox = () => {
    // dispatch(createBox(order.id))
    // setBoxCreation(true)
    dispatch(startBoxCreation(order.id))
  }

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5,
      // delay: 1000
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 5,
      // delay: 1000
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor, {});
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const itemsInList = order.items.filter(item => itemsInBoxes.indexOf(item.id) === -1)
  const handleMoveAllItemsToBox = () => {
    for (const item of itemsInList) {
      dispatch(addItemTo1stBox({ itemId: item.id, orderId: order.id }))
    }
  }

  return (
    <div className={styles.order}>
      <div className={styles.order_number}>№{order.id}</div>
      <div className={styles.order_body}>
        <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} collisionDetection={closestCenter} sensors={sensors}>
          <div>
            <div className={styles.itemslist_title}><span>Не распределенные вещи</span> <img src={arrowIcon} className={styles.btn} onClick={handleMoveAllItemsToBox} /></div>
            <OrderItems
              // items={order.items.filter(item => itemsInBoxes.indexOf(item.id) === -1 && item.id !== activeItem?.id)}
              items={itemsInList}
              orderId={order.id}
            />
          </div>
          {/* <div className={styles.items}>
            {order.items.filter(item => itemsInBoxes.indexOf(item.id) === -1 && item.id !== activeItem?.id).map(item => <DraggableItem key={item.id} item={item} orderId={order.id} />)}
          </div> */}
          <div className={styles.line} />
          <div>
            <div className={styles.create_box_btn} onClick={handleCreateBox}><img src={plusIcon} /><span>Создать коробку</span></div>
            <div className={styles.boxes} >
              {order.boxes.map((box, index) =>
                // <React.Fragment key={box.id}>
                <DroppableBox key={box.id} box={box} order={order} />
                // {/* <div className={styles.line} /> */}
                // {/* </React.Fragment> */}
              )}
              {/* <div>
              <div className={styles.create_box_btn} onClick={handleCreateBox}><img src={plusIcon} /><span>Создать коробку</span></div>
            </div> */}
            </div>
          </div>
          <DragOverlay
            modifiers={[restrictToWindowEdges]}
            dropAnimation={{
              duration: 200,
              easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
            }}>
            {activeItem && <DraggableItem item={activeItem} orderId={order.id} className={styles.overlay} />}
          </DragOverlay>
        </DndContext>
      </div>
    </div>
  )
}
