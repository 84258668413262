const RouteBase = '/analytics/'

// export enum IndicatorType {
//   revenue = 'revenue',
//   new_clients = 'new_clients',
//   items_in_cart = 'items_in_cart',
//   number_of_checkouts = 'number_of_checkouts',
//   average_order = 'average_order',
// }
export enum IndicatorType {
  "AverageOrder" = "AverageOrder",
  "Clients" = "Clients",
  "OrdersCount" = "OrdersCount",  // number_of_checkouts
  "Sales" = "Sales",              // revenue
  "ItemInCart" = "ItemInCart",
}

type TabConfig = {
  id: IndicatorType
  label: string
  route: string
}

type IAnalyticsTabConfig = {
  [key in IndicatorType]: TabConfig
}

function buildTabRoute(routeBase: string, tabId: IndicatorType) {
  return routeBase + tabId
}

export const AnalyticsTabConfig: IAnalyticsTabConfig = {
  [IndicatorType.Sales]: {
    id: IndicatorType.Sales,
    label: 'Выручка',
    route: buildTabRoute(RouteBase, IndicatorType.Sales)
  },
  [IndicatorType.Clients]: {
    id: IndicatorType.Clients,
    label: 'Новые клиенты',
    route: buildTabRoute(RouteBase, IndicatorType.Clients)
  },
  [IndicatorType.ItemInCart]: {
    id: IndicatorType.ItemInCart,
    label: 'Товар в корзине',
    route: buildTabRoute(RouteBase, IndicatorType.ItemInCart)
  },
  [IndicatorType.OrdersCount]: {
    id: IndicatorType.OrdersCount,
    label: 'Оформление',
    route: buildTabRoute(RouteBase, IndicatorType.OrdersCount)
  },
  [IndicatorType.AverageOrder]: {
    id: IndicatorType.AverageOrder,
    label: 'Средний заказ',
    route: buildTabRoute(RouteBase, IndicatorType.AverageOrder)
  },
  // [IndicatorType.discount]: {
  //   id: IndicatorType.discount,
  //   label: 'Установка скидки',
  //   route: buildTabRoute(RouteBase, IndicatorType.discount)
  // },
  // [IndicatorType.edit]: {
  //   id: IndicatorType.edit,
  //   label: 'Редактирование цены',
  //   route: buildTabRoute(RouteBase, IndicatorType.edit)
  // },
  // [IndicatorType.history]: {
  //   id: IndicatorType.history,
  //   label: 'История',
  //   route: buildTabRoute(RouteBase, IndicatorType.history)
  // },
}