import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { FeedbackSorting, setSorting } from '../../../../../store/slices/feedback';
import styles from "./Reviews.module.scss";
import { CustomSelect_v2 } from '../../../../simples/CustomSelect_v2';

export type SortOptions = Array<{ label: string, value: FeedbackSorting }>

type Props = {
  options: SortOptions
}

export default function SortSelect({ options }: Props) {

  const dispatch = useAppDispatch()
  const value = useAppSelector(state => state.feedback.sorting)

  const handleChange = ({ value }) => {
    dispatch(setSorting(value))
  }

  return (
    <CustomSelect_v2 className={styles.sorting_select} name='sorting' options={options} value={value} onChange={handleChange} hideError />
    // <Select className={styles.sorting_select} name='sorting' options={options} value={value} onChange={handleChange} />
  )
}
