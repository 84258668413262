import { AxiosProgressEvent } from "axios";
import api from "./api";

type Answer = {
  success: boolean
}
type Props = {
  req: string
  onDownloadProgress?: (percent: number) => void
  onError?: (error: string) => void
  onFinish?: () => void
  defaultFileName?: string
}

// TODO refactoring
async function download(config: Props) {

  const { req, onDownloadProgress, defaultFileName, onError, onFinish } = config

  let answer: Answer = { success: false };
  await api
    .get<Blob>(req, {
      "responseType": "blob",
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
        const uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / (progressEvent?.total || 100)) * 100) as any,
        );
        onDownloadProgress && onDownloadProgress(uploadPercentage);
      }
    })
    .then((response) => {
      const url = window.URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = url;

      const filename = response.headers['content-disposition']?.split('filename=')[1].replaceAll('"', "") || defaultFileName || "template";

      link.setAttribute(
        "download",
        filename
      );
      document.body.appendChild(link);

      link.click();

      window.URL.revokeObjectURL(link.href);
      link.parentNode?.removeChild(link);
      answer.success = true;
    })
    .catch((err) => {
      onError && onError(err?.toString() || "error")
    }).finally(() => {
      onFinish && onFinish()
    });

  return answer;
}

export default download