import React from 'react'
import classNames from 'classnames'

import styles from './FieldTitle.module.scss'

type Props = {
  text?: string
  required?: boolean
  className?: string
}

export default function FieldTitle({text, required, className}: Props) {
  return (
    <div className={classNames(styles.title, className, {[styles.required]: required})}>{text}</div>
  )
}
