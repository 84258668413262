import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction, useCallback,
  useEffect, useMemo, useRef,
  useState,
} from "react";
import styles from "./LogoForm.module.scss";
import {ReactComponent as UploadIcon} from "../../assets/upload.svg";
import trash from "../../assets/trash.svg";
import classNames from "classnames";
import {BrandLogosService} from "../../../../../services/BrandLogosService";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../../store";
import {fetchLogoList} from "../../../../../store/slices/getters/logo-list";
import {UploadArea} from "./UploadArea";
import {ModalModeration} from "./Modal";

interface LogoFormProps {
  selectedId: number | null;
  setSelectedId: Dispatch<SetStateAction<number | null>>;
  upload: boolean;
  setUpload: Dispatch<SetStateAction<boolean>>;
  isUpdate: boolean;
}
const LogoForm: FC<LogoFormProps> = ({
  selectedId,
  setSelectedId,
  upload,
  setUpload,
  isUpdate
}) => {
  const { logoList } = useSelector((state: RootState) => state.logoList);
  const {createLogo, updateLogo, removeLogo} = BrandLogosService;

  const [title, setTitle] = useState<string>( "");
  const [preview, setPreview] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<undefined | File>();
  const [currentUpdate, setCurrentUpdate] = useState<boolean>(true);
  const [errorText, setErrorText] = useState("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  const titleInput = useRef<HTMLInputElement>(null);

  const current = useMemo(() => {
    return logoList.find(logo => logo.id === selectedId);
  }, [logoList, selectedId]);

  useEffect(() => {
    upload && setSelectedId(null);
  }, [upload]);

  useEffect(() => {
    if(isUpdate && current) {
      setTitle(current.name);
      // setSelectedFile(null);
      setPreview("");
    } else {
      setTitle("");
    }
  }, [isUpdate, selectedId]);

  useEffect(() => {
    if (current?.name !== title) {
      setCurrentUpdate(false);
    } else {
      setCurrentUpdate(true);
    }
  }, [title]);

  const changeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleUpload = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files !== null) {
      if (e.target.files.length < 1) {
        return;
      }
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", event => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement("img");
        if (typeof _loadedImageUrl === "string") {
          image.src = _loadedImageUrl;
        }
        image.addEventListener("load", () => {
          const { width, height, src } = image;
          setPreview(src);
          if(width > 600 || height > 600) {
            setPreview("");
            setSelectedFile(undefined);
            setErrorText("Некорректный формат изображения");
          }
        });
      });

      if(file.size > 1048576){
        setPreview("");
        setSelectedFile(undefined);
        setErrorText("Некорректный формат изображения");
      }

      if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg"){
        setSelectedFile(file);
      } else {
        setPreview("");
        setSelectedFile(undefined);
        setErrorText("Некорректный формат изображения");
      }
    }
  }, [selectedFile]);

  const handleUpdateLogo = () => {
    (selectedId && selectedFile) && updateLogo(selectedId, title, selectedFile);
  };

  const handleCreateLogo = () => {
    createLogo(title, selectedFile);
  };

  const handleModal = (type) => {
    setOpenModal(true);
  };

  const handleRemoveModal = () => {
    setModalType("delete");
    handleModal(modalType);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleReset = () => {
    setTitle("");
    setSelectedFile(undefined);
    setPreview("");
    setSelectedId(null);
    setErrorText("");
    setUpload(false);
  };

  const handleRemove = () => {
    selectedId && removeLogo(selectedId);
    handleCloseModal();
    handleReset();
  };

  const handleSubmit = () => {
    if (upload) {
      handleCreateLogo();
    } else if (isUpdate) {
      handleUpdateLogo();
      setSelectedId(null);
    }
    handleReset();
    setModalType("");
    handleModal(modalType);
  };

  useEffect(() => {
    dispatch(fetchLogoList);
  }, [dispatch, handleSubmit]);

  return (
    <div
      className={classNames(
        styles.upload_form,
        !isUpdate && !upload ? styles.disabled : "",
      )}>
      <input
        type="text"
        value={title}
        placeholder="Название бренда"
        ref={titleInput}
        onChange={changeTitle}
      />
      <div className={styles.selectLogo}>
        {upload && (
          <UploadArea info={"Загрузите изображение логотипа"}/>
        ) && (preview && <>
          <div className={styles.remove}>
            <button onClick={handleRemoveModal}>
              <img src={trash} alt="trash" />
            </button>
          </div>
          <img src={preview} alt="logo" />
        </>) || ( current ? (
          <>
            <div className={styles.remove}>
              <button onClick={handleRemoveModal}>
                <img src={trash} alt="trash" />
              </button>
            </div>
            <img src={!preview ? `${process.env.REACT_APP_API_URL}/${current.file}` : preview} alt="logo" />
          </>
        ) : (
          <UploadArea info = {"Выберите файл из списка справа"} />
        ))}
      </div>
      <input
        type="file"
        id="upload-btn"
        onChange={handleUpload}
        placeholder="Загрузить изображение"
        hidden
      />
      <div>
        <label className={classNames(styles.upload, classNames((!current && !upload) && styles.inactive))} htmlFor="upload-btn">
          <UploadIcon className={styles.icon}/>
          <p>
            {!isUpdate
              ? "Загрузить изображение"
              : "Загрузить новое изображение"}
          </p>
        </label>
      </div>

      <div className={styles.errorText}>
        {errorText ? errorText : "Размер изображения не должен превышать 1Mb"}
      </div>
      <div className={styles.buttons}>
        <button
          className={styles.submit}
          // disabled={(!title && !preview || currentUpdate) }
          disabled={((!title || !preview) || currentUpdate ) }
          onClick={handleSubmit}>
            Отправить на модерацию
        </button>
        <button
          className={styles.cancel}
          onClick={handleReset}
        >
            Отмена
        </button>
      </div>
      <ModalModeration type={modalType} isActive={openModal} handleSubmit={handleRemove} handleClose={handleCloseModal}/>
    </div>
  );
};

export default LogoForm;
