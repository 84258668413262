import React, { useState } from 'react'
import { CustomHint } from '../../../../simples/CustomHint'

import styles from './Remove.module.scss'
import { CustomTextarea, TextLengthStyles } from '../../../../simples/CustomTextarea'
import { BlueButton } from '../../../../simples/BlueButton'
import { ModalDialog } from '../../../../simples/ModalDialog'
import api from "../../../../../utils/api";
import { ToastContainer, toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { setIsDeleted } from '../../../../../store/slices/auth'

export default function Remove() {
  const [reason, setReason] = useState('')
  const [isConfirmActive, setIsConfirmActive] = useState(false)
  const isRemoved = useAppSelector(state => state.auth.user?.companyStatus?.deleted || false) // false //true

  const restoreSuccess = () => toast.success("Запрос на восстановление принят", { theme: "light" });
  const removeSuccess = () => toast.success("Запрос на удаление принят", { theme: "light" });
  const someError = () => toast.error("Ошибка", { theme: "light" });
  const dispatch = useAppDispatch()

  const handleChangeReason = (value: string) => {
    setReason(value)
  }
  const handleShowDialog = () => {
    setIsConfirmActive(true)
  }
  const handleCloseDialog = () => {
    setIsConfirmActive(false)
  }
  const handleRestore = () => {
    api.post<{ success: boolean }>('/companies/restore', { message: reason })
      .then((res) => {
        if (res.data.success) {
          restoreSuccess();
          handleCloseDialog();
          dispatch(setIsDeleted(false))
        }
        else { someError() }
      })
      .catch((err) => { alert(err); someError() });
  }
  const handleRemove = () => {
    api.post<{ success: boolean }>('/companies/delete', { message: reason })
      .then((res) => {
        if (res.data.success) {
          removeSuccess();
          handleCloseDialog();
          dispatch(setIsDeleted(true))
        }
        else { someError() }
      })
      .catch((err) => { alert(err); someError() });
  }

  return (
    <div className={styles.remove}>
      {/* <ToastContainer /> */}
      <ModalDialog isActive={isConfirmActive} text='Удалить аккаунт?'
        handleClose={handleCloseDialog}
        buttonsConfig={[{
          text: 'Да',
          onClick: handleRemove
        }, {
          text: 'Нет',
          onClick: handleCloseDialog
        }]} />
      <div className={styles.head}>
        <h1>Удаление аккаунта</h1><CustomHint hintWidth={470} hint='При удалении аккаунта у Вас будет возможность его восстановить в течении 180 дней с момента удаления' />
      </div>
      <div className={styles.info}>При удалении аккаунта необходимо завершить все сборочные задания; вывезти остатки FBO и баланс аккаунта, иметь нулевой остаток</div>
      <div className={styles.reason}>
        <CustomTextarea label='Укажите причину удаления аккаунта' maxLength={180} value={reason} onChange={handleChangeReason} showTextLength textLengthStyle={TextLengthStyles.v2} />
      </div>
      {isRemoved && <div className={styles.warning}>! Восстановить аккаунт можно в течение 180 дней</div>}
      <div className={styles.btns}>
        <BlueButton title='Восстановить аккаунт' onClick={handleRestore}
          disabled={!isRemoved}
        />
        <BlueButton title='Удалить аккаунт' onClick={handleShowDialog}
          disabled={isRemoved}
        />
      </div>
    </div >
  )
}
