import api from "../../../../../utils/api";
import React, { useState, useEffect } from 'react'
import { Endpoints } from '../../../../../constants/server';
import { ListDataType, ListHeadData, ListProductDataType } from '../../../../../types/productListTypes';
// import ProductList, { AvailableProductData } from '../../../../complexes/ProductList/ProductList';
import { BlueButton, ButtonSize, ButtonType } from '../../../../simples/BlueButton';
import { FilterBtn } from '../../../../simples/FilterBtn'

import styles from "./HistoryTab.module.scss"
import { ModalDialog } from "../../../../simples/ModalDialog";
import { HistoryList, AvailableHistoryFields, DiscountHistoryItem } from "./parts/HistoryList";
import ModalNotification from "../ModalNotification/ModalNotification";
// import { AvailableHistoryFields } from "./parts/HistoryList/HistoryList";
import { format } from 'date-fns';
import { DefaultHeaders } from "../../../../complexes/ProductList/DefaultHeaders";

const headersDiscount: ListHeadData[] = [{
  name: AvailableHistoryFields.url,
  text: "Фото",
  type: ListDataType.image,
  minWidth: "100px",
  width: "220px"
},
{
  ...DefaultHeaders.barcode,
  sortable: true,
},
//  {
//   name: AvailableHistoryFields.article,
//   text: "Артикул",
//   minWidth: "100px",
//   width: "220px"
// }, 
{
  name: AvailableHistoryFields.name,
  text: "Наименование",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.price,
  text: "Цена",
  sortable: true,
  minWidth: "95px",
  width: "215px"
}, {
  name: AvailableHistoryFields.actual_discount,
  text: "Актуальная скидка",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.discount_price,
  text: "Цена со скидкой",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.period_string,
  text: "Период действия",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.period,
  // text: "Время действия в днях",
  text: "Время действия",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.discount_status_name,
  text: "Статус",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.value_remains,
  text: "Остаток на складе",
  sortable: true,
  minWidth: "135px",
  width: "255px"
}, {
  name: AvailableHistoryFields.discount_value,
  text: "Участвует в скидке",
  sortable: true,
  minWidth: "135px",
  width: "255px"
}, {
  name: AvailableHistoryFields.brand,
  text: "Бренд",
  sortable: true,
  minWidth: "85px",
  width: "205px"
}, {
  name: AvailableHistoryFields.gender,
  text: "Пол",
  sortable: true,
  minWidth: "55px",
  width: "175px"
}, {
  name: AvailableHistoryFields.category,
  text: "Категория",
  sortable: true,
  minWidth: "110px",
  width: "230px"
}, {
  name: AvailableHistoryFields.subcategory,
  text: "Подкатегория",
  sortable: true,
  minWidth: "130px",
  width: "250px"
}, {
  name: AvailableHistoryFields.sizes,
  text: "Размер",
  minWidth: "75px",
  width: "195px"
}, {
  name: AvailableHistoryFields.seasons,
  text: "Сезон",
  minWidth: "75px",
  width: "195px"
  // }, {
  //   name: AvailableHistoryFields.barcodes,
  //   text: "Штрихкод",
  //   minWidth: "110px",
  //   width: "130px"
}, {
  name: AvailableHistoryFields.created_at,
  text: "Дата создания",
  sortable: true,
  minWidth: "110px",
  width: "230px"
}];


const headersPrice: ListHeadData[] = [{
  name: AvailableHistoryFields.url,
  text: "Фото",
  type: ListDataType.image,
  minWidth: "100px",
  width: "220px"
},
{
  ...DefaultHeaders.barcode,
  sortable: true,
},
//  {
//   name: AvailableHistoryFields.article,
//   text: "Артикул",
//   minWidth: "100px",
//   width: "220px"
// }, 
{
  name: AvailableHistoryFields.name,
  text: "Наименование",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.preview_price,
  text: "Предыдущая цена",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.diff_preview_price_and_price_value,
  text: "Изменение цены",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.diff_preview_price_and_price_percents,
  text: "Изменение цены (%)",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.price,
  text: "Новая цена",
  sortable: true,
  minWidth: "95px",
  width: "215px"
}, {
  name: AvailableHistoryFields.period_string,
  text: "Период действия",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.period,
  // text: "Время действия в днях",
  text: "Время действия",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.price_status_name,
  text: "Статус",
  sortable: true,
  minWidth: "140px",
  width: "260px"
}, {
  name: AvailableHistoryFields.value_remains,
  text: "Остаток на складе",
  sortable: true,
  minWidth: "135px",
  width: "255px"
}, {
  name: AvailableHistoryFields.discount_value,
  text: "Участвует в скидке",
  sortable: true,
  minWidth: "135px",
  width: "255px"
}, {
  name: AvailableHistoryFields.brand,
  text: "Бренд",
  sortable: true,
  minWidth: "85px",
  width: "205px"
}, {
  name: AvailableHistoryFields.gender,
  text: "Пол",
  sortable: true,
  minWidth: "100px",
  width: "200px"
}, {
  name: AvailableHistoryFields.category,
  text: "Категория",
  sortable: true,
  minWidth: "110px",
  width: "230px"
}, {
  name: AvailableHistoryFields.subcategory,
  text: "Подкатегория",
  sortable: true,
  minWidth: "130px",
  width: "250px"
}, {
  name: AvailableHistoryFields.sizes,
  text: "Размер",
  minWidth: "75px",
  width: "195px"
}, {
  name: AvailableHistoryFields.seasons,
  text: "Сезон",
  minWidth: "75px",
  width: "195px"
  // }, {
  //   name: AvailableHistoryFields.barcodes,
  //   text: "Штрихкод",
  //   minWidth: "110px",
  //   width: "130px"
}, {
  name: AvailableHistoryFields.created_at,
  text: "Дата создания",
  sortable: true,
  minWidth: "110px",
  width: "230px"
}];




type GoodsAnswer = {
  success: boolean;
  data?: Array<DiscountHistoryItem>;
};

enum DiscountType {
  all = "all",
  activeNow = "activeNow",
  planned = "planned",
  completed = "completed"
}

export default function History() {

  const [products, setProducts] = useState<DiscountHistoryItem[]>([])
  const [discountTypes, setDiscountTypes] = useState<DiscountType[]>([])
  const [showDiscount, setShowDiscount] = useState(true)
  const [showCancelDiscountDialog, setShowCancelDiscountDialog] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState<string[]>([])
  const [notificationText, setNotificationText] = useState("")
  const [notificationTitle, setNotificationTitle] = useState("")

  const errors = []

  const loadProducts = () => {
    setProducts([])
    let req = (showDiscount ? `${Endpoints.DISCOUNT_HISTORY}` : `${Endpoints.PRODUCTS_PRICES_HISTORY}`) + "?";
    const now = format(new Date(), "yyyy-MM-dd")
    if (discountTypes.length === 1) {
      switch (discountTypes[0]) {
        case DiscountType.activeNow:
          req += `to_start_date=${now}&from_finish_date=${now}`
          break;
        case DiscountType.planned:
          req += `from_start_date=${now}`
          break;
        case DiscountType.completed:
          req += `to_finish_date=${now}`
          break;
        default:
          break;
      }
    }
    api
      .get<GoodsAnswer>(req)
      .then((res) => {
        setProducts(res.data?.data || [])
      })
      .catch((err) => console.log(err));
  }

  useEffect(loadProducts, [discountTypes, showDiscount])

  const handleRemoveFilter = (fid) => {
    // setDiscountTypes(discountTypes.filter(dt => dt !== fid))
    setDiscountTypes([])
  }
  const handleAddFilter = (fid) => {
    // setDiscountTypes([...discountTypes, fid])
    setDiscountTypes([fid])
  }
  const handleResetFilter = () => {
    setDiscountTypes([])
  }
  const handleShowCancelDisountDialog = () => {
    setShowCancelDiscountDialog(true)
  }
  const handleCloseCancelDisountDialog = () => {
    setShowCancelDiscountDialog(false)
  }
  const handleRemoveDisount = () => {
    setShowCancelDiscountDialog(false)
    const ids = selectedProducts
    api.post(Endpoints.REMOVE_DISCOUNT, { ids })
      .then(async (res) => {
        setNotificationText(res?.data?.data?.toString() || "")
        loadProducts()
      })
      .catch((err) => {
        // const errorText = err?.response?.data?.errors || err.toString()
        // setNotificationText(JSON.stringify(errorText))
        setNotificationText(err?.response?.data?.errorText?.text || "Ошибка на стороне сервера")
        setNotificationTitle(err?.response?.data?.errorText?.title)
        // setNotificationText("text")
        // setNotificationTitle("title")
      })
  }
  const handleCloseModalNotification = () => {
    setNotificationText("")
    setNotificationTitle("")
  }
  const handleSetShowDiscount = () => {
    setShowDiscount(true)
  }
  const handleSetShowPrices = () => {
    setShowDiscount(false)
  }
  const handleSetSelectedProducts = (ids: string[]) => {
    setSelectedProducts([...selectedProducts, ...ids])
  }
  const handleDeselectProducts = (ids: string[]) => {
    setSelectedProducts([...selectedProducts.filter(id => ids.indexOf(id) === -1)])
  }

  return (
    <div className={styles.wrap}>
      <ModalNotification text={notificationText} title={notificationTitle} onClose={handleCloseModalNotification} />
      <ModalDialog isActive={showCancelDiscountDialog}
        text="Отменить выбранную скидку?"
        buttonsConfig={[{
          text: "Да",
          type: ButtonType.Yes,
          onClick: handleRemoveDisount,
          size: ButtonSize.Big
        }, {
          text: "Нет",
          type: ButtonType.Cancel,
          onClick: handleCloseCancelDisountDialog,
          size: ButtonSize.Big
        }]}
        handleClose={handleCloseCancelDisountDialog} />
      <div className={styles.filters}>
        <span className={styles.title}>Фильтры</span>
        <div className={styles.filters_wrap}>
          <FilterBtn fid={DiscountType.all} text='Все' onCheck={handleResetFilter} onClickRemove={handleRemoveFilter} checked={discountTypes.length === 0} />
          <FilterBtn fid={DiscountType.activeNow} text='Действующие' onCheck={handleAddFilter} onClickRemove={handleRemoveFilter} checked={discountTypes.indexOf(DiscountType.activeNow) !== -1} />
          <FilterBtn fid={DiscountType.planned} text='Планируемые' onCheck={handleAddFilter} onClickRemove={handleRemoveFilter} checked={discountTypes.indexOf(DiscountType.planned) !== -1} />
          <FilterBtn fid={DiscountType.completed} text='Завершённые' onCheck={handleAddFilter} onClickRemove={handleRemoveFilter} checked={discountTypes.indexOf(DiscountType.completed) !== -1} />
        </div>
      </div>
      <div className={styles.view_selectors}>
        <span className={styles.title}>Отображать</span>
        <div className={styles.filters_wrap}>
          <FilterBtn fid='all' text='Скидки' onCheck={handleSetShowDiscount} onClickRemove={handleSetShowPrices} checked={showDiscount} />
          <FilterBtn fid='all' text='Цены' onCheck={handleSetShowPrices} onClickRemove={handleSetShowDiscount} checked={!showDiscount} />
        </div>
      </div>
      {/* <ProductList
        data={[]} headers={showDiscount ? headersDiscount : headersPrice} errors={errors}
        className={styles.products_list}
      /> */}
      <HistoryList
        data={products} headers={showDiscount ? headersDiscount : headersPrice} errors={errors}
        selectedProducts={selectedProducts}
        selectProducts={handleSetSelectedProducts}
        deselectProducts={handleDeselectProducts}
        className={styles.products_list}

      />
      <div className={styles.controls}>
        <BlueButton disabled={products.length === 0} onClick={handleShowCancelDisountDialog} title='Отменить скидку' />
      </div>
    </div>
  )
}
