import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { CategorySelectorState } from "../../components/pages/ProductsImportTable/parts/CategorySelector";
import MassImportEditService from "../../services/MassImportEditService";
import { CategorySelectTypes } from "../../types/selectOptions";
import { ProductsDTO } from "../../types/swagger/llyApi_new";

export interface MassImportState {
  isFakeBarcodes: boolean; // TODO remove
  sendToModerateAnswer: {
    moderate: number;
    saved: number;
  } | null;
  isFetchingTemplate: boolean;
  fetchingTemplateProgress: number;
  fetchingTemplateError?: string;
  downloadingProgress: number;

  isUploadingArch: boolean;
  uploadingProgress: number;
  uploadingFileName?: string;
  uploadingError?: string;
  importedData: ProductsDTO[];
  importedDataErrors: string[];

  templateCategory: number;
  templateSelectorState: CategorySelectorState;
}

export const initialState: MassImportState = {
  isFakeBarcodes: false,
  sendToModerateAnswer: null,
  isFetchingTemplate: false,
  isUploadingArch: false,
  fetchingTemplateProgress: 0,
  uploadingProgress: 0,
  downloadingProgress: 0,
  templateCategory: 33,
  templateSelectorState: {
    values: {},
    options: {
      [CategorySelectTypes.for]: [],
      [CategorySelectTypes.type]: [],
      [CategorySelectTypes.category]: [],
      [CategorySelectTypes.subcategory]: [],
    }
  },
  importedData: [],
  importedDataErrors: []
};

export interface FetchGoodsRequest {
  categoryID: number;
  searchRequest?: string;
}

export const fetchTemplate = createAsyncThunk<boolean, void, { state: RootState }>(
  "MassImport/fetchTemplate", async (_, { getState, dispatch, rejectWithValue }) => {

    const state = getState();
    const category = getSelectedSubcategory(state);

    if (!category) throw new Error("category not selected");

    const data = await MassImportEditService.getArchTemplate({
      category,
      onDownloadProgress(percent) {
        dispatch(setDownloadProgress(percent));
      }
    });
    if (!data || !data.success) {
      rejectWithValue(data?.error || "error");
    }
    return data.success;
  }
);
export const uploadArch = createAsyncThunk<any, { formDataFile: File }, { state: RootState }>(
  "MassImport/uploadArch", async ({ formDataFile }, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {

    const state = getState();

    const category = getSelectedSubcategory(state);
    if (!category) throw new Error("category not selected");

    dispatch(setUploadingFileName(formDataFile.name));

    const response = await MassImportEditService.uploadArch({
      category,
      formDataFile,
      onUploadProgress(percent) {
        dispatch(setUploadingProgress(percent));
      },
    })

    if (response.success) {
      return fulfillWithValue(response.response?.data);
    } else {
      return rejectWithValue(response.response?.message || response.error)
    }
  }
);

export const sendToModerate = createAsyncThunk<any, void, { state: RootState, }>(
  "MassImport/sendToModerate", async (_, { fulfillWithValue, rejectWithValue }) => {
    const response = await MassImportEditService.sendToModerate();
    if (response.success) {
      return fulfillWithValue(response?.data);
    } else {
      return rejectWithValue(response.error || "Error!");
    }
  }
);


export const massImportSlice = createSlice({
  name: "massImport",
  initialState,
  reducers: {
    setFakeBarcodes: (state, action) => {
      state.isFakeBarcodes = action.payload;
    },
    closeInfoDialog: (state) => {
      state.sendToModerateAnswer = null;
    },
    setTemplateCategory: (state, action) => {
      state.templateCategory = action.payload;
    },
    setTemplateSelectorState: (state, action) => {
      state.templateSelectorState = action.payload;
    },
    setUploadingProgress: (state, action) => {
      state.uploadingProgress = action.payload;
    },
    setDownloadProgress: (state, action) => {
      state.downloadingProgress = action.payload;
    },
    setUploadingFileName: (state, action) => {
      state.uploadingFileName = action.payload;
    },
    clearUploadedData: (state) => {
      state.uploadingFileName = "";
      state.importedData = [];
      state.importedDataErrors = [];
      state.isFakeBarcodes = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplate.pending, (state) => {
      state.isFetchingTemplate = true;
      state.fetchingTemplateError = "";
    });
    builder.addCase(fetchTemplate.fulfilled, (state, action) => {
      state.isFetchingTemplate = false;
    });
    builder.addCase(fetchTemplate.rejected, (state) => {
      state.isFetchingTemplate = false;
      state.fetchingTemplateError = "";
    });

    builder.addCase(uploadArch.pending, (state) => {
      state.isUploadingArch = true;
      state.uploadingProgress = 0;
      state.uploadingError = "";
      state.importedData = [];
      state.importedDataErrors = [];
      state.isFakeBarcodes = false;
    });
    builder.addCase(uploadArch.fulfilled, (state, action) => {
      state.isUploadingArch = false;
      state.importedData = action.payload?.products || [];
      state.importedDataErrors = action.payload?.errors || [];
      // if (action.payload) { }
    });
    builder.addCase(uploadArch.rejected, (state, action) => {
      state.isUploadingArch = false;
      state.uploadingError = action.payload as string || "Error!";
    });

    // builder.addCase(sendToModerate.pending, (state) => {
    // });
    builder.addCase(sendToModerate.fulfilled, (state, action) => {
      state.sendToModerateAnswer = action.payload;
      state.importedData = [];
      state.importedDataErrors = [];
      state.isFakeBarcodes = false;
    });
    builder.addCase(sendToModerate.rejected, (state, action) => {
      alert("Error!"); // TODO remove
    });
  },
});

const getSelectedSubcategory = (state: RootState) =>
  state.massImport.templateSelectorState.values[CategorySelectTypes.subcategory];

export const selectedSubcategorySelector = () => getSelectedSubcategory;
export const getUploadingProgress = () => (state: RootState) => state.massImport.uploadingProgress;
export const getUploadingStatus = () => (state: RootState) => state.massImport.isUploadingArch;
export const getUploadingError = () => (state: RootState) => state.massImport.uploadingError;
export const getImportedData = () => (state: RootState) => state.massImport.importedData;
export const getImportedDataErrors = () => (state: RootState) => state.massImport.importedDataErrors;
export const getUploadingFileName = () => (state: RootState) => state.massImport.uploadingFileName;

export const getDownloadProgress = () => (state: RootState) => state.massImport.downloadingProgress;
export const getDownloadStatus = () => (state: RootState) => state.massImport.isFetchingTemplate;

export const {
  clearUploadedData, closeInfoDialog, setFakeBarcodes,
  setTemplateCategory, setTemplateSelectorState, setUploadingProgress, setDownloadProgress, setUploadingFileName
} = massImportSlice.actions;



export default massImportSlice.reducer;