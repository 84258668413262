import React from 'react'
import { Categories } from '../../../../../../../types/swagger/llyApi'

import styles from "./ProductCategory.module.scss"

import arrow from "./assets/arrow.svg"
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/store'
import { getCategory, getOpenedCategories, setOpenedCategories } from '../../../../../../../store/slices/sellProduct'
import { Progress } from '../../../../../../simples/Progress'

// TODO remove фикс, в сваггере не прописано поле
export interface MyCategories extends Omit<Categories, "category"> {
  children?: Categories[]
}


type Props = {
  id?: number
  lvl: number
}

export default function Category({ id, lvl }: Props) {

  const dispatch = useAppDispatch();
  const openedCategories = useAppSelector(getOpenedCategories());
  const category = useAppSelector(getCategory(id || Infinity)) as MyCategories;  // TODO remove  as MyCategories

  if (!id) return <></>

  const handleClick = () => {
    if (openedCategories.indexOf(id || Infinity) === -1) {
      dispatch(setOpenedCategories([...openedCategories, id]))
    } else {
      dispatch(setOpenedCategories(openedCategories.filter(cid => cid !== id)))
    }
  }

  const isOpen = category.id && openedCategories.indexOf(category.id) !== -1
  const isEmpty = !category || !category.children || category.children.length === 0

  return (
    <div className={classNames(styles.category, styles['lvl_' + lvl], { [styles.open]: isOpen })}>
      <div className={classNames(styles.title, { [styles.empty]: isEmpty })} onClick={handleClick}>
        {category.children && category.children.length > 0 && <img src={arrow} /> || <div className={styles.gag}></div>}
        <div className={styles.data}>
          <span>{category.name}</span>
          <div className={styles.progress}>
            <span className={styles.percent}>{category.workload}%</span>
            <Progress className={styles.progress_bar} value={category.workload || 0} />
          </div>
        </div>
      </div>
      {isOpen && category.children && <div className={styles.body}>
        <div>{category.children.map(c => <Category key={c.id} id={c.id} lvl={lvl + 1} />)}</div>
      </div>}
    </div>
  )
}
