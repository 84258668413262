import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { fetchUserData } from "../../store/slices/auth";
import { AppRoutes } from "../AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const dispatch = useAppDispatch();
  const { isAuth, isLoadingData } = useAppSelector(state => state.auth);

  useEffect(() => {
    localStorage.getItem("refresh_token") && dispatch(fetchUserData());
  }, [dispatch]);

  return <div>
    <ToastContainer />
    {isLoadingData
      ? <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Загрузка...</div>
      : <AppRoutes isAuth={isAuth} />
    }
  </div>;
}

export default App;
