import classNames from "classnames";
import React from "react";
import styles from './PinkErrorText.module.scss';

type Props = {
  title: string;
  isShow: boolean;
  className?: string;
};

const PinkErrorText = ({ title, isShow, className }: Props) => (
  <>{isShow && <h4 className={classNames(styles.error, className)}>! {title}</h4>}</>
);

export default PinkErrorText;
