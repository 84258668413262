import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/store';
import { fetchCategories, getCategories, getGagText, getOpenedCategories, getServerError, setServerError } from '../../../../../../../store/slices/sellProduct';
import { DebouncedInput } from '../../../../../../complexes/DebouncedInput';
import { ModalServerError, ServerError } from '../../../../../../complexes/ModalServerError';
import Category from './Category';

import search_icon from "./assets/search.svg"

import styles from "./ProductCategory.module.scss"

export default function ProductCategory() {

  const dispatch = useAppDispatch();
  const [searchText, setsearchText] = useState("")
  const categories = useAppSelector(getCategories());
  const gagText = useAppSelector(getGagText());
  const serverError = useAppSelector(getServerError());

  useEffect(() => {
    dispatch(fetchCategories({ text: searchText }));
  }, [searchText])

  const handleCloseErrorDialog = () => {
    dispatch(setServerError(undefined))
  }

  return (<div className={styles.wrap}>
    <ModalServerError error={serverError} onClose={handleCloseErrorDialog} />
    <div className={styles.search}>
      <DebouncedInput className={styles.input} debounceDelay={200} onChange={setsearchText} value={searchText} placeholder="Поиск" icon={search_icon} />
      <span className={styles.text}>Наполненность LL</span>
    </div>
    <div className={styles.content}>
      {(categories && categories.length > 0)
        ? categories.map(c => <Category key={c.id} lvl={0} id={c.id}  />)
        : <div>{gagText}</div>
      }
    </div>
  </div>)
}
