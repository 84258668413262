import React from "react";
import classNames from "classnames";
import styles from "./ParagraphButton.module.scss";

type Props = {
  title: string;
  onPress: () => void;
  disabled: boolean;
  className?: string;
};

const ParagraphButton = ({ title, onPress, disabled, className }: Props) => {
  const classes = classNames(
    styles.button,
    disabled && styles.disabledButton,
    className
  );

  return <button className={classes} onClick={onPress}>{title}</button>;
};

export default ParagraphButton;
