import React, { FC } from "react";
import { useAppSelector } from "../../../../../../../hooks/store";
import styles from "./Tags.module.scss";

interface Props {
    showTagsField: (evt: React.MouseEvent) => void;
}

const Tags: FC<Props> = ({ showTagsField }) => {

    const allTags = useAppSelector((state) => state.product.commonData.tags);
    const selectedTags = useAppSelector((state) => state.product.tags.values);
    const readonly = useAppSelector(state => state.product.isReadonlyMode)

    const tags = allTags.filter(t => selectedTags.indexOf(t.id) !== -1)


    return (
        <div className={styles.wrapper}>
            <ul className={styles.list}>
                {tags.map((tag) => <li key={`tag_${tag.id}`}>{"#" + tag.name}</li>)}
            </ul>
            {!readonly && <button onClick={showTagsField} className={styles.btn}>
                Показать все теги
            </button>}
        </div>
    );
};

export default React.memo(Tags);