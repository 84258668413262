import React, { useEffect, useState } from 'react'
import { ModalDialog } from '../../../../simples/ModalDialog'
import { Checkbox } from '../../../../simples/Checkbox'
import { BlueButton } from '../../../../simples/BlueButton'
import styles from './CP.module.scss'
import classNames from 'classnames'
import CodeInput from '../../../../simples/CodeInput/CodeInput'
import api from '../../../../../utils/api'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { CustomTextInput_v2, IOnChange } from '../../../../simples/CustomTextInput_v2'
import successIcon from './assets/success.svg'
import { EmailRegexp, PhoneRegexp } from '../../../../../utils/employees'

export enum ChangeMode {
  email = 'email',
  phone = 'phone'
}

type Props = {
  oldPhone: string
  onClose: () => void
  oldEmail: string
  changeMode?: ChangeMode
}



enum Step {
  start = 'start',
  code = 'code',
  newPhone = 'newPhone',
  confirmNewPhone = 'confirmNewPhone',
  newPhoneSuccess = 'newPhoneSuccess',
  newEmailSuccess = 'newEmailSuccess',
}

const codeSendedError = () => toast.error("Ошибка при отправке кода", { theme: "light" });
const codeCheckError = () => toast.error("Ошибка при проверке кода", { theme: "light" });
const changeEmailError = () => toast.error("Ошибка при изменении почты", { theme: "light" });
const supportMsgSended = () => toast.warn("TODO: добавить отправку сообщения", { theme: "light" });

// export const SMS_SEND_DELAY = 0.2 * 60 * 1000
export const SMS_SEND_DELAY = 2 * 60 * 1000

export default function ChangePhoneEmail({ oldPhone, onClose, oldEmail, changeMode }: Props) {

  const [step, setStep] = useState<Step>(Step.start)
  const [lostPhone, setLostPhone] = useState(false)
  const [code, setCode] = useState<string[]>(['', '', '', ''])
  const [isWrongCode, setIsWrongCode] = useState(false)
  const [codeConfirmed, setCodeConfirmed] = useState(false)
  const [isCheckingCode, setIsCheckingCode] = useState(false)
  const [isLoadingCode, setIsLoadingCode] = useState(false)
  const [lastSmsTS, setLastSmsTS] = useState(0)
  const [canSendSms, setCanSendSms] = useState(true)
  const [timeToNextSMS, setTimeToNextSMS] = useState(0)
  const [newPhone, setNewPhone] = useState('')
  const [newEmail, setNewEmail] = useState('')

  useEffect(() => {
    // очистить
    setStep(Step.start)
    setLostPhone(false)
    setCode(['', '', '', ''])
    setIsWrongCode(false)
    setCodeConfirmed(false)
    setIsCheckingCode(false)
    setNewPhone('')
    setNewEmail('')
  }, [])

  useEffect(() => {
    const timeout = lastSmsTS + SMS_SEND_DELAY - Date.now()

    const tid = setTimeout(() => {
      setCanSendSms(true)
    }, timeout);
    console.log(timeout, canSendSms);


    const iid = setInterval(() => {
      let timeout = lastSmsTS + SMS_SEND_DELAY - Date.now()
      if (timeout < 0) {
        timeout = 0
        clearInterval(iid)
      }
      setTimeToNextSMS(timeout)
    }, 1000)

    return () => {
      clearTimeout(tid)
      clearInterval(iid)
    }
  }, [lastSmsTS])


  const handleToggleLostPhone = () => {
    setLostPhone(!lostPhone)
  }

  // function checkCode(code: string[]) {
  //   setIsCheckingCode(true)
  //   setCanSendSms(false)
  //   api.post<{ success: boolean }>('/user/main-phone-confirm', { code: code.join('') })
  //     .then(response => {
  //       if (response?.data?.success) {
  //         // setCode(['', '', '', ''])
  //         setIsWrongCode(false)
  //         setCodeConfirmed(true)
  //       } else {
  //         setIsWrongCode(true)
  //         setIsCheckingCode(false)
  //       }
  //     }).catch(err => {
  //       codeCheckError()
  //       console.log(err);
  //     })
  //   // setCodeConfirmed(true) // TODO REMOVE!!!
  // }

  function reqCode() {
    if (lostPhone) {
      supportMsgSended()
      onClose()
      return
    }
    if (!canSendSms) return
    setCanSendSms(false)
    setLastSmsTS(Date.now())
    setIsLoadingCode(true)
    api.get<{ success: boolean }>('/user/main-phone-confirm').then((data) => {
      if (data.data.success) {
        setCodeConfirmed(false)
        setIsCheckingCode(false)
        setCode(['', '', '', ''])
        setStep(Step.code)
      } else {
        codeSendedError()
      }
      // setStep(Step.code) // TODO REMOVE!!!
      setIsLoadingCode(false)
    }).catch(console.log)
  }
  function reqNewPhoneCode() {
    if (!canSendSms) return
    setLastSmsTS(Date.now())
    setIsLoadingCode(true)
    setCanSendSms(false)

    api.get<{ success: boolean }>(`/user/phone-confirm?phone=${newPhone.replaceAll(/[^0-9]*/g, '')}`).then((data) => {
      if (data.data.success) {
        setCodeConfirmed(false)
        setCode(['', '', '', ''])
        setIsCheckingCode(false)
        setStep(Step.confirmNewPhone)
      } else {
        codeSendedError()
      }
      setIsLoadingCode(false)
    }).catch(e => {
      codeSendedError()
      console.log(e)
    })
    // .finally(() => {
    //   setStep(Step.confirmNewPhone) // TODO REMOVE!!!
    //   setIsCheckingCode(false) // TODO REMOVE!!!
    // })
  }


  function changeEmail() {
    api.post<{ success: boolean }>('/user/mail-confirm', { email: newEmail })
      .then(response => {
        console.log(response?.data?.success);
        if (response?.data?.success) {
          setStep(Step.newEmailSuccess)
        } else {
          changeEmailError()
        }
      }).catch(err => {
        console.log(err);
        changeEmailError()
      })
    // setStep(Step.newEmailSuccess) // TODO REMOVE!!!
  }


  let content: JSX.Element
  const warningText = changeMode === ChangeMode.email ? '! Основную почту можно изменить один раз в месяц' : '! Основной номер можно изменить один раз в месяц'

  switch (step) {
    case Step.newEmailSuccess: {
      content = <div className={classNames(styles.content, { [styles.lost_phone]: lostPhone })}>
        <h1>Подтвердите почту</h1>
        <div className={styles.warning}>{warningText}</div>
        <div className={styles.email_sended_info}>На почту <span className={styles.email}>{newEmail}</span> было выслано письмо. Перейдите по ссылке, что бы подтвердить ее</div>
        <BlueButton title='Далее' onClick={onClose} />
      </div >
      break;
    }
    case Step.newPhoneSuccess: {
      const handleClose = () => {
        onClose()
      }
      content = <div className={classNames(styles.content, { [styles.lost_phone]: lostPhone })}>
        <img src={successIcon} alt="" />
        <div className={styles.success_text}>Ваш телефон изменён</div>
        <BlueButton title='Далее' onClick={handleClose} />
      </div >
      break;
    }
    case Step.newPhone: {
      const handleSendNewPhone = () => {
        if (changeMode === ChangeMode.email) {
          changeEmail()
        } else {
          reqNewPhoneCode()
        }
      }
      const handleChangeNewPhone = ({ value }: IOnChange) => {
        setNewPhone(value.toString())
      }
      const handleChangeNewEmail = ({ value }: IOnChange) => {
        setNewEmail(value.toString())
      }

      const emailHasError = !EmailRegexp.test(newEmail)
      const phoneHasError = !PhoneRegexp.test(newPhone)
      const hasError = changeMode === ChangeMode.email ? emailHasError : phoneHasError

      // return (<ModalDialog buttonsConfig={[]} isActive={isActive} hasCloseBtn hideBtns>
      content = <div className={classNames(styles.content, { [styles.lost_phone]: lostPhone })}>
        <h1>{changeMode === ChangeMode.email ? 'Введите новую почту' : 'Введите новый номер'}</h1>
        <div className={styles.warning}>{warningText}</div>
        {changeMode === ChangeMode.email
          ? <CustomTextInput_v2 name='newEmail' onChange={handleChangeNewEmail} value={newEmail} className={styles.new_email} />
          : <CustomTextInput_v2 name='newPhone' onChange={handleChangeNewPhone} value={newPhone} className={styles.new_phone}
            placeholder={"+7 (___) ___-__-__"} mask={"+7 (999) 999-99-99"} />}
        <BlueButton title='Далее' disabled={!canSendSms || hasError} onClick={handleSendNewPhone} />
      </div >
      break;
      {/* </ModalDialog >) */ }
    }

    case Step.confirmNewPhone: {
      const handleChangeCode = (code: string[]) => {
        setCode(code)
        setIsWrongCode(false)
        if (code.indexOf('') === -1) {
          setIsCheckingCode(true)
          setCanSendSms(false)
          api.post<{ success: boolean }>('/user/phone-confirm', { phone: newPhone.replaceAll(/[^0-9]*/g, ''), code: code.join('') })
            .then(response => {
              if (response?.data?.success) {
                // setCode(['', '', '', ''])
                setIsWrongCode(false)
                setCodeConfirmed(true)
                setLastSmsTS(0)
                setCanSendSms(true)
              } else {
                setIsWrongCode(true)
                setCode(['', '', '', ''])
                setIsCheckingCode(false)
                // setCanSendSms(true)
              }
            }).catch(err => {
              codeCheckError()
              console.log(err);
            })
        }
      }

      const showNewPhoneSuccess = () => {
        setStep(Step.newPhoneSuccess)
      }

      // return (<ModalDialog buttonsConfig={[]} isActive={isActive} hasCloseBtn hideBtns>
      content = <div className={classNames(styles.content, { [styles.lost_phone]: lostPhone })}>
        <h1>Введите код</h1>
        <div className={styles.info}>Введите код высланный на номер {newPhone}</div>
        <CodeInput className={styles.code_input} code={code} onChange={handleChangeCode} disabled={isCheckingCode} />
        <div className={classNames(styles.warning, styles.wrong_code)}>{isWrongCode ? '! Код введён неверно' : ''}</div>
        <div className={styles.send_sms_timer}>{canSendSms ? '' : `Отправить SMS-код можно через ${format(timeToNextSMS, 'mm:ss')}`}</div>
        <div className={styles.resend_sms_btn} onClick={reqCode}>{(canSendSms && !codeConfirmed) ? 'Отправить SMS-код' : ''}</div>
        <BlueButton title='Далее' disabled={!codeConfirmed} onClick={showNewPhoneSuccess} />
      </div >
      break;
      {/* </ModalDialog >) */ }
    }

    case Step.code: {
      const handleChangeCode = (code: string[]) => {
        setCode(code)
        setIsWrongCode(false)
        if (code.indexOf('') === -1) {
          setIsCheckingCode(true)
          setCanSendSms(false)
          setLastSmsTS(Date.now())
          api.post<{ success: boolean }>('/user/main-phone-confirm', { code: code.join('') })
            .then(response => {
              if (response?.data?.success) {
                // setCode(['', '', '', ''])
                setIsWrongCode(false)
                setCodeConfirmed(true)
                setLastSmsTS(0)
                setCanSendSms(true)
              } else {
                setIsWrongCode(true)
                setCode(['', '', '', ''])
                setIsCheckingCode(false)
                // setCanSendSms(true)
              }
            }).catch(err => {
              codeCheckError()
              console.log(err);
            })
          //  // setCodeConfirmed(true) // TODO REMOVE!!!
        }
      }

      const showNewPhoneUI = () => {
        setCanSendSms(true)
        setLastSmsTS(0)
        setCode(['', '', '', ''])
        setStep(Step.newPhone)
      }

      // return (<ModalDialog buttonsConfig={[]} isActive={isActive} hasCloseBtn hideBtns>
      content = <div className={classNames(styles.content, { [styles.lost_phone]: lostPhone })}>
        <h1>{changeMode === ChangeMode.email ? 'Изменить почту' : 'Изменить основной номер'}</h1>
        <div className={styles.info}>Введите код высланный на номер {oldPhone}</div>
        <CodeInput className={styles.code_input} code={code} onChange={handleChangeCode} disabled={isCheckingCode} />
        <div className={classNames(styles.warning, styles.wrong_code)}>{isWrongCode ? '! Код введён неверно' : ''}</div>
        <div className={styles.send_sms_timer}>{canSendSms ? '' : `Отправить SMS-код можно через ${format(timeToNextSMS, 'mm:ss')}`}</div>
        <div className={styles.resend_sms_btn} onClick={reqCode}>{(canSendSms && !codeConfirmed) ? 'Отправить SMS-код' : ''}</div>
        <BlueButton title='Далее' disabled={!codeConfirmed} onClick={showNewPhoneUI} />
      </div >
      break;
      {/* </ModalDialog >) */ }
    }
    case Step.start:
    default: {
      // return (<ModalDialog buttonsConfig={[]} isActive={isActive} hasCloseBtn hideBtns>
      content = <div className={classNames(styles.content, { [styles.lost_phone]: lostPhone })}>
        <h1>{changeMode === ChangeMode.email ? 'Изменить почту' : 'Изменить основной номер'}</h1>
        <div className={styles.info}>Для подтверждения мы пришлем смс с кодом на этот номер</div>
        <div className={styles.old_phone}>{oldPhone}</div>
        <Checkbox className={styles.lost_phone_cb} checked={lostPhone} onChange={handleToggleLostPhone} label='Нет доступа к номеру телефона' />
        <div className={styles.lost_phone_info}>{lostPhone ? <>Заявка будет отправлена в службу тех поддержки.<br />Ожидайте ответ на почту</> : ''}</div>
        <div className={styles.warning}>{warningText}</div>
        <BlueButton title={lostPhone ? 'Написать в службу поддержки' : 'Отправить SMS'} onClick={reqCode} disabled={isLoadingCode || !canSendSms} />
      </div >
      {/* </ModalDialog >) */ }
    }
  }

  return (<ModalDialog buttonsConfig={[]} isActive={changeMode !== undefined} hasCloseBtn hideBtns handleClose={onClose}>
    {content}
  </ModalDialog >)
}
