import React from 'react'
import { useAppSelector } from '../../../hooks/store'

import styles from "./CompanyRating.module.scss"

export default function CompanyRating() {

  const companyRating = useAppSelector(state => state.auth.user?.companyRating || 0)

  return (
    <div className={styles.rating}>
      <span>Рейтинг магазина</span>
      <span className={styles.value}>{companyRating}</span>
    </div>
  )
}
