import React from 'react'
import { ButtonConfig, ModalDialog } from '../../../../simples/ModalDialog'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { clearUploadingPhotos } from '../../../../../store/slices/product'
import { Progress } from '../../../../simples/Progress'

import styles from './VariantPhotos.module.scss'

import defaultThumbnail from '../../assets/defailt_thumbnail.svg'

export default function UploadingDialog() {
  const uploadingProgress = useAppSelector(state => state.product.uploadingProgress)
  const dispatch = useAppDispatch()
  const isInprogress = !!uploadingProgress.find(p => p.isActive === true)
  const buttonsConfig: ButtonConfig[] = [{
    text: 'Готово',
    disabled: isInprogress,
    onClick: () => {
      dispatch(clearUploadingPhotos())
    }
  }]
  return (
    <ModalDialog isActive={uploadingProgress.length > 0} buttonsConfig={buttonsConfig} width={654}>
      <div className={styles.uploading}>
        <h1>Загрузка фотографий</h1>
        {uploadingProgress.map(p => <div key={p.id} className={styles.uploading_row}>
          <div className={styles.thumbnail_wrap}>
            <img src={p.thumbnail || defaultThumbnail} />
          </div>
          <div className={styles.uploading_file}>
            <div className={styles.uploading_name}>{p.name} {p.rejected && <span><b>Ошибка!</b></span>}</div>
            {/* <div className={styles.uploading_progress}>{p.progress}</div> */}
            <Progress value={p.progress} className={styles.uploading_progress} />
          </div>
        </div>)}
      </div>
    </ModalDialog>
  )
}
