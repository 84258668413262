import classNames from "classnames";
import React, { useEffect, useState } from "react";
import TextTimer from "../../../../complexes/TextTimer/TextTimer";
import { ParagraphButton } from "../../../../simples/ParagraphButton";
import styles from "./ActiveWithTimerText.module.scss";

type Props = {
  buttonText: string;
  middleText: string;
  timerSeconds: number;
  onPress: () => void;
  className?: string;
  triggerReset: boolean;
};

const ActiveWithTimerText = ({
  buttonText,
  middleText,
  timerSeconds,
  onPress,
  className,
  triggerReset,
}: Props) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const onFinishTimer = () => setIsDisabled(false);

  useEffect(() => {
    setIsDisabled(true);
  }, [triggerReset]);

  return (
    <span className={classNames(styles.container, className)}>
      <ParagraphButton
        onPress={onPress}
        title={buttonText}
        disabled={isDisabled}
        className={styles.paragraphButton}
      />
      {isDisabled && (
        <>
          <p className={styles.middleText}>{middleText}</p>
          <TextTimer onFinish={onFinishTimer} seconds={timerSeconds} />
        </>
      )}
    </span>
  );
};

export default ActiveWithTimerText;
