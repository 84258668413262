import React, { FC, useState } from 'react';
import defaultPhoto from "../../assets/product_default_icon.svg";
import calendar_icon from "../../assets/calendar_icon.svg";
import finish_icon from "../../assets/finish_icon.svg";
import planed_icon from "../../assets/planed_icon.svg";
import active_icon from "../../assets/active_icon.svg";
import { ListData, ListDataType, ListHeadData, ListProductDataType, ListRow } from '../../../../../../../types/productListTypes';
// import { ProductsDiscountHistoryDTO, ProductsPricesHistoryDTO } from '../../../../../../../types/swagger/llyApi';
import { ItemsList } from '../../../../../../complexes/ItemsList';
import { format } from 'date-fns';

import styles from "./HistoryList.module.scss"
import { ProductsDiscountHistoryDTO, ProductsPricesHistoryDTO } from '../../../../../../../types/swagger/llyApi_new';

export interface RowData {
  foto: string;
  id: string;
  name: string;
  brand: string;
}

export interface IDiscountConfig {
  count: number
  discount: number
  discountInPercent: boolean
  valueInPercent: boolean
}


const mapHistoryDataToListDataRow = (
  // product: ListProductDataType,
  historyItem: DiscountHistoryItem,
  visibleData: ListHeadData[],
  rowIndex: number,
  errors?: string[],
  // isFakeBarcodes?: boolean,
  selectedProducts?: string[],
  // discount?: IDiscountConfig
): ListRow => {
  const data: ListData[] = visibleData.map((c) => {
    const d: ListData = { name: c.name, text: "" };

    // const product0 = product.productsColors[0];
    switch (c.name) {
      case AvailableHistoryFields.row_index:
        d.text = rowIndex.toString();
        break;
      case AvailableHistoryFields.url:
        d.text = historyItem[c.name] || defaultPhoto;
        d.type = ListDataType.image;
        break;
      case AvailableHistoryFields.price:
      case AvailableHistoryFields.preview_price:
      case AvailableHistoryFields.discount_price:
      case AvailableHistoryFields.diff_preview_price_and_price_value:
        d.dataForSorting = historyItem[c.name] || 0
        d.text = `${(historyItem[c.name] || 0).toLocaleString('ru-RU') } ₽`;
        break;
      case AvailableHistoryFields.diff_preview_price_and_price_percents: {
        d.dataForSorting = historyItem[c.name] || 0
        let diff = Math.round((historyItem[c.name] || 0))
        if (isNaN(diff)) diff = 0
        d.text = `${diff}%`;
        break;
      }
      case AvailableHistoryFields.actual_discount: {
        let text = historyItem[c.name]?.toString() || ""
        d.dataForSorting = historyItem[c.name]
        // d.dataForSorting = text
        if (typeof historyItem[c.name] === 'number') {
          text += "%"
        }
        if (historyItem.is_calendar_discount) {
          d.content = <>{text} <img src={calendar_icon} /></>
          d.type = ListDataType.content;
        } else {
          d.text = text;
        }
        break;
      }
      case AvailableHistoryFields.sizes:
      case AvailableHistoryFields.seasons:
      case AvailableHistoryFields.barcodes:
        d.list = historyItem[c.name] || []
        d.type = ListDataType.list;
        break;
      case AvailableHistoryFields.period_string: {
        const dates = (historyItem[c.name] || "").split(" - ")
        const _d1 = new Date(dates[0])
        d.dataForSorting = _d1.getTime()
        let _d2
        if (dates[1]) {
          _d2 = new Date(dates[1])
        }

        try {
          d.dataForSorting = _d1.getTime().toString()
          // d.text = `${format(_d1, "dd.MM.yy")} — ${format(_d2, "dd.MM.yy")}`
          d.text = `${format(_d1, "dd.MM.yy")} — `
          if (_d2) d.text += `${format(_d2, "dd.MM.yy")}`
        } catch (error) {
          d.text = "error"
        }
        break;
      }
      case AvailableHistoryFields.period: {
        d.list = [`${historyItem[c.name]} дней`]
        // const dates = (historyItem[AvailableHistoryFields.period_string] || "").split(" - ")
        // если есть дата окончания
        if (historyItem.discount_remains) {
          d.list.push(`(осталось ${historyItem.discount_remains || "_"} дней)`)
        }
        d.dataForSorting = Number(historyItem[c.name]) || 0
        console.log(historyItem[c.name], historyItem.discount_remains);
        
        d.type = ListDataType.list;
        break;
      }
      case AvailableHistoryFields.created_at: {
        const _d = new Date((historyItem[c.name] || 0) * 1000)
        d.dataForSorting = _d.getTime()
        d.text = format(_d, "dd.MM.yyyy");
        break;
      }
      case AvailableHistoryFields.price_status_name:
      case AvailableHistoryFields.discount_status_name: {
        const text = historyItem[c.name]
        d.type = ListDataType.content;
        d.dataForSorting = text;
        switch (text) {
          case "Завершено":
          case "Завершенная":
            d.content = <><img src={finish_icon} /> {text}</>
            break;
          case "Запланировано":
          case "Запланированная":
            d.content = <><img src={planed_icon} /> {text}</>
            break;
          case "Действует":
          case "Действующая":
            d.content = <><img src={active_icon} /> {text}</>
            break;
          default:
            d.content = <>{text}</>
            break;
        }
        break;
      }
      case AvailableHistoryFields.discount_value: {
        d.type = ListDataType.content;
        d.content = <><span>{historyItem[c.name]} <span className={styles.value_remains}>(осталось {historyItem.value_remains || 0})</span></span></>
        // d.dataForSorting = historyItem.value_remains
        d.dataForSorting =historyItem[c.name] || 0
        break;
      }

      default:
        if (typeof historyItem[c.name] === 'number') {
          d.text = historyItem[c.name]
        } else {
          d.text = historyItem[c.name] || ""
        }
        break;
    }
    return d;
  });

  let hasErrors = false;
  if (errors && errors?.indexOf(historyItem.id) > -1) {
    hasErrors = true
  }
  let selected = false;
  if (selectedProducts && selectedProducts?.indexOf(historyItem.id) > -1) {
    selected = true
  }

  let hideCheckbox
  const _status = historyItem[AvailableHistoryFields.discount_status_name] || historyItem[AvailableHistoryFields.price_status_name]
  if (_status === "Завершено" || _status === "Завершенная") {
    hideCheckbox = true
  }

  return {
    // key: historyItem.product_id || 0, // product.id,
    key: historyItem.id, // product.id,
    data,
    hasErrors,
    selected,
    hideCheckbox,
    // hasDiscount: historyItem.hasDiscount
  };
};

export enum Order { ASC, DESC }


// http://88.198.56.78:8100/docs#/%D0%A2%D0%BE%D0%B2%D0%B0%D1%80%D1%8B/get_products_prices_history
// +
// http://88.198.56.78:8100/docs#/%D0%A2%D0%BE%D0%B2%D0%B0%D1%80%D1%8B/get_discount_history



export type DiscountHistoryItem = ProductsDiscountHistoryDTO & ProductsPricesHistoryDTO


export enum AvailableHistoryFields {
  /** номер строки */
  row_index = "row_index",

  /** Ссылка на изображение */
  url = "url",
  /** Артикул */
  article = "article",
  /** Наиминование */
  name = "name",
  /** Бренд */
  brand = "brand",
  /** Пол */
  gender = "gender",
  /** Категория */
  category = "category",
  /** Подкатегория */
  subcategory = "subcategory",
  /** ID продукта */
  product_id = "product_id",
  /** Цена */
  price = "price",
  /** Период действия */
  period = "period",
  /** Цена со скидкой */
  discount_price = "discount_price",
  /** Статус (константа) */
  discount_status = "discount_status",
  /** Время действия (осталось) */
  discount_remains = "discount_remains",
  /** Остаток на складе */
  storage_remains = "storage_remains",
  /** Участвует в скидке */
  discount_value = "discount_value",
  /** Участвует в скидке (осталось) */
  value_remains = "value_remains",
  /** Статус */
  discount_status_name = "discount_status_name",
  /** Актуальная скидка */
  actual_discount = "actual_discount",
  /** Период действия */
  period_string = "period_string",
  /** Скидка из календаря акций */
  is_calendar_discount = "is_calendar_discount",
  /** Размер */
  sizes = "sizes",
  /** штрихкод */
  barcodes = "barcodes",
  /** Сезон */
  seasons = "seasons",


  /** Новая и предыдущая в процентах */
  diff_preview_price_and_price_percents = "diff_preview_price_and_price_percents",
  /** Новая и предыдущая в рублях */
  diff_preview_price_and_price_value = "diff_preview_price_and_price_value",
  /** Предыдущая цена */
  preview_price = "preview_price",
  /** Статус */
  price_status_name = "price_status_name",


  /** дата создания */
  created_at = "created_at",
}




interface ProductListProps {
  // data: ListProductDataType[];
  data: DiscountHistoryItem[],
  errors?: string[];
  selectedProducts?: string[];
  headers: ListHeadData[];
  className?: string;
  isFakeBarcodes?: boolean; // TODO remove
  selectProducts?: (selectedProducts: string[]) => void
  deselectProducts?: (selectedProducts: string[]) => void
  removeItemsFromList?: (selectedProducts: string[]) => void
  isRemovable?: boolean
  discount?: IDiscountConfig
}

const HistoryList: FC<ProductListProps> = ({
  data, errors, headers, isFakeBarcodes, className, isRemovable, discount,
  selectedProducts, selectProducts, deselectProducts, removeItemsFromList
}) => {

  let visibleData: Array<ListRow> = data.map((row, rowIndex) => (
    // mapHistoryDataToListDataRow(row, headers, rowIndex + 1, errors, isFakeBarcodes, selectedProducts, discount)
    mapHistoryDataToListDataRow(row, headers, rowIndex + 1, errors, selectedProducts)
  ));

  return (<ItemsList
    className={className}
    headers={headers}
    items={visibleData}
    selectProducts={selectProducts}
    deselectProducts={deselectProducts}
    isRemovable={isRemovable}
    removeItemsFromList={removeItemsFromList}
    selectedItems={selectedProducts}
  />)

};

export default HistoryList;