import React, { FC, useState, useEffect } from "react";

import { TagList } from "./parts/TagList";
import { TagsType } from "../../../../../../../types/product";
import { SearchAndSort } from "./parts/SearchAndSort";

import api from "../../../../../../../utils/api";

import styles from "./FamousTags.module.scss";
import { CustomDatePicker, DatePickerDate } from "../../../../../../complexes/CustomDatePicker";
import { datePickerDateToDate, dateToDatePickerDate } from "../../../../../../complexes/CustomDatePicker/CustomDatePicker";
import { BlueButton } from "../../../../../../simples/BlueButton";
import { format } from "date-fns";

const LIMIT = 10;
const MinDate = new Date(2022, 0, 1)
const tagsApi = "tags";
const Now = new Date()

const defaultDateFrom = dateToDatePickerDate(MinDate)
const defaultDateTo = dateToDatePickerDate(Now)

enum SortBy {
  alpabet = "alpabet",
  amount = "amount"
}
enum Order { ASC, DESC }

const formatDateForServer = (d?: DatePickerDate) => {
  if (!d) return ""
  return format(datePickerDateToDate(d), "yyyy-MM-dd")
}


const FamousTags: FC = () => {
  const [allTags, setAllTags] = useState<TagsType[]>([]);
  const [isAllTime, setIsAllTime] = useState(true)
  const [dateFrom, setDateFrom] = useState<DatePickerDate>(defaultDateFrom)
  const [dateTo, setDateTo] = useState<DatePickerDate>(defaultDateTo)
  const [searcheText, setSearcheText] = useState("")
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.amount)
  const [sortOrder, setSortOrder] = useState<Order>(Order.DESC)
  const [showAllTags, setShowAllTags] = useState(false)

  useEffect(() => {
    let req = tagsApi
    if (!isAllTime) req += `?dateFrom=${formatDateForServer(dateFrom)}&dateTo=${formatDateForServer(dateTo)}`
    api.get<{ success: boolean, data: TagsType[] }>(req).then((resp) => {
      setAllTags(resp.data.data)
    })
  }, [dateFrom, dateTo, isAllTime])

  let sortedAndSearchedTags = allTags
    .filter((tag) => tag.name.toLocaleLowerCase().includes(searcheText.toLocaleLowerCase()))
    .sort((a, b) => {
      if (sortBy === SortBy.alpabet) {
        return (sortOrder === Order.DESC) ? b.name.toLowerCase().localeCompare(a.name.toLowerCase()) : a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      } else {
        return (sortOrder === Order.DESC) ? b.id - a.id : a.id - b.id
      }
    })
  if (!showAllTags) {
    sortedAndSearchedTags = sortedAndSearchedTags.slice(0, LIMIT)
  }

  const handleShowAllTags = () => {
    setShowAllTags(true)
  }

  useEffect(() => {
    const _from = datePickerDateToDate(dateFrom);
    const _to = datePickerDateToDate(dateTo)
    if (_from.getTime() > _to.getTime()) {
      setDateTo(dateFrom)
    }
  }, [dateFrom])

  useEffect(() => {
    const _from = datePickerDateToDate(dateFrom);
    const _to = datePickerDateToDate(dateTo)
    if (_from.getTime() > _to.getTime()) {
      setDateFrom(dateTo)
    }
  }, [dateTo])


  const handleSetDateFrom = (date: DatePickerDate) => {
    setDateFrom(date)
  }
  const handleSetDateTo = (date: DatePickerDate) => {
    setDateTo(date)
  }
  const handleSetIsAllTime = () => {
    setIsAllTime(true)
  }
  const handleSelectTime = () => {
    setIsAllTime(false)
  }

  const toggleSortOrder = () => {
    (sortOrder === Order.ASC) ? setSortOrder(Order.DESC) : setSortOrder(Order.ASC)
  }

  const handleSortAlpabet = () => {
    if (sortBy !== SortBy.alpabet) {
      setSortBy(SortBy.alpabet)
      setSortOrder(Order.ASC)
    } else {
      toggleSortOrder()
    }
  }
  const handleSortAmount = () => {
    if (sortBy !== SortBy.amount) {
      setSortBy(SortBy.amount)
      setSortOrder(Order.DESC)
    } else {
      toggleSortOrder()
    }
  }

  return (
    <div className={styles.famousTags}>
      <div className={styles.dates_wrap}>
        <span className={styles.label}>Период</span>
        <BlueButton
          title="За всё время"
          onClick={handleSetIsAllTime}
          disabled={isAllTime}
        />
        {isAllTime ?
          <BlueButton title="Выбрать даты" onClick={handleSelectTime} /> :
          <div className={styles.dates}>
            <CustomDatePicker minDate={MinDate} date={dateFrom} onChange={handleSetDateFrom} label="с" />
            <CustomDatePicker date={dateTo} onChange={handleSetDateTo} label="по" />
          </div>
        }
      </div>

      <SearchAndSort
        searcheText={searcheText}
        setSearcheText={setSearcheText}
        sortByAlpabet={handleSortAlpabet}
        sortByAmount={handleSortAmount}
      />

      {allTags.length ? (
        <TagList tags={sortedAndSearchedTags} />
      ) : (
        <div className={styles.tagsNotFound}>Теги не найдены</div>
      )}

      {!showAllTags ? (
        <button onClick={handleShowAllTags} className={styles.btn}>
          Показать все
        </button>
      ) : null}
    </div>
  );
};

export default FamousTags;
