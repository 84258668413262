import React from 'react'

import styles from './Panel.module.scss'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'

type Props = {
  children?: JSX.Element | JSX.Element[] | string | undefined
  className?: string
  isLoading?: boolean
}

export default function Panel({ children, className, isLoading }: Props) {
  return (
    <div className={classNames(styles.panel, className)}>{isLoading ? <Skeleton height={400} /> : children}</div>
  )
}
