import api from "./api";

type Answer = {
  success: boolean
  response?: any
  error?: string
}
type Props = {
  url: string,
  formDataFile: any,
  onUploadProgress?: (percent: number) => void
  onFinish?: (answer: Answer) => void
}

async function upload({ url, formDataFile, onUploadProgress, onFinish }: Props) {
  let answer: Answer = { success: false };

  const fileHeader: any = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: function (progressEvent: ProgressEvent) {
      const uploadPercentage = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total) * 100) as any,
      );
      onUploadProgress && onUploadProgress(uploadPercentage);
    }
  };

  await api
    .post(url, { file: formDataFile }, fileHeader)
    .then(response => {
      answer.response = response?.data;
      answer.success = true;
    }).catch(response => {
      answer.response = response?.response?.data;
      answer.success = false;
      answer.error = response.message || "error!";
    }).finally(() => {
      onFinish && onFinish(answer)
    });

  return answer;
}

export default upload