import { isSameMonth, nextMonday, startOfWeek } from 'date-fns'
import React from 'react'
import { Promotions } from '../../../../../types/swagger/llyApi'
import WeekActions from '../CalendarWeek/WeekActions'
import WeekDays from '../CalendarWeek/WeekDays'

import styles from "./CalendarMonth.module.scss"

type Props = {
  actions: Promotions[]
  selectAction: (actionId: number) => void
  firstDayOfMonth: Date
}

export default function CalendarMonth({ actions, firstDayOfMonth, selectAction }: Props) {

  const firstDaysOfWeekOfMonth: Date[] = [];

  let monday = startOfWeek(firstDayOfMonth, { weekStartsOn: 1 })
  do {
    firstDaysOfWeekOfMonth.push(monday)
    monday = nextMonday(monday)
  } while (isSameMonth(monday, firstDayOfMonth))


  return (
    <div className={styles.calendar_week}>
      <WeekDays />
      {firstDaysOfWeekOfMonth.map(firstDayOfWeek => {
        return <WeekActions key={firstDayOfWeek.getTime()} selectAction={selectAction} actions={actions} firstDayOfWeek={firstDayOfWeek} firstDayOfMonth={firstDayOfMonth} compactView={true} />
      })}
    </div>
  )
}