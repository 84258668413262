import { Endpoints } from "../constants/server";
import { IRemainings, IRequest } from "../store/slices/fbs";
import { CollectedOrder, OrderDTO, ProductsDTO } from "../types/swagger/llyApi_new";
import { formatDateForServer } from "../utils/action";
import api from "../utils/api";

export enum Order { ASC, DESC }

export interface IGetOrders {
  searchRequest?: string;
  fromDate?: Date;
  toDate?: Date;
}
export interface ICreateOrder {
  request: IRequest
  remainings: IRemainings[]
}
export type CreateNewOrderResponse = {
  collectedOrders: CollectedOrder[]
}
type ServerRemainings = {
  product_id: string
  storage_id: number
  count: number
}
// export interface IGetItemsBalance 

export type NewOrdersAnswer = {
  needUpdate?: any;
  success: boolean;
  data?: Array<OrderDTO>;
};
export type CollectedOrdersAnswer = {
  needUpdate?: any;
  success: boolean;
  data?: Array<CollectedOrder>;
};
export type CreateNewOrderAnswer = {
  success?: boolean
  data?: CollectedOrder[]
};

export type ShippedOrdersAnswer = {
  needUpdate?: any;
  success: boolean;
  data?: Array<CollectedOrder>;
};
export type ClosedOrdersAnswer = {
  needUpdate?: any;
  success: boolean;
  data?: Array<CollectedOrder>;
};
export type ItemsBalanceAnswer = {
  success?: boolean
  data?: ProductsDTO
}
export type ReloadOrdersAnswer = {
  success?: boolean
  data?: CollectedOrder[]
};

// interface DeleteProductsAnswer {
//   success: boolean;
//   count: number;
//   fully: boolean;
//   goods?: Array<ProductsDTO>;
//   statuses?: GoodsListCategory[];
// }

// interface RestoreProductsAnswer {
//   success: boolean;
//   count: number;
//   goods?: Array<ProductsDTO>;
//   statuses?: GoodsListCategory[];
// }

export const DISCOUNT_CATEGORY_ID = 5

class FBSService {

  // static async restoreProducts(ids: Array<string>, selectedStateId: number) {
  //   let data: RestoreProductsAnswer = { success: false, count: 0 };
  //   const req = Endpoints.RESTORE_PRODUCTS;
  //   await api
  //     .post(req, {
  //       products: ids
  //     })
  //     .then((res) => {
  //       data.count = res?.data?.data?.count || 0;
  //     })
  //     .catch((err) => console.log(err));

  //   data.goods = (await GoodsListService.getGoods({ id: selectedStateId }));
  //   data.statuses = (await GoodsListService.getGoodsStatuses());


  //   return data;
  // }

  // static async deleteProducts(ids: Array<string>, fully: boolean, selectedStateId: number) {
  //   let data: DeleteProductsAnswer = { success: false, fully, count: 0, goods: [] };
  //   // const req = fully ? Endpoints.DELETE_PRODUCTS : Endpoints.SOFT_DELETE_PRODUCTS;
  //   let req: string = Endpoints.DELETE_PRODUCTS;
  //   const reqData = {
  //     products: ids, // JSON.stringify(ids), // 
  //   }
  //   if (!fully) {
  //     req += '?soft-delete=1';
  //   }

  //   await api.post(req, reqData).then((res) => {
  //     data.success = true;
  //     data.count = res?.data?.data?.count || 0;
  //   }).catch((err) => console.log(err));

  //   data.goods = (await GoodsListService.getGoods({ id: selectedStateId }));
  //   data.statuses = (await GoodsListService.getGoodsStatuses());
  //   return data;
  // }


  static async getNewOrders({
    searchRequest, fromDate, toDate
  }: IGetOrders) {
    let data: NewOrdersAnswer = { success: false };

    let req = `${Endpoints.GET_FBS_NEW_ORDERS}?`;
    if (searchRequest && searchRequest !== "") { req += `&search=${searchRequest}`; }
    // if (limit && limit > 0) { req += `&limit=${limit}`; }
    // if (offset && offset > 0) { req += `&offset=${offset}`; }
    // if (order_by) {
    //   req += `&order_by=${order_by}`;
    //   if (order) { req += `&order=${order}`; }
    // }
    if (fromDate) { req += `&from_date=${formatDateForServer(fromDate)}`; }
    if (toDate) { req += `&to_date=${formatDateForServer(toDate)}`; }

    await api
      .get<NewOrdersAnswer>(req)
      .then((res) => {
        data = res.data;
      })
      .catch((err) => console.log(err));
    return data.data || [];
  }

  static async reloadOrders(ids: number[]) {
    let data: ReloadOrdersAnswer = { success: false };

    let req = `${Endpoints.RELOAD_ORDERS}?ids=${ids.join(',')}`;

    await api
      .get<ReloadOrdersAnswer>(req)
      .then((res) => {
        data = res.data;
      })
      .catch((err) => console.log(err));
    return data.data || [];
  }


  static async createNewOrder({ request, remainings }: ICreateOrder) {

    const { orders, fromLocationId, isCourier } = request

    let data: CreateNewOrderAnswer = { success: false };

    let req = `${Endpoints.GET_FBS_CREATE_NEW_ORDER}`;
    // if (searchRequest && searchRequest !== "") { req += `&search=${searchRequest}`; }
    // if (fromDate) { req += `&from_date=${formatDateForServer(fromDate)}`; }
    // if (toDate) { req += `&to_date=${formatDateForServer(toDate)}`; }

    const balance_write_off: ServerRemainings[] = []
    for (const r of remainings) {
      for (const w of r.warehouses) {
        balance_write_off.push({
          product_id: r.item.itemId, // .id,
          storage_id: Number(w.id),
          count: w.shipment || 0
        })
      }
    }

    const reqData = orders.map(o => {

      return {
        id: o.id,
        location_id: fromLocationId,
        isCourier,
        packages: o.boxes.map(box => {
          // const items: number[] = box.itemIds.map(boxItemId => {
          //   o.items.
          //   return -1
          // })
          // костыль для бэка
          const items: number[] = []
          for (const itemId of box.itemIds) {
            const item = o.items.find(item => item.id === itemId)
            if (!item) { console.error('Data is corrupted!'); throw new Error("Data is corrupted!"); }
            items.push(item.dbId)
          }

          return {
            "weight": box.weight,
            "length": box.size.l,
            "width": box.size.w,
            "height": box.size.h,
            // "items": box.itemIds
            items
          }
        }),
        balance_write_off
      }
    })

    await api
      .post<CollectedOrdersAnswer>(req, reqData)
      .then((res) => {
        data = res.data;
      })
      .catch((err) => console.log(err));
    return data.data || [];

    // console.log(reqData);
    
    // return [
    //   {
    //     "id": 9,
    //     "order_id": 9,
    //     "date": "2023-11-10",
    //     "delivery_date": "2023-11-13",
    //     "hours_remain": 289,
    //     "address": "ул. 65-летия Победы, 12",
    //     "consumer_name": "string",
    //     "delivery_by": "Курьер",
    //     "delivery_from": "ул. Новозаводская, 8, корп. 3",
    //     "documents": [
    //       {
    //         "id": 3,
    //         "name": "invoice",
    //         "url": "https://www.cdek.ru/storage/source/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%D0%B0/25.06.21/%D0%9F%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D0%BD%D0%B0%D1%8F%20%D1%84%D0%BE%D1%80%D0%BC%D0%B0%20%D0%B4%D0%BE%D0%B2%D0%B5%D1%80%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%B8%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%B0%D1%87%D1%83%20%20%D0%BE%D1%82%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
    //       },
    //       {
    //         "id": 4,
    //         "name": "Штрихкод_места",
    //         "url": "https://www.cdek.ru/storage/source/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%D0%B0/25.06.21/%D0%9F%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D0%BD%D0%B0%D1%8F%20%D1%84%D0%BE%D1%80%D0%BC%D0%B0%20%D0%B4%D0%BE%D0%B2%D0%B5%D1%80%D0%B5%D0%BD%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%BD%D0%B0%20%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%B8%20%D0%BF%D0%B5%D1%80%D0%B5%D0%B4%D0%B0%D1%87%D1%83%20%20%D0%BE%D1%82%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
    //       }
    //     ],
    //     "price": 30330,
    //     "delivery_price": 10800,
    //     "items": [
    //       {
    //         "id": "335-436-3",
    //         "product_id": 335,
    //         "product_color_id": 436,
    //         "size_id": 3,
    //         "product_name": "Тест №4789879",
    //         "article": "15335FBCEB1M",
    //         "count": 9,
    //         "price": 45495,
    //         "remains": [],
    //         "item_ids": [
    //           24,
    //           25,
    //           26,
    //           27,
    //           28,
    //           29,
    //           30,
    //           31,
    //           32
    //         ]
    //       }
    //     ],
    //     "delivery_status": [],
    //     "raw_status": "NEW_STATUS",
    //     "updated_at": 1700926633,
    //     "packages_count": 1
    //   }
    // ]
  }

  static async getCollectedOrders({
    searchRequest, fromDate, toDate
  }: IGetOrders) {
    let data: CollectedOrdersAnswer = { success: false };

    let req = `${Endpoints.GET_FBS_COLLECTED_ORDERS}?`;
    if (searchRequest && searchRequest !== "") { req += `&search=${searchRequest}`; }
    if (fromDate) { req += `&from_date=${formatDateForServer(fromDate)}`; }
    if (toDate) { req += `&to_date=${formatDateForServer(toDate)}`; }

    await api
      .get<CollectedOrdersAnswer>(req)
      .then((res) => {
        data = res.data;
      })
      .catch((err) => console.log(err));
    return data.data || [];
  }


  static async getShippedOrders({
    searchRequest, fromDate, toDate
  }: IGetOrders) {
    let data: ShippedOrdersAnswer = { success: false };

    let req = `${Endpoints.GET_FBS_SHIPPED_ORDERS}?`;
    if (searchRequest && searchRequest !== "") { req += `&search=${searchRequest}`; }
    if (fromDate) { req += `&from_date=${formatDateForServer(fromDate)}`; }
    if (toDate) { req += `&to_date=${formatDateForServer(toDate)}`; }

    await api
      .get<ShippedOrdersAnswer>(req)
      .then((res) => {
        data = res.data;
      })
      .catch((err) => console.log(err));
    return data.data || [];
  }

  // static async getItemsBalance(ids: string[]) {
  //   let req = `${Endpoints.GET_ITEMS_BALANCE}?date=${formatDateForServer(Date.now())}`
  //   const data = []
  //   await api.get<ItemsBalanceAnswer>(req).then((res) => {
  //     console.log(res);
  //   }).catch((err) => console.log(err));
  //   return data;
  // }

  static async getClosedOrders({
    searchRequest, fromDate, toDate
  }: IGetOrders) {
    let data: ClosedOrdersAnswer = { success: false };

    let req = `${Endpoints.GET_FBS_CLOSED_ORDERS}?`;
    if (searchRequest && searchRequest !== "") { req += `&search=${searchRequest}`; }
    if (fromDate) { req += `&from_date=${formatDateForServer(fromDate)}`; }
    if (toDate) { req += `&to_date=${formatDateForServer(toDate)}`; }

    await api
      .get<ClosedOrdersAnswer>(req)
      .then((res) => {
        data = res.data;
      })
      .catch((err) => console.log(err));
    return data.data || [];
  }


  // static async getGoodsStatuses(): Promise<GoodsListCategory[]> {
  //   const goodsStatuses = [
  //     { id: 0, title: "Созданные", countGoods: 0 },
  //     { id: 1, title: "На создании", countGoods: 0 },
  //     { id: 2, title: "На модерации", countGoods: 0 },
  //     { id: 3, title: "Отклоненные", countGoods: 0 },
  //     { id: 4, title: "Архив", countGoods: 0 },
  //     { id: DISCOUNT_CATEGORY_ID, title: "Товары со скидкой", countGoods: 0 },
  //   ];
  //   let req: string = Endpoints.PRODUCTS_COUNT;
  //   await api.post(req).then((res) => {
  //     if (res?.data?.success === true) {
  //       res.data.data.forEach(s => {
  //         let i = goodsStatuses.findIndex(_s => _s.id === parseInt(s.id));
  //         if (i !== -1) {
  //           goodsStatuses[i].countGoods = s.count || 0;
  //         }
  //       });
  //     }
  //   }).catch((err) => console.log(err));
  //   return goodsStatuses;
  // }
}




export default FBSService;
