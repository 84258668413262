import React, { useEffect } from 'react'
import { CustomTextInput } from '../../../simples/CustomTextInput'
import { CustomSelect, Option } from '../../../simples/CustomSelect'
import { Checkbox } from '../../../simples/Checkbox'
import { CustomDropzome } from '../../../simples/CustomDropzome'
import { isValid, parse } from 'date-fns'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { fetchTypes, setCertDataValue, uploadDoc } from '../../../../store/slices/certificates'
// import { formatDate } from '../utils'
import { parseDate } from '../../../../utils/action'

import styles from '../Brands.module.scss'
import { formatDate } from '../../../../utils/dates'

type Props = {
  readonly?: boolean
}


export default function CertEditor({ readonly }: Props) {

  const { requirements, docTypes, uploadingProgress, uploadedDoc } = useAppSelector(state => state.certificates)
  const { selectedRequirements, selectedDocType, title, docId, fromDate, toDate, termless } = useAppSelector(state => state.certificates.certData)

  const dispatch = useAppDispatch()

  const handleOnDrop = (files: File[]) => {
    if (files.length === 1) {
      dispatch(uploadDoc({ file: files[0] }))
    }
  }

  useEffect(() => {
    dispatch(fetchTypes())
  }, [])


  const requirementsOptions: Option[] = requirements.map(r => ({
    value: r.id,
    label: r.name
  }))
  const docTypesOptions: Option[] = docTypes.map(r => ({
    value: r.id,
    label: r.name
  }))


  const handleChange = ({ name, value }) => {
    dispatch(setCertDataValue({ name, value }))
  }
  const handleChangeFromDate = ({ value }) => {
    const d = parseDate(value)
    if (isValid(d) && d.getTime() < Date.now()) {
      dispatch(setCertDataValue({ name: 'fromDate', value: d.getTime() }))
    }
  }
  const handleChangeToDate = ({ value }) => {
    const d = parseDate(value)
    if (isValid(d)) {
      dispatch(setCertDataValue({ name: 'toDate', value: d.getTime() }))
    }
  }
  const handleSetTermless = (value: boolean) => {
    dispatch(setCertDataValue({ name: 'termless', value }))
  }

  const dropzoneText = (uploadingProgress !== undefined)
    ? `Загрузка... ${uploadingProgress}%`
    : ((uploadedDoc !== undefined)
      ? `Загружен файл "${uploadedDoc.base_name}.${uploadedDoc.extension}"`
      : 'Загружаемый файл должен быть в формате .pdf, .jpeg, .jpg или .png, а размер не должен превышать 32 Мб')



  return (
    <div className={styles.create_cert}>
      <h1>Основные данные</h1>
      <div className={styles.row}>
        <div className={styles.col}>
          <CustomTextInput name='title' value={title} onChange={handleChange} label='Название' required maxLength={30} readonly={readonly} />
        </div>
        <div className={styles.col}>
          <CustomSelect name='selectedRequirements' value={selectedRequirements}
            placeholder='Выберите тип'
            onChange={handleChange} label='Тип соответствия требованиям'
            options={requirementsOptions} listMaxHeight={200} required
            readonly={readonly} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.col}>
          <CustomSelect name='selectedDocType' value={selectedDocType}
            placeholder='Выберите тип'
            onChange={handleChange} label='Тип документа'
            options={docTypesOptions} listMaxHeight={200} required
            readonly={readonly} />
        </div>
        <div className={styles.col}>
          <CustomTextInput name='docId' value={docId} onChange={handleChange} label='Укажите номер документа' required maxLength={30} readonly={readonly} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.col}>
          <CustomTextInput
            name='fromDate' onChange={handleChangeFromDate} label='Выдан' required
            value={fromDate !== undefined ? formatDate(fromDate) : ''}
            mask='99.99.9999' placeholder={readonly ? undefined : '__.__.____'}
            readonly={readonly} />
        </div>
        <div className={styles.col}>
          {(termless && readonly)
            ? ''
            : < CustomTextInput
              name='toDate' onChange={handleChangeToDate} label='Истекает' required={!termless} disabled={termless}
              value={toDate !== undefined ? formatDate(toDate) : ''}
              mask='99.99.9999' placeholder={readonly ? undefined : '__.__.____'}
              readonly={readonly} />
          }
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.col}>
          <Checkbox onChange={handleSetTermless} checked={termless} label='Бессрочный' readonly={readonly} />
        </div>
        <div className={styles.col}>
        </div>
      </div>

      {readonly
        ? <div className={styles.uploaded_cert}><span>Загружен сертификат:</span><span className={styles.cert_file}>{uploadedDoc?.name}</span></div>
        : <CustomDropzome
          title={dropzoneText}
          multiple={false}
          className={styles.dropzone} onDrop={handleOnDrop} />}
    </div>
  )
}
