import React, { FC } from "react";

import { TagsType } from "../../../../../../../../../types/product";

import styles from "./TagList.module.scss";

type Props = {
    tags: Array<TagsType>
}

const TagList: FC<Props> = ({ tags }) => {
  return (
    <div className={styles.tagsListWrapper}>
      {tags.map((tag) => {
        return (
          <div key={tag.id} className={styles.tag}>
            <p>#{tag.name}</p>
            <span>{tag.id}</span>
          </div>
        );
      })}
    </div>
  );
};

export default TagList;
