export enum BannerTextType {
  percent = "percent",
  text = "text"
}
export enum BannerShapeType {
  SQUARE = "SQUARE",
  TRIANGLE = "TRIANGLE",
  CIRCLE = "CIRCLE"
}
export interface BannerConfig {
  textType: BannerTextType
  shape: BannerShapeType
  color: string
}