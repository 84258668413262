import React from 'react'
import { Promotions } from '../../../../../types/swagger/llyApi'
import styles from "./CalendarDay.module.scss"
import calendarStyles from "../../ActionsCalendar.module.scss"
import DayAction from './DayAction'
import { isAfter, isBefore, isEqual, isSameDay } from 'date-fns'

type Props = {
  title: string
  actions: Promotions[]
  dayDate: Date
  selectAction: (actionId: number) => void
}

export default function CalendarDay({ actions, title, dayDate, selectAction }: Props) {

  const thisDayActions = actions.filter(action => {
    const actionFrom = new Date(action.start_date || Date.now())
    const actionTo = new Date(action.finish_date || Date.now())
    return (isBefore(actionFrom, dayDate) || isSameDay(actionFrom, dayDate)) && isAfter(actionTo, dayDate)
  })

  return (
    <div className={styles.calendar_day}>
      <div className={styles.calendar_day_title}><span>{title}</span></div>
      <div className={styles.body}>
        {thisDayActions.map(action => <DayAction key={action.id} action={action} selectAction={selectAction} />)}
      </div>
    </div>
  )
}
