import React from 'react'
import { InfoPanel } from '../../../../simples/InfoPanel'

import styles from './AboutShop.module.scss'

export default function Info() {
  return (
    <div className={styles.info}>
      <InfoPanel title='Склад FBS'>Адрес склада FBS будет использоваться для расчета базовой стоимости логистики</InfoPanel>
      <InfoPanel title='Правила написания'><div>
        При создании и изменении &quot;Названия магазина&quot;- придерживайтесь этих правил.
        <br /><br />
        В &quot;Названии магазина&quot; не должно быть:<br />
        -нецензурных выражений и их сокращений;<br />
        -смайликов и нечитаемых символов;<br />
        -опечаток;<br />
        -контактов магазина, ссылок на сайт и телефонных номеров;<br />
        -слов «лучший», «первый», «самый» и других эпитетов, которые Вы не можете подтвердить документально;<br />
        -перечисления видов товаров;<br />
        -чужих товарных знаков;<br />
        -слов «LLY» и «ЛЛЮ», «Look like you»
      </div></InfoPanel>
      <InfoPanel title='Нарушения'>Если в названии нет нарушений законодательства и правил, то название появится в личном кабинете и на карточках товаров</InfoPanel>
      <InfoPanel title='Наименование'>Если заявка на установку нового названия отклонена из-за нарушений, то заменим название на юридическое наименование организации или ФИО, если Вы индивидуальный предприниматель, при условии, что не было ранее подтвержденного названия</InfoPanel>
    </div>
  )
}
