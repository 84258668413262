import React, { useRef } from 'react'

// import styles from './Employees.module.scss'
import styles from './CodeInput.module.scss'
import classNames from 'classnames'

type Props = {
  code: string[]
  onChange: (code: string[]) => void
  hasError?: boolean
  disabled?: boolean
  className?: string
}

export default function CodeInput({ code, onChange, hasError, disabled, className }: Props) {

  const refs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ]

  const handleChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let nextIndex = index + 1
    if (nextIndex <= 3) {
      refs[nextIndex].current?.focus();
      refs[nextIndex].current?.select();
    }
    const char = e.target.value
    const newCode = code.map((c, i) => i === index ? char : c)
    onChange(newCode)
  }

  return (
    <div className={classNames(styles.code, className, { [styles.error]: hasError })}>
      <div className={styles.inputs}>
        <input type="text" ref={refs[0]} maxLength={1} value={code[0] || ''} onChange={handleChange(0)} disabled={disabled} />
        <input type="text" ref={refs[1]} maxLength={1} value={code[1] || ''} onChange={handleChange(1)} disabled={disabled} />
        <input type="text" ref={refs[2]} maxLength={1} value={code[2] || ''} onChange={handleChange(2)} disabled={disabled} />
        <input type="text" ref={refs[3]} maxLength={1} value={code[3] || ''} onChange={handleChange(3)} disabled={disabled} />
      </div>
      {hasError && <div className={styles.error_text}>! Код введён неверно</div>}
    </div>
  )
}
