import React from 'react'
import classNames from 'classnames';
import { BlueButton } from '../../../../simples/BlueButton';
import { Filters } from '../Filters'
import { CustomLink } from '../../../../simples/CustomLink';

import styles from "./Reviews.module.scss";
import SortSelect from './SortSelect';

import templates_icon from "../../assets/templates.svg"
import file_icon from "../../assets/file.svg"
import { Cards } from '../Cards';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { FeedbackSorting, openMassAnswerDialog, openTemplatesDialog, showConditions } from '../../../../../store/slices/feedback';
import { CompanyRating } from '../../../../complexes/CompanyRating';

const QuestionsOptions = [
  {
    label: "Дата по возрастанию",
    value: FeedbackSorting.OLD_TO_NEW
  },
  {
    label: "Дата по убыванию",
    value: FeedbackSorting.NEW_TO_OLD
  },
]

const ReviewsOptions = [
  {
    label: "Дата по возрастанию",
    value: FeedbackSorting.OLD_TO_NEW
  },
  {
    label: "Дата по убыванию",
    value: FeedbackSorting.NEW_TO_OLD
  },
  {
    label: "Сначала положительные",
    value: FeedbackSorting.GOOD_TO_BAD
  },
  {
    label: "Сначала отрицательные",
    value: FeedbackSorting.BAD_TO_GOOD
  },
]


export default function Reviews() {


  const isReviews = !useAppSelector(state => state.feedback.isQuestions)

  const dispatch = useAppDispatch()
  const handleMassResponse = () => {
    dispatch(openMassAnswerDialog())
  }
  const handleTemplates = () => {
    dispatch(openTemplatesDialog())
  }
  const handleShowConditions = () => {
    dispatch(showConditions())
  }

  return (
    <div>
      <div className={styles.row}>
        <Filters />
        <CompanyRating />
      </div>

      <div className={classNames(styles.row, styles.justify_content_flex_start)}>
        <SortSelect options={isReviews ? ReviewsOptions : QuestionsOptions} />
        {isReviews && <BlueButton title='Массовый ответ' onClick={handleMassResponse} />}
        <CustomLink text='Мои шаблоны' icon={templates_icon} onClick={handleTemplates} />
        <CustomLink text='Условия публикации ответов' icon={file_icon} isFile={true} onClick={handleShowConditions} />
      </div>
      <Cards />
    </div>
  )
}
