import React from 'react'
import { ModalDialog } from '../../../../simples/ModalDialog'

import styles from './AboutShop.module.scss'
import Logo from '../../../../complexes/Logo/Logo'
// import Logo from './Logo'

type Props = {
  isActive: boolean
  onClose: () => void
  shopLvl: number
  shopLvlText: string
  commission: number
  logoUrl?: string
  progress: number
}

export default function ShopLvlInfoDialog({ isActive, onClose, commission, shopLvl, shopLvlText, logoUrl, progress }: Props) {

  return (
    <ModalDialog isActive={isActive} width={920} hasCloseBtn={true} buttonsConfig={[{ text: 'Понятно', onClick: onClose }]} handleClose={onClose}>
      <div className={styles.info_dialog}>
        <h1>Уровень магазина</h1>
        <div className={styles.content}>
          <div className={styles.status}>
            <Logo logoUrl={logoUrl} progress={progress} />
            <div className={styles.status_group}>
              <div className={styles.lvl}>Уровень магазина №{shopLvl} <div className={styles.lvl_name}>{shopLvlText}</div></div>
              <div className={styles.commission}>Комиссия <div className={styles.value}>{commission}%</div></div>
            </div>
          </div>
          <ol className={styles.info}>
            <li>Уровень магазина составляется на основе Ваших успехов на маркетплейсе, взамен мы снижаем комиссию</li>
            <li>Мы считаем рейтинг магазина, отслеживаем достижения в вопросах логистики и смотрим на прибыльность магазина</li>
            <li>Доставляйте товары в сроки, оформляйте карточки товаров по правилам и уровень Вашего магазина будет расти!</li>
            <li>Уровень 4 станет доступен после 6 месяцев, уровень 5 после 12 месяцев с момента регистрации</li>
            <li>Регулярное участие в событиях календаря акций повышает Ваш уровень</li>
            <li>Достижения магазина рассчитываются каждый месяц на основании предыдущего месяца. Если в прошлом месяце продажи равны 0, то уровень магазина понижается на 1. Если продажи за прошлый месяц были больше предыдущего на 50%, то магазину будет  присвоен следующий уровень</li>
          </ol>
        </div>
      </div>
    </ModalDialog>
  )
}
