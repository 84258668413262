import React from 'react'
import styles from "./RadioButtonsWithContent.module.scss"
import classNames from 'classnames'

type Props = {
  checked: boolean
  content: JSX.Element
  onChange: (value: boolean) => void
  disabled?: boolean
}

export default function RadioButtonWithContent({ checked, content, disabled, onChange }: Props) {
  const handleClick = () => {
    if (disabled) return
    onChange(true);
  }
  return (
    <div className={classNames(styles.rbtn, { [styles.selected]: checked, [styles.disabled]: disabled })} onClick={handleClick}>
      <div className={styles.content}>{content}</div>
    </div>
  )
}
