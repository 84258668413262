import { Endpoints } from "../constants/server";
import { RegistrationData } from "../types/auth";
import { OrganisationData, UserData } from "../types/registration";
import apiWithoutToken from "../utils/apiWithoutToken";
import api from "../utils/api";
import { sendFormData } from "../utils/helper";

type ConfirmCodeData = {
  phone: string;
  smsCode: string;
};

type LoginsAnswer = {
  data: {
    success: boolean;
  };
};

type AuthObjSend = {
  phone: string;
  sms_only?: boolean;
};

type SignupAnswer = {
  data?: {
    company: OrganisationData;
    user: UserData;
  };
  token?: string;
  refresh_token?: string;
  success: boolean;
};

type RefreshTokenResponse = {
  success: boolean;
  token: string;
  refresh_token: string;
};

export type ConfirmSmsData = {
  success: boolean;
  token?: string;
  refresh_token?: string;
  result?: "login" | "register";
};

export class AuthService {
  static async login(phone: string, smsOnly: boolean) {
    const objData: AuthObjSend = { phone };
    if (smsOnly) objData.sms_only = smsOnly;
    const data = sendFormData(objData);
    const response: LoginsAnswer = await apiWithoutToken.post(
      Endpoints.LOGIN,
      data
    );

    return response.data.success;
  }

  static async confirmCode(objData: ConfirmCodeData) {
    let resData: ConfirmSmsData = {
      success: false,
    };
    await apiWithoutToken
      .post<ConfirmSmsData>(Endpoints.CONFIRM_PHONE, objData)
      .then((res) => {
        resData = res.data;
      })
      .catch((err) => err);

    if (resData.result === "login" && resData.refresh_token && resData.token) {
      this.saveTokens(resData.refresh_token, resData.token);
    }

    return resData;
  }

  static async signup(data: RegistrationData) {
    let resData: SignupAnswer = { success: false };
    await apiWithoutToken
      .post<SignupAnswer>(Endpoints.AUTH_SIGNUP, data)
      .then((res) => {
        resData = res.data;
      });
    if (resData.success) {
      const { token, refresh_token } = resData;
      token && refresh_token && this.saveTokens(refresh_token, token);
    }

    return resData.data;
  }

  static async refreshToken() {
    const refreshToken = localStorage.getItem("refresh_token");
    let data: RefreshTokenResponse = {
      success: false,
      refresh_token: "",
      token: "",
    };
    await apiWithoutToken
      .get<RefreshTokenResponse>(
        `${Endpoints.REFRESH_TOKEN}?refresh_token=${refreshToken}`
      )
      .then((res) => {
        data = res.data;
      })
      .catch((err) => err);
    data.success && AuthService.saveTokens(data.refresh_token, data.token);
    return data.success;
  }

  static async logout() {
    const refreshToken = localStorage.getItem("refresh_token");
    await api
      .post<RefreshTokenResponse>(`${Endpoints.LOGOUT}?refresh_token=${refreshToken}`).catch((err) => err);
    AuthService.removeTokens()
  }

  private static saveTokens(refreshToken: string, accessToken: string) {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
  }
  private static removeTokens() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }
}
