import React, { useEffect, useState } from 'react'
import { ListDataType, ListHeadData, ListRow } from '../../../../../../../types/productListTypes'
import { ItemsList } from '../../../../../../complexes/ItemsList'
import styles from './Confirmation.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/store'
import { BlueButton } from '../../../../../../simples/BlueButton'
import { Checkbox } from '../../../../../../simples/Checkbox'
import { SelectedOrdersItems, buildRequest, nextStep } from '../../../../../../../store/slices/fbs'
import classNames from 'classnames'


const HeadersMulti: ListHeadData[] = [{
  name: 'id',
  text: "ID и дата заказа",
  type: ListDataType.text,
  sortable: true,
  minWidth: "140px",
  width: "140px"
}, {
  name: 'address',
  text: "Адрес доставки",
  type: ListDataType.text,
  sortable: true,
  minWidth: "280px",
  width: "280px"
}, {
  name: 'buyer',
  text: "Покупатель",
  type: ListDataType.text,
  sortable: true,
  minWidth: "220px",
  width: "220px"
}, {
  name: 'cost',
  // text: "Стоимость без доставки",
  content: <>Стоимость <br />без доставки</>,
  type: ListDataType.content,
  sortable: true,
  minWidth: "120px",
  width: "120px",
}, {
  name: 'rates',
  text: "Товары и артикул",
  type: ListDataType.text,
  sortable: true,
  minWidth: "240px",
  width: "240px"
}, {
  name: 'itemsCount',
  text: "Кол-во",
  type: ListDataType.text,
  sortable: true,
  minWidth: "80px",
  width: "80px"
}, {
  name: 'FBSBalance',
  text: "Остаток по FBS",
  type: ListDataType.text,
  sortable: true,
  minWidth: "160px",
  width: "160px"
}]

const HeadersSingle: ListHeadData[] = [{
  name: 'id',
  text: "ID и дата заказа",
  type: ListDataType.text,
  sortable: true,
  minWidth: "140px",
  width: "140px"
}, {
  name: 'address',
  text: "Адрес доставки",
  type: ListDataType.text,
  sortable: true,
  minWidth: "280px",
  width: "280px"
}, {
  name: 'buyer',
  text: "Покупатель",
  type: ListDataType.text,
  sortable: true,
  minWidth: "220px",
  width: "220px"
}, {
  name: 'cost',
  // text: "Стоимость без доставки",
  content: <>Стоимость <br />без доставки</>,
  type: ListDataType.content,
  sortable: true,
  minWidth: "120px",
  width: "120px",
}, {
  name: 'rates',
  text: "Товары и артикул",
  type: ListDataType.text,
  sortable: true,
  minWidth: "340px",
  width: "340px"
}, {
  name: 'FBSBalance',
  text: "Остаток по FBS",
  type: ListDataType.text,
  sortable: true,
  minWidth: "160px",
  width: "160px"
}]

function buildCountString(count: number) {
  let word: string;
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    word = "товаров";
  } else if (lastDigit === 1) {
    word = "товар";
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    word = "товара";
  } else {
    word = "товаров";
  }

  return `${count} ${word}`;
}

export default function Confirmation() {

  const dispatch = useAppDispatch()
  const orders = useAppSelector(s => s.fbs.newOrders.filter(o => s.fbs.newDeliveryRequestOrdersIDs.includes(o.id.toString())))
  const { newDeliveryUnicItems } = useAppSelector(s => s.fbs)
  const [selectedOrdersItems, setSelectedItems] = useState<SelectedOrdersItems>({})
  const [hasMultiItems, setHasMultiItems] = useState(false)
  const [notEnoughItems, setNotEnoughItems] = useState<string[]>([])
  // console.log(newDeliveryUnicItems);

  // console.log(orders);

  useEffect(() => {
    // dispatch(fetchItemsBalance([]))
    const allSelected = {}
    for (const o of orders) {
      allSelected[o.id] = []
      for (const item of o.items) {
        allSelected[o.id].push(item.id)
        if (item.count && item.count > 1) { setHasMultiItems(true) }
      }
    }
    setSelectedItems(allSelected)
  }, [])

  useEffect(() => {
    const ids: string[] = []
    const selectedCount: { [key: string]: number } = {}
    if (orders) {
      for (const oid in selectedOrdersItems) {
        const order = orders?.find(o => o.id.toString() === oid);
        for (const iid of selectedOrdersItems[oid]) {
          const item = order?.items.find(item => item.id === iid)
          if (item) {
            if (!selectedCount[item.id]) {
              selectedCount[item.id] = item.count
            }
            else {
              selectedCount[item.id] += item.count
            }
          }
        }
      }

      // for (const item of o.items) {
      //   if (selectedItems[o.id].includes(item.id)) {
      //     if (!selectedCount[item.id]) {
      //       selectedCount[item.id] = item.count
      //     }
      //     else {
      //       selectedCount[item.id] += item.count
      //     }
      //   }
      // }

      for (const iid in selectedCount) {
        const remainsValue = newDeliveryUnicItems[iid].remains.map(r => r.remains).reduce((v, a) => v + (a||0), 0)
        // console.log({ iid, remainsValue, selected: selectedCount[iid] });
        if (selectedCount[iid] > remainsValue) {
          ids.push(iid)
        }
      }
    }
    // console.log({ ids, selectedCount, selectedItems });

    setNotEnoughItems(ids)
  }, [selectedOrdersItems])

  // console.log(notEnoughItems);



  // const items: ListRow[] = [{
  //   key: '1',
  //   data: [{
  //     name: 'id',
  //     type: ListDataType.content,
  //     content: <div className={styles.id}>
  //       <div className={styles.number}>№2456</div>
  //       <div className={styles.count}>3 товара</div>
  //       <div className={styles.date}>01.02.2023</div>
  //     </div>
  //   }, {
  //     name: 'address',
  //     type: ListDataType.text,
  //     text: 'г. Москва, ул. Библиотечная, д 25'
  //   }, {
  //     name: 'buyer',
  //     type: ListDataType.text,
  //     text: 'Петров Пётр Петрович'
  //   }, {
  //     name: 'cost',
  //     type: ListDataType.text,
  //     text: '50 000'
  //   }, {
  //     name: 'rates',
  //     type: ListDataType.content,
  //     className: styles.rates,
  //     content: <>
  //       <div className={styles.wrap}>
  //         <Checkbox />
  //         <div className={styles.item}>
  //           <div className={styles.product}>Футболка женская</div>
  //           <div className={styles.code}>15560B22222XS</div>
  //         </div>
  //       </div>
  //       <div className={styles.wrap}>
  //         <Checkbox />
  //         <div className={styles.item}>
  //           <div className={styles.product}>Футболка женская</div>
  //           <div className={styles.code}>15560B22222XS</div>
  //         </div>
  //       </div>
  //     </>
  //   }, {
  //     name: 'itemsCount',
  //     type: ListDataType.content,
  //     className: styles.items_count,
  //     content: <>
  //       <div className={styles.wrap}>2</div>
  //       <div className={styles.wrap}>3</div>
  //     </>
  //   }, {
  //     name: 'FBSBalance',
  //     type: ListDataType.content,
  //     className: styles.balance,
  //     content: <>
  //       <div className={styles.wrap}>5</div>
  //       <div className={styles.wrap}>7</div>
  //     </>
  //   }]
  // }]

  const handleSetSelection = (oId: number, itemId: string) => (checked: boolean) => {
    if (checked) {
      setSelectedItems({ ...selectedOrdersItems, [oId]: [...(selectedOrdersItems[oId] || []), itemId] })
    } else {
      setSelectedItems({ ...selectedOrdersItems, [oId]: [...(selectedOrdersItems[oId] || []).filter(id => id !== itemId)] })
    }
  }

  const items: ListRow[] = orders.map(o => {
    let itemsCount = 0
    for (const item of o.items) {
      itemsCount += item.count || 1
    }

    // console.log(o.delivery_date);
    
    const row = {
      key: o.id.toString(),
      data: [
        {
          name: 'id',
          type: ListDataType.content,
          content: <div className={styles.id}>
            <div className={styles.number}>№{o.id}</div>
            <div className={styles.count}>{buildCountString(itemsCount)}</div>
            {/* <div className={styles.date}>{format(new Date(o.delivery_date), 'dd.MM.yyyy')}</div> */}
            <div className={styles.date}>{JSON.stringify(o.delivery_date)}</div>
          </div>
        },
        {
          name: 'address',
          type: ListDataType.text,
          text: o.address// 'г. Москва, ул. Библиотечная, д 25'
        }, {
          name: 'buyer',
          type: ListDataType.text,
          text: o.consumer_name //'Петров Пётр Петрович'
        }, {
          name: 'cost',
          type: ListDataType.text,
          text: o.price.toLocaleString() //'50 000'
        }, {
          name: 'rates',
          type: ListDataType.content,
          className: styles.rates,
          content: <>
            {o.items.map(item => <div className={classNames(styles.wrap, { [styles.not_enough]: notEnoughItems.includes(item.id) })} key={item.id}>
              <Checkbox checked={selectedOrdersItems[o.id] && selectedOrdersItems[o.id].includes(item.id)} onChange={handleSetSelection(o.id, item.id)} />
              <div className={styles.item}>
                <div className={styles.product}>{item.product_name}</div>
                <div className={styles.code}>{item.article}</div>
              </div>
            </div>)}
            {/* <div className={styles.wrap}>
              <Checkbox />
              <div className={styles.item}>
                <div className={styles.product}>Футболка женская</div>
                <div className={styles.code}>15560B22222XS</div>
              </div>
            </div> */}
          </>
        }
      ]
    }

    if (hasMultiItems) {
      row.data.push({
        name: 'itemsCount',
        type: ListDataType.content,
        className: styles.items_count,
        content: <>
          {o.items.map((item, n) => <div className={classNames(styles.wrap, { [styles.not_enough]: notEnoughItems.includes(item.id) })} key={n} >{item.count}</div>)}
          {/* <div className={styles.wrap}>3</div> */}
        </>
      })
    }
    row.data.push({
      name: 'FBSBalance',
      type: ListDataType.content,
      className: styles.balance,
      content: <>
        {/* <div className={styles.wrap}>5</div>
          <div className={styles.wrap}>7</div> */}
        {o.items.map((item, n) => <div className={classNames(styles.wrap, { [styles.not_enough]: notEnoughItems.includes(item.id) })} key={n} >{item.remains.map(r => r.remains).reduce((v, a) => v + (a||0), 0)}</div>)}
      </>
    })

    return row
  })

  const handleNextStep = () => {
    dispatch(buildRequest(selectedOrdersItems))
    dispatch(nextStep())
  }


  return (
    <div>
      <div className={styles.table}>
        <ItemsList headers={hasMultiItems ? HeadersMulti : HeadersSingle} items={items} disablePagination />
      </div>
      <div className={styles.footer}>
        <div className={styles.info}>Снимите выделение с вещей которых нет на складе</div>
        <BlueButton title='Далее' onClick={handleNextStep} disabled={notEnoughItems.length > 0} />
      </div>
    </div>
  )
}
