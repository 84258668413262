import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import { setStep } from "../../../../../store/slices/product";
import { StepsIndicator } from "../../../../complexes/StepsIndicator";

// interface Props {
//   activePage: number;
//   goToStep: (step: number) => void
// }

const steps = [
  { title: "Категория товаров", num: 1 },
  { title: "Характеристики", num: 2 },
  { title: "Цвет, Размер, Штрихкод", num: 3 },
  { title: "Цена", num: 4 },
];

// const CreateFormSteps: FC<Props> = ({ activePage, goToStep }) => {
const CreateFormSteps: FC = () => {

  const dispatch = useAppDispatch()
  const activePage = useAppSelector(state => state.product.step)


  const handleClick = (step: number) => {
    // goToStep(step)
    if (process.env.NODE_ENV !== "production") {
      dispatch(setStep(step))
    } else {
      // возврат на предидущий шаг, на первый нельзя вернуться т.к.товар уже создан
      if (step > 1 && step === activePage - 1) {
        dispatch(setStep(step))
      }
    }
  }

  return <StepsIndicator steps={steps} currentStep={activePage} onClick={handleClick} />

  // return (
  //   <div className={styles.wrapper}>
  //     <ul className={styles.list}>
  //       {steps.map((step) => (
  //         <li
  //           className={classNames(
  //             activePage >= step.num ? styles.itemActive : styles.item,
  //             { [styles.step_finished]: activePage > step.num }
  //           )}
  //           key={step.num}
  //           onClick={handleClick(step.num)}
  //         >
  //           <span
  //             className={
  //               classNames(
  //                 activePage >= step.num ? styles.stepAccess : styles.stepFail,
  //                 styles.step_number,
  //               )
  //             }
  //           >
  //             {step.num}
  //             {activePage > step.num && <img src={step_v_icon} />}
  //           </span>
  //           <p>{step.title}</p>
  //         </li>
  //       ))}
  //     </ul>
  //   </div>
  // );
};

export default CreateFormSteps;
