import { FC } from "react";
import styles from "./Steps.module.scss";
import step_v_icon from "./assets/step_v.svg"
import classNames from "classnames";


// interface Props {
//   activePage: number;
//   goToStep: (step: number) => void
// }

export type Step = { title: string, num: number }

// const steps: Step[] = [
//   { title: "Категория товаров", num: 1 },
//   { title: "Характеристики", num: 2 },
//   { title: "Цвет, Размер, Штрихкод", num: 3 },
//   { title: "Цена", num: 4 },
// ];


type Props = {
  onClick: (step: number) => void
  currentStep: number
  steps: Step[]
}

// const CreateFormSteps: FC<Props> = ({ activePage, goToStep }) => {
const StepsIndicator: FC<Props> = ({onClick, currentStep, steps}) => {

  // const handleClick = (step: number) => () => {
  //   // goToStep(step)
  //   if (process.env.NODE_ENV !== "production") {
  //     dispatch(setStep(step))
  //   } else {
  //     // возврат на предидущий шаг, на первый нельзя вернуться т.к.товар уже создан
  //     if (step > 1 && step === activePage - 1) {
  //       dispatch(setStep(step))
  //     }
  //   }
  // }
  const handleClick = (step: number) => () => {
    onClick(step)
  }

  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        {steps.map((step) => (
          <li
            className={classNames(
              currentStep >= step.num ? styles.itemActive : styles.item,
              { [styles.step_finished]: currentStep > step.num }
            )}
            key={step.num}
            onClick={handleClick(step.num)}
          >
            <span
              className={
                classNames(
                  currentStep >= step.num ? styles.stepAccess : styles.stepFail,
                  styles.step_number,
                )
              }
            >
              {step.num}
              {currentStep > step.num && <img src={step_v_icon} />}
            </span>
            <p>{step.title}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StepsIndicator;
