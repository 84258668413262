import React, { FC } from "react";
import { CategorySelector } from "../CategorySelector";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import { fetchTemplate, getImportedData, selectedSubcategorySelector, setFakeBarcodes, setTemplateSelectorState } from "../../../../../store/slices/massImport";
import { RootState } from "../../../../../store";
import { BlueButton } from "../../../../simples/BlueButton";
import { ButtonSize } from "../../../../simples/BlueButton/BlueButton";
import downloadIcon from "../../assets/download.svg";
import styles from "./TemplateSelector.module.scss";

const TemplateSelector: FC = () => {

  const dispatch = useAppDispatch();
  const state = useAppSelector((state: RootState) => state.massImport.templateSelectorState);
  const selectedSubcategory = useAppSelector(selectedSubcategorySelector());
  const importedData = useAppSelector(getImportedData());

  const handleDownload = () => {
    dispatch(fetchTemplate());
  }
  const handleGenerateBCodes = () => {
    dispatch(setFakeBarcodes(true));
  }
  const handleSetState = (newState) => {
    dispatch(setTemplateSelectorState(newState));
  }

  return (<div className={styles.wrap}>
    <CategorySelector state={state} setState={handleSetState} />
    <div className={styles.footer} >
      <BlueButton
        title="Выгрузить таблицу товаров"
        size={ButtonSize.Normal}
        disabled={!selectedSubcategory}
        onClick={handleDownload}
        iconSrc={downloadIcon}
      />
      <BlueButton
        title="Сгенерировать штрихкоды"
        size={ButtonSize.Normal}
        disabled={importedData.length === 0}
        onClick={handleGenerateBCodes}
      />
    </div>
  </div>
  );
}

export default TemplateSelector;
