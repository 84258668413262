import React from 'react';
import styles from "./RadioButtonsWithContent.module.scss";
import classNames from 'classnames';
import RadioButtonWithContent from './RadioButtonWithContent';

export enum Variant { v1 }

type Props = {
  className?: string
  value?: string
  options: RadioButtonsOption[]
  onChange: (value: string) => void
  disabled?: boolean
  variant?: Variant
}

type RadioButtonsOption = {
  value: string
  content: JSX.Element
  disabled?: boolean
}

export default function RadioButtons({ value, options, className, onChange, disabled, variant }: Props) {
  const handleChange = (value) => () => {
    if (disabled) return
    onChange(value);
  }
  return (
    <div className={classNames(styles.wrap, className, { [styles.v1]: variant === Variant.v1 })}>
      {options.map(o => <RadioButtonWithContent disabled={o.disabled} key={o.value} checked={o.value === value} content={o.content} onChange={handleChange(o.value)} />)}
    </div>
  )
}
