import {PLUG_VALUE} from "../constants/defaults";
import {Endpoints} from "../constants/server";
import api from "../utils/api";

type InnDataAnswer = {
  kpp: string;
  management: {
    name: string;
  };
  name: {
    full_with_opf: string;
    short_with_opf: string;
  };
  inn: string;
  ogrn: string;
  type: string;
};

type BicDataAnswer = {
  name: {
    short: string;
    payment: string;
    full: string;
  };
  correspondent_account: string;
};

type TinRequestAnswer = {
  data: {
    data: Array<{data: InnDataAnswer}>;
  };
};

type BicRequestAnswer = {
  data: {
    data: Array<{data: BicDataAnswer}>;
  };
};
class CompanyService {
  static async getOrganisationInfoByTin(tin: string) {
    const response: TinRequestAnswer = await api.get(
      `${Endpoints.COMPANIES_INN}?inn=${tin}`,
    );
    if (!response.data.data[0]) return null;
    const data = response.data.data[0].data;

    return {
      name: data.name?.short_with_opf || PLUG_VALUE,
      fullName: data.name?.full_with_opf || PLUG_VALUE,
      director: data.management?.name || PLUG_VALUE,
      INN: data?.inn || PLUG_VALUE,
      OGRN: data?.ogrn || PLUG_VALUE,
      KPP: data?.kpp || PLUG_VALUE,
      isIP: data?.type === 'INDIVIDUAL'
    };
  }
  static async getPaymentInfoByBic(bic: string) {
    const response: BicRequestAnswer = await api.get(
      `${Endpoints.COMPANIES_BIK}?bik=${bic}`,
    );
    if (!response.data.data[0]) return null;
    const data = response.data.data[0].data;
    const name = data.name.short || data.name.payment || data.name.full;
    return {
      name: name,
      correspondentAccount: data.correspondent_account,
      paymentAccount: null,
    };
  }
}

export default CompanyService;
