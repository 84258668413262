import React, { useEffect, useState } from 'react'
import styles from './MainPage.module.scss'
import { Layout } from '../../complexes/Layout'
import Chart from './parts/Chart'
// import Indicators from './parts/Indicators/Indicators'
import classNames from 'classnames'
import NewsPanel from './parts/NewsPanel'
import Sidebar from './parts/Sidebar'
import api from "../../../utils/api";
import { News, NotificationTrait } from '../../../types/swagger/llyApi_new'
import { toast } from 'react-toastify'
import Warnings from './parts/Warnings'
import Indicators from '../../complexes/Indicators/Indicators'

type FetchResponse = {
  success?: boolean
  data?: {
    news?: News[]
    notifications?: NotificationTrait[]
  }
  errors?: string[]
}

export default function MainPage() {

  const [news, setNews] = useState<News[]>([])
  const [notifications, setNotifications] = useState<NotificationTrait[]>([])
  const [warnings, setWarnings] = useState<NotificationTrait[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    api.get<FetchResponse>('/companies/news').then(resp => {
      if (resp.data.success) {
        const notifications = resp.data.data?.notifications || []
        setNotifications(notifications.filter(n => !n.is_important))
        setWarnings(notifications.filter(n => n.is_important))
        setNews(resp.data.data?.news || [])
      } else {
        toast.error("Ошибка", { theme: "light" });
      }
      setIsLoading(false)
    }).catch(e => {
      toast.error("Ошибка", { theme: "light" });
      console.log(e);
    })
  }, [])

  return (<Layout title='Главная'>
    {/* <Layout title='Главная' minWidth={1200}> */}
    <div className={styles.wrap}>
      <div className={styles.head}>
        <Warnings warnings={warnings} />
        <Indicators />
      </div>
      <div className={styles.content}>
        <div className={classNames(styles.body, styles.panels_column)}>
          <Chart />
          <NewsPanel isLoading={isLoading} news={news} notifications={notifications} />
        </div>
        <Sidebar />
      </div>
    </div>
  </Layout>)
}
