import React, { useState, useEffect } from 'react'
import { BlueButton } from '../../simples/BlueButton';
import ProductList, { AvailableProductData } from '../ProductList/ProductList';
import styles from "./ModalProductSearch.module.scss";

import searchIcon from "./assets/search_icon.svg";
import xIcon from "./assets/cros.svg";
import filtersIcon from "./assets/filters_icon.svg";
import { FilterLabel } from './parts/FilterLabel';
// import { SearchInput } from './parts/SearchInput';
import { FilterKey, FiltersOptions, FiltersPanel } from './parts/FiltersPanel';
import { FiltersValues } from './parts/FiltersPanel';
import { DiscountOption, DiscountOptionTitle, FilterOption } from './parts/FiltersPanel/FiltersPanel';
import { Endpoints } from '../../../constants/server';

import api from "../../../utils/api";
import { ListDataType, ListHeadData, ListProductDataType } from '../../../types/productListTypes';
import useDebounce from '../../../hooks/lifecycle';
import { TextInput } from '../../simples/TextInput';
import { ProductsDTO } from '../../../types/swagger/llyApi_new';
import { DefaultHeaders } from '../ProductList/DefaultHeaders';


const headers: ListHeadData[] = [
  DefaultHeaders.photo,
  DefaultHeaders.barcode,
  DefaultHeaders.name,
  { ...DefaultHeaders.retail_price, text: "Розничная цена" },
  DefaultHeaders.discountValue,
  DefaultHeaders.discountPercent,
  DefaultHeaders.price,
  DefaultHeaders.fbo,
  DefaultHeaders.fbs,
  DefaultHeaders.discount_value,
  DefaultHeaders.brand,
  DefaultHeaders.sex,
  DefaultHeaders.type,
  DefaultHeaders.category,
  DefaultHeaders.subcategory,
  DefaultHeaders.size,
  DefaultHeaders.color,
  DefaultHeaders.season,
  DefaultHeaders.dateOfCreation,
];

export type FiltersOptionsFromServer = {
  [key in FilterKey]?: string[]
}
export type AditionlParameter = { key: string, value: string | number }


type GoodsAnswer = {
  success: boolean;
  data?: Array<ProductsDTO>;
  availableFiltersValues?: FiltersOptionsFromServer;
};

type Props = {
  isVisible: boolean;
  onClose: (list: string[]) => void;
  aditionlParameters?: AditionlParameter[]
}


export default function ModalProductSearch({ onClose, isVisible, aditionlParameters }: Props) {
  const onCloseModal = () => {
    onClose([]);
    setSelectedData([]);
  }
  const onClickOk = () => {
    // onClose(JSON.parse(JSON.stringify(data)));
    onClose(selectedData)
    setSelectedData([]);
  }


  const [availableFiltersOption, setAvailableFiltersOption] = useState<FiltersOptions>({});
  const [selectedFiltersValues, setSelectedFiltersValues] = useState<FiltersValues>({});
  const [discountOptionValue, setDiscountOptionValue] = useState(DiscountOption.all);
  const [hideZeroBalances, setHideZeroBalances] = useState(false);
  const [filtersPanelActive, setFiltersPanelActive] = useState(false);
  const [search, setSearch] = useState("");

  const [data, setData] = useState<ProductsDTO[]>([]);
  const [selectedData, setSelectedData] = useState<string[]>([]);

  const debaunsedSearchRequest = useDebounce(search, 500);

  useEffect(() => {
    if (!isVisible) return
    setData([]);
    let req = `${Endpoints.GET_PRODUCTS}?`;
    const parameters: string[] = []
    // if (search !== "") { req += `&search=${search}`; }
    if (search !== "") { parameters.push(`search=${search}`) }
    const activeFilters = Object.keys(selectedFiltersValues)
    if (activeFilters.length > 0) {
      // req += `&filters=${JSON.stringify(selectedFiltersValues)}`;
      parameters.push(`filters=${JSON.stringify(selectedFiltersValues)}`)
    }
    switch (discountOptionValue) {
      case DiscountOption.with:
        // req += `&with_discount=1`;
        parameters.push(`with_discount=1`)
        break;
      case DiscountOption.without:
        // req += `&with_discount=0`;
        parameters.push(`with_discount=0`)
        break;

      default:
        break;
    }
    if (hideZeroBalances) {
      // req += `&hide_zero_balances=1`;
      parameters.push(`hide_zero_balances=1`)
    }

    if (aditionlParameters && aditionlParameters.length > 0) {
      // req += '&' + aditionlParameters.map(p => (`${p.key}=${p.value}`)).join('&')
      aditionlParameters.forEach(p => parameters.push(`${p.key}=${p.value}`))
    }

    req += parameters.join('&')

    api.get<GoodsAnswer>(req)
      .then((res) => {
        setData(res.data.data || []);

        if (res.data.availableFiltersValues) {
          const afks = Object.keys(res.data.availableFiltersValues);
          const newAvailableFiltersOption = {};
          for (const key in res.data.availableFiltersValues) {
            if (Object.prototype.hasOwnProperty.call(res.data.availableFiltersValues, key)) {
              const _options: string[] = res.data.availableFiltersValues[key];
              newAvailableFiltersOption[key] = _options.map(option => ({ title: option, id: option }))
            }
          }
          setAvailableFiltersOption(newAvailableFiltersOption);
        }
      })
      .catch((err) => console.log(err));

  }, [isVisible, selectedFiltersValues, debaunsedSearchRequest, hideZeroBalances, discountOptionValue])




  const handleShowFilters = () => {
    setFiltersPanelActive(true);
  }
  const handleHideFilters = () => {
    setFiltersPanelActive(false);
  }

  const handleRemoveFilter = (filterKey: string) => (valueId: string) => {
    const filterValues: string[] = selectedFiltersValues[filterKey] || [];
    setSelectedFiltersValues({
      ...selectedFiltersValues,
      [filterKey]: filterValues.filter(vid => vid !== valueId)
    })
  }

  const handleRemoveDiscountOptionValue = () => {
    setDiscountOptionValue(DiscountOption.all);
  }
  const handleResetZeroBalancesOption = () => {
    setHideZeroBalances(false);
  }
  const handleSelectProducts = ids => {
    setSelectedData([...selectedData, ...ids])
  }
  const handleDeselectProducts = ids => {
    setSelectedData([...selectedData.filter(id => ids.indexOf(id) === -1)])
  }


  const selectedFilters: JSX.Element[] = [];
  if (hideZeroBalances) {
    selectedFilters.push(<FilterLabel text="Скрыть нулевые остатки" key="Скрыть нулевые остатки" fid="Скрыть нулевые остатки" onClickRemove={handleResetZeroBalancesOption} />)
  }
  if (discountOptionValue !== DiscountOption.all) {
    selectedFilters.push(<FilterLabel text={DiscountOptionTitle[discountOptionValue]} key={discountOptionValue} fid={discountOptionValue} onClickRemove={handleRemoveDiscountOptionValue} />)
  }

  Object.keys(selectedFiltersValues).map(filterKey => {
    selectedFiltersValues[filterKey].forEach(filterValue => {
      let title = "";
      const option: FilterOption[] | undefined = availableFiltersOption[filterKey]
      if (option) {
        title = option.find(value => value.id === filterValue)?.title || "";
      }
      selectedFilters.push(<FilterLabel text={title} fid={filterValue} key={filterValue} onClickRemove={handleRemoveFilter(filterKey)} />)
    });
  })

  const handleResetFilters = () => {
    setSelectedFiltersValues({});
    setDiscountOptionValue(DiscountOption.all);
    setHideZeroBalances(false);
    setFiltersPanelActive(false);
  }
  const handleApplyFilters = (selectedFiltersOptions: FiltersValues, hideZeroBalances: boolean, discountOptionValue: DiscountOption) => {
    setSelectedFiltersValues(selectedFiltersOptions);
    setDiscountOptionValue(discountOptionValue);
    setHideZeroBalances(hideZeroBalances);
    setFiltersPanelActive(false);
  }

  const handleClearSearch = () => {
    setSearch("")
  }

  return !isVisible ? null
    :
    <article className={styles.modal_container}>
      <div onClick={onCloseModal} className={styles.modal_underflow}></div>

      <div className={styles.content}>
        <h1>Карточки ваших товаров</h1>
        <div className={styles.head}>
          <div className={styles.filter}>
            <FiltersPanel
              applyFilters={handleApplyFilters}
              resetFilters={handleResetFilters}
              onClose={handleHideFilters}
              discountOptionValue={discountOptionValue}
              hideZeroBalances={hideZeroBalances}
              className={styles.filters_panel}
              isVisible={filtersPanelActive}
              availableFiltersOption={availableFiltersOption}
              selectedFiltersOptions={selectedFiltersValues}
            />
            <BlueButton title='Фильтры' onClick={handleShowFilters} iconSrc={filtersIcon} />
            <div className={styles.filters}>
              {selectedFilters}
            </div>
          </div>
          <TextInput
            className={styles.search}
            value={search}
            onChange={setSearch}
            icon={search.length === 0 ? searchIcon : xIcon}
            onClickOnIcon={handleClearSearch}
            placeholder="Поиск" />
        </div>
        <ProductList
          selectedProducts={selectedData}
          selectProducts={handleSelectProducts}
          deselectProducts={handleDeselectProducts}
          data={data} headers={headers}
          className={styles.products_list}
          emptyText="Поиск не дал результатов"
          isModalDialog={true}
        />
        <div className={styles.footer}>
          <BlueButton title='Готово' onClick={onClickOk} />
        </div>
      </div>
    </article>
}
