export enum DocType {
  TYPE_OGRN = 'TYPE_OGRN',
  TYPE_INN = 'TYPE_INN',
  TYPE_ARTICLE = 'TYPE_ARTICLE',
  TYPE_ORDER = 'TYPE_ORDER',
  TYPE_OGRNIP = 'TYPE_OGRNIP',
  TYPE_PASSPORT = 'TYPE_PASSPORT',
  TYPE_USN_STATEMENT = 'TYPE_USN_STATEMENT'
}

export enum TaxSystemType {
  USN = "USN",
  OSNO = "OSNO",
}

export enum SellerType {
  IP = 'IP',
  UL = 'UL',
}
export const DocsTypesForSellrType = {
  [SellerType.IP]: [DocType.TYPE_OGRNIP, DocType.TYPE_INN, DocType.TYPE_PASSPORT], // документы для ПИ
  [SellerType.UL]: [DocType.TYPE_OGRN, DocType.TYPE_INN, DocType.TYPE_ORDER, DocType.TYPE_ARTICLE]
}

export const DocNames = {
  [DocType.TYPE_OGRNIP]: 'Свидетельство ОГРНИП',
  [DocType.TYPE_INN]: 'Свидетельство ИНН',
  [DocType.TYPE_PASSPORT]: 'Копия паспорта ИП (два разворота в одном файле - с фотографией и регистрацией)',
  [DocType.TYPE_OGRN]: 'Свидетельство ОГРН (или лист записи ЕГРЮЛ)',
  [DocType.TYPE_INN]: 'Свидетельство ИНН',
  [DocType.TYPE_ORDER]: 'Устав компании с отметками налогового органа о регистрации',
  [DocType.TYPE_ARTICLE]: 'Решение или приказ о назначении генерального директора',
}

export enum LawDocStatus {
  ok = 'ok',
  rejected = 'error',
  moderation = 'waiting',
}


export type LawDoc = {
  type: DocType
  id?: number
  file?: string
  title?: string
  progress?: number
  moderationsStatusName?: string
  moderationsStatus?: number
  moderationsDescription?: string
  status?: LawDocStatus
}

