import React, { useEffect, useState } from 'react'

type Props = {
  value: string
  onChange?: (value: string) => void
}

export default function TextInput({ value, onChange }: Props) {

  const [tmpValue, setTmpValue] = useState('')

  useEffect(() => {
    setTmpValue(value)
  }, [value, onChange])


  const handleChange = e => {
    setTmpValue(e?.target?.value || "")
  }

  const handleBlur = e => {
    console.log('handleBlur', tmpValue);
    onChange && onChange(tmpValue || "")
  }

  return (
    <input type="text" value={tmpValue} onChange={handleChange} onBlur={handleBlur} />
  )
}
