import React, { useEffect } from "react";
import styles from "./goodsSearch.module.scss";
import useDebounce from "../../../../../hooks/lifecycle";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import { fetchGoods, setSearchRequest } from "../../../../../store/slices/goodsList";
import { RootState } from "../../../../../store";
import classNames from "classnames";
import { GoodsListTexts } from "../../../../../constants/goodsListTexts";

function GoodsSearch() {

  const dispatch = useAppDispatch();
  const searchRequest = useAppSelector((state: RootState) => state.productsList.searchRequest);

  const debaunsedSearchRequest = useDebounce(searchRequest, 500);

  useEffect(() => {
    dispatch(fetchGoods());
  }, [debaunsedSearchRequest]);

  const handleOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchRequest(ev.target.value));
  };
  const handleClear = () => {
    dispatch(setSearchRequest(""));
  };

  return (
    <main className={styles.wrapper}>
      <article className={styles.body}>
        <input
          className={styles.input}
          type="text"
          placeholder={GoodsListTexts.GoodsSearch_defaultText}
          name="search"
          onChange={handleOnChange}
          value={searchRequest}
        ></input>
        <div className={classNames(styles.btn_clear, { [styles.disabled]: searchRequest.length === 0 })} onClick={handleClear}></div>
      </article>
    </main>
  );
}

export default GoodsSearch;
