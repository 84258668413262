import React from 'react'
import { InfoPanel } from '../../../simples/InfoPanel'
import styles from '../Brands.module.scss'

export default function CertificatesInfo() {
  return (
    <div className={styles.brands_info}>
      <InfoPanel title='Внимание'>
        <div>При успешной модерации покупатели увидят то, что товар сертифицирован</div>
      </InfoPanel>
    </div>
  )
}
