import classNames from "classnames";
import styles from "./PurpleRadioInput.module.scss";

type Props = {
  title?: string;
  isChecked: boolean;
  onClick: () => void;
  className?: string;
};

const PurpleRadioInput = ({title, onClick, className, isChecked}: Props) => (
  <span onClick={onClick} className={classNames(styles.container, className)}>
    <span
      className={classNames(styles.radioInput, isChecked && styles.checked)}
    />
    {title && <p className={styles.title}>{title}</p>}
  </span>
);

export default PurpleRadioInput;
