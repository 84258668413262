import React, { useEffect, useState } from 'react'
import styles from './Indicators.module.scss'
import { IndicatorsTrait } from '../../../types/swagger/llyApi_new'
import Indicator from './Indicator'
import api from "../../../utils/api";

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { SellProductTabConfig } from '../../pages/SellProduct/parts/SellProductContent/const';
import classNames from 'classnames';
import { AnalyticsTabConfig, IndicatorType } from '../../pages/Analytics/const';


// enum IndicatorType {
//   "AverageOrder" = "AverageOrder",
//   "Clients" = "Clients",
//   "OrdersCount" = "OrdersCount",
//   "Sales" = "Sales",
//   "ItemInCart" = "ItemInCart",
// }

type IndicatorConfig = {
  type: IndicatorType
  title: string
  navigate: () => void
}
type FetchResponse = {
  success?: boolean
  data?: IndicatorsTrait[]
  errors?: string[]
}

type Props = {
  hideBtn?: boolean
  className?: string
  activeTabId?: string
}

export default function Indicators({ hideBtn, className, activeTabId }: Props) {

  const navigate = useNavigate();

  const IndicatorsConfigs: IndicatorConfig[] = [{
    type: IndicatorType.Sales,
    title: 'Продажи / мес.',
    navigate: () => { navigate(AnalyticsTabConfig.Sales.route) },
  }, {
    type: IndicatorType.Clients,
    title: 'Клиентов / мес.',
    navigate: () => { navigate(AnalyticsTabConfig.Clients.route) },
  }, {
    type: IndicatorType.ItemInCart,
    title: 'Товаров в корзине / мес.',
    navigate: () => { navigate(AnalyticsTabConfig.ItemInCart.route) },
  }, {
    type: IndicatorType.OrdersCount,
    title: 'Выкуп / мес.',
    navigate: () => { navigate(AnalyticsTabConfig.OrdersCount.route) },
  }, {
    type: IndicatorType.AverageOrder,
    title: 'Средний заказ / мес.',
    navigate: () => { navigate(AnalyticsTabConfig.AverageOrder.route) },
  }]


  const [indicators, setIndicators] = useState<IndicatorsTrait[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    api.get<FetchResponse>('/companies/indicators').then(resp => {
      if (resp.data.success) {
        setIndicators(resp.data.data || [])
      } else {
        toast.error("Ошибка", { theme: "light" });
      }
      setIsLoading(false)
    }).catch(e => {
      toast.error("Ошибка", { theme: "light" });
      console.log(e);
    })
  }, [])

  const handleClick = () => {
    navigate(SellProductTabConfig.categories.route)
  }


  return (
    <div className={classNames(styles.indicators, className)}>
      <div className={classNames(styles.indicators_list, { [styles.no_btn]: hideBtn })}>
        {IndicatorsConfigs.map((n, i) => <Indicator key={i}
          isSelected={n.type === activeTabId}
          title={n.title}
          data={indicators.find(indicator => indicator.type === n.type)}
          navigate={n.navigate}
          isLoading={isLoading}
        />)}
      </div>
      {!hideBtn && <div className={styles.indicators_btn} onClick={handleClick}>Подробнее</div>}
    </div>
  )
}
