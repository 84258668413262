import React, { useEffect, useState, useRef } from 'react'
import styles from './Chart.module.scss'
// import api from '../../../../utils/api'
// import { formatDateForServer } from '../../../../utils/action'
// import { PriceDynamicCollection } from '../../../../types/swagger/llyApi_new'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import { Checkbox } from '../../../simples/Checkbox'
// import { DatePickerDate, datePickerDateToDate } from '../../../complexes/CustomDatePicker/CustomDatePicker'


const Padding = 0 //30

export type Series = {
  id: string
  label: string
  // notSwitchable?: boolean
  rawData: Array<{ value: number | null, date: Date }>
  parts: {
    startIndex: number
    dots: Point[]
    path: string
    bgpath: string
  }[],
}


// type ServerDataResponse = {
//   success?: boolean
//   data?: PriceDynamicCollection[]
//   errors?: {
//     title?: string
//     errors?: string[]
//   }
// }

type ChartDate = {
  rawDate: string
  date: Date
  label: {
    year: string
    month: string
    day: string
  }
}

type ChartSonfig = {
  minVal: number
  maxVal: number
  maxHeight: number
  maxWidth: number
  colWidth: number
  dataScaleFactor: number
  series: Series[]
  allDates: ChartDate[]
}

// const DateFormat = 'yyyy dd MMMM'
const Palette = ['#9E61FF', '#FF5980', '#FFD75E', '#91DC59', '#39C286', '#76EDF4', '#5EB9FC', '#3A5EC1', '#838383', '#000000']

function formatValue(value: number): { value: string, text: string } {
  if (value % 1000000 === 0) { const _v = value / 1000000; return { value: `${_v}`, text: _v !== 0 ? 'млн' : '' } }
  if (value % 1000 === 0) { const _v = value / 1000; return { value: `${_v}`, text: _v !== 0 ? 'тыс' : '' } }
  return { value: value.toString(), text: '' }
}

export type SerieConfig = {
  id: string
  label: string
  // notSwitchable?: boolean
  data: {
    date: string
    value?: number
  }[]
}

type BuildChartSonfig = {
  seriesConfig: SerieConfig[]
  disabledSeries: string[]
  minValue?: number
  maxValue?: number
}

// function buildChartSonfig(serverData: PriceDynamicCollection[], disabledSeries: string[], xLabel: string, yLabel: string): ChartSonfig {
function buildChartSonfig({ seriesConfig, disabledSeries, maxValue, minValue }: BuildChartSonfig): ChartSonfig {
  const config: ChartSonfig = {
    minVal: Infinity,
    maxVal: 0,
    maxHeight: 0,
    maxWidth: 0,
    colWidth: 0,
    dataScaleFactor: 0,
    series: [],
    allDates: [],
  }


  for (let i = 0; i < seriesConfig.length; i++) {
    const size = seriesConfig[i]
    // const rawData: Array<number | null> = []
    const rawData: Array<{ value: number | null, date: Date }> = []
    for (const p of size.data) {
      const date = new Date(p.date)
      rawData.push({
        value: p.value || null,
        date
      })
      if (config.allDates.find(d => d.rawDate === p.date) === undefined) {
        // const label = format(date, DateFormat, { locale: ru })
        config.allDates.push({
          rawDate: p.date,
          date,
          label: {
            year: format(date, 'yyyy', { locale: ru }),
            month: format(date, 'MMMM', { locale: ru }),
            day: format(date, 'dd', { locale: ru }),
          }
        })
      }
    }
    if (disabledSeries.indexOf(size.id) === -1) {
      if (minValue === undefined) { config.minVal = Math.min(...rawData.map(d => d.value).filter(d => d !== null) as number[], config.minVal) }
      if (maxValue === undefined) { config.maxVal = Math.max(...rawData.map(d => d.value).filter(d => d !== null) as number[], config.maxVal) }
    }

    config.series.push({
      id: size.id,
      label: size.label,
      // color: Palette[i % Palette.length],
      rawData,
      parts: [],
      // notSwitchable: size.notSwitchable
      // path: '',
      // dots: []
    })
  }

  config.minVal *= 0.80
  config.maxVal *= 1.1

  if (minValue !== undefined) { config.minVal = minValue }
  if (maxValue !== undefined) { config.maxVal = maxValue }

  const dataDiff = config.maxVal - config.minVal
  config.maxHeight = ViewBoxSize.height - Padding
  config.maxWidth = ViewBoxSize.width - Padding
  config.colWidth = config.maxWidth / (config.allDates.length - 1)
  config.dataScaleFactor = config.maxHeight / dataDiff


  // scale values
  const smoothCommand = SmoothCommandBuilder(0.1) //0.2
  for (const size of config.series) {

    let partRawData: Point[] = []
    let startIndex: number | undefined
    for (let i = 0; i < size.rawData.length; i++) {
      if (size.rawData[i].value !== null) {
        partRawData.push([i * config.colWidth + Padding, ViewBoxSize.height - ((size.rawData[i].value as number) - config.minVal) * config.dataScaleFactor])
        if (startIndex === undefined) { startIndex = i }
      }
      // если разрыв или если последний элемент, добавляем часть
      if ((size.rawData[i].value === null || i === size.rawData.length - 1) && partRawData.length > 0) {
        const path = svgPathD(partRawData, smoothCommand)
        const lastPoint = partRawData[partRawData.length - 1]
        const firstPoint = partRawData[0]
        const bgpath = path + `L ${lastPoint[0]},${config.maxHeight} L ${firstPoint[0]},${config.maxHeight} z`
        size.parts.push({
          dots: partRawData,
          path,
          bgpath,
          startIndex: (startIndex as number),
        })
        partRawData = []
        startIndex = undefined
      }
    }
  }

  // console.log(config);


  return config
}



// type Point = [number, number | null]
type Point = [number, number]

const Vec2 = Object.assign({}, {
  add([ax, ay]: Point, [bx, by]: Point): Point {
    return [ax + bx, ay + by];
    // return [ax + bx, (ay || by || 0) + (by || ay || 0)];
  },
  sub([ax, ay]: Point, [bx, by]: Point): Point {
    return [ax - bx, ay - by];
    // return [ax - bx, (ay || by || 0) - (by || ay || 0)];
  },
  scale(s: number, [x, y]: Point): Point {
    // return [s * x, s * (y || 0)];
    return [s * x, s * y];
  }
});

type Command = (i: number, a: Point[]) => string

const SmoothCommandBuilder = (smoothing: number): Command => (i: number, a: Point[]) => {
  const pStart = a[i - 1];
  const pEnd = a[i];

  const pPrev = a[i - 2] || pStart;
  const pNext = a[i + 1] || pEnd;

  // start control point
  const [cpsX, cpsY] = Vec2.add(
    pStart,
    Vec2.scale(smoothing, Vec2.sub(pEnd, pPrev))
  );
  // end control point
  const [cpeX, cpeY] = Vec2.add(
    pEnd,
    Vec2.scale(smoothing, Vec2.sub(pStart, pNext))
  );
  return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${pEnd[0]},${pEnd[1]}`;
}

const svgPathD = (points: Point[], command: Command) =>
  points.reduce(
    (acc, point, i, a) =>
      i === 0
        ? // if first point
        `M ${point[0]},${point[1]}`
        : // else
        `${acc} ${command(i, a)}`,
    ''
  )


type ClosestDot = {
  x: number
  y: number
  seriesIndex: number
  partIndex: number
  index: number
  rawIndex: number
}



const ViewBoxSize = { width: 1200, height: 400 }

type SeriesColors = { [key: string]: string }

export enum ChartMode {
  default = 'default',
  zones = 'zones',
}

type Props = {
  // colorId?: number
  // dateFrom: DatePickerDate
  // dateTo: DatePickerDate
  // serverData: PriceDynamicCollection[]
  seriesConfig: SerieConfig[]
  xLabel: string
  yLabel: string
  minValue?: number
  maxValue?: number
  yStart?: number
  yStep?: number
  mode?: ChartMode
  notSwitchableSeries?: boolean
  hideControls?: boolean
  isLoading?: boolean
  warningText?: string
  tooltipFormatter?: (activeSerie: Series, rawIndex: number) => JSX.Element
}

// export default function CustomChart({ colorId, dateFrom, dateTo }: Props) {
export default function CustomChart({ seriesConfig, xLabel, yLabel, minValue, maxValue, yStart, yStep, mode,
  notSwitchableSeries, hideControls, tooltipFormatter, isLoading, warningText }: Props) {
  const itemName = 'Блузка'
  // const [serverData, setServerData] = useState<PriceDynamicCollection[]>([])
  const [chartConfig, setChartConfig] = useState<ChartSonfig>()
  const [disabledSeries, setDisabledSeries] = useState<string[]>([])
  const [seriesColors, setSeriesColors] = useState<SeriesColors>({})

  const overlayRef = useRef(null)

  useEffect(() => {
    const colors: SeriesColors = {}
    seriesConfig.forEach((s, i) =>
      colors[s.id] = Palette[i % Palette.length]
    )
    setSeriesColors(colors)
  }, [seriesConfig])

  useEffect(() => {
    // setChartConfig(buildChartSonfig(serverData.filter(sd => disabledSeries.indexOf(sd.size) === -1)))
    setChartConfig(buildChartSonfig({ disabledSeries, seriesConfig, minValue, maxValue }))
  }, [seriesConfig, disabledSeries])


  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return (() => {
      window.removeEventListener('mousemove', handleMouseMove);
    })
  }, [chartConfig])

  const [selectedPoint, setSelectedPoint] = useState<ClosestDot>()



  const filteredSeries = chartConfig === undefined ? [] : chartConfig.series.filter(s => disabledSeries.indexOf(s.label) === -1)

  const handleMouseMove = (event: MouseEvent) => {
    if (filteredSeries.length > 0 && event.target !== null && overlayRef.current !== null && overlayRef.current === event.target) {
      const t: HTMLElement = event.target as HTMLElement
      const rect = t.getBoundingClientRect()

      const scaleFactorW = rect.width / ViewBoxSize.width
      const scaleFactorH = rect.height / ViewBoxSize.height
      const scaleFactor = Math.min(scaleFactorH, scaleFactorW)

      const localX = (event.clientX - rect.left) / scaleFactor
      const localY = (event.clientY - rect.top) / scaleFactor

      let closestDot: ClosestDot = {
        x: -1, y: -1,
        rawIndex: 0,
        index: 0,
        partIndex: 0,
        seriesIndex: 0,
      }
      let minDist = Infinity

      filteredSeries.forEach((size, seriesIndex) => {
        // console.log(size, size.parts);

        if (size.parts && size.parts.length > 0) {
          size.parts.forEach((part, partIndex) => {
            // console.log(part);

            for (let i = 0; i < part.dots.length; i++) {
              const dot = part.dots[i];

              if (dot[1] !== null) {
                const dx = dot[0] - localX;
                const dy = dot[1] - localY;
                // const d = Math.sqrt(dx ** 2 + dy ** 2)
                const d = dx ** 2 + dy ** 2
                if (d < minDist) {
                  minDist = d;
                  closestDot.x = dot[0]// * scaleFactor;
                  closestDot.y = dot[1]// * scaleFactor;
                  closestDot.rawIndex = part.startIndex + i
                  closestDot.index = i;
                  closestDot.partIndex = partIndex;
                  closestDot.seriesIndex = seriesIndex
                }
              }
            }
          })
        }
      })

      if (closestDot.x !== -1) {
        setSelectedPoint(closestDot)
      }
    } else {
      setSelectedPoint(undefined)
    }
  }


  const handleToggleSerie = (label: string) => () => {
    if (disabledSeries.indexOf(label) === -1) {
      setDisabledSeries([...disabledSeries, label])
    } else {
      setDisabledSeries(disabledSeries.filter(sl => sl !== label))
    }
  }


  const gridLines: JSX.Element[] = []
  const gridLabels: JSX.Element[] = []
  let line3Y = ((chartConfig?.maxVal || 130) - 90) * (chartConfig?.dataScaleFactor || 1);
  let line4Y = ((chartConfig?.maxVal || 130) - 60) * (chartConfig?.dataScaleFactor || 1);

  if (chartConfig && chartConfig.allDates.length > 0) {
    let lastYear = chartConfig.allDates[0].date.getFullYear()

    // X
    for (let i = 0; i < chartConfig.allDates.length; i++) {
      const isNewYear = lastYear !== chartConfig.allDates[i].date.getFullYear()
      if (isNewYear) { lastYear = chartConfig.allDates[i].date.getFullYear() }

      const x = i * chartConfig.colWidth + Padding
      const y = chartConfig.maxHeight + Padding

      gridLines.push(<line key={`line-x-${i}`}
        className={classNames(styles.grid_line, { [styles.new_month]: isNewYear })}
        x1={x} y1={0} x2={x} y2={y}
      />)
      const dateLabel = chartConfig.allDates[i].label
      gridLabels.push(<div key={`label-x-${i}`} className={classNames(styles.grid_label, styles.grid_label_x)} style={{ top: y, left: x }}>
        <span className={styles.grid_label_year}>{dateLabel.year}</span>
        <span>{dateLabel.day} {dateLabel.month}</span>
      </div>)
    }

    // Y
    const diff = chartConfig.maxVal - chartConfig.minVal
    const rowsCount = 5
    let _yStep: number | undefined = yStart;
    if (yStep === undefined) {
      let __yStep = Math.floor(diff / rowsCount).toString()
      __yStep = (__yStep[0] + __yStep[1]).padEnd(__yStep.length, '0') // округляем
      _yStep = parseInt(__yStep)
    } else {
      _yStep = yStep
    }

    const startFrom = Math.floor(chartConfig.maxVal / _yStep) * _yStep


    // let _n = 0
    for (let i = startFrom; i > chartConfig.minVal; i -= _yStep) {
      const x = chartConfig.maxWidth + Padding
      const y = (chartConfig.maxVal - i) * chartConfig.dataScaleFactor //i * chartConfig.colWidth + Padding

      // if (_n === 1) { line3Y = y }
      // if (_n === 2) { line4Y = y }
      // _n++

      gridLines.push(<line key={`line-y-${i}`}
        className={classNames(styles.grid_line)}
        x1={0} y1={y} x2={x} y2={y}
      />)
      const _v = formatValue(i)
      gridLabels.push(<div key={`label-y-${i}`}
        className={classNames(styles.grid_label, styles.grid_label_y)}
        style={{ top: y, left: 0 }}
      ><span className={styles.grid_label_value}>{_v.value}</span> {_v.text}</div>)
    }

    // gridLabels.push(<div key='yaxis_name' className={styles.yaxis_name}>Руб</div>)
    gridLabels.push(<div key='yaxis_name' className={styles.yaxis_name}>{xLabel}</div>)

  }


  // type Serie = {
  //   id: string
  //   label: string
  // }

  // const series: Serie[] = serverData.map((s, i) => ({
  //   id: s.size,
  //   label: s.size,
  //   // data: s.data
  // }))

  const activeSerie = chartConfig && selectedPoint ? chartConfig.series[selectedPoint.seriesIndex] : undefined

  if (isLoading) {
    return <div className={styles.chart_wrap}>
      <Skeleton height={552} width={1260} />
    </div>
  }


  return (
    <div>
      <div>
        {chartConfig !== undefined ?
          <div className={styles.chart_wrap}>
            {!hideControls && <div className={classNames(styles.chart_controls, { [styles.not_switchable_series]: notSwitchableSeries })}>
              {/* {serverData.map((s, i) => <div key={s.size} className={styles.serie_cb}>
                <Checkbox
                  label={s.size}
                  checked={disabledSeries?.indexOf(s.size) === -1}
                  onChange={handleToggleSerie(s.size)}
                />
                <div style={{ width: '21px', borderTop: `2px solid ${seriesColors[s.size]}` }}></div>
              </div>)} */}
              {warningText !== undefined && <div className={styles.warning}>{warningText}</div>}
              {chartConfig.series.map((s, i) => <div key={s.id} className={styles.serie_cb}>
                {notSwitchableSeries
                  ? <span className={styles.serie_label}>{s.label}</span>
                  : <Checkbox
                    className={styles.serie_label}
                    label={s.label}
                    checked={disabledSeries?.indexOf(s.id) === -1}
                    onChange={handleToggleSerie(s.id)}
                  />}
                <div style={{ width: '21px', borderTop: `2px solid ${seriesColors[s.id]}` }}></div>
              </div>)}
            </div>}
            <div className={styles.chart}>
              <div className={styles.chart_overlay} ref={overlayRef}></div>
              {selectedPoint && <div className={styles.chart_tooltip} style={{ left: selectedPoint.x, top: selectedPoint.y }}>
                <div className={styles.tooltip_arrow}></div>
                {activeSerie
                  && activeSerie.rawData[selectedPoint.rawIndex].value
                  && <div className={styles.tooltip_wrap}>
                    {tooltipFormatter
                      ? tooltipFormatter(activeSerie, selectedPoint.rawIndex)
                      : <>
                        <div className={styles.price}>{activeSerie.rawData[selectedPoint.rawIndex].value?.toLocaleString()} ₽</div>
                        <div>{itemName}: {activeSerie?.label}</div>
                        <div>{format(activeSerie.rawData[selectedPoint.rawIndex].date, 'dd.MM.yyyy')}</div>
                      </>}
                  </div>}
              </div>}
              <div>
                {gridLabels}
              </div>
              <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
                viewBox={`0 0 ${ViewBoxSize.width} ${ViewBoxSize.height}`}
              // onMouseMove={handleMouseMove}
              // preserveAspectRatio="none"
              // preserveAspectRatio="xMinYMin meet"
              >
                <g>
                  <line className={styles.frame_line} x1={0} y1={0} x2={chartConfig.maxWidth + Padding} y2={0} />
                  <line className={styles.frame_line} x1={0} y1={0} x2={0} y2={chartConfig.maxHeight + Padding} />
                  <line className={styles.frame_line} x1={chartConfig.maxWidth + Padding} y1={0} x2={chartConfig.maxWidth + Padding} y2={chartConfig.maxHeight + Padding} />
                  <line className={styles.frame_line} x1={0} y1={chartConfig.maxHeight + Padding} x2={chartConfig.maxWidth + Padding} y2={chartConfig.maxHeight + Padding} />
                </g>
                {mode === ChartMode.zones && selectedPoint && <g>
                  {selectedPoint.y < line3Y && <rect x="0" y="0" width={chartConfig.maxWidth + Padding} height={line3Y} fill='rgba(255, 89, 128, 0.1)' />}
                  {selectedPoint.y >= line3Y && selectedPoint.y <= line4Y && <rect x="0" y={line3Y} width={chartConfig.maxWidth + Padding} height={line4Y - line3Y} fill='rgba(57, 194, 134, 0.1)' />}
                  {selectedPoint.y > line4Y && <rect x="0" y={line4Y} width={chartConfig.maxWidth + Padding} height={chartConfig.maxHeight + Padding - line4Y} fill='rgba(255, 200, 58, 0.2)' />}
                </g>}
                <g className={styles.grid}>
                  {gridLines}
                </g>
                {filteredSeries.map((size, i) => <g key={size.label}>
                  {size.parts.map((part, n) => <g key={n}>
                    {/* <circle className={classNames(styles.chart_startdot)} cy={part.dots[0][1]} cx={part.dots[0][0]} r='2' fill={seriesColors[size.label]} stroke={seriesColors[size.label]} /> */}
                    {(part.dots.length === 1 || part.dots[0][0] !== 0) && <circle className={classNames(styles.chart_startdot)} cy={part.dots[0][1]} cx={part.dots[0][0]} r='2' strokeWidth={2} fill='transparent' stroke={seriesColors[size.label]} />}
                    <path className={classNames(styles.line, styles.active)} d={part.path} stroke={seriesColors[size.label]} fill="transparent" />
                    {/* <circle className={classNames(styles.chart_startdot)} cy={part.dots[part.dots.length - 1][1]} cx={part.dots[part.dots.length - 1][0]} r='2' fill={seriesColors[size.label]} stroke={seriesColors[size.label]} /> */}
                    {(part.dots[part.dots.length - 1][0] !== chartConfig.maxWidth + Padding) && <circle className={classNames(styles.chart_enddot)} cy={part.dots[part.dots.length - 1][1]} cx={part.dots[part.dots.length - 1][0]} r='2' strokeWidth={2} fill='transparent' stroke={seriesColors[size.label]} />}
                  </g>
                  )}
                </g>)}

                {filteredSeries.map((size, i) => <g key={size.label}>

                  <linearGradient id={`gradient-${i}`} x1="100%" y1="100%" className={classNames(styles.gradient, { [styles.active]: selectedPoint && selectedPoint.seriesIndex === i })}>
                    <stop offset="0%" stopColor={seriesColors[size.label]} stopOpacity=".05" />
                    <stop offset="100%" stopColor={seriesColors[size.label]} stopOpacity=".2" />
                  </linearGradient>

                  {size.parts.map((part, n) => <g key={n}>

                    {(mode !== ChartMode.zones && selectedPoint && selectedPoint.seriesIndex === i)
                      && <path className={classNames(styles.line, styles.active)}
                        d={part.bgpath}
                        fill={`url(#gradient-${i}`}
                      />}
                    {part.dots.filter(dot => dot[1] !== null).map((dot, j) => <circle key={j}
                      className={classNames(styles.chart_dot, { [styles.active]: selectedPoint?.index === j && selectedPoint.seriesIndex === i && selectedPoint.partIndex === n })}
                      cy={dot[1] as number} cx={dot[0]}
                      r={(selectedPoint?.index === j && selectedPoint.seriesIndex === i && selectedPoint.partIndex === n) ? '1' : '0'}
                    />)}
                    {selectedPoint && <line className={styles.cursor_line}
                      x1={selectedPoint.rawIndex * chartConfig.colWidth + Padding}
                      y1={0}
                      x2={selectedPoint.rawIndex * chartConfig.colWidth + Padding}
                      y2={chartConfig.maxHeight + Padding}
                    />}
                  </g>)}
                </g>)}
              </svg>
            </div>
          </div>
          : <Skeleton height='100%' />}
      </div>
    </div>
  )
}
