const RouteBase = '/logistics/FBS/'

export enum LogisticsFBSTabId {
  fbs = 'fbs',
  fbs_balances = 'fbs_balances',
}

type TabConfig = {
  id: LogisticsFBSTabId
  label: string
  // route: string
}

type ILogisticsFBSTabConfig = {
  [key in LogisticsFBSTabId]: TabConfig
}

function buildTabRoute(routeBase: string, tabId: LogisticsFBSTabId) {
  return routeBase + tabId
}

export const LogisticsFBSTabConfig: ILogisticsFBSTabConfig = {
  [LogisticsFBSTabId.fbs]: {
    id: LogisticsFBSTabId.fbs,
    label: 'FBS',
    // route: buildTabRoute(RouteBase, LogisticsFBOTabId.leftovers_in_stock)
  },
  [LogisticsFBSTabId.fbs_balances]: {
    id: LogisticsFBSTabId.fbs_balances,
    label: 'Остатки по FBS',
    // route: buildTabRoute(RouteBase, LogisticsFBOTabId.current_orders)
  },
}