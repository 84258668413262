import classNames from 'classnames';
import React from 'react'
import { useAppSelector } from '../../../../../hooks/store';

import styles from "./Filters.module.scss";
import StarFilter from './StarFilter';
import TypeFilter from './TypeFilter';

interface Props {
  close: () => void
}


export default function Menu({ close }: Props) {

  const isQuestions = useAppSelector(state => state.feedback.isQuestions)
  const isReviews = !isQuestions

  return (
    <div className={classNames(styles.menu, {[styles.questions]: isQuestions})}>
      <div className={styles.underflow} onClick={close}></div>
      <div className={styles.content}>
        {isReviews && <StarFilter />}
        <TypeFilter />
      </div>
    </div>
  )
}


