import { Endpoints } from "../constants/server";
import { GoodsListCategory } from "../store/slices/goodsList";
import { GoodsAnswer, ListProductDataType } from "../types/productListTypes";
import { ProductsDTO } from "../types/swagger/llyApi_new";
import api from "../utils/api";

export enum Order { ASC, DESC }

interface GetGoodsInterface {
  id: number;
  searchRequest?: string;
  limit?: number;
  offset?: number;
  order_by?: string;
  order?: string;
}

interface DeleteProductsAnswer {
  success: boolean;
  count: number;
  fully: boolean;
  goods?: Array<ProductsDTO>;
  statuses?: GoodsListCategory[];
}

interface RestoreProductsAnswer {
  success: boolean;
  count: number;
  goods?: Array<ProductsDTO>;
  statuses?: GoodsListCategory[];
}

export const DISCOUNT_CATEGORY_ID = 5

class GoodsListService {

  static async restoreProducts(ids: Array<string>, selectedStateId: number) {
    let data: RestoreProductsAnswer = { success: false, count: 0 };
    const req = Endpoints.RESTORE_PRODUCTS;
    await api
      .post(req, {
        products: ids
      })
      .then((res) => {
        data.count = res?.data?.data?.count || 0;
      })
      .catch((err) => console.log(err));

    data.goods = (await GoodsListService.getGoods({ id: selectedStateId }));
    data.statuses = (await GoodsListService.getGoodsStatuses());


    return data;
  }

  static async deleteProducts(ids: Array<string>, fully: boolean, selectedStateId: number) {
    let data: DeleteProductsAnswer = { success: false, fully, count: 0, goods: [] };
    // const req = fully ? Endpoints.DELETE_PRODUCTS : Endpoints.SOFT_DELETE_PRODUCTS;
    let req: string = Endpoints.DELETE_PRODUCTS;
    const reqData = {
      products: ids, // JSON.stringify(ids), // 
    }
    if (!fully) {
      req += '?soft-delete=1';
    }

    await api.post(req, reqData).then((res) => {
      data.success = true;
      data.count = res?.data?.data?.count || 0;
    }).catch((err) => console.log(err));

    data.goods = (await GoodsListService.getGoods({ id: selectedStateId }));
    data.statuses = (await GoodsListService.getGoodsStatuses());
    return data;
  }


  static async getGoods({
    id, searchRequest, limit, offset, order_by, order
  }: GetGoodsInterface) {
    let data: GoodsAnswer = { success: false };

    let req = `${Endpoints.GET_PRODUCTS}?status_id=${id}`;
    if (searchRequest && searchRequest !== "") { req += `&search=${searchRequest}`; }
    if (limit && limit > 0) { req += `&limit=${limit}`; }
    if (offset && offset > 0) { req += `&offset=${offset}`; }

    if (order_by) {
      req += `&order_by=${order_by}`;
      if (order) { req += `&order=${order}`; }
    }

    await api
      .get<GoodsAnswer>(req)
      .then((res) => {
        data = res.data;
      })
      .catch((err) => console.log(err));
    return data.data || [];
  }


  static async getGoodsStatuses(): Promise<GoodsListCategory[]> {
    const goodsStatuses = [
      { id: 0, title: "Созданные", countGoods: 0 },
      { id: 1, title: "На создании", countGoods: 0 },
      { id: 2, title: "На модерации", countGoods: 0 },
      { id: 3, title: "Отклоненные", countGoods: 0 },
      { id: 4, title: "Архив", countGoods: 0 },
      { id: DISCOUNT_CATEGORY_ID, title: "Товары со скидкой", countGoods: 0 },
    ];
    let req: string = Endpoints.PRODUCTS_COUNT;

    await api.post(req).then((res) => {
      if (res?.data?.success === true) {
        res.data.data.forEach(s => {
          let i = goodsStatuses.findIndex(_s => _s.id === parseInt(s.id));
          if (i !== -1) {
            goodsStatuses[i].countGoods = s.count || 0;
          }
        });
      }
    }).catch((err) => console.log(err));
    return goodsStatuses;
  }
}




export default GoodsListService;
