import React, { FC, useState } from 'react';
import defaultPhoto from "./assets/product_default_icon.svg";

// import styles from "./ProductList.module.scss";
import { ListData, ListDataType, ListHeadData, ListProductDataType, ListRow } from '../../../types/productListTypes';
import { format } from 'date-fns';
import { ItemsList } from '../ItemsList';
import { ProductsDTO } from '../../../types/swagger/llyApi_new';
import classNames from 'classnames';

import styles from "./ProductList.module.scss"
import Skeleton from 'react-loading-skeleton';

export interface RowData {
  foto: string;
  id: string;
  name: string;
  brand: string;
}

export interface IDiscountConfig {
  count: number
  discount: number
  discountInPercent: boolean
  valueInPercent: boolean
}

export type EditedValue = { id: string, value: string | number }

export enum AvailableProductData {
  // checkbox = "checkbox",
  row_index = "row_index",
  photo = "photo",
  id = "id",
  name = "name",
  brand = "brand",
  category = "category",
  size = "size",
  artikul = "artikul",
  retail_price = "retail_price",
  editable_retail_price = "editable_retail_price",
  edited_price_diff = "edited_price_diff",
  edited_price_diff_percent = "edited_price_diff_percent",
  price = "price",
  barcode = "barcode",
  season = "season",
  color_article = "color_article",
  color = "color",
  sex = "sex",
  type = "type",
  subcategory = "subcategory",
  priceWithDiscount = "priceWithDiscount",
  discountPercent = "discountPercent",
  discountValue = "discountValue",
  stockBalance = "stockBalance",
  discountItemsCount = "discountItemsCount",
  dateOfCreation = "dateOfCreation",
  fbo = "fbo",
  fbs = "fbs",
  storage = 'storage',

  discount_value = "discount_value", // TODO жду от сервера
}

type MapProductToListDataRowProps = {
  product: ProductsDTO
  visibleData: ListHeadData[]
  rowIndex: number
  errors?: string[]
  isFakeBarcodes?: boolean
  selectedProducts?: string[]
  discount?: IDiscountConfig
  editedRetailPrices?: EditedValue[]
  isEditablePrice?: boolean
}

const mapProductToListDataRow = (
  { product, visibleData, rowIndex, errors, isFakeBarcodes, selectedProducts, discount, editedRetailPrices, isEditablePrice }: MapProductToListDataRowProps
): ListRow => {
  const _tmp = (editedRetailPrices?.find(o => o.id === product.id)?.value || product?.price || 0).toString().replaceAll(' ', '').replaceAll(' ', '').replaceAll('₽', '')
  const edited_retail_price = parseInt(_tmp) || 0

  const data: ListData[] = visibleData.map((c) => {
    const d: ListData = { name: c.name, text: "", alignCenter: c.alignCenter };

    // const product0 = product.productsColors[0];

    const [c_name, c_id] = c.name.split('-')

    switch (c_name) {
      // case AvailableProductData.checkbox:
      //   d.boolValue = (selectedProducts?.indexOf(product.id) || -1) >= 0;
      //   break;
      case AvailableProductData.storage:
        d.text = product.storages?.find(s => s.id?.toString() === c_id)?.value?.toLocaleString() || '---'
        break;
        case AvailableProductData.row_index:
          d.text = rowIndex.toString();
          break;
      case AvailableProductData.id:
        d.text = product.id.toString();
        // d.text = product.product_id.toString();
        break;
      case AvailableProductData.photo:
        // d.text = product0?.productsPhotos[0]?.file.url || defaultPhoto;
        d.text = product.url || defaultPhoto;
        d.type = ListDataType.image;
        break;
      case AvailableProductData.artikul:
        d.text = product.id.toString();
        // d.text = product.product_id.toString();
        break;
      case AvailableProductData.name:
        d.text = product.name;
        break;
      case AvailableProductData.brand:
        d.text = product.brand;
        // d.text = product.brend;
        break;
      case AvailableProductData.category:
        // d.text = product.category.name;
        d.text = product.category;
        break;
      case AvailableProductData.size:
        // d.list = product0?.sizes?.map(s => (s.name)); //.map((s,i) => (<span key={i}>{s}</span>))
        // d.type = ListDataType.list;
        d.text = product.size_name;
        d.dataForSorting = product.size_rus || 0
        break;
      case AvailableProductData.season:
        // d.list = product.seasons.map(season => season.name);
        d.list = product.seasons;
        d.type = ListDataType.list;
        break;
      case AvailableProductData.color_article:
      case AvailableProductData.color:
        // d.text = product0?.color_article?.toString() || ""; // product0.color // TODO в ответе нет поля color но есть colors
        d.text = product.main_color || "";
        break;
      case AvailableProductData.retail_price:
        d.text = `${(product.price || 0)?.toLocaleString('ru-RU')} ₽`;
        d.dataForSorting = product.price || 0
        break;
      case AvailableProductData.editable_retail_price: {
        d.isEditable = isEditablePrice
        if (isEditablePrice) {
          d.type = ListDataType.textInput
          d.text = `${edited_retail_price}`;
        } else {
          d.text = `${edited_retail_price.toLocaleString('ru-RU')} ₽`;
        }
        break;
      }
      case AvailableProductData.edited_price_diff:
        d.text = `${(edited_retail_price - (product.price || 0))?.toLocaleString('ru-RU')} ₽`;
        break;
      case AvailableProductData.edited_price_diff_percent: {
        const product_price = (product.price || 0)
        const diff = edited_retail_price - product_price
        d.text = `${Math.round(diff / product_price * 100) || 0}  %`;
        break;
      }

      case AvailableProductData.barcode:
        if (isFakeBarcodes) {
          d.text = Math.floor(Math.random() * Math.pow(10, 10)).toString();
        } else {
          // d.text = product0?.productsColorsSizes[0]?.barcode.toString();
          d.text = product.barcode || ""
        }
        break;
      case AvailableProductData.sex:
        // d.text = product.category?.parent?.parent?.parent?.name;
        d.text = product.gender;
        break;
      case AvailableProductData.type:
        // d.text = product.category?.parent?.parent?.name;
        d.text = product.type
        break;
      case AvailableProductData.subcategory:
        // d.text = product.category?.parent?.name;
        d.text = product.subcategory;
        break;
      case AvailableProductData.discountValue: {
        const _dv = (discount?.discount || 0)
        if (_dv === 0) {
          d.text = "Нет скидки"
        } else {
          if (discount?.discountInPercent) {
            d.text = `${Math.round((_dv * (product.price || 0) / 100)).toLocaleString('ru-RU')} ₽`;
          } else {
            d.text = `${_dv.toLocaleString('ru-RU')} ₽`;
          }
        }
        break;
      }
      case AvailableProductData.discountPercent: {
        const _dv = (discount?.discount || 0)
        let text = ""
        let value = _dv
        if (discount?.discountInPercent) {
          text = `${_dv.toLocaleString('ru-RU')}%`;
        } else {
          // const _dv = (_dpv * (product.price || 0.1) / 100)
          value = (_dv / (product.price || 0) * 100)
          if (isNaN(value)) value = 0
          text = `${value.toFixed(2)}%`;
        }


        d.type = ListDataType.content
        d.content = <span
          className={classNames({
            [styles.warning]: value > 0
          })}>{text}</span>
        break;
      }
      case AvailableProductData.priceWithDiscount: {
        const _dv = (discount?.discount || 0)
        let _p = product.price || 0
        if (discount?.discountInPercent) {
          _p -= _dv * (product.price || 0) / 100
        } else {
          _p -= _dv
        }
        if (_p < 0) { _p = 0 }
        d.text = `${Math.round(_p).toLocaleString('ru-RU')} ₽`;
        break;
      }
      case AvailableProductData.stockBalance: {
        d.text = `----`;
        break;
      }
      case AvailableProductData.dateOfCreation: {
        const _d = new Date((product.created_at || 0) * 1000)
        d.text = format(_d, "dd.MM.yyyy");
        break;
      }
      case AvailableProductData.discountItemsCount: {
        const _dc = (discount?.count || 0)
        if (discount?.valueInPercent) {
          d.text = `${_dc}%`;
        } else {
          d.text = `${_dc}`;
        }
        break;
      }

      case AvailableProductData.fbo: {
        const value = product.fbo || 0
        d.type = ListDataType.content
        d.content = <span
          className={classNames({
            [styles.warning]: (value > 0 && value < 49),
            [styles.low]: (value > 49 && value < 100),
            [styles.empty]: (value === 0),
          })}>{value.toString()}</span>
        break;
      }
      case AvailableProductData.fbs: {
        const value = product.fbs || 0
        d.type = ListDataType.content
        d.content = <span
          className={classNames({
            [styles.warning]: (value > 0 && value < 49),
            [styles.low]: (value > 49 && value < 100),
            [styles.empty]: (value === 0),
          })}>{value.toString()}</span>
        break;
      }

      case AvailableProductData.discount_value: {
        d.text = product.discount_products_count?.toString() //"-----" // product.discount_value;
        break;
      }

      default:
        d.text = "---";
        break;
    }
    return d;
  });

  let hasErrors = false;
  if (errors && errors?.indexOf(product.id) > -1) {
    // if (errors && errors?.indexOf(product.product_id) > -1) {
    hasErrors = true
  }
  let selected = false;
  if (selectedProducts && selectedProducts?.indexOf(product.id) > -1) {
    // if (selectedProducts && selectedProducts?.indexOf(product.product_id) > -1) {
    selected = true
  }

  return {
    // key: product.id,
    key: product.id,
    data,
    hasErrors,
    selected,
    // hasDiscount: product.hasDiscount
  };
};

export enum Order { ASC, DESC }

interface ProductListProps {
  data: ProductsDTO[];
  errors?: string[];
  selectedProducts?: string[];
  headers: ListHeadData[];
  className?: string;
  isFakeBarcodes?: boolean; // TODO remove
  selectProducts?: (selectedProducts: string[]) => void
  deselectProducts?: (selectedProducts: string[]) => void
  removeItemsFromList?: (selectedProducts: string[]) => void
  isRemovable?: boolean
  discount?: IDiscountConfig
  editedRetailPrices?: EditedValue[]
  isEditablePrice?: boolean
  onChangePrices?: (newPrices: EditedValue[]) => void
  emptyText?: string
  isLoading?: boolean
  isModalDialog?: boolean
}

const ProductList: FC<ProductListProps> = ({
  data, errors, headers, isFakeBarcodes, className, isRemovable, discount, onChangePrices, isLoading, isModalDialog,
  selectedProducts, editedRetailPrices, isEditablePrice, selectProducts, deselectProducts, removeItemsFromList, emptyText
}) => {

  let visibleData: Array<ListRow> = data.map((row, rowIndex) => (
    mapProductToListDataRow({
      product: row, visibleData: headers, rowIndex: rowIndex + 1, errors, isFakeBarcodes,
      selectedProducts, discount, editedRetailPrices, isEditablePrice
    })
  ));

  // const handleChange = (fildName: string, values: EditedValue[]) => {
  const handleChange = (fdataId: string, fieldName: string, newValue: string) => {



    switch (fieldName) {
      case AvailableProductData.editable_retail_price: {
        const newValues = [...(editedRetailPrices || [])]
        const valuesIndex = newValues.findIndex(d => d.id === fdataId)
        const newEditedValue: EditedValue = { id: fdataId, value: newValue }

        if (valuesIndex > -1) {
          newValues[valuesIndex] = newEditedValue
        } else {
          newValues.push(newEditedValue)
        }
        onChangePrices && onChangePrices(newValues)
        break;
      }
      default:
        break;
    }

  }

  if(isLoading) {
    return <Skeleton count={11} height={58} />
  }

  return (<ItemsList
    className={className}
    headers={headers}
    items={visibleData}
    selectProducts={selectProducts}
    deselectProducts={deselectProducts}
    isRemovable={isRemovable}
    removeItemsFromList={removeItemsFromList}
    selectedItems={selectedProducts}
    onChange={handleChange}
    emptyText={emptyText}
    isModalDialog={isModalDialog}
  />)

};

export default ProductList;