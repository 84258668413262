import React, { FC } from "react";
import { ReactComponent as LogoSvg } from "../../../assets/icons/appLogo.svg";

type Props = {
  large: boolean;
  className?: string;
};

const Logo: FC<Props> = ({ large, className }) => {
  const sizes = {
    width: large ? "123px" : "84px",
    height: large ? "172px" : "117px",
  };

  return <LogoSvg {...sizes} className={className}  />;
};

export default Logo;
