import React from 'react'
import styles from './Statistic.module.scss'
import { useParams } from 'react-router-dom';
import { StatisticTabConfig, StatisticTabId } from './consts';
import { ITab, Tabs } from '../../complexes/Tabs';
import { Layout } from '../../complexes/Layout';
import { PriceDynamics } from './parts/PriceDynamics';
import Indicators from '../../complexes/Indicators/Indicators';
import { StatisticByCategory } from './parts/StatisticByCategory';
import Turnover from './parts/Turnover/Turnover';
import { RemainingStock } from './parts/RemainingStock';
import { SalesStatistics } from './parts/SalesStatistics';

export default function Statistic() {
  const params = useParams();
  const tabId = params.tabId


  const tabs: ITab[] = [{
    ...StatisticTabConfig[StatisticTabId.turnover],
    content: <Turnover />
  }, {
    ...StatisticTabConfig[StatisticTabId.by_category],
    content: <StatisticByCategory />
  }, {
    ...StatisticTabConfig[StatisticTabId.remaining_stock],
    content: <RemainingStock />
  }, {
    ...StatisticTabConfig[StatisticTabId.sales],
    content: <SalesStatistics />
  }, {
    ...StatisticTabConfig[StatisticTabId.price_dynamics],
    content: <PriceDynamics />
  },]

  const activeTabIndex = tabs.findIndex(tab => tab.id === tabId)

  return (
    <Layout title='Статистика' v2 minWidth={1600} maxWidth={1900} >
      <Indicators hideBtn className={styles.indicators} />
      <Tabs tabs={tabs} activeTabIndex={activeTabIndex} />
    </Layout>
  );
}
