import React, { ChangeEvent, useEffect, useState } from 'react'
import { Endpoints } from '../../../../../constants/server'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { closeMassAnswerDialog, fetchFeedback, fetchTemplates } from '../../../../../store/slices/feedback'
import { ModalDialog, ButtonConfig } from '../../../../simples/ModalDialog'
import { Option, Select } from '../../../../simples/Select'
import api from "../../../../../utils/api";

import styles from "./MassAnswer.module.scss"

type Template = {
  id: number
  title: string
  text: string
}

type Answer = {
  success?: boolean
  data?: Template[]
}

const selectOptions: Option[] = [{ label: "Пять звёзд", value: 5 }, { label: "Четыре звезды", value: 4 }]

export default function MassAnswer() {

  const dispatch = useAppDispatch()
  const isActive = useAppSelector(state => state.feedback.isMassAnswerOpen)
  const templates = useAppSelector(state => state.feedback.templates)

  const [stars, setStars] = useState(5)
  const [text, setText] = useState("")

  useEffect(() => {
    dispatch(fetchTemplates())
  }, [])


  const handleMassAnswer = () => {
    api.post<Answer>(Endpoints.FEEDBACK_MASS_RESPONSE, {
      stars, text
    })
      .then((res) => {
        if (res.data?.success) {
          alert('Ответ сохранён')
          handleClose()
          dispatch(fetchFeedback())
        } else {
          alert('Ошибка!')
        }
      })
      .catch((err) => {
        alert(err)
        console.log(err)
      });
  }
  const handleClose = () => {
    dispatch(closeMassAnswerDialog())
  }
  const handleSelectStars = ({ value }) => {
    setStars(parseInt(value))
  }
  const handleChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }

  const btns: ButtonConfig[] = [{ text: "Отправить", onClick: handleMassAnswer, disabled: text.length < 3 }]


  return (
    <ModalDialog width={920} buttonsConfig={btns} isActive={isActive} handleClose={handleClose} hasCloseBtn={true}>
      <h1>Массовый ответ</h1>
      <div className={styles.info}>Здесь можно отравить шаблонный ответ всем покупателям с выбранной оценкой</div>
      <div className={styles.stars}>
        <label>Ответить на отзывы с оценкой</label>
        <Select className={styles.select} name='stars' onChange={handleSelectStars} options={selectOptions} value={stars} />
      </div>
      <div className={styles.textarea_label}>Текст</div>
      <textarea className={styles.textarea} onChange={handleChangeText} value={text} maxLength={170} />
      <div className={styles.templates}>
        <span className={styles.templates_label}>Созданные шаблоны</span>
        <div className={styles.list}>
          {templates.map(t => <div key={t.id} className={styles.template_label} onClick={() => { setText(t.text || "") }}>{t.title}</div>)}
        </div>
      </div>
    </ModalDialog>
  )
}
