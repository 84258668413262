export const sendFormData = (data: any): FormData => {
  const sendData = new FormData();
  for (let key in data) {
    if (data[key] !== undefined && data[key] !== null) {
      if (Array.isArray(data[key])) {
        sendData.append(`${key}`, JSON.stringify(data[key]));
      } else {
        sendData.append(`${key}`, data[key]);
      }
    }
  }

  return sendData;
};
