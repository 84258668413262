import React, {Dispatch, FC, SetStateAction} from "react";
import styles from "./Search.module.scss";
import loop from "../../../../assets/loop.svg";

interface Props {
    handleSearch: Dispatch<SetStateAction<string>>;
}

const Search:FC<Props> = ({
  handleSearch,
}) => {
  return (
    <div className={styles.search}>
      <input
        type="text"
        placeholder="Поиск"
        onChange={e => handleSearch(e.target.value)}
      />
      <img src={loop} alt="Search Icon" />
    </div>
  );
};

export default Search;