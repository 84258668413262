import styles from "./UploadArea.module.scss";
import React, {FC} from "react";

interface Props {
    info: string
}

const UploadArea:FC<Props> = ({info}) => {
  return (
    <div className={styles.selectEllipse}>
      <span className={styles.selectText}>
        {info}
      </span>
    </div>
  );
};

export default UploadArea;