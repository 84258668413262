import classNames from 'classnames'
import { add, differenceInDays, endOfWeek, isAfter, isBefore, isSameMonth, startOfWeek } from 'date-fns'
import React from 'react'
import { Promotions } from '../../../../../types/swagger/llyApi'
import { formatPeriod } from '../../../../../utils/action'
import calendar_icon from "../../assets/calendar_icon.svg"

import styles from "./CalendarWeek.module.scss"

type Props = {
  actions: Promotions[]
  firstDayOfWeek: Date
  firstDayOfMonth?: Date
  compactView?: boolean
  selectAction: (actionId: number) => void
}

type WeekAction = {
  action: Promotions
  startDayOfThisWeek: number
  daysOnThisWeek: number
}

export const DaysOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"]

export default function WeekActions({ actions, firstDayOfWeek, compactView, firstDayOfMonth, selectAction }: Props) {

  const weekStartDate = startOfWeek(firstDayOfWeek, { weekStartsOn: 1 })
  const weekEndDate = endOfWeek(firstDayOfWeek, { weekStartsOn: 1 })


  const thisWeekActions: WeekAction[] = []
  for (let i = 0; i < actions.length; i++) {
    const action = actions[i];
    const actionFrom = new Date(action.start_date || Date.now())
    const actionTo = new Date(action.finish_date || Date.now())

    // если начало акции раньше конца недели (начало состоялось) и окончание позже начала недели (ещё не кончилась)
    if (isBefore(actionFrom, weekEndDate) && isAfter(actionTo, weekStartDate)) {
      let startDayOfThisWeek = 0
      // если начало акции на этой неделе
      if (isAfter(actionFrom, weekStartDate)) {
        startDayOfThisWeek = (actionFrom.getDay() + 7 - 1) % 7
      }

      let daysOnThisWeek = 7
      // если окончание акции на этой недел
      if (isBefore(actionTo, weekEndDate)) {
        daysOnThisWeek = 7 - differenceInDays(weekEndDate, actionTo)
      }
      thisWeekActions.push({
        action,
        startDayOfThisWeek,
        daysOnThisWeek
      })
    }
  }

  const handleSelectAction = (id?: number) => () => {
    id && selectAction(id)
  }

  return (
    <div className={classNames(styles.wrap, { [styles.compact_view]: compactView })}>
      <div className={styles.actions}>
        {thisWeekActions.map((twa) => <div
          onClick={handleSelectAction(twa.action.id)}
          key={twa.action.id}
          className={classNames(styles.action, styles["col-" + twa.daysOnThisWeek], styles["offset-" + twa.startDayOfThisWeek])}
        >
          <div className={styles.action_head}>{twa.action.title}</div>
          <div className={styles.action_body}>
            <div className={styles.period}>
              <img src={calendar_icon} alt="" /><span>Период:</span> <span>{formatPeriod(twa.action.start_date || "", twa.action.finish_date || "")}</span>
            </div>
            <div className={styles.discount}>
              <span>{twa.action.text}</span> <span>-{twa.action.percent}%!</span>
            </div>
          </div>
        </div>)}
      </div>
      <div className={styles.table}>
        {DaysOfWeek.map((dayOfWeek, n) => {
          const day = add(weekStartDate, { days: n })
          const isAnotherMonth = (firstDayOfMonth && !isSameMonth(firstDayOfMonth, day))
          return (<div key={dayOfWeek} className={classNames(styles.week_cell, styles.day_body, { [styles.is_another_month]: isAnotherMonth })}>
            {day.getDate()}
          </div>)
        })}
      </div>
    </div>
  )
}
