import React from 'react'

import styles from './CustomTextarea.module.scss'
import classNames from 'classnames'

export enum TextLengthStyles {
  v1 = 'v1',
  v2 = 'v2'
}

type Props = {
  readonly?: boolean
  value?: string
  label?: string
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void
  className?: string
  maxLength?: number
  showTextLength?: boolean
  textLengthStyle?: TextLengthStyles
  error?: string
}

const DefaultMaxLength = 255

export default function CustomTextarea({ readonly, value, onChange, className, maxLength, showTextLength, label, placeholder,
  required, disabled, textLengthStyle, error }: Props) {

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(e.target.value)
  }

  const isEmpty = value === "" || value === undefined || value === null
  const hasError = !!error || required && isEmpty || readonly && required && isEmpty

  return (
    <div className={classNames(styles.textarea, className, {
      [styles.disabled]: disabled,
      [styles.required]: required,
      [styles.placeholder]: !!placeholder,
      [styles.has_error]: !!hasError,
      [styles.is_empty]: isEmpty,
    })}>
      {label && <div className={styles.label}>
        {label}
      </div>}
      {/* <textarea className={styles.text} placeholder={placeholder} value={value || ''} onChange={handleChange} maxLength={maxLength || DefaultMaxLength} disabled={readonly} /> */}
      {!readonly
        ? <textarea className={styles.text} placeholder={placeholder} value={value || ''} onChange={handleChange} maxLength={maxLength || DefaultMaxLength} disabled={disabled} />
        : <div className={styles.text_readonly}>{value}</div>
        }
      {showTextLength && !readonly && <div
        className={classNames(styles.maxlen, {
          [styles.warning]: (value?.length || 0) >= (maxLength || 0),
          [styles.v2]: textLengthStyle === TextLengthStyles.v2
        })}
      >{value?.length || 0}/{maxLength || 0}</div>}
      <div className={styles.error}>{error}</div>
      {/* {readonly
        ? <div className={styles.text}>{value || placeholder}</div>
        : <>
          <textarea className={styles.text} placeholder={placeholder} value={value} onChange={handleChange} maxLength={maxLength} />
          {showTextLength && <div className={classNames(styles.maxlen, { [styles.warning]: (value?.length || 0) >= (maxLength || 0) })} >{value?.length || 0}/{maxLength || 0}</div>}
        </>
      } */}
    </div >
  )
}
