const RouteBase = '/price/'

export enum PricesAndDiscountsTabId {
  discount = 'discount',
  edit = 'edit',
  history = 'history'
}

type TabConfig = {
  id: PricesAndDiscountsTabId
  label: string
  route: string
}

type IPricesAndDiscountsTabConfig = {
  [key in PricesAndDiscountsTabId]: TabConfig
}

function buildTabRoute(routeBase: string, tabId: PricesAndDiscountsTabId) {
  return routeBase + tabId
}

export const PricesAndDiscountsTabConfig: IPricesAndDiscountsTabConfig = {
  [PricesAndDiscountsTabId.discount]: {
    id: PricesAndDiscountsTabId.discount,
    label: 'Установка скидки',
    route: buildTabRoute(RouteBase, PricesAndDiscountsTabId.discount)
  },
  [PricesAndDiscountsTabId.edit]: {
    id: PricesAndDiscountsTabId.edit,
    label: 'Редактирование цены',
    route: buildTabRoute(RouteBase, PricesAndDiscountsTabId.edit)
  },
  [PricesAndDiscountsTabId.history]: {
    id: PricesAndDiscountsTabId.history,
    label: 'История',
    route: buildTabRoute(RouteBase, PricesAndDiscountsTabId.history)
  },
}