import React, { useState } from 'react'
import styles from './Orders.module.scss'
import { CalendarPeriod, CalendarPeriodSelector } from '../../../../complexes/CalendarPeriodSelector'
import { CustomDatePicker, DatePickerDate } from '../../../../complexes/CustomDatePicker'
import { BlueButton, ButtonStyle } from '../../../../simples/BlueButton'
import { datePickerDateToDate, dateToDatePickerDate } from '../../../../complexes/CustomDatePicker/CustomDatePicker'
import { sub } from 'date-fns'

export type PeriodDates = { from: number, to: number }

type Props = {
  periodDates: PeriodDates
  setPeriodDates: (data: PeriodDates) => void
}

export default function DateSelector({ periodDates, setPeriodDates }: Props) {
  const [period, setPeriod] = useState<CalendarPeriod | undefined>(CalendarPeriod.month)
  const [dateFrom, setDateFrom] = useState<DatePickerDate>(dateToDatePickerDate(new Date(periodDates.from)))
  const [dateTo, setDateTo] = useState<DatePickerDate>(dateToDatePickerDate(sub(new Date(periodDates.to), { months: 1 })))

  const handleSetPeriod = (period: CalendarPeriod) => {
    setPeriod(period)
    const now = new Date()
    switch (period) {
      case CalendarPeriod.day:
        setDateFrom(dateToDatePickerDate(now))
        setDateTo(dateToDatePickerDate(sub(now, { days: 1 })))
        break;
      case CalendarPeriod.week:
        setDateFrom(dateToDatePickerDate(now))
        setDateTo(dateToDatePickerDate(sub(now, { weeks: 1 })))
        break;
      case CalendarPeriod.month:
        setDateFrom(dateToDatePickerDate(now))
        setDateTo(dateToDatePickerDate(sub(now, { months: 1 })))
        break;
      case CalendarPeriod.year:
        setDateFrom(dateToDatePickerDate(now))
        setDateTo(dateToDatePickerDate(sub(now, { years: 1 })))
        break;
      default:
        break;
    }
  }
  const handleSetDateFrom = (date: DatePickerDate) => {
    setDateFrom(date)
    setPeriod(undefined)
  }
  const handleSetDateTo = (date: DatePickerDate) => {
    setDateTo(date)
    setPeriod(undefined)
  }

  const handleApplayPeriod = () => {
    const from = datePickerDateToDate(dateFrom).getTime()
    const to = datePickerDateToDate(dateTo).getTime()
    setPeriodDates({
      from: Math.min(from, to),
      to: Math.max(from, to)
    })
  }

  return (
    <div className={styles.period}>
      <div className={styles.title}>Сформировать статистику за</div>
      <div className={styles.period_selector}>
        <CalendarPeriodSelector onChange={handleSetPeriod} period={period} className={styles.switch} />
        <div className={styles.dates}>
          <CustomDatePicker date={dateFrom} onChange={handleSetDateFrom} label="с" />
          <CustomDatePicker date={dateTo} onChange={handleSetDateTo} label="по" />
        </div>
        <BlueButton title='Применить' buttonStyle={ButtonStyle.Style1} onClick={handleApplayPeriod} />
      </div>
    </div>
  )
}
