import React from 'react'
import Stars from '../Stars'
import user_icon from "../../assets/user.svg"
import item_default from "../../assets/item_default.png"
import styles from "./Card.module.scss"
import classNames from 'classnames'
import { FeedbackDTO } from '../../../../../types/swagger/llyApi_new'
import { format } from 'date-fns'
import { useAppSelector } from '../../../../../hooks/store'
import { useNavigate } from 'react-router-dom'
import { Routes } from '../../../../../constants/routes'

interface Props {
  selected?: boolean
  feedbackData: FeedbackDTO
}


export default function Card({ feedbackData, selected }: Props) {

  const text = feedbackData.feedback_text


  const isReviews = !useAppSelector(state => state.feedback.isQuestions)
  const navigate = useNavigate();

  const handleShowItem = () => {
    // navigate(`${Routes.UPDATE_PRODUCT_CARD}/${feedbackData.product_id}/${feedbackData.color_id}`);
    navigate(`${Routes.PRODUCT}/${feedbackData.product_id}/${feedbackData.color_id}`);
  }

  return (
    <div className={classNames(styles.card, { [styles.selected]: selected })}>
      <div className={styles.head}>
        <div className={styles.user}>
          <img src={feedbackData.consumer_avatar || user_icon} alt="user" />
          <div>{feedbackData.consumer_name}</div>
        </div>
        {isReviews && <div className={styles.stars}>{feedbackData.rating || 0} <Stars starsCount={feedbackData.rating || 0} showEmpty={true} /></div>}
      </div>
      <div className={styles.body}>
        <div onClick={handleShowItem} className={styles.item} style={{ backgroundImage: `url(${feedbackData.product_url || item_default})` }}></div>
        <div className={styles.content}>
          <div>
            <h3 onClick={handleShowItem}>{feedbackData.product_name}</h3>
            <div className={styles.text}>{text}</div>
          </div>
          <div className={styles.date}>{format(feedbackData.feedback_date*1000, "dd.MM.yyyy")}</div>
        </div>
      </div>
    </div>
  )
}
