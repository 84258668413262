import React, { useEffect, useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import ru from 'date-fns/locale/ru'

// import inputArrowIcon from "./input_arrow.svg"
import styles from "./CustomDatePicker.module.scss"
import "./CustomDatePicker.scss"
import classNames from 'classnames'
import YearSelector from './YearSelector'
import { format } from 'date-fns'

// src\components\pages\SellProduct\parts\SellProductContent\parts\FamousTags\parts\DatePick\DatePick.tsx

const months = [
  "Январь",
  "Феварль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь"
];
export type DatePickerDate = {
  year: number
  mounth: number
  day: number
  hours: number
  minutes: number
  seconds: number
}

type Props = {
  date?: DatePickerDate
  onChange: (date: DatePickerDate) => void
  label?: string
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  dateFormat?: string
  className?: string
  fullWidth?: boolean
}

export const dateToDatePickerDate = (date: Date): DatePickerDate => {
  return {
    year: date.getFullYear(),
    mounth: date.getMonth(),
    day: date.getDate(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
  }
}

export const datePickerDateToDate = (date: DatePickerDate) => {
  return new Date(date.year, date.mounth, date.day, date.hours, date.minutes, date.seconds)
}

const now = new Date()

// https://reactdatepicker.com/
export default function CustomDatePicker({ date, label, onChange, minDate, maxDate, disabled, dateFormat, className, fullWidth }: Props) {

  const [stateDate, setStateDate] = useState<Date>()

  const ref = useRef<ReactDatePicker>(null);

  useEffect(() => {
    const _date = date ? datePickerDateToDate(date) : new Date;
    setStateDate(_date)
  }, [date])


  const handleChange = (date: Date | null) => {
    if (date) {
      setStateDate(date)
    }
  }
  const handleChangeYear = (year: number) => {
    if (stateDate) {
      const d = new Date(stateDate.setFullYear(year))
      setStateDate(d)
    }
  }
  const handleChangeMonth = (month: number) => {
    if (stateDate) {
      const d = new Date(stateDate.setMonth(month))
      setStateDate(d)
    }
  }
  const handleSave = () => {
    if (stateDate) {
      onChange(dateToDatePickerDate(stateDate));
    }
    ref?.current?.setOpen(false);
  }

  const customInput = <span className={classNames(styles.date_input, { [styles.fixed_width]: fullWidth !== true })}>{
    dateFormat !== undefined
      ? format(stateDate || 0, dateFormat)
      : `${date?.day} ${months[date?.mounth || 0]} ${date?.year}`
  }</span>

  return (
    <div className={classNames(styles.wrap, "custom_react_datepicker_wrap", { [styles.disabled]: disabled }, className)}>
      {label && <span className={styles.label}>{label}</span>}
      <ReactDatePicker
        disabled={disabled}
        disabledKeyboardNavigation
        ref={ref}
        dateFormat={dateFormat || "d MMMM yyyy"}
        locale={ru}
        shouldCloseOnSelect={false}
        selected={stateDate}
        onChange={handleChange}
        calendarClassName="custom_react_datepicker"
        minDate={minDate}
        maxDate={maxDate}
        customInput={customInput}

        renderCustomHeader={({ date, changeYear, changeMonth }) => (
          <div>
            <YearSelector
              selectedYear={date.getFullYear()}
              onChange={(date) => {
                changeYear(date)
                handleChangeYear(date)
              }}
              min={(minDate || now).getFullYear()} max={(minDate || now).getFullYear() + 10}
            />
            <div className="months">
              {months.map((option, index) => (
                <span key={option}
                  onClick={() => {
                    changeMonth(index)
                    handleChangeMonth(index)
                  }}
                  className={classNames("month", { selected: index === date.getMonth() })}
                >
                  {option}
                </span>
              ))}
            </div>
            <div className='selected_month'>{months[date.getMonth()]}</div>

          </div>
        )}
      >
        <div className='footer'><span className='save' onClick={handleSave}>Применить</span></div>
      </ReactDatePicker>
    </div>
  )
}
