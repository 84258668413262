import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  CheckboxesSizesType,
  CompoundType, DetailType,
  DetaisType,
  InitializedType,
  MaterialType,
  OneColorType,
  ProductDataType,
  ProductsColorsSizeType,
  ProductsColorsType,
  ProductsCompoundsType,
  ProductsDetailsType,
  ProductsPhotoType,
  SeasonsOptionsType,
  SeasonsType,
  SizeType,
  TagsType,
  UpdateProductDataType,
} from "../../types/update-product";
import {AppDispatch, RootState} from "../index";
import {ProductUpdateService} from "../../services/ProductUpdateService";
import {Endpoints} from "../../constants/server";
import api from "../../utils/api";
import {getUnicID} from "../../utils/getUnicId";
import {
  getFashionMock,
  getNewBarcode,
  getNewColor,
  getNewColorArticle,
  getNewDetail,
  getNewProductsCompound,
  getObjFasionUploaded,
  getObjFileUploaded,
  getPhotosMock,
  getProgressObj,
} from "./getters/update-product-getters";
import {DEFAULT_SELECT} from "../../constants/defaults";
import { MinPrice } from "../../constants/editingProductCard";

import { arraySwap } from '@dnd-kit/sortable';
import ProductEditService from "../../services/ProductEdit";

const additionalColor = {
  id: 1,
  name: "Белый",
  rgb: "#ffffff",
  created_at: 0,
};

const season = {
  id: 1,
  created_at: 0,
  name: "",
};

const dataActionProduct: ProductDataType = {
  id: 0,
  category_id: 0,
  company_id: 0,
  name: "Женская Блузка",
  description: "",
  brand: "Zara",
  main_article: "46582034858",
  country_id: 1,
  package_id: 0,
  collection: "Название коллекции",
  price: 0,
  published_from: 0,
  created_at: 0,
  updated_at: 0,
  category: {
    id: 4,
    parent_id: 3,
    name: "Идёт загрузка...",
    created_at: 0,
  },
  country: {
    id: 0,
    name: "Россия",
  },
  package: {
    id: 0,
    type_id: 1,
    equipment: "Блуза 1 шт.",
    information: "Твёрдая",
    height: 0,
    width: 0,
    depth: 0,
    gross_weight: 0,
    net_weight: 0,
    created_at: 0,
    updated_at: 0,
  },
  productsColors: [
    {
      man_article: "",
      id: 1,
      product_id: 0,
      colors_id: 1, // Это выбранный цвет, на бекенд
      color_article: 0,
      // color: {
      //   id: 1,
      //   name: "Белый",
      //   rgb: "#ffffff",
      //   created_at: 0,
      // },
      additionalColors: [
        {
          id: 1,
          name: "Белый",
          rgb: "#ffffff",
          created_at: 0,
        },
      ],
      sizes: [],
      productsColorsSizes: [
        {
          product_color_id: 1,
          price: 50,
          size_id: 1,
          man_barcode: 555555555,
          barcode: 555555555,
          size: {
            id: 0,
            category_id: 0,
            name: "string",
            created_at: 0,
          },
        },
        {
          product_color_id: 1,
          price: 50,
          size_id: 2,
          man_barcode: 22222222,
          barcode: 22222222,
          size: {
            id: 1,
            category_id: 0,
            name: "string",
            created_at: 0,
          },
        },
      ],
      productsFashion: getFashionMock(),
      productsPhotos: getPhotosMock(),
    },
  ],
  productsCompound: [
    {
      product_id: 1,
      compound_id: 1,
      material_id: 1, //Дропдаун
      value: 60,
      created_at: 0,
      updated_at: 0,
      compound: {
        id: 1,
        category_id: 4,
        name: "Материал",
        created_at: 0,
      },
      material: {
        id: 1,
        name: "Хлопок",
        created_at: 0,
      },
    },
    {
      product_id: 1,
      compound_id: 1,
      material_id: 2, //Дропдаун
      value: 40,
      created_at: 0,
      updated_at: 0,
      compound: {
        id: 2,
        category_id: 4,
        name: "Материал",
        created_at: 0,
      },
      material: {
        id: 2,
        name: "Хлопок",
        created_at: 0,
      },
    },
    {
      product_id: 1,
      compound_id: 2,
      material_id: 2,
      value: "",
      created_at: 0,
      updated_at: 0,
      compound: {
        id: 2,
        category_id: 4,
        name: "Материал подкладки",
        created_at: 0,
      },
      material: {
        id: 2,
        name: "Лён",
        created_at: 0,
      },
    },
  ],
  productsDetails: [],
  seasons: [],
  tags: [],
};

/**
 * Начальное состояние редьюсера update-product/
 */
export const initialState: UpdateProductDataType = {
  initialized: "none",
  mode: false,
  sending: false,
  optionsSelects: {
    // Выпадающие списки всем select
    colors: [],
    seasons: [],
    countries: [],
    packages: [],
    materials: [],
  },
  allTags: [],
  compounds: [],
  sizes: [],
  details: [],
  invalidFormPhotoArray: [],
  invalidFormFieldsArray: [],
  currents: {
    checkboxesChecked: [], // Выбранные фотографии
    checkboxesSizesChecked: [], // Выбранные размеры и ШК
    currentColorId: 1,
    currentCategories: {
      gender: {id: 1, parent_id: null, name: ""},
      type: {id: 2, parent_id: 1, name: ""},
      category: {id: 3, parent_id: 2, name: ""},
      subCategory: {id: 4, parent_id: 3, name: ""},
    },
    showModalSlider: {
      show: false,
      imagesArray: [],
      startIndex: 0,
    },
    showModalUploadFile: {
      show: false,
      progress: [],
    },
    showModalDeleteFile: false,
  },
  categories: {
    id: 4,
    parent_id: 3,
    name: "Блузы",
    created_at: null,
    parent: {
      id: 3,
      parent_id: 2,
      name: "Блузы и рубашки",
      created_at: null,
      parent: {
        id: 2,
        parent_id: 1,
        name: "Одежда",
        created_at: null,
        parent: {
          id: 1,
          parent_id: null,
          name: "Женская",
          created_at: null,
        },
      },
    },
  },
  data: dataActionProduct,
};

// -------------------------------------------
// Slice
// -------------------------------------------

/**
 * Создание слайса updateProductSlice.
 */
export const updateProductSlice = createSlice({
  name: "updateProduct",
  initialState,
  reducers: {
    initialPageAC(state, action: PayloadAction<ProductDataType>) {
      state.data = action.payload;
    },
    setInitializedAC(state, action: PayloadAction<InitializedType>) {
      state.initialized = action.payload;
    },

    initialCheckedCheckboxesAC(
      state,
      action: PayloadAction<Array<ProductsColorsType>>,
    ) {
      // Занесёт все выбранные и генерированные чекбоксы с бэкенда и поставит им disabled
      const checkedFromBackend = [] as Array<CheckboxesSizesType>;
      action.payload.forEach(color => {
        //Итерируемся forEach по цветам
        const checked = color.productsColorsSizes.map(size => ({
          //Итерируемся map по размерам с ШК
          colorId: size.product_color_id,
          sizeId: size.size_id,
          barcode: size.barcode,
          barcodeMan: size.man_barcode,
          generated: true,
        }));

        checkedFromBackend.push(...checked);
      });

      state.currents.checkboxesSizesChecked = checkedFromBackend;
    },

    setModeForm(state) {
      state.mode = !state.mode;
    },

    setSendingFormAC(state, action: PayloadAction<boolean>) {
      state.sending = action.payload;
    },

    setModeFormWithToggleParameterAC(state, action: PayloadAction<boolean>) {
      state.mode = action.payload;
    },

    setShowModalSliderAC(
      state,
      action: PayloadAction<{index: number; fileArray: Array<any>}>,
    ) {
      state.currents.showModalSlider = {
        show: !state.currents.showModalSlider.show,
        startIndex: action.payload.index,
        imagesArray: action.payload.fileArray,
      };
    },
    setShowModalUploadAC(state, action: PayloadAction<boolean>) {
      if (action.payload !== state.currents.showModalUploadFile.show)
        state.currents.showModalUploadFile.show = action.payload;
    },
    setShowModalDeleteAC(state, action: PayloadAction<boolean>) {
      if (action.payload !== state.currents.showModalDeleteFile)
        state.currents.showModalDeleteFile = action.payload;
    },

    //Модальное окно загрузки фото
    addNewShowModalUploadProgressAC(state) {
      state.currents.showModalUploadFile.progress.push(getProgressObj());
    },
    setShowModalUploadFileAC(
      state,
      action: PayloadAction<{index: number; file: any}>,
    ) {
      state.currents.showModalUploadFile.progress[action.payload.index].file =
        action.payload.file;
    },
    clearShowModalUploadProgressAC(state) {
      state.currents.showModalUploadFile.progress = [];
    },
    setShowModalUploadProgressAC(state, action: PayloadAction<any>) {
      state.currents.showModalUploadFile.progress[
        action.payload.index
      ].percent = action.payload.percent;
    },

    //меняем Категории
    setInputCategoryGenderAC(state, action: PayloadAction<number>) {
      state.currents.currentCategories.gender.id = action.payload;
    },
    setInputCategoryTypeAC(state, action: PayloadAction<number>) {
      state.currents.currentCategories.type.id = action.payload;
    },
    setInputCategorySubcategoriesAC(state, action: PayloadAction<number>) {
      state.currents.currentCategories.subCategory.id = action.payload;
    },
    setInputCategoryCategoriesAC(state, action: PayloadAction<number>) {
      state.currents.currentCategories.category.id = action.payload;
    },

    setInputNameAC(state, action: PayloadAction<string>) {
      state.data.name = action.payload;
    },

    setInputBrandAC(state, action: PayloadAction<string>) {
      state.data.brand = action.payload;
    },

    // setInputSupplierArticleAC(state, action: PayloadAction<string>) {
    //   state.data.main_article = action.payload;
    // },

    setInputCountryAC(state, action: PayloadAction<number>) {
      state.data.country_id = action.payload;
    },

    //////////////////////////////////
    ////////////////////////////////////
    // State Блока Упаковка
    setInputPackageAC(state, action: PayloadAction<number>) {
      state.data.package.type_id = action.payload;
    },

    // State Блока Комплектация
    setInputEquipmentAC(state, action: PayloadAction<string>) {
      state.data.package.equipment = action.payload;
    },

    setInputPackTypeAC(state, action: PayloadAction<string>) {
      state.data.package.information = action.payload;
    },

    setInputWidthAC(state, action: PayloadAction<number>) {
      state.data.package.width = action.payload;
    },

    setInputDeptAC(state, action: PayloadAction<number>) {
      state.data.package.depth = action.payload;
    },

    setInputHeightAC(state, action: PayloadAction<number>) {
      state.data.package.height = action.payload;
    },

    // >> Особая валидация: Netto должна быть минимум на 10 меньше Brutto
    setInputWeightBruttoAC(state, action: PayloadAction<number>) {
      state.data.package.gross_weight = action.payload;
      if (
        +state.data.package.gross_weight - 10 <
        +state.data.package.net_weight
      ) {
        state.data.package.net_weight = +state.data.package.gross_weight - 10;
      }
    },
    setInputWeightNettoAC(state, action: PayloadAction<number>) {
      state.data.package.net_weight = action.payload;
      if (
        +state.data.package.gross_weight - 10 <
        +state.data.package.net_weight
      ) {
        state.data.package.net_weight = +state.data.package.gross_weight - 10;
      }
    },
    // << Особая валидация: Netto должна быть минимум на 10 меньше Brutto

    // State Блока Коллекция и сезон
    setInputCollectionNameAC(state, action: PayloadAction<string>) {
      state.data.collection = action.payload;
    },

    // Добавление, удаление и изменение сезонов >>>
    addInputSeasonAC(state) {
      const examples = state.data.seasons.map(season => season.id);
      const freeOption = state.optionsSelects.seasons.filter(
        option => !examples.includes(option.id),
      );
      state.data.seasons.push({...season, id: freeOption[0].id});
    },
    removeInputSeasonAC(state, action: PayloadAction<number>) {
      state.data.seasons.splice(action.payload, 1);
    },
    setInputSeasonAC(
      state,
      action: PayloadAction<{index: number; action: number | string}>,
    ) {
      state.data.seasons[action.payload.index].id = Number(
        action.payload.action,
      );
    },
    // Добавление, удаление и изменение сезонов <<<

    // Добавление, удаление и изменение Составов - Compounds >>>
    addInputCompoundsAC(state, action: PayloadAction<number>) {
      const filteredCompounds = state.data.productsCompound.filter(
        compound => compound.compound_id === Number(action.payload),
      );
      const examples = filteredCompounds.map(compound => compound.material_id);
      const freeOption = state.optionsSelects.materials.filter(
        option => !examples.includes(option.id),
      );
      state.data.productsCompound.push({
        ...getNewProductsCompound(),
        compound_id: action.payload,
        material_id: freeOption[0].id,
      });
    },
    removeInputCompoundsAC(
      state,
      action: PayloadAction<{compound_id: number; material_id: number}>,
    ) {
      const compoundIndex = state.data.productsCompound.findIndex(
        compound =>
          compound.compound_id === action.payload.compound_id &&
          compound.material_id === action.payload.material_id,
      );
      state.data.productsCompound.splice(compoundIndex, 1);
    },
    setInputOptionCompoundsAC(
      state,
      action: PayloadAction<{
        compound_id: number;
        material_id: number;
        action: any;
      }>,
    ) {
      const compoundIndex = state.data.productsCompound.findIndex(
        compound =>
          compound.compound_id === action.payload.compound_id &&
          compound.material_id === action.payload.material_id,
      );

      state.data.productsCompound[compoundIndex].material_id = Number(
        action.payload.action,
      );
    },
    setInputValueCompoundsAC(
      state,
      action: PayloadAction<{
        compound_id: number;
        material_id: number;
        action: any;
      }>,
    ) {
      const compoundIndex = state.data.productsCompound.findIndex(
        compound =>
          compound.compound_id === action.payload.compound_id &&
          compound.material_id === action.payload.material_id,
      );

      state.data.productsCompound[compoundIndex].value = action.payload.action;
    },
    // Добавление, удаление и изменение Составов - Compounds <<<

    // Цвета
    setCurrentColorIdAC(state, action: PayloadAction<number>) {
      state.currents.currentColorId = action.payload;
    },
    addNewColorAC(state) {
      // const additionalColorPush = [{...additionalColor, id: 1}];
      // state.optionsSelects.colors[0]
      const newProductColor = getNewColor(state.optionsSelects.colors[0].id)

      state.data.productsColors.push(newProductColor)

      // state.data.productsColors.push({
        // ...getNewColor(),
        // additionalColors: additionalColorPush,
      // });

      // //Ставим артикул цвета
      // const currentIndexColor = state.data.productsColors.findIndex(
      //   color => color.id === state.currents.currentColorId,
      // );
      // state.data.productsColors[
      //   state.data.productsColors.length - 1
      // ].color_article = getNewColorArticle(state, currentIndexColor + 1);

      // //Переключаем на новый цвет
      state.currents.currentColorId =
        state.data.productsColors[state.data.productsColors.length - 1].id;
    },
    removeColorAC(state, action: PayloadAction<number>) {
      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === Number(action.payload),
      );
      state.data.productsColors.splice(currentIndexColor, 1);

      if(action.payload === state.currents.currentColorId){
      state.currents.currentColorId =
        state.data.productsColors[state.data.productsColors.length - 1].id;
      }
    },
    setInputGeneralColorAC(state, action: PayloadAction<any>) {
      const currentColor = state.data.productsColors.find(
        color => color.id === state.currents.currentColorId,
      );
      if(!currentColor) return
      // const currentIndex = state.data.productsColors.findIndex(
      //   color => color.id === Number(action.payload.colorsId),
      // );
      // state.data.productsColors[currentIndex].colors_id = Number(
      //   action.payload.action,
      // );


      //Ставим артикул цвета
      // const currentIndexColor = state.data.productsColors.findIndex(
      //   color => color.id === state.currents.currentColorId,
      // );
      // state.data.productsColors[
      //   state.data.productsColors.length - 1
      // ].color_article = getNewColorArticle(state, currentIndexColor);

      // state.data.productsColors[
      //   state.data.productsColors.length - 1
      // ].color_article = getNewColorArticle();

      currentColor.colors_id = Number(action.payload.action);
      currentColor.color_article = getNewColorArticle(currentColor);

      currentColor.productsColorsSizes = currentColor.productsColorsSizes.map(stateSize => ({
        ...stateSize,
        barcode: "",
      }))
    },
    setManArt(state, action: PayloadAction<string>) {
      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === state.currents.currentColorId,
      );
      // state.data.productsColors[currentIndexColor].man_article = (parseInt(action.payload) || 0).toString();
      state.data.productsColors[currentIndexColor].man_article = action.payload;
    },

    setInputlArticleColorAC(state, action: PayloadAction<any>) {
      // не писать артикул цвета
      const currentIndex = state.data.productsColors.findIndex(
        color => color.id === state.currents.currentColorId,
      );
      state.data.productsColors[currentIndex].color_article = action.payload;
    },
    // Добавление, удаление и изменение доп. цветов >>>
    addInputAdditionalColorAC(
      state,
      action: PayloadAction<{colorsId: number; index: number}>,
    ) {
      const currentColor = state.data.productsColors.find(
        color => color.id === Number(action.payload.colorsId)
      );
      if(!currentColor) return
      // const currentIndexColor = state.data.productsColors.findIndex(
      //   color => color.id === Number(action.payload.colorsId),
      // );
      // const countId =
      //   state.data.productsColors[currentIndexColor].additionalColors.length +
      //   1;
      // if (countId <= 2) {
        // state.data.productsColors[currentIndexColor].additionalColors.push({
        //   ...additionalColor,
        //   id: countId,
        // });
        // //Ставим артикул цвета
        // state.data.productsColors[
        //   state.data.productsColors.length - 1
        // ].color_article = getNewColorArticle(state.data.productsColors[currentIndexColor]);
      if(currentColor.additionalColors.length < 2){
        currentColor.additionalColors.push({
            ...additionalColor,
            id: currentColor.additionalColors.length+1,
          });
          currentColor.color_article = getNewColorArticle(currentColor);
      } else alert("Можно выбрать только 2 дополнительны цвета");
    },
    removeInputAdditionalColorAC(
      state,
      action: PayloadAction<{colorsId: number; index: number}>,
    ) {
      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === Number(action.payload.colorsId),
      );
      state.data.productsColors[currentIndexColor].additionalColors.splice(
        action.payload.index,
        1,
      );
    },
    setInputAdditionalColorAC(state, action: PayloadAction<any>) {
      // const currentIndexColor = state.data.productsColors.findIndex(
      //   color => color.id === Number(action.payload.colorsId),
      // );
      // state.data.productsColors[currentIndexColor].additionalColors[
      //   Number(action.payload.index)
      // ].id = action.payload.action;
      // //Ставим артикул цвета
      // state.data.productsColors[
      //   state.data.productsColors.length - 1
      // ].color_article = getNewColorArticle(state, currentIndexColor);
      const currentColor = state.data.productsColors.find(
        color => color.id === Number(action.payload.colorsId)
      );
      if(!currentColor) return
      currentColor.additionalColors[Number(action.payload.index)].id = action.payload.action;
      currentColor.color_article = getNewColorArticle(currentColor);
    },
    // Добавление, удаление и изменение доп цветов <<<

    // file работа с файлами и state фото
    setNewFileAC(state, action: PayloadAction<any>) {
      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === state.currents.currentColorId,
      );
      state.data.productsColors[currentIndexColor].productsPhotos.push(
        action.payload,
      );
    },
    //Меняем основное фото товара
    setGeneralPhotoAC(state, action: PayloadAction<number>) {
      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === state.currents.currentColorId,
      );

      state.data.productsColors[currentIndexColor].productsPhotos =
        state.data.productsColors[currentIndexColor].productsPhotos.map(
          photo => {
            if (photo.file_id === action.payload) {
              return {...photo, main: 1};
            } else if (photo.main === 1) {
              return {...photo, main: 0};
            } else {
              return {...photo, main: 0};
            }
          },
        );
    },
    setNewFashionAC(state, action: PayloadAction<any>) {
      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === state.currents.currentColorId,
      );
      state.data.productsColors[currentIndexColor].productsFashion =
        action.payload;
    },
    setInputPhotoCheckAC(state, action: PayloadAction<any>) {
      // Переключение чекбоксов на фотографиях
      const currentColorId = state.currents.currentColorId;
      const getChecked = state.currents.checkboxesChecked.findIndex(
        photo =>
          photo.colorId === currentColorId && photo.photoId === action.payload,
      );
      if (getChecked === -1) {
        state.currents.checkboxesChecked.push({
          colorId: currentColorId,
          photoId: action.payload,
        });
      } else {
        state.currents.checkboxesChecked.splice(getChecked, 1);
      }
    },
    removeChangedPhotosAC(state) {
      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === state.currents.currentColorId,
      );
      const photos =
        state.data.productsColors[currentIndexColor].productsPhotos;
      const checkeds = state.currents.checkboxesChecked;
      const totalArray = checkeds.map(photo => photo.photoId);
      const total = photos.filter(
        photo => totalArray.indexOf(photo.file_id) === -1,
      );

      state.data.productsColors[currentIndexColor].productsPhotos = total;
      state.currents.checkboxesChecked = [];
    },
    setFileIdToProgressAC(
      state,
      action: PayloadAction<{index: number; id: number}>,
    ) {
      if (state.currents.showModalUploadFile.progress[action.payload.index]) {
        state.currents.showModalUploadFile.progress[action.payload.index].id = action.payload.id;
      }
    },
    escapePhotoAC(state, action: PayloadAction<number>) {
      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === state.currents.currentColorId,
      );
      const progressFileId =
        state.currents.showModalUploadFile.progress[action.payload].id;
      const photoIndex = state.data.productsColors[
        currentIndexColor
      ].productsPhotos.findIndex(photo => photo.file_id === progressFileId);

      state.currents.showModalUploadFile.progress.splice(action.payload, 1);
      state.data.productsColors[currentIndexColor].productsPhotos.splice(
        photoIndex,
        1,
      );
    },
    movePhotoAC(state, action: PayloadAction<{ oldIndex: number, newIndex: number }>) {
      const { oldIndex, newIndex } = action.payload
      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === state.currents.currentColorId,
      );
      state.data.productsColors[currentIndexColor].productsPhotos = arraySwap(state.data.productsColors[currentIndexColor].productsPhotos, oldIndex, newIndex)
    },

    // Детали
    addNewDetailAC(state, action: PayloadAction<{id: number; detail: DetailType}>) {
      const productId = state.data.id;
      const categoryId = state.data.category.id;

      state.data.productsDetails.push(
        getNewDetail(
          action.payload.id,
          action.payload.detail,
          categoryId,
          productId,
        ),
      );
    },
    removeNewDetailAC(state, action: PayloadAction<number>) {
      const currentIndexDetail = state.data.productsDetails.findIndex(
        detail => detail.detail_id === action.payload,
      );
      if (currentIndexDetail !== -1)
        state.data.productsDetails.splice(currentIndexDetail, 1);
    },
    setInputDetailOptionAC(state, action: PayloadAction<any>) {
      const currentIndexDetail = state.data.productsDetails.findIndex(
        detail => detail.detail_id === action.payload.detailId,
      );
      state.data.productsDetails[currentIndexDetail].option_id =
        action.payload.action;
    },
    setInputDetailValueAC(state, action: PayloadAction<any>) {
      const currentIndexDetail = state.data.productsDetails.findIndex(
        detail => detail.detail_id === action.payload.detailId,
      );
      state.data.productsDetails[currentIndexDetail].value =
        action.payload.action;
    },

    setInputBarcodeAC(
      state,
      action: PayloadAction<{sizeId: number; action: string | number}>,
    ) {
      const currentColorId = state.currents.currentColorId;

      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === state.currents.currentColorId,
      );

      const sizeIndex = state.data.productsColors[
        currentIndexColor
      ].productsColorsSizes.findIndex(
        size =>
          size.product_color_id === currentColorId &&
          size.size_id === action.payload.sizeId,
      );

      state.data.productsColors[currentIndexColor].productsColorsSizes[
        sizeIndex
      ].barcode = action.payload.action;
    },

    setInputPriceAC(
      state,
      action: PayloadAction<{ sizeId: number; action: string | number }>,
    ) {
      const currentColorId = state.currents.currentColorId;

      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === state.currents.currentColorId,
      );

      const sizeIndex = state.data.productsColors[
        currentIndexColor
      ].productsColorsSizes.findIndex(
        size =>
          size.product_color_id === currentColorId &&
          size.size_id === action.payload.sizeId,
      );

      state.data.productsColors[currentIndexColor].productsColorsSizes[
        sizeIndex
      ].price = (parseInt(action.payload.action.toString()) || 0);
    },
    setInputBarcodeManAC(
      state,
      action: PayloadAction<{sizeId: number; action: string | number}>,
    ) {
      const currentColorId = state.currents.currentColorId;

      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === state.currents.currentColorId,
      );

      const sizeIndex = state.data.productsColors[
        currentIndexColor
      ].productsColorsSizes.findIndex(
        size =>
          size.product_color_id === currentColorId &&
          size.size_id === action.payload.sizeId,
      );

      state.data.productsColors[currentIndexColor].productsColorsSizes[
        sizeIndex
      ].man_barcode = action.payload.action;
    },
    setInputBarcodeCheckAC(state, action: PayloadAction<number>) {
      // Переключение чекбоксов на размерах
      const currentColorId = state.currents.currentColorId;
      const getChecked = state.currents.checkboxesSizesChecked.findIndex(
        checkbox =>
          checkbox.colorId === currentColorId &&
          checkbox.sizeId === action.payload,
      );

      if (getChecked === -1) {
        const currentIndexColor = state.data.productsColors.findIndex(
          color => color.id === currentColorId,
        );
        const sizeIndex = state.data.productsColors[
          currentIndexColor
        ]?.productsColorsSizes.findIndex(
          size =>
            size.product_color_id === currentColorId &&
            size.size_id === action.payload,
        );

        state.currents.checkboxesSizesChecked.push({
          colorId: currentColorId,
          sizeId: action.payload,
          barcode:
            state.data.productsColors[currentIndexColor]?.productsColorsSizes[
              sizeIndex
            ].barcode,
          barcodeMan:
            state.data.productsColors[currentIndexColor]?.productsColorsSizes[
              sizeIndex
            ].man_barcode,
          generated: false,
        });
      } else {
        state.currents.checkboxesSizesChecked.splice(getChecked, 1);
      }
    },
    setGeneratedBarcodeAC(state, action: PayloadAction<number>) {
      // поставим disabled размеру, у уоторго сгенерирован ШК
      const currentColorId = state.currents.currentColorId;
      const checkboxIndex = state.currents.checkboxesSizesChecked.findIndex(
        checked =>
          checked.sizeId === action.payload &&
          checked.colorId === currentColorId,
      );

      state.currents.checkboxesSizesChecked[checkboxIndex].generated = true;
    },

    addNewBarcodeAC(state, action: PayloadAction<number>) {
      const currentColorId = state.currents.currentColorId;
      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === currentColorId,
      );
      state.data.productsColors[currentIndexColor]?.productsColorsSizes.push(
        getNewBarcode(currentColorId, action.payload),
      );
    },

    toggleNewTagAC(state, action: PayloadAction<number>) {
      const indexChoosedTag = state.data.tags.findIndex(
        tag => tag.id === action.payload,
      );

      if (indexChoosedTag === -1) {
        if (state.data.tags.length < 10) {
          const indexTag = state.allTags.find(
            tag => tag.id === action.payload,
          ) as TagsType;
          state.data.tags.push(indexTag);
        }
      } else {
        state.data.tags.splice(indexChoosedTag, 1);
      }
    },

    removeBarcodeAC(state, action: PayloadAction<number>) {
      const currentColorId = state.currents.currentColorId;
      const currentIndexColor = state.data.productsColors.findIndex(
        color => color.id === currentColorId,
      );
      const sizeIndex = state.data.productsColors[
        currentIndexColor
      ].productsColorsSizes.findIndex(
        size =>
          size.product_color_id === currentColorId &&
          size.size_id === action.payload,
      );

      state.data.productsColors[currentIndexColor].productsColorsSizes.splice(
        sizeIndex,
        1,
      );
    },

    setInputDescriptionAC(state, action: PayloadAction<string>) {
      state.data.description = action.payload;
    },

    setOptionsColorsSelectsAC(
      state,
      action: PayloadAction<Array<OneColorType>>,
    ) {
      state.optionsSelects.colors = action.payload;
    },

    setOptionsSeasonsSelectsAC(
      state,
      action: PayloadAction<Array<SeasonsOptionsType>>,
    ) {
      state.optionsSelects.seasons = action.payload;
    },

    setCategoriesOptionsAC(state, action: PayloadAction<any>) {
      state.categories = action.payload;
    },
    setCountriesOptionsAC(state, action: PayloadAction<any>) {
      state.optionsSelects.countries = action.payload;
    },

    setPackagesOptionsAC(state, action: PayloadAction<any>) {
      state.optionsSelects.packages = action.payload;
    },
    setMaterialsOptionsAC(state, action: PayloadAction<Array<MaterialType>>) {
      state.optionsSelects.materials = action.payload;
    },

    setListCompoundsAC(state, action: PayloadAction<Array<CompoundType>>) {
      state.compounds = action.payload;
    },
    setListSizesAC(state, action: PayloadAction<Array<SizeType>>) {
      state.sizes = action.payload;
    },

    setListDetailsAC(state, action: PayloadAction<Array<DetaisType>>) {
      state.details = action.payload;
    },

    setAllTagsAC(state, action: PayloadAction<Array<TagsType>>) {
      state.allTags = action.payload;
    },

    setInvalidFormPhotoAC(state, action: PayloadAction<any>) {
      if (action.payload === 0) {
        state.invalidFormPhotoArray = [];
      } else {
        state.invalidFormPhotoArray.push(action.payload);
      }
    },

    // Работа с валидацией инпутов
    addInvalidFormFieldAC(
      state,
      action: PayloadAction<{id: number; name: string; text: string}>,
    ) {
      const index = state.invalidFormFieldsArray.findIndex(
        invalidFormField => invalidFormField.id === action.payload.id,
      );
      if (index === -1) {
        state.invalidFormFieldsArray.push({
          id: action.payload.id,
          name: action.payload.name,
          text: action.payload.text,
        });
      }
    },
    removeInvalidFormFieldAC(state, action: PayloadAction<number>) {
      const index = state.invalidFormFieldsArray.findIndex(
        invalidFormField => invalidFormField.id === action.payload,
      );
      if (index !== -1) state.invalidFormFieldsArray.splice(index, 1);
    },
  },
});

export const {
  setModeForm,
  setSendingFormAC,
  setModeFormWithToggleParameterAC,
  setShowModalSliderAC,
  setShowModalUploadAC,
  addNewShowModalUploadProgressAC,
  clearShowModalUploadProgressAC,
  setShowModalUploadProgressAC,
  setFileIdToProgressAC,
  setShowModalUploadFileAC,
  setShowModalDeleteAC,

  setNewFileAC,
  setGeneralPhotoAC,
  setNewFashionAC,
  setInputPhotoCheckAC,
  removeChangedPhotosAC,
  escapePhotoAC,
  movePhotoAC,

  initialPageAC,
  setInitializedAC,
  initialCheckedCheckboxesAC,
  setOptionsColorsSelectsAC,
  setOptionsSeasonsSelectsAC,
  setCategoriesOptionsAC,
  setCountriesOptionsAC,
  setPackagesOptionsAC,
  setMaterialsOptionsAC,
  setAllTagsAC,

  addNewDetailAC,
  removeNewDetailAC,
  setListCompoundsAC,
  setListSizesAC,
  setListDetailsAC,

  setInputCategoryGenderAC,
  setInputCategoryTypeAC,
  setInputCategorySubcategoriesAC,
  setInputCategoryCategoriesAC,

  addInputCompoundsAC,
  removeInputCompoundsAC,
  setInputOptionCompoundsAC,
  setInputValueCompoundsAC,

  //Добавляем, Меняем, Удаляем - цвета
  setCurrentColorIdAC,
  addNewColorAC,
  removeColorAC,
  setInputGeneralColorAC,
  setManArt,
  addInputAdditionalColorAC,
  removeInputAdditionalColorAC,
  setInputAdditionalColorAC,
  setInputlArticleColorAC,

  setInputNameAC,
  setInputBrandAC,
  // setInputSupplierArticleAC,
  setInputCountryAC,
  setInputEquipmentAC,
  setInputWidthAC,
  setInputDeptAC,
  setInputHeightAC,
  setInputWeightBruttoAC,
  setInputWeightNettoAC,
  setInputCollectionNameAC,

  addInputSeasonAC,
  removeInputSeasonAC,
  setInputSeasonAC,

  // Упаковка
  setInputPackageAC,

  // Детали
  setInputDetailOptionAC,
  setInputDetailValueAC,

  //Размеры и ШК
  addNewBarcodeAC,
  removeBarcodeAC,
  setInputBarcodeAC,
  setInputPriceAC,
  setInputBarcodeManAC,
  setInputBarcodeCheckAC,
  setGeneratedBarcodeAC,

  //Добавить/удалить Тег
  toggleNewTagAC,

  setInputDescriptionAC,

  setInvalidFormPhotoAC,
  addInvalidFormFieldAC,
  removeInvalidFormFieldAC,
} = updateProductSlice.actions;

const isValidPhotos = (state: RootState) => {
  const colors = state.updateProduct.data.productsColors;
  for (let i = 0; i < colors.length; i++) {
    const photos = colors[i].productsPhotos;
    if (photos.length === 0) {
      //Если в любом из цветов нет ни одной фото
      return false;
    }
  }
  return true;
};

export const generateBarcodesThunk = () => {
  return async (dispath: AppDispatch, getState: any) => {
    const state: RootState = getState();
    const product_id = state.updateProduct.data.id
    const currentColorId = state.updateProduct.currents.currentColorId;
    const color = state.updateProduct.data.productsColors.find(
      (color: ProductsColorsType) => color.id === currentColorId,
    )
    if(!color) return

    const sizes = color?.productsColorsSizes.map(s => ({size_id: s.size_id})) || [];

    try {
      const data = await ProductEditService.generateBarCodes({ product_id, color_id: color?.colors_id, sizes })
      if (data.data.success === true) {
        // return data.data.data
        data.data.data.sizes?.forEach(size => {
          dispath(setInputBarcodeAC({sizeId: size.size_id, action: size.barcode}));
          dispath(setGeneratedBarcodeAC(size.size_id));
        })
      } else {
        // rejectWithValue("Error!")  // TODO уточнить
      }
    } catch (error) {
      console.log(error);
    }
  }
}


export const getOptionsThunk = (category: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const colorsOptions = await ProductUpdateService.optionsValues(
        Endpoints.OPTIONS_COLOR,
      );
      const seasonsOptions = await ProductUpdateService.optionsValues(
        Endpoints.OPTIONS_SEASONS,
      );
      const countriesOptions = await ProductUpdateService.optionsValues(
        Endpoints.OPTIONS_COUNTRIES,
      );
      const categoriesParent = await ProductUpdateService.optionsValues(
        Endpoints.OPTIONS_CATEGORIES_PARENT + `?id=${category}`,
      );
      const packagesOptions = await ProductUpdateService.optionsValues(
        Endpoints.OPTIONS_PACKAGES,
      );
      const materialsOptions = await ProductUpdateService.optionsValues(
        Endpoints.OPTIONS_MATERIALS + `?id=${category}`,
      );
      const listCompounds = await ProductUpdateService.optionsValues(
        Endpoints.LIST_COMPOUNDS + `?id=${category}`,
      );

      const listSizesBarcodes = await ProductUpdateService.optionsValues(
        Endpoints.LIST_SIZES + `?id=${category}`,
      );
      const listDetails = await ProductUpdateService.optionsValues(
        Endpoints.LIST_DETAILS + `?id=${category}`,
      );
      const allTags = await ProductUpdateService.optionsValues(
        Endpoints.ALL_TAGS,
      );

      dispatch(setOptionsColorsSelectsAC(colorsOptions.data));
      dispatch(setOptionsSeasonsSelectsAC(seasonsOptions.data));
      dispatch(setCountriesOptionsAC(countriesOptions.data));
      dispatch(setCategoriesOptionsAC(categoriesParent.data));
      dispatch(setPackagesOptionsAC(packagesOptions.data));
      dispatch(setMaterialsOptionsAC(materialsOptions.data));
      dispatch(setListCompoundsAC(listCompounds.data));

      dispatch(setListSizesAC(listSizesBarcodes.data));
      dispatch(setListDetailsAC(listDetails.data));
      dispatch(setAllTagsAC(allTags.data));
    } catch (e) {
      console.log("Невозможно подгрузить поля выбора");
      throw e;
    }
  };
};

export const sendPageThunk = (id?: number | string) => {
  return async (dispatch: AppDispatch, getState: any) => {
    const body = {
      category_id: getState().updateProduct.data.category.id,
      name: getState().updateProduct.data.name,
      description: getState().updateProduct.data.description,
      brand: getState().updateProduct.data.brand,
      main_article: getState().updateProduct.data.main_article,
      country_id: getState().updateProduct.data.country_id,
      package: {
        type_id: getState().updateProduct.data.package.type_id,
        equipment: getState().updateProduct.data.package.equipment,
        information: getState().updateProduct.data.package.information,
        height: getState().updateProduct.data.package.height,
        width: getState().updateProduct.data.package.width,
        depth: getState().updateProduct.data.package.depth,
        gross_weight: getState().updateProduct.data.package.gross_weight,
        net_weight: getState().updateProduct.data.package.net_weight,
      },
      collection: getState().updateProduct.data.collection,
      price: getState().updateProduct.data.price,
      productsColors: getState().updateProduct.data.productsColors.map(
        (color: ProductsColorsType) => ({
          colors_id: color.colors_id,
          color_article: color.color_article,
          man_article: color.man_article,
          additionalColors: color.additionalColors.map(
            (additionalColor: OneColorType) => ({
              color_id: additionalColor.id,
            }),
          ),
          sizes: color.productsColorsSizes.map(
            (size: ProductsColorsSizeType) => ({
              id: size.size_id,
              man_barcode: size.man_barcode,
              barcode: size.barcode,
              price: size.price || MinPrice
            }),
          ),
          productsPhotos: color.productsPhotos.map(
            (photo: ProductsPhotoType) => ({
              main: photo.main,
              file_id: photo.file_id,
            }),
          ),
          productsFashion: {
            file_id: color.productsFashion.file_id,
          },
        }),
      ),
      productsCompound: getState().updateProduct.data.productsCompound.map(
        (compound: ProductsCompoundsType) => ({
          compound_id: compound.compound_id,
          material_id: compound.material_id,
          value: compound.value,
        }),
      ),

      productsDetails: getState()
        .updateProduct.data.productsDetails.map(
          (detail: ProductsDetailsType) => ({
            detail_id: detail.detail_id,
            option_id: detail.option_id,
            value: detail.value,
          }),
        )
        .filter(
          detail =>
            (!!detail.option_id || !!detail.value) &&
            detail.option_id !== DEFAULT_SELECT,
        ),

      seasons: getState().updateProduct.data.seasons.map(
        (season: SeasonsType) => season.id,
      ),
      tags: getState().updateProduct.data.tags.map((tag: TagsType) => tag.id),
    };

    //Включаем флаг, что идёт отправка
    dispatch(setSendingFormAC(true));

    if (!isValidPhotos(getState())) {
      dispatch(
        setInvalidFormPhotoAC({
          id: 1,
          name: "photo",
          text: "Добавьте минимум 1 фото для каждого цвета",
        }),
      );
      dispatch(setSendingFormAC(false));
    } else {
      const link = Endpoints.UPDATE_PRODUCT + `/${id}`;
      const json = JSON.stringify(body);
      try {
        const response = await ProductUpdateService.sendUpdateProductForm(
          link,
          json,
        );
        if (response.status === 200) {
          alert("Данные успешно отправлены response.success " + response.success);
        }
      } catch (error) {
        alert(`Ошибка на стороне сервера: ${error}`)
      }

      dispatch(setSendingFormAC(false));
      dispatch(setInvalidFormPhotoAC(0));

    }
  };
};

export const reinitialPageThunk = (id?: number | string) => {
  return async (dispatch: AppDispatch) => {
    const link = Endpoints.GET_PRODUCT + `/${id}`;
    const page = await ProductUpdateService.initialPage(link);

    dispatch(initialPageAC(page.data));
  };
};

export const initialPageThunk = (id?: number | string, color_id?: string) => {


  return async (dispatch: AppDispatch) => {
    try {
      const link = Endpoints.GET_PRODUCT + `/${id}`;
      const page = await ProductUpdateService.initialPage(link);

      dispatch(initialPageAC(page.data));

      dispatch(initialCheckedCheckboxesAC(page.data.productsColors));
      dispatch(getOptionsThunk(page.data.category.id));
      // dispatch(setCurrentColorIdAC(page.data.productsColors[0] ?? page.data.productsColors[0]?.id));
      if (color_id) {
        dispatch(setCurrentColorIdAC(parseInt(color_id)));
      } else {
        page.data.productsColors[0]?.id && dispatch(setCurrentColorIdAC(page.data.productsColors[0]?.id));
      }
      dispatch(setInitializedAC("done"));
      dispatch(setModeFormWithToggleParameterAC(false));
    } catch (e) {
      dispatch(initialPageAC(dataActionProduct));

      dispatch(setInitializedAC("error"));
      dispatch(getOptionsThunk(dataActionProduct.category.id));
      throw e;
    }
  };
};

export const fileUploaderThunk = (formDataFile: any, index: number) => {
  return async (dispatch: AppDispatch) => {
    // ! Локальный обход ошибки - убрать в проде
    const fileHeader: any = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent: ProgressEvent) {
        const uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100) as any,
        );
        dispatch(
          setShowModalUploadProgressAC({
            percent: uploadPercentage,
            index: index,
          }),
        );
      }.bind(this),
    };

    const response = await api
      .post(Endpoints.POST_FILE, formDataFile, fileHeader)
      .then(response => response.data);

    // const response = await ProductUpdateService.uploadFiles(Endpoints.POST_FILE, formDataFile, dispatch, index);
    const {id, name, url} = response.data;
    //Добавляем новые файлы в state
    dispatch(setNewFileAC(getObjFileUploaded(id, name, url)));
    dispatch(setFileIdToProgressAC({index: index, id: id}));
  };
};

export const fashionUploaderThunk = (formDataFile: any, index: number) => {
  return async (dispatch: AppDispatch) => {
    // ! Локальный обход ошибки - убрать в проде
    const fileHeader: any = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent: ProgressEvent) {
        const uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100) as any,
        );
        dispatch(
          setShowModalUploadProgressAC({
            percent: uploadPercentage,
            index: index,
          }),
        );
      }.bind(this),
    };

    const response = await api
      .post(Endpoints.POST_FILE, formDataFile, fileHeader)
      .then(response => response.data);

    // const response = await ProductUpdateService.uploadFiles(Endpoints.POST_FILE, formDataFile, dispatch, index);
    const {id, name, url} = response.data;
    //Добавляем новые файлы в state
    dispatch(setNewFashionAC(getObjFasionUploaded(id, name, url)));
  };
};

export const fileChangerThunk = (formDataFile: any, index: number) => {
  return async (dispatch: AppDispatch) => {

    // ! Локальный обход ошибки - убрать в проде
    const fileHeader: any = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent: ProgressEvent) {
        const uploadPercentage = parseInt(
          Math.round((progressEvent.loaded / progressEvent.total) * 100) as any,
        );
        dispatch(
          setShowModalUploadProgressAC({
            percent: uploadPercentage,
            index: index,
          }),
        );
      }.bind(this),
    };

    const response = await api
      .post(Endpoints.POST_FILE, formDataFile, fileHeader)
      .then(response => response.data);

    // const response = await ProductUpdateService.uploadFiles(Endpoints.POST_FILE, formDataFile, dispatch, index);
    const {id, name, url} = response.data;
    //Добавляем новые файлы в state
    dispatch(setNewFileAC(getObjFileUploaded(id, name, url)));

    //Удаляем чекнутые
    dispatch(removeChangedPhotosAC());
  };
};

export default updateProductSlice.reducer;
