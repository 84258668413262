import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import { RootState } from "../../../../../store";
import { fetchGoodsStatuses, setSelectedCategoryID } from "../../../../../store/slices/goodsList";
import styles from "./goodsListMain.module.scss";
import { BtnGoodsNavigate } from "./parts/BtnGoodsNavigate";
import { GoodsListTable } from "./parts/GoodsListTable";


function GoodsListMain() {
  
  const dispatch = useAppDispatch();
  const categories = useAppSelector((state: RootState) => state.productsList.categories);
  const selectedCategoryID = useAppSelector((state: RootState) => state.productsList.selectedCategoryID);

  React.useEffect(() => {
    dispatch(fetchGoodsStatuses());
  }, []);

  function handlerClick(id: number) {
    dispatch(setSelectedCategoryID(id));
  }

  return (
    <main className={styles.wrapper}>
      <article className={styles.body}>
        <section className={styles.body_wrapper__navigate}>
          {categories.map((item) => (
            <React.Fragment key={item.id}>
              <BtnGoodsNavigate
                title={item.title}
                id={item.id}
                swichOn={item.id === selectedCategoryID}
                isClick={handlerClick}
                countGoods={item.countGoods}
              />
            </React.Fragment>
          ))}
        </section>

        <section className={styles.body_goods_list}>
          <GoodsListTable categoryID={selectedCategoryID} />
        </section>
      </article>
    </main>
  );
}

export default GoodsListMain;
