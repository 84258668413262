import React from "react";
import styles from "./LeftBicPart.module.scss";
import {BicTextInput} from "../BicTextInput";
import {LeftRegistrationCard} from "../LeftRegistrationCard";
import {PinkErrorText} from "../../../../simples/PinkErrorText";

type Props = {
  isError: boolean;
  bic: string;
  setBic: React.Dispatch<React.SetStateAction<string>>;
};

const LeftBicPart = ({isError, bic, setBic}: Props) => (
  <LeftRegistrationCard>
    <h2 className={styles.title}>Регистрация</h2>
    <BicTextInput bic={bic} className={styles.textInput} setBic={setBic} />
    <PinkErrorText
      className={styles.errorText}
      isShow={isError}
      title={"По данному БИК нет данных"}
    />
  </LeftRegistrationCard>
);

export default LeftBicPart;
