import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../constants/routes";
import { Logo } from "../../simples/Logo";
import styles from "./Auth.module.scss";
import AuthCard from "./parts/PhoneAuthCard/PhoneAuthCard";
import { SmsAuthCard } from "./parts/SmsAuthCard";

function Auth() {
  const params = useLocation();
  const navigate = useNavigate();
  const isAuthPage = params.pathname === Routes.AUTH;

  useEffect(() => {
    !isAuthPage && !params.state?.phone && navigate(Routes.AUTH);
  }, [isAuthPage, navigate, params.state?.phone]);

  return (
    <div className={styles.container}>
      <Logo large={true} className={styles.logo} />
      {isAuthPage && <AuthCard />}
      {!isAuthPage && <SmsAuthCard phone={params.state?.phone} />}
    </div>
  );
}

export default Auth;
