import React, { useState, useEffect } from 'react'
import { ButtonConfig, ModalDialog } from '../../simples/ModalDialog'
import { CustomTextInput } from '../../simples/CustomTextInput'
import { BlueButton } from '../../simples/BlueButton'
import { FioRegexp, PhoneRegexp } from '../../../utils/employees'
import api from "../../../utils/api";

import styles from './Employees.module.scss'
import format from 'date-fns/format'
import CodeInput from '../../simples/CodeInput/CodeInput'
import { EmployeeDTO } from '../../../types/swagger/llyApi_new'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import { SMS_SEND_DELAY } from './const'

type Props = {
  isActive: boolean,
  onClose: () => void
}


async function sendCode(phone: string) {
  const req = '/employees/send-code'
  const data = { phone }

  let success = false
  await api.post<{ success: boolean }>(req, data).then(resp => {
    success = resp.data.success
  }).catch(err => {
    const text = err?.response?.data?.errors ? `Ошибка: ${err?.response?.data?.errors[0]}` : 'Ошибка при отправке кода.'
    const codeSendedError = () => toast.error(text, { theme: "light" });
    codeSendedError()
  })
  return success
}

async function addUser({ code, phone, fio, role }: { code: string, phone: string, fio: string, role: string }) {
  const req = '/employees/create'
  const data = { name: fio, phone, role, code }

  const resp = await api.post<{ success: boolean, errors: { codeHasError?: boolean }, data?: EmployeeDTO }>(req, data)
  return resp.data
}

// const SMS_SEND_DELAY = 0.01 * 60 * 1000

export default function AddEmployee({ isActive, onClose }: Props) {

  //   Добавление сотрудника:
  // POST /employees/create
  // http://88.198.56.78:8150/docs#/%D0%A1%D0%BE%D1%82%D1%80%D1%83%D0%B4%D0%BD%D0%B8%D0%BA%D0%B8/post_employees_create
  const [fio, setFio] = useState('')
  const [phone, setPhone] = useState('')
  const [role, setRole] = useState('')
  // const [isPhoneConfirmed, setIsPhoneConfirmed] = useState(false)
  const [lastSmsTS, setLastSmsTS] = useState(0)
  const [canSendSms, setCanSendSms] = useState(true)
  const [timeToNextSMS, setTimeToNextSMS] = useState(0)
  const [code, setCode] = useState(['', '', '', ''])
  const [codeHasError, setCodeHasError] = useState(false)

  const [phoneHasError, setPhoneHasError] = useState(false)
  const [fioHasError, setFioHasError] = useState(false)
  const [roleHasError, setRoleHasError] = useState(false)


  // useEffect(() => {
  //   setPhoneHasError(!PhoneRegexp.test(phone))
  // }, [phone])
  // useEffect(() => {
  //   setFioHasError(!FioRegexp.test(fio))
  // }, [fio])
  // useEffect(() => {
  //   setRoleHasError(role.length < 1)
  // }, [role])


  const сreateSuccess = () => toast.success("Сотрудник успешно добавлен", { theme: "light" });
  const codeSended = () => toast.success("Код отправлен", { theme: "light" });
  const codeSendedError = () => toast.error("Ошибка при отправке кода", { theme: "light" });

  useEffect(() => {
    const timeout = lastSmsTS + SMS_SEND_DELAY - Date.now()

    const tid = setTimeout(() => {
      setCanSendSms(true)
    }, timeout);

    const iid = setInterval(() => {
      let timeout = lastSmsTS + SMS_SEND_DELAY - Date.now()
      if (timeout < 0) {
        timeout = 0
        clearInterval(iid)
      }
      setTimeToNextSMS(timeout)
    }, 1000)

    return () => {
      clearTimeout(tid)
      clearInterval(iid)
    }
  }, [lastSmsTS])


  useEffect(() => {
    setCode(['', '', '', ''])
    setFio('')
    setPhone('')
    setRole('')
    setPhoneHasError(false)
    setFioHasError(false)
    setRoleHasError(false)
  }, [isActive])



  const handleChange = ({ name, value }) => {
    switch (name) {
      case 'fio':
        setFio(value)
        // setFioHasError(!FioRegexp.test(value))
        setFioHasError(value.length === 0)
        break;
      case 'phone':
        // setIsPhoneConfirmed(false)
        setPhone(value)
        setPhoneHasError(!PhoneRegexp.test(value))
        break;
      case 'jobTitle':
        setRole(value)
        setRoleHasError(value.length < 1)
        break;
      default:
        break;
    }
    return
  }

  const handleChangeCode = (code: string[]) => {
    setCode(code)
  }

  // const phoneHasError = !PhoneRegexp.test(phone)
  // const fioHasError = !FioRegexp.test(fio)
  // const roleHasError = role.length < 1

  const hasErrors = phoneHasError || fioHasError || roleHasError || phone.length === 0 || fio.length === 0 || role.length === 0 || code.join('').length < 4



  const handleSendCode = async () => {
    if (phoneHasError) return
    setCanSendSms(false)
    const res = await sendCode(phone)
    if (res) {
      codeSended()
      setLastSmsTS(Date.now())
    } else {
      // codeSendedError()
      // Номер уже зарегистрирован
    }

    // if (res) {
    //   setIsPhoneConfirmed(true)
    // }
  }

  const handleAddEmployee = async () => {
    const resp = await addUser({ fio, phone, role, code: code.join('') })
    if (resp.success === true) {
      сreateSuccess()
      setFio('')
      setPhone('')
      setRole('')
      onClose()
    } else {
      if (resp.errors.codeHasError) {
        setCodeHasError(true)
      }
    }
  }

  const btns: ButtonConfig[] = [
    {
      text: 'Подтвердить',
      onClick: handleAddEmployee,
      disabled: hasErrors
    }
  ]

  return (
    <ModalDialog buttonsConfig={btns} isActive={isActive} width={920} hasCloseBtn handleClose={onClose}>
      <h1>Добавить сотрудника</h1>
      <div className={classNames(styles.content, styles.add_employee)}>
        <div className={styles.row}>
          <CustomTextInput className={styles.input} name='fio' onChange={handleChange} value={fio} label='ФИО'
            error={fioHasError ? '! Заполните поле' : undefined} />
          <div className={styles.sms_btn_wrap}>
            <span>Введите СМС-код</span>
            <BlueButton title='Отправить SMS' onClick={handleSendCode} disabled={phoneHasError || phone.length === 0 || !canSendSms} />
          </div>
        </div>
        <div className={styles.row}>
          <CustomTextInput mask={"+7 (999) 999-99-99"} className={styles.input} name='phone' onChange={handleChange}
            value={phone} label='Телефон' error={phoneHasError ? '! Неверный номер' : undefined} />
          <div>
            <CodeInput code={code} onChange={handleChangeCode} hasError={codeHasError} disabled={phoneHasError || phone.length === 0} />
          </div>
        </div>
        <div className={styles.row}>
          <CustomTextInput className={styles.input} name='jobTitle' onChange={handleChange} value={role} label='Должность'
            error={roleHasError ? '! Заполните поле' : undefined} />
          <div className={styles.info}>
            <div>Отправить код пуш-уведомлением повторно можно через {format(timeToNextSMS, 'mm:ss')}</div>
            <div>Отправить SMS-код можно через {format(timeToNextSMS, 'mm:ss')}</div>
          </div>
        </div>
      </div>
    </ModalDialog>
  )
}