import React, { useEffect } from 'react'
import { Layout } from '../../complexes/Layout'
import styles from './Logos.module.scss'
import { SideBar } from './components/SideBar'
import { Editor } from './components/Editor'
import { LogosList } from './components/LogosList'
import { fetchLogoList, LogosMode } from '../../../store/slices/getters/logo-list'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'

export default function Logos() {

  const dispatch = useAppDispatch();
  const { mode } = useAppSelector((state) => state.logoList);

  useEffect(() => {
    dispatch(fetchLogoList());
  }, []);

  return (
    <Layout title='Логотипы' hint='Здесь будут отражены все логотипы, которые Вы создали. Добавляйте новые логотипы и редактируйте старые'>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <div className={styles.input_label}>{mode !== LogosMode.list && 'Название бренда'}</div>
            <div className={styles.content}>
              {mode !== LogosMode.list && <Editor />}
              {/* <Editor /> */}
              <LogosList />
            </div>
          </div>
        </div>
        <div className={styles.wrapper_side}>
          <SideBar
            title={"Размер логотипа"}
            description={"Логотип должен быть 600px в длину и 600px в высоту в формате .png <br/> <br/> Изображения, используемые в качестве логотипов, должны соответствовать <a href='#'>правилам размещения логотипов</a>"}
          />
          <SideBar
            title={"Все логотипы проходят модеряцию"}
          />
        </div>
      </div>
    </Layout>
  )
}
