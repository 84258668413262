import React, { useEffect, useState } from 'react'
import { SearchInput } from '../../../../complexes/SearchInput'
import styles from './BalancesInWarehouses.module.scss'
import { CustomSelect_v2, IOnSelectChange, Option } from '../../../../simples/CustomSelect_v2'
import { ItemsList } from '../../../../complexes/ItemsList'
import { DefaultHeaders } from '../../../../complexes/ProductList/DefaultHeaders'
import { ListDataType, ListHeadData, ListRow } from '../../../../../types/productListTypes'
import { AvailableProductData } from '../../../../complexes/ProductList'
import api from '../../../../../utils/api'
import { Endpoints } from '../../../../../constants/server'
import { FBSLeftover } from '../../../../../types/swagger/llyApi_new'
import classNames from 'classnames'
import { IStorage } from '../../../../../types/fbs_fbo'
import { BlueButton } from '../../../../simples/BlueButton'
import { CustomTextInput_v2, IOnChange } from '../../../../simples/CustomTextInput_v2'

const Headers: ListHeadData[] = [{
  ...DefaultHeaders.photo,
  minWidth: "120px",
  width: "120px"
}, {
  name: AvailableProductData.barcode,
  text: 'Артикул (штрихкод)',
  minWidth: "220px",
  width: "220px"
}, {
  ...DefaultHeaders.name,
  minWidth: "320px",
  width: "320px"
}, {
  ...DefaultHeaders.color,
  minWidth: "320px",
  width: "320px"
}, {
  ...DefaultHeaders.size,
  minWidth: "140px",
  width: "140px"
}, {
  ...DefaultHeaders.fbs,
  minWidth: "140px",
  width: "140px"
}]

// type IStorage = {
//   /** ID склада */
//   id?: number
//   /** Тип и адресс скалада */
//   string?: string
//   /** Склад FBO */
//   is_fbo?: boolean
// }

type ServerStoragesResponse = {
  success?: boolean
  data?: IStorage[]
}

type ServerBalancesResponse = {
  success?: string
  data?: FBSLeftover[]
  meta?: {
    /** Количество объектов в data */
    totalCount?: number
  }
}

export default function BalancesInWarehouses() {
  const [search, setSearch] = useState('')
  const [warehouse, setWarehouse] = useState<string | undefined>(undefined)
  const [fbsStorages, setFbsStorages] = useState<IStorage[] | undefined>(undefined)
  const [items, setItems] = useState<FBSLeftover[]>([])
  const [hideEmpty, setHideEmpty] = useState(false)

  const [isEditingLeftovers, setIsEditingLeftovers] = useState(false)
  const [editedValues, setEditedValues] = useState<{ [key: string]: number }>({})


  useEffect(() => {
    setFbsStorages(undefined)
    const req = '/storages';
    api.get<ServerStoragesResponse>(req).then(data => {
      if (data.data.data !== undefined) {
        const list = data.data.data.filter(w => !w.is_fbo)
        setFbsStorages(list)
        if (list.length > 0) {
          setWarehouse(list[0].id?.toString())
        }
      }
    })
  }, [])

  const loadLeftovers = () => {
    setItems([])
    if (!warehouse) return
    const req = `/logistics-fbs/leftovers/${warehouse}`
    api.get<ServerBalancesResponse>(req).then(data => {
      if (data.data.data !== undefined) {
        setItems(data.data.data)
      }
    })
  }
  const saveLeftovers = (leftovers: { [key: string]: number }) => {

    if (!warehouse) return

    const data: { "id": string, "leftover": number, timestamp?: number }[] = []
    for (const key in leftovers) {
      if (Object.prototype.hasOwnProperty.call(leftovers, key)) {
        const value = leftovers[key];
        const item = _items.find(i => i.id === key)
        if (value !== null && item) {
          data.push({
            "id": key,
            "leftover": value,
            timestamp: item.timestamp
          })
        }
      }
    }

    setItems([])
    const req = `/logistics-fbs/leftovers-edit/${warehouse}`
    api.post<{ success?: string, data?: FBSLeftover[] }>(req, data).then(data => {
      // if (data.data.data !== undefined) {
      //   setItems(data.data.data)
      // }
      loadLeftovers()
    })
  }

  useEffect(loadLeftovers, [warehouse])

  const _s = search.toLocaleLowerCase()
  let _items = items.filter(o => o.id.toString().toLocaleLowerCase().includes(_s)
    || o.article.toLocaleLowerCase().includes(_s)
    || o.name.toLocaleLowerCase().includes(_s)
    || o.color.toString().toLocaleLowerCase().includes(_s)
    || o.size.toString().toLocaleLowerCase().includes(_s)
  )


  const handleChangeWarehouse = ({ value }: IOnSelectChange) => {
    setWarehouse(value?.toString())
  }

  const options: Option[] = fbsStorages?.map(s => ({
    value: s.id?.toString() as string,
    label: s.string
  })) || []

  const handleChangeValue = ({ name, value }: IOnChange) => {
    // console.log(name, value);
    const id = name.toString()
    const v = parseInt(value.toString())

    setEditedValues({ ...editedValues, [id]: v })
  }


  if (hideEmpty) {
    _items = _items.filter(item => item.leftover > 0)
  }
  const listItems: ListRow[] = _items.map(item => ({
    key: item.id,
    data: [{
      name: AvailableProductData.photo,
      type: ListDataType.image,
      text: item.image_url
    }, {
      name: AvailableProductData.barcode,
      text: item.article
    }, {
      name: AvailableProductData.name,
      text: item.name
    }, {
      name: AvailableProductData.color,
      text: item.color
      // dataForSorting?: string | number;
      // alignCenter?: boolean
    }, {
      name: AvailableProductData.size,
      text: item.size
      // dataForSorting?: string | number;
      // alignCenter?: boolean
    }, {
      // isEditable: isEditingLeftovers,
      type: isEditingLeftovers ? ListDataType.content : ListDataType.text,
      name: AvailableProductData.fbs,
      text: item.leftover.toString(),
      content: isEditingLeftovers ? <CustomTextInput_v2 className={styles.value_input} onChange={handleChangeValue} name={item.id} value={editedValues[item.id] || item.leftover || '0'} /> : <></>
      // dataForSorting?: string | number;
      // alignCenter?: boolean
    }]
  }))


  // const handleChangeValue = (dataId: string, fieldName: string, newValue: string) => {
  //   console.log(dataId, fieldName, newValue);
  // }
  const handleEditLeftovers = () => {
    setIsEditingLeftovers(true)
  }
  const handleSaveLeftovers = () => {
    setIsEditingLeftovers(false)
    // TODO: send data
    // console.log(editedValues);
    saveLeftovers(editedValues)
    setEditedValues({})
    // TODO reload
  }

  return (
    <div className={styles.wrap}>
      <SearchInput onChange={setSearch} className={styles.search} />
      <div className={styles.info}>
        <div className={styles.warnings}>
          <div>! Остатки по FBS контролируете лично Вы как продавец площадки</div>
          <div>! Обратите внимание, в первую очередь карточки торгуются по системе FBO</div>
        </div>
        <a href=''>Инструкция по работе с FBS</a>
      </div>
      <CustomSelect_v2 name='warehouse' onChange={handleChangeWarehouse} options={options} value={warehouse} className={styles.warehouse} />
      <div className={styles.controls}>
        <div className={styles.filters}>
          <div
            className={classNames(styles.btn, { [styles.selected]: !hideEmpty })}
            onClick={() => { setHideEmpty(false) }}
          >Все</div>
          <div
            className={classNames(styles.btn, { [styles.selected]: hideEmpty })}
            onClick={() => { setHideEmpty(true) }}
          >Скрыть нулевые</div>
        </div>
        {!isEditingLeftovers && <div>
          <BlueButton title='Изменить остатки' onClick={handleEditLeftovers} />
        </div>}
      </div>
      <div className={styles.list}>
        <ItemsList headers={Headers} items={listItems} />
      </div>
      {isEditingLeftovers && <div className={styles.save_leftovers}>
        <div className={styles.info}>
          <span>Изменяя остаток по FBS Вы соглашаетесь с</span>
          <a>санкциями при нарушении условий поставки по FBS</a>
        </div>
        <BlueButton title='Сохранить изменения' onClick={handleSaveLeftovers} />
      </div>}
    </div>
  )
}
