import React from "react";
import {
  Navigate,
  Route,
  Routes as RoutesDom,
} from 'react-router-dom'
import {Routes} from '../../../constants/routes'
import { Auth } from "../../pages/Auth";
import { Registration } from "../../pages/Registration";

const AuthRoutes = () => (
  <RoutesDom>
    <Route path={Routes.AUTH} element={<Auth />} />
    <Route path={Routes.CONFIRM_CODE} element={<Auth />} />
    <Route path={Routes.REGISTRATION} element={<Registration />} />
    <Route path={Routes.REGISTRATION_BIC} element={<Registration />} />
    <Route path={Routes.REGISTRATION_BIC_ACTIVE} element={<Registration />} />
    <Route path={Routes.REGISTRATION_TIN} element={<Registration />} />
    <Route path="*" element={<Navigate replace to={Routes.AUTH} />} />
  </RoutesDom>
);

export default AuthRoutes;
