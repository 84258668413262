import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { acceptConditions } from '../../../../../store/slices/feedback'
import { ModalDialog, ButtonConfig } from '../../../../simples/ModalDialog'

import styles from "./Conditions.module.scss"

export default function Conditions() {

  const isActive = useAppSelector(state => state.feedback.conditionsIsVisible)
  const dispatch = useAppDispatch()

  const handleAcceptConditions = () => {
    dispatch(acceptConditions())
  }

  const btns: ButtonConfig[] = [{ text: "Ознакомлен", onClick: handleAcceptConditions }]

  return (
    <ModalDialog width={920} buttonsConfig={btns} isActive={isActive} handleClose={handleAcceptConditions} hasCloseBtn={true}>
      <h1>Условия публикации ответов</h1>
      <div className={styles.wrap}>
        <div className={styles.info}>
          <span className={styles.blue_text}>Все ответы пользователям проходят внутреннюю модерацию по следующим правилам:</span>
          <ol>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus dui, fermentum sed cras. Elit sit id sit egestas tempor, ut aliquam. Volutpat ut tortor faucibus praesent tellus. Tortor, ultrices aliquam orci duis magna cursus sapien eu tempor.</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
          </ol>
          <span className={styles.red_text}>В случае нарушения правил пользования площадки, ваш аккаунт продавца может быть заблокирован в одностороннем порядке.</span>
          <hr />
          <span className={styles.blue_text}>Все ответы пользователям проходят внутреннюю модерацию по следующим правилам:</span>
          <ol>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus dui, fermentum sed cras. Elit sit id sit egestas tempor, ut aliquam. Volutpat ut tortor faucibus praesent tellus. Tortor, ultrices aliquam orci duis magna cursus sapien eu tempor.</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
          </ol>
          <span className={styles.red_text}>В случае нарушения правил пользования площадки, ваш аккаунт продавца может быть заблокирован в одностороннем порядке.</span>
          <hr />
          <span className={styles.blue_text}>Все ответы пользователям проходят внутреннюю модерацию по следующим правилам:</span>
          <ol>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus dui, fermentum sed cras. Elit sit id sit egestas tempor, ut aliquam. Volutpat ut tortor faucibus praesent tellus. Tortor, ultrices aliquam orci duis magna cursus sapien eu tempor.</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
          </ol>
          <span className={styles.red_text}>В случае нарушения правил пользования площадки, ваш аккаунт продавца может быть заблокирован в одностороннем порядке.</span>
          <hr />
          <span className={styles.blue_text}>Все ответы пользователям проходят внутреннюю модерацию по следующим правилам:</span>
          <ol>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus dui, fermentum sed cras. Elit sit id sit egestas tempor, ut aliquam. Volutpat ut tortor faucibus praesent tellus. Tortor, ultrices aliquam orci duis magna cursus sapien eu tempor.</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
          </ol>
          <span className={styles.red_text}>В случае нарушения правил пользования площадки, ваш аккаунт продавца может быть заблокирован в одностороннем порядке.</span>
          <hr />
          <span className={styles.blue_text}>Все ответы пользователям проходят внутреннюю модерацию по следующим правилам:</span>
          <ol>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus dui, fermentum sed cras. Elit sit id sit egestas tempor, ut aliquam. Volutpat ut tortor faucibus praesent tellus. Tortor, ultrices aliquam orci duis magna cursus sapien eu tempor.</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
          </ol>
          <span className={styles.red_text}>В случае нарушения правил пользования площадки, ваш аккаунт продавца может быть заблокирован в одностороннем порядке.</span>
          <hr />
          <span className={styles.blue_text}>Все ответы пользователям проходят внутреннюю модерацию по следующим правилам:</span>
          <ol>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus dui, fermentum sed cras. Elit sit id sit egestas tempor, ut aliquam. Volutpat ut tortor faucibus praesent tellus. Tortor, ultrices aliquam orci duis magna cursus sapien eu tempor.</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
            <li>Ultrices et habitant ut rutrum sed vitae adipiscing</li>
          </ol>
          <span className={styles.red_text}>В случае нарушения правил пользования площадки, ваш аккаунт продавца может быть заблокирован в одностороннем порядке.</span>
        </div>
      </div>
    </ModalDialog>
  )
}
