import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { fetchBrands } from '../../../../store/slices/brands'
import styles from '../Brands.module.scss'
import Brand from './Brand'
import { TextInput } from '../../../simples/TextInput'
import classNames from 'classnames'

export enum Order { ASC, DESC }


export default function BrandsTab() {

  const dispatch = useAppDispatch()
  const [searchText, setSearchText] = useState('')
  const [sortByColumn, setSortByColumn] = useState('name')
  const [order, setOrder] = useState(Order.ASC)

  let brandsList = useAppSelector(state => state.brands.brandsList) || []
  if (searchText.length > 0) {
    const _searchText = searchText.toLocaleLowerCase()
    brandsList = brandsList.filter(b => b.name.toLocaleLowerCase().indexOf(_searchText) > -1)
  }

  brandsList = [...brandsList].sort((b1, b2) => {
    if (order === Order.ASC) {
      return b1[sortByColumn]?.toLowerCase().localeCompare(b2[sortByColumn].toLowerCase()) || 0;
    } else {
      return b2[sortByColumn]?.toLowerCase().localeCompare(b1[sortByColumn].toLowerCase()) || 0;
    }
  })

  useEffect(() => {
    dispatch(fetchBrands())
  }, [])

  const handleSort = (column: string) => () => {
    if (column === sortByColumn) {
      if (order === Order.DESC) {
        setOrder(Order.ASC)
      } else {
        setOrder(Order.DESC)
      }
    } else {
      setSortByColumn(column)
    }
  }

  return (
    <div className={styles.brands_certs}>
      <div className={styles.warning}>Загружаемый файл должен быть в формате .pdf, .jpeg, .jpg или .png, а размер не должен превышать 32 Мб</div>
      {/* <CustomTextInput name='search' placeholder='Поиск' value={searchText} onChange={handleOnChangeSearch}  /> */}
      <TextInput onChange={setSearchText} value={searchText} placeholder='Поиск' className={styles.search} />
      <h1>Сертифицируемые бренды</h1>
      <table className={styles.grid}>
        <thead>
          <tr className={classNames({
            [styles.order_asc]: order === Order.ASC,
            [styles.order_desc]: order === Order.DESC,
          })}>
            <th onClick={handleSort('name')} className={classNames({[styles.sort]: sortByColumn === 'name'})}><span>Бренд</span></th>
            <th onClick={handleSort('status')} className={classNames({[styles.sort]: sortByColumn === 'status'})}><span>Статус</span></th>
            <th>Документы</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {brandsList.map(b => <Brand key={b.id} brand={b} />)}
        </tbody>
      </table>
    </div>
  )
}
