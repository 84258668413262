import React, { ChangeEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/store";
import { toggleTag } from "../../../../../../../store/slices/product";
import { ModalDialog } from "../../../../../../simples/ModalDialog";
import styles from "./TagsField.module.scss";

interface Props {
  active: boolean;
  onClose: () => void;
}

const TagsField: React.FunctionComponent<Props> = ({ active, onClose }) => {
  const dispatch = useAppDispatch();

  const [searchText, setSearchText] = useState('')

  const allTags = useAppSelector((state) => state.product.commonData.tags);
  const selectedTags = useAppSelector((state) => state.product.tags.values);
  const tags = allTags.filter(t => selectedTags.indexOf(t.id) !== -1)

  // const currentTags = search ? searchedTags : allTags;

  const currentTags = searchText.length > 1
    ? allTags.filter(t => t.name?.includes(searchText.toLowerCase()),)
    : allTags;

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchText(target.value)
  };

  const onToggleTag = (id: number) => {
    dispatch(toggleTag(id))
  };

  const handleClose = () => {
    setSearchText('');
    onClose();
  }


  return (
    <ModalDialog
      buttonsConfig={[{ text: 'Готово', onClick: handleClose }]}
      isActive={active}
      handleClose={handleClose}
      width={780}
      hasCloseBtn={true}>

      <div className={styles.fieldHeader}>
        <h2 className={styles.title}>Выберите нужные теги</h2>
      </div>
      <div className={styles.fieldWrapper}>
        <div className={styles.inputWrapper}>
          <input
            value={searchText}
            onChange={handleSearch}
            type="text"
            placeholder="Поиск"
            className={styles.search}
          />
        </div>
        <ul className={styles.list}>
          {currentTags.map((tag) => (
            <li
              className={
                tags.find(t => t.id === tag.id)
                  ? styles.includes
                  : styles.item
              }
              onClick={() => onToggleTag(tag.id)}
              key={tag.id}>
              {"#" + tag.name}
            </li>
          ))}
        </ul>
      </div>
    </ModalDialog>
  );
};

export default React.memo(TagsField);
