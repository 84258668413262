import React, { useState } from 'react'
import styles from './Orders.module.scss'
import { ItemsList } from '../../../../complexes/ItemsList'
import { BlueButton } from '../../../../simples/BlueButton'
import { ListDataType, ListHeadData, ListRow } from '../../../../../types/productListTypes'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { buildNewDeliveryRequest, fetchShippedOrders, setSentPeriodDates } from '../../../../../store/slices/fbs'
import Skeleton from 'react-loading-skeleton'
import { format, sub } from 'date-fns'
import { ru } from 'date-fns/locale';
import DateSelector, { PeriodDates } from './DateSelector'

const Headers: ListHeadData[] = [{
  name: 'id',
  text: "ID и дата заказа",
  type: ListDataType.text,
  sortable: true,
  minWidth: "140px",
  width: "140px"
}, {
  name: 'status',
  text: "Статус",
  type: ListDataType.text,
  sortable: true,
  minWidth: "250px",
  width: "250px"
}, {
  name: 'address',
  text: "Адрес доставки",
  type: ListDataType.text,
  sortable: true,
  minWidth: "180px",
  width: "180px"
}, {
  name: 'dtype',
  text: "Тип доставки",
  type: ListDataType.text,
  sortable: true,
  minWidth: "180px",
  width: "180px"
}, {
  name: 'docs',
  text: "Документация",
  type: ListDataType.text,
  sortable: true,
  minWidth: "260px",
  width: "260px"
}, {
  name: 'costOfDelivery',
  content: <>Стоимость<br />доставки</>,
  type: ListDataType.content,
  className: styles.cost,
  sortable: true,
  minWidth: "120px",
  width: "120px"
}, {
  name: 'cost',
  content: <>Стоимость<br />без доставки</>,
  type: ListDataType.content,
  className: styles.cost,
  sortable: true,
  minWidth: "120px",
  width: "120px",
}]

export default function SentOrders() {

  const { isLoadingShippedOrders: isLoadingOrders, ordersSearch, shippedOrders, sentPeriodDates } = useAppSelector(state => state.fbs)
  const [openedHistory, setOpenedHistory] = useState<number[]>([])
  // const [periodDates, setPeriodDates] = useState({ from: Date.now(), to: sub(Date.now(), { months: 1 }).getTime() })
  const dispatch = useAppDispatch()


  const handleToggle = (id: number) => () => {
    if (openedHistory.includes(id)) {
      setOpenedHistory(openedHistory.filter(oid => oid !== id))
    } else {
      setOpenedHistory([...openedHistory, id])
    }
  }

  // const expired = Math.random() > 0.5
  // const items: ListRow[] = (new Array(5)).fill(0).map(_ => ({
  //   key: '1',
  //   data: [{
  //     name: 'id',
  //     type: ListDataType.content,
  //     content: <div className={styles.id}>
  //       <div className={styles.number}>№2456</div>
  //       <div className={styles.date}>01.02.2023</div>
  //     </div>
  //   }, {
  //     name: 'dedlineDate',
  //     type: ListDataType.content,
  //     content: <div className={styles.dedlineDate}>
  //       <div className={styles.date}>01.02.2023</div>
  //       <div className={classNames(styles.status, { [styles.expired]: expired })}>{expired ? "Просрочен" : "Осталось на сборку: 72ч"}</div>
  //     </div>
  //   }, {
  //     name: 'address',
  //     type: ListDataType.text,
  //     text: 'г. Москва, ул. Библиотечная, д 25'
  //   }, {
  //     name: 'buyer',
  //     type: ListDataType.text,
  //     text: 'Петров Пётр Петрович'
  //   }, {
  //     name: 'rates',
  //     type: ListDataType.content,
  //     content: <div className={styles.rates}>
  //       <div className={styles.product}>Футболка женская</div>
  //       <div className={styles.code}>15560B22222XS</div>
  //     </div>
  //   }, {
  //     name: 'costOfDelivery',
  //     type: ListDataType.text,
  //     text: '300'
  //   }, {
  //     name: 'cost',
  //     type: ListDataType.text,
  //     text: '3 000'
  //   }]
  // }))

  // const shippedOrders: CollectedOrder[] = [{
  //   address: 'г. Москва, ул. Библиотечная, д 25',
  //   consumer_name: '',
  //   date: '01.02.2023',
  //   delivery_by: 'Курьер',
  //   delivery_date: '',
  //   delivery_from: '',
  //   delivery_price: 123,
  //   hours_remain: 10,
  //   id: 123,
  //   order_id: 123,
  //   price: 321,
  //   updated_at: Date.now(),
  //   number: 'RV2292858745HK',
  //   delivery_status: [{
  //     date_time: Date.now(),
  //     name: 'Ожидает в пункте выдачи',
  //     city: 'Московская обл. г.Ногинск, п.Затишье'
  //   }, {
  //     date_time: Date.now(),
  //     name: 'Прибыло в сортировачный центр 3',
  //     city: 'Московская обл. г.Ногинск, п.Затишье 3'
  //   }, {
  //     date_time: Date.now(),
  //     name: 'Прибыло в сортировачный центр 2',
  //     city: 'Московская обл. г.Ногинск, п.Затишье 2'
  //   }, {
  //     date_time: Date.now(),
  //     name: 'Прибыло в сортировачный центр 1',
  //     // city: 'Московская обл. г.Ногинск, п.Затишье 1'
  //   }],
  //   documents: [{
  //     name: 'Термопринт.pdf',
  //     id: 1,
  //     url: 'asdf'
  //   }, {
  //     name: 'Накладная.pdf',
  //     id: 1,
  //     url: 'asdf'
  //   }, {
  //     name: 'Лист_отправления.pdf',
  //     id: 1,
  //     url: 'asdf'
  //   }]
  // }]

  // const [period, setPeriod] = useState<CalendarPeriod | undefined>(CalendarPeriod.month)
  // const [dateFrom, setDateFrom] = useState<DatePickerDate>(dateToDatePickerDate(new Date()))
  // const [dateTo, setDateTo] = useState<DatePickerDate>(dateToDatePickerDate(sub(Date.now(), { months: 1 })))

  // const handleApplayPeriod = () => {
  //   const from = datePickerDateToDate(dateFrom).getTime()
  //   const to = datePickerDateToDate(dateTo).getTime()
  //   setPeriodDates({
  //     from: Math.min(from, to),
  //     to: Math.max(from, to)
  //   })
  // }

  React.useEffect(() => {
    dispatch(fetchShippedOrders({ fromDate: new Date(sentPeriodDates.from), toDate: new Date(sentPeriodDates.to) }));
  }, [sentPeriodDates]);


  const _s = ordersSearch.toLocaleLowerCase()
  const orders = shippedOrders.filter(o => o.number?.toLocaleLowerCase().includes(_s)
    || o.address.toLocaleLowerCase().includes(_s)
    || o.consumer_name.toLocaleLowerCase().includes(_s)
    || o.id.toString().toLocaleLowerCase().includes(_s)
  )

  const items: ListRow[] = orders.map(o => {
    return {
      key: o.id.toString(),
      data: [{
        name: 'id',
        type: ListDataType.content,
        content: <div className={styles.id}>
          <div className={styles.number}>№{o.id}</div>
          <div className={styles.date}>{o.date}</div>
        </div>
      }, {
        name: 'status',
        type: ListDataType.content,
        content: <div className={styles.status}>
          <div className={styles.track}>{o.number}</div>
          {/* <div>{o.delivery_status}</div> */}
          <div className={styles.statuses}>
            <div className={styles.main_status}>
              {o.delivery_status && o.delivery_status.length > 0 && <>
                <div className={styles.status_name}><div className={styles.dot}></div>{o.delivery_status[0].name}</div>
                {o.delivery_status[0].city && <div className={styles.status_city}>{o.delivery_status[0].city}</div>}
                <div className={styles.status_time}>{format(new Date(o.delivery_status[0].date_time), "d MMMM yyyy 'в' HH:mm", { locale: ru })}</div>
              </>}
            </div>
            {openedHistory.includes(o.id) && <div className={styles.statuses_history_wrap}>
              <div className={styles.line}></div>
              <div className={styles.statuses_history}>{o.delivery_status?.map((s, n) => <div key={n} className={styles.status_history}>
                <div className={styles.status_name}><div className={styles.dot}></div>{s.name}</div>
                {s.city && <div className={styles.status_city}>{s.city}</div>}
                <div className={styles.status_time}>{format(new Date(s.date_time), "d MMMM yyyy 'в' HH:mm", { locale: ru })}</div>
              </div>)}</div>
            </div>}
          </div>
          <div className={styles.toggle} onClick={handleToggle(o.id)}>{openedHistory.includes(o.id) ? 'Свернуть' : 'Развернуть'}</div>
        </div>
      }, {
        name: 'address',
        className: styles.address,
        type: ListDataType.text,
        text: o.address
      }, {
        name: 'dtype',
        className: styles.dtype,
        type: ListDataType.text,
        text: o.delivery_by
      }, {
        name: 'docs',
        type: ListDataType.content,
        content: <div className={styles.docs}>
          {o.documents?.map(doc => <a href={doc.url} key={doc.id} target="_blank" rel="noreferrer">{doc.name}</a>)}
        </div>
      }, {
        name: 'costOfDelivery',
        className: styles.cost,
        type: ListDataType.text,
        text: o.delivery_price.toLocaleString()
      }, {
        name: 'cost',
        className: styles.cost,
        type: ListDataType.text,
        text: o.price.toLocaleString()
      }]
    }
  })



  // const handleSetPeriod = (period: CalendarPeriod) => {
  //   setPeriod(period)
  //   const now = new Date()
  //   switch (period) {
  //     case CalendarPeriod.day:
  //       setDateFrom(dateToDatePickerDate(now))
  //       setDateTo(dateToDatePickerDate(sub(now, { days: 1 })))
  //       break;
  //     case CalendarPeriod.week:
  //       setDateFrom(dateToDatePickerDate(now))
  //       setDateTo(dateToDatePickerDate(sub(now, { weeks: 1 })))
  //       break;
  //     case CalendarPeriod.month:
  //       setDateFrom(dateToDatePickerDate(now))
  //       setDateTo(dateToDatePickerDate(sub(now, { months: 1 })))
  //       break;
  //     case CalendarPeriod.year:
  //       setDateFrom(dateToDatePickerDate(now))
  //       setDateTo(dateToDatePickerDate(sub(now, { years: 1 })))
  //       break;
  //     default:
  //       break;
  //   }
  // }
  // const handleSetDateFrom = (date: DatePickerDate) => {
  //   setDateFrom(date)
  //   setPeriod(undefined)
  // }
  // const handleSetDateTo = (date: DatePickerDate) => {
  //   setDateTo(date)
  //   setPeriod(undefined)
  // }

  // const handleBuildOrder = () => {
  //   dispatch(buildNewDeliveryRequest())
  // }
  
  const handleSetPeriodDates = (data: PeriodDates) => {
    dispatch(setSentPeriodDates(data))
  }
  // if (isLoadingOrders) {
  //   return <Skeleton height={80} count={5} />
  // }
  return (
    <div className={styles.new_orders}>
      {/* <div className={styles.period}>
        <div className={styles.title}>Сформировать статистику за</div>
        <div className={styles.period_selector}>
          <CalendarPeriodSelector onChange={handleSetPeriod} period={period} className={styles.switch} />
          <div className={styles.dates}>
            <CustomDatePicker date={dateFrom} onChange={handleSetDateFrom} label="с" />
            <CustomDatePicker date={dateTo} onChange={handleSetDateTo} label="по" />
          </div>
          <BlueButton title='Применить' buttonStyle={ButtonStyle.Style1} onClick={handleApplayPeriod} />
        </div>
      </div> */}
      <DateSelector periodDates={sentPeriodDates} setPeriodDates={handleSetPeriodDates} />
      <div className={styles.table}>
        {isLoadingOrders
          ? <Skeleton height={80} count={5} />
          : <ItemsList headers={Headers} items={items} emptyText={<div className={styles.empty_text}><h1>Нет заказов</h1><span>Но они скоро появятся! </span></div>} />
        }
      </div>
      {/* <div className={styles.footer}>
        <BlueButton title='Собрать заказ' onClick={handleBuildOrder} />
      </div> */}
    </div>
  )
}
