import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import styles from './Controls.module.scss'
import { Search } from './parts/Search'
import classNames from 'classnames'
import zoom from "../../../../assets/zoom.svg";
import { ReactComponent as GridIcon } from "../../../../assets/grid.svg";
import { ReactComponent as ListIcon } from "../../../../assets/list.svg";

interface ControlsProps {
  viewType: boolean;
  setViewType: Dispatch<SetStateAction<boolean>>;
  setSearch: Dispatch<SetStateAction<string>>;
}

export default function Controls({ viewType, setViewType, setSearch }: ControlsProps) {

  const setView = useCallback(() => setViewType(prevState => !prevState), []);

  return (
    <div className={styles.controls}>
      <Search handleSearch={setSearch} />
      <div className={styles.btns}>
        <button
          className={styles.scaleLogo}
          onClick={setView}>
          <img src={zoom} alt="zoom" />
          <span>Изменить масштаб</span>
        </button>
        <div className={styles.viewControlIcons}>
          <GridIcon className={classNames(styles.icon, !viewType && styles.active)} />
          <ListIcon className={classNames(styles.icon, viewType && styles.active)} />
        </div>
      </div>
    </div>
  )
}
