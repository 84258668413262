import React from 'react'
import { DaysOfWeek } from './CalendarWeek'

import styles from "./CalendarWeek.module.scss"


export default function WeekDays() {
  // week_head
  return (
    <div className={styles.week_days}>
      {DaysOfWeek.map((dayOfWeek, n) => {
        return (<div key={dayOfWeek} className={styles.week_cell}>
          <div className={styles.day_head}>{dayOfWeek}</div>
        </div>)
      })}
    </div>
  )
}
