import React from 'react'

import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { useNavigate } from 'react-router-dom'
import { activeVariantSelector, colorSelector, productHasErrors, resetState, sendToModeration, setEditMode, updateProductAll, updateProductStep3_4 } from '../../../../../store/slices/product'
import { Routes } from '../../../../../constants/routes'
import { Category } from '../Category'
import { About } from '../About'
import { VariantSelector } from '../VariantSelector'
import { VariantColors } from '../VariantColors'
import { Seasons } from '../Seasons'
import { Collection } from '../Collection'
import { Compositions } from '../Compositions'
import { Details } from '../Details'
import { Package } from '../Package'
import { Description } from '../Description'
import { TagsBlock } from '../TagsBlock'
import { BlueButton } from '../../../../simples/BlueButton'
import { VariantPhotos } from '../VariantPhotos'
import { CustomHint } from '../../../../simples/CustomHint'

import styles from '../../Product.module.scss'
import { VariantSizesForCard } from '../VariantSizesForCard'
import { toast } from 'react-toastify'

export default function ProductCard() {
  const dispatch = useAppDispatch()

  const productState = useAppSelector(state => state.product)
  const navigate = useNavigate();

  const activeVariant = useAppSelector(activeVariantSelector)
  const currentColor = useAppSelector(colorSelector(activeVariant.colors_id))

  const readonly = useAppSelector(state => state.product.isReadonlyMode)

  const handleCancel = () => {
    dispatch(setEditMode(false))
  }
  const handleSave = () => {
    // dispatch(updateProductStep3_4()).then(() => {
    dispatch(updateProductAll()).then(() => {
      dispatch(sendToModeration()).then((action) => {
        if (action.payload.success) {
          // toast.success("Товар успешно сохранён и отправлен на модерацию", { theme: "light" });
          dispatch(resetState())
          navigate(Routes.PRODUCTS_LIST);
        }
      }).catch((e) => {
        console.log(e);
        toast.error("Ошибка", { theme: "light" });
      })
    }).catch((e) => {
      console.log(e);
      toast.error("Ошибка", { theme: "light" });
    })
  }


  const { hasPromotion, hasDiscount, about } = productState
  const colorPrice = activeVariant.priceForAnySize
  const productHasDiscount = hasPromotion || hasDiscount
  const isProductHasErrors = productHasErrors(productState)

  const handleToggleEditMode = () => {
    dispatch(setEditMode(true))
  }

  return (
    <div className={classNames(styles.wrap, styles.card)}>
      <div className={styles.column}>
        <div className={styles.head}>
          {/* {readonly && <div className={styles.edit_btn}>Редактировать карточку товара</div>} */}
          {readonly && <div className={styles.edit_btn} onClick={handleToggleEditMode}>Редактировать карточку товара</div>}
          <h1>{about.values.name} {about.values.brand}</h1>
          <div className={styles.price}>
            {colorPrice
              ? <p>Розничная цена цвет <span>{currentColor?.name?.toLowerCase()}</span>: <span>{colorPrice}</span> ₽</p>
              : <p>Розничная цена цвет <span>{currentColor?.name?.toLowerCase()}</span>: <span>поразмерно</span></p>
            }
            {productHasDiscount && <div className={styles.discount}>
              {hasDiscount && <span>% Действует скидка</span>}
              {hasPromotion && <span>% Действует скидка LL</span>}
              <CustomHint hint='Цена указана до учёта скидки, подробнее в разделе Цены и Скидки' hintWidth={350} />
            </div>}
          </div>
        </div>
        <Category className={styles.category} />
        <About />
        <div>
          <VariantSelector className={styles.variant_selector} />
          <VariantColors />
        </div>
        <div className={styles.seasons_collection}>
          <Seasons />
          <Collection />
        </div>
        <Compositions />
        <Details />
        <TagsBlock />
        <Package />
        <VariantSizesForCard />
        <Description />
        {!readonly && <div className={styles.footer}>
          <BlueButton title='Отменить' onClick={handleCancel} />
          <BlueButton title='Отправить на модерацию' onClick={handleSave} disabled={isProductHasErrors} />
        </div>}
      </div>
      <div className={styles.photos_wrap}>
        <VariantPhotos />
      </div>
    </div>
  )
}
