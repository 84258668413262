const RouteBase = '/logistics/FBO/'

export enum LogisticsFBOTabId {
  leftovers_in_stock = 'leftovers_in_stock',
  current_orders = 'current_orders',
}

type TabConfig = {
  id: LogisticsFBOTabId
  label: string
  // route: string
}

type ILogisticsFBOTabConfig = {
  [key in LogisticsFBOTabId]: TabConfig
}

function buildTabRoute(routeBase: string, tabId: LogisticsFBOTabId) {
  return routeBase + tabId
}

export const LogisticsFBOTabConfig: ILogisticsFBOTabConfig = {
  [LogisticsFBOTabId.leftovers_in_stock]: {
    id: LogisticsFBOTabId.leftovers_in_stock,
    label: 'Остатки на складе',
    // route: buildTabRoute(RouteBase, LogisticsFBOTabId.leftovers_in_stock)
  },
  [LogisticsFBOTabId.current_orders]: {
    id: LogisticsFBOTabId.current_orders,
    label: 'Актуальные заявки',
    // route: buildTabRoute(RouteBase, LogisticsFBOTabId.current_orders)
  },
}