import React, { useState, useEffect, useRef, ChangeEvent } from 'react'
import { BlueButton } from '../../../../simples/BlueButton'
import { Checkbox } from '../../../../simples/Checkbox'
import { IHint } from '../../../../simples/IHint'
import { RadioButtons } from '../../../../simples/RadioButtons'

import styles from "./PriceEditing.module.scss"
import uploadIcon from "../../assets/upload.svg"
import downloadIcon from "../../assets/download.svg"
import { ProductList, AvailableProductData, EditedValue } from '../../../../complexes/ProductList'
import classNames from 'classnames'
import { GoodsAnswer, ListDataType, ListHeadData, ListProductDataType } from '../../../../../types/productListTypes'
import { CustomDatePicker, DatePickerDate } from '../../../../complexes/CustomDatePicker'
import { datePickerDateToDate, dateToDatePickerDate } from '../../../../complexes/CustomDatePicker/CustomDatePicker'
import download from '../../../../../utils/download'
import { Endpoints } from '../../../../../constants/server'
import ModalNotification from '../ModalNotification/ModalNotification'
import api from "../../../../../utils/api"
import { ModalProgress } from '../../../../simples/ModalProgress'
import upload from '../../../../../utils/upload'
import { ModalProductSearch } from '../../../../complexes/ModalProductSearch'
import { format } from 'date-fns'
import { DebouncedInput } from '../../../../complexes/DebouncedInput'
import { textAsIntValidator } from '../../../../../utils/validators'
import { ModalServerError, ServerError } from '../../../../complexes/ModalServerError'
import { ProductsDTO } from '../../../../../types/swagger/llyApi_new'
import { DefaultHeaders } from '../../../../complexes/ProductList/DefaultHeaders'

const headers: ListHeadData[] = [
  DefaultHeaders.photo,
  DefaultHeaders.barcode,
  DefaultHeaders.name,

  DefaultHeaders.retail_price,
  DefaultHeaders.edited_price_diff,
  DefaultHeaders.edited_price_diff_percent,
  DefaultHeaders.editable_retail_price,

  DefaultHeaders.size,
  DefaultHeaders.color,

  DefaultHeaders.fbo,
  DefaultHeaders.fbs,
  // DefaultHeaders.stockBalance,

  DefaultHeaders.brand,
  DefaultHeaders.sex,
  DefaultHeaders.category,
  DefaultHeaders.subcategory,
  DefaultHeaders.season,
  DefaultHeaders.dateOfCreation,
];

const hidableHeaders = [AvailableProductData.brand, AvailableProductData.sex, AvailableProductData.category, AvailableProductData.subcategory, AvailableProductData.season, AvailableProductData.dateOfCreation]

const defaultDateFrom = dateToDatePickerDate(new Date())
enum PriceDirection {
  up = "up",
  down = "down"
}

export default function PriceEditing() {

  const [productsSearchMode, setProductsSearchrMode] = useState(false);
  const [priceDirection, setPriceDirection] = useState(PriceDirection.down)
  const [percentValue, setPercentValue] = useState("")
  const [priceInPercent, setPriceInPercent] = useState(true)
  const [applyPriceNow, setApplyPriceNow] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [dateFrom, setDateFrom] = useState<DatePickerDate>(defaultDateFrom)
  const [notificationText, setNotificationText] = useState("")
  const [modalProgressValue, setModalProgressValue] = useState<number>(0)
  const [isModalProgressActive, setIsModalProgressActive] = useState(false)
  const [progressText, setProgressText] = useState("")
  const [editedRetailPrices, setEditedRetailPrices] = useState<EditedValue[]>([])

  const [serverError, setServerError] = useState<ServerError>()

  const [products, setProducts] = useState<ProductsDTO[]>([]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const loadProducts = async (ids: string[]) => {
    let data: ProductsDTO[] = []
    if (ids.length > 0) {
      const req = `${Endpoints.GET_SELECTED_PRODUCTS}`;
      await api
        .post<GoodsAnswer>(req, { products: ids })
        .then((res) => {
          if (res.data.success) {
            data = res.data?.data || [];
          } else {
            if (res.data.errors) {
              setNotificationText(`${res.data.errors.title}: ${res.data.errors.errors.join(', ')}`)
            } else {
              setNotificationText("Ошибка")
            }
          }
        })
        .catch((err) => console.log(err));
    }
    return data
  }

  const clearListData = () => {
    setProducts([])
  }

  const handleChangePriceDirection = d => {
    setPriceDirection(d)
  }


  const percentValueValidator = (v: string) => {
    let _v = parseInt(v)
    if (isNaN(_v) || _v < 0) { _v = 0 }
    else {
      if (_v < 5) { _v = 5 }
    }
    if (_v > 90) { _v = 90 }
    return _v > 0 ? _v.toString() : ""
  }

  useEffect(() => {
    let _v = parseInt(percentValue) || 0
    if (priceInPercent) {
      const pdiff = (priceDirection === PriceDirection.down) ? (1 - _v / 100) : (1 + _v / 100)
      const newEditedRetailPrices = products.map(p => ({ id: p.id, value: Math.round((p?.price || 0) * pdiff || 0) }))
      setEditedRetailPrices(newEditedRetailPrices)
    }
  }, [percentValue, priceInPercent, priceDirection, products])

  const handleTogglePriceInPercent = () => {
    setPriceInPercent(!priceInPercent)
  }

  const handleSetDateFrom = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    if (d.getTime() > Date.now()) {
      setDateFrom(date)
    }
  }

  const handleUploadTable = () => {
    inputRef.current?.click();
  }
  const handleDownloadTable = () => {
    setModalProgressValue(0)
    setIsModalProgressActive(true)
    setProgressText("Скачивание архива")
    download({
      req: `${Endpoints.GET_PRODUCTS_FOR_PRICE_EDIT}?products_id=${products.map(p => p.id).join(',')}`,
      onDownloadProgress: pv => {
        setModalProgressValue(pv)
      },
      onError: e => {
        setNotificationText(e)
      },
      onFinish: () => {
        setIsModalProgressActive(false)
      }
    })
  }
  const handleSave = () => {

    const req = Endpoints.SET_PRODUCTS_PRICES

    const from_date = format(applyPriceNow ? Date.now() : datePickerDateToDate(dateFrom), "yyyy-MM-dd")
    const data = editedRetailPrices.map(pp => ({
      from_date,
      "price": pp.value,
      "product_id": pp.id
    }))
    api.post<GoodsAnswer>(req, data)
      .then((data) => {
        // if (isModalProgressActive) { setProgressText("Цены изменены") }
        // else { setNotificationText(`Цена применена на ${data.data.data?.length} товаров`) }
        if (data.data.success) {
          setNotificationText(`Цена применена на ${data.data.data?.length} товаров`)
          clearListData()
        } else {
          if (data.data.errors) {
            setNotificationText(`${data.data.errors.title}: ${data.data.errors.errors.join(', ')}`)
          } else {
            setNotificationText("Ошибка")
          }
        }
      })
      .catch(e => {
        // setNotificationText(e.toString())
        if (e?.response?.data?.errors) {
          setServerError(e?.response?.data?.errors)
        } else {
          setNotificationText("Ошибка на стороне сервера")
        }
      })
      .finally(() => {
        // reloadListData()
      })

  }

  const handleCloseModalNotification = () => {
    setNotificationText("")
  }


  const handleSetApplyPriceNow = (v) => {
    setApplyPriceNow(v)
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    setModalProgressValue(0)
    setIsModalProgressActive(true)
    setProgressText("Отправка архива")

    const url = Endpoints.EDIT_PRODUCTS_PRICE_UPLOAD

    upload({
      url, formDataFile: file,
      onUploadProgress: pv => {
        setModalProgressValue(pv)
      },
      onFinish: (answer) => {
        setIsModalProgressActive(false)
        answer.response?.meat?.totalCount && setNotificationText(`Цена применена на ${answer.response?.meat?.totalCount} товаров`)
        // reloadListData()
        clearListData()
      },
    })
  };

  const handleSearchProducts = () => {
    setProductsSearchrMode(true);
  }
  const handleCloseSearch = async (values: string[]) => {
    setProductsSearchrMode(false);
    const data = await loadProducts(values)
    setProducts(data);
  }

  const handleRemoveItemsFromList = (ids: string[]) => {
    setProducts(products.filter(p => ids.indexOf(p.id) === -1))
  }
  const handleChangePrices = (newPrices: EditedValue[]) => {
    newPrices.map(np => {
      const p = products.find(p => p.id === np.id)
      const pp = p?.price || 0
      if (p) {
        if (np.value as number > pp * 1.9) {
          np.value = Math.floor(pp * 1.9) || 0
        }
        if (np.value as number < pp * 0.1) {
          np.value = Math.ceil(pp * 0.1) || 0
        }
      }
      return np
    })
    setEditedRetailPrices(newPrices)
  }

  // let linkText = "товаров"
  // if (products.length === 1) { linkText = "товар" }
  // if (products.length > 1 && products.length < 5) { linkText = "товара" }
  // linkText = `${products.length} ${linkText}`

  let linkText = "товаров"
  let _v = products.length
  if (_v > 15) _v %= 10
  if (_v === 1) { linkText = "товар" }
  if (_v > 1 && _v < 5) { linkText = "товара" }
  linkText = `${products.length} ${linkText}`

  const productsWithDiscount = products.filter(p => p.hasDiscount).map(p => p.id)
  // const productsWithDiscount = products.filter(p => p.hasDiscount).map(p => p.product_id)
  const saveBtnIsDisabled = (productsWithDiscount.length > 0) || (products.length < 1) || percentValue === ""

  const handleClearServerError = () => {
    setServerError(undefined)
  }

  const activeHeaders = showAll ? headers : headers.filter(h => hidableHeaders.indexOf(h.name as AvailableProductData) === -1)

  return (
    <div className={styles.wrap}>
      <ModalProductSearch onClose={handleCloseSearch} isVisible={productsSearchMode} />
      <ModalNotification text={notificationText} onClose={handleCloseModalNotification} />
      <ModalServerError onClose={handleClearServerError} error={serverError} />
      <ModalProgress isActive={isModalProgressActive} progress={modalProgressValue} text={progressText} />
      <div className={styles.row}>
        <RadioButtons
          onChange={handleChangePriceDirection}
          value={priceDirection}
          disabled={!priceInPercent}
          className={classNames(styles.rado_btns, { [styles.disabled]: !priceInPercent })}
          options={[
            { label: "Снизить цену на %", value: PriceDirection.down },
            { label: "Повысить цену на %", value: PriceDirection.up }
          ]} />
        <DebouncedInput
          debounceDelay={1000}
          preValidator={textAsIntValidator}
          validator={percentValueValidator}
          placeholder="Введите значение..."
          onChange={setPercentValue}
          value={percentValue.toString()}
          disabled={!priceInPercent}
          className={classNames(styles.discount_value, { [styles.disabled]: !priceInPercent })}
          textBelow="не более 90%"
          postfix='%' />
        <Checkbox checked={!priceInPercent} onChange={handleTogglePriceInPercent} label="Редактировать цену в рублях" />
        <IHint hint='Новая стоимость будет применена на все товары, привязанные к карточке' />
      </div>
      <div className={styles.row}>
        <span className={styles.text1}>Опубликовать цену</span>
        <div>
          <CustomDatePicker date={dateFrom} onChange={handleSetDateFrom} label="с" disabled={applyPriceNow} />
        </div>
        <Checkbox checked={applyPriceNow} onChange={handleSetApplyPriceNow} label="Применить цену сейчас" />
      </div>
      <div className={classNames(styles.row, styles.controls1)}>
        <div className={styles.btns}>
          <BlueButton title='Добавить карточки для изменения цены' onClick={handleSearchProducts} />
          <BlueButton title='Выгрузить таблицу товаров' onClick={handleDownloadTable} iconSrc={downloadIcon} />
          <BlueButton title='Загрузить таблицу товаров' onClick={handleUploadTable} iconSrc={uploadIcon} />
          <input
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </div>
        <Checkbox label='Показать все поля' checked={showAll} onChange={setShowAll} />
      </div>
      <div className={styles.info}>
        <span className={styles.count_info}>Всего участвует в скидке <span className={styles.link} onClick={handleSearchProducts}>{linkText}</span></span>
      </div>
      <ProductList
        data={products}
        headers={activeHeaders}
        errors={productsWithDiscount}
        isRemovable={true}
        removeItemsFromList={handleRemoveItemsFromList}
        isEditablePrice={!priceInPercent}
        editedRetailPrices={editedRetailPrices}
        onChangePrices={handleChangePrices}
      />
      <div className={classNames(styles.row, styles.controls2)}>
        <BlueButton title='Сохранить' onClick={handleSave} disabled={saveBtnIsDisabled} />
      </div>
    </div>
  )
}
