import React, {Dispatch, FC, SetStateAction} from "react";
import styles from "./GridView.module.scss";
import plus from "../../../../../../../assets/icons/addBtn.svg";

import {LogoType} from "../../../../../../../types/logos";
import {LogoCard} from "./LogoCard";

interface Props {
  logoList: LogoType[];
  handleSelected: (id: number) => void;
  setUpload: Dispatch<SetStateAction<boolean>>;
  setIsUpdate: Dispatch<SetStateAction<boolean>>;
  selectedId: number | null;
}

const GridView: FC<Props> = ({
  logoList,
  handleSelected,
  setUpload,
  setIsUpdate,
  selectedId,
}) => {
  const logoHandler = () => {
    setUpload(true);
    setIsUpdate(false);
  };

  return (
    <section className={styles.container}>
      <section className={styles.gridView}>
        <button className={styles.create} onClick={logoHandler}>
          <img src={plus} alt="Create Logo" />
          <p className={styles.title}>Создать новый логотип</p>
        </button>
        {logoList.map((item,index) => (
          <LogoCard
            key={item.id}
            item={item}
            selectedId={selectedId || null}
            handleSelected={handleSelected}
            gridCount={index}
          />
        ))}
      </section>
    </section>
  );
};

export default GridView;
