import React from "react";
import styles from "./GoodsListFooter.module.scss";
import { Pagination } from "../GoodsListMain/parts/GoodsListTable/parts/Pagination";
import trashIcon from "../../../../../assets/icons/trash_icon.svg";
import restoreIcon from "../../../../../assets/icons/goods_list_restore_icon.svg";
import { restoreProducts, setGoodsToDelet, setModeratedProducts } from "../../../../../store/slices/goodsList";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import { RootState } from "../../../../../store";
import { ArchiveCategoryID } from "../../const";
import { BlueButton } from "../../../../simples/BlueButton";
import { ButtonSize } from "../../../../simples/BlueButton/BlueButton";
import { GoodsListTexts } from "../../../../../constants/goodsListTexts";
import { setSelectedIds } from "../../../../../store/slices/massEdit";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../../constants/routes";


export default function GoodsListFooter() {
  const dispatch = useAppDispatch();
  const selectedStateId = useAppSelector((state: RootState) => state.productsList.selectedCategoryID);

  const selectedGoods = useAppSelector((state: RootState) => state.productsList.selectedGoods);
  const isRestoringProducts = useAppSelector((state: RootState) => state.productsList.isRestoringProducts);


  const handleDeleteSelected = () => {
    if (selectedGoods.length > 0) {
      dispatch(setGoodsToDelet(selectedGoods));
    }
  };

  const handleRestoreSelected = () => {
    if (selectedGoods.length > 0) {
      dispatch(restoreProducts({ goodsToRestore: selectedGoods, selectedStateId }));
    }
  };

  const navigate = useNavigate();
  const handleMassEdit = () => {
    if (selectedGoods.length > 0) {
      dispatch(setSelectedIds(selectedGoods));
      navigate(`${Routes.MASS_EDIT}`);
    }
  };

  const handleSetModerated = () => {
    dispatch(setModeratedProducts(selectedGoods))
  }

  return (
    <div className={styles.footer}>
      <div className={styles.buttons}>
        {
          (selectedStateId === ArchiveCategoryID)
            ? <BlueButton onClick={handleRestoreSelected} size={ButtonSize.Normal} title={GoodsListTexts.GoodsListFooter_restore} disabled={isRestoringProducts} />
            : <BlueButton onClick={handleMassEdit} size={ButtonSize.Normal} title={GoodsListTexts.GoodsListFooter_edit} />
        }
        <BlueButton onClick={handleDeleteSelected} size={ButtonSize.Normal} title={GoodsListTexts.GoodsListFooter_remove} />
        {selectedStateId === 2 && <div className={styles.debug_btns_wrap}>
          <div className={styles.debug_btns}>
            <BlueButton title='Пройти модерацию' onClick={handleSetModerated} />
          </div>
        </div>}
      </div>
      <Pagination />
    </div>
  );
}
