import React from 'react'

import styles from '../CompanyDocuments.module.scss'
import { BlueButton, ButtonStyle } from '../../../simples/BlueButton'
import classNames from 'classnames'
import { IconBtn, IconType } from '../../../simples/IconBtn'
import { CompaniesDocumentsDTO } from '../../../../types/swagger/llyApi_new'
import { DocNames, DocType, LawDoc, LawDocStatus } from '../../../../types/law'
import Dropzone from 'react-dropzone'
import { useAppDispatch } from '../../../../hooks/store'
import { removeDoc, uploadDoc } from '../../../../store/slices/companyDocuments'
import { CustomHint } from '../../../simples/CustomHint'


// enum DocType {
//   TYPE_OGRN = 'TYPE_OGRN',
//   TYPE_INN = 'TYPE_INN',
//   TYPE_ARTICLE = 'TYPE_ARTICLE',
//   TYPE_ORDER = 'TYPE_ORDER',
//   TYPE_OGRNIP = 'TYPE_OGRNIP',
//   TYPE_PASSPORT = 'TYPE_PASSPORT',
//   TYPE_USN_STATEMENT = 'TYPE_USN_STATEMENT',
// }



type Props = {
  doc: LawDoc
}

// const ErrorText = '! Загрузите документ'
const accept = { "image/png": [".png"], 'image/jpg': ['.jpg'], 'application/pdf': ['.pdf'] }
const maxSize = 32 * 1024 ** 2

export default function Doc({ doc }: Props) {

  const dispatch = useAppDispatch()

  const handle = () => { return }
  const onDrop = (data: File[]) => {
    if (data.length === 1) {
      dispatch(uploadDoc({ file: data[0], docType: doc.type }))
    }
  }


  const handleRemove = () => {
    // onRemove(doc.type as DocType)
    dispatch(removeDoc(doc.type))
  }

  return (
    <div className={classNames(styles.doc, {
      [styles.ok]: doc.status === LawDocStatus.ok,
      [styles.error]: doc.status === LawDocStatus.rejected || !doc.status,
      [styles.waiting]: doc.status === LawDocStatus.moderation,
    })} >
      <CustomHint hint={doc.moderationsDescription}><div className={styles.status_icon} /></CustomHint>
      <div className={styles.doc_name}>{DocNames[doc.type]}</div>
      <div className={styles.doc_btns}>
        <Dropzone onDrop={onDrop} multiple={false} accept={accept} maxSize={maxSize} >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <BlueButton title='Загрузить' onClick={handle} buttonStyle={ButtonStyle.Style1}  />
            </div>
          )}
        </Dropzone>
        <IconBtn icon={IconType.Trash} onClick={handleRemove} />
      </div>
      <div className={styles.doc_info}>
        {doc.progress ? `Загрузка... ${doc.progress}%` : (doc.file || '')}
      </div>
    </div>
  )
}
