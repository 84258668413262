const RouteBase = '/statistic/'

export enum StatisticTabId {
  turnover = 'turnover',
  by_category = 'by_category',
  remaining_stock = 'remaining_stock',
  sales = 'sales',
  price_dynamics = 'price_dynamics',
}

type TabConfig = {
  id: StatisticTabId
  label: string
  route: string
}

type IStatisticTabConfig = {
  [key in StatisticTabId]: TabConfig
}

function buildTabRoute(routeBase: string, tabId: StatisticTabId) {
  return routeBase + tabId
}

export const StatisticTabConfig: IStatisticTabConfig = {
  [StatisticTabId.turnover]: {
    id: StatisticTabId.turnover,
    label: 'Оборачиваемость',
    route: buildTabRoute(RouteBase, StatisticTabId.turnover)
  },
  [StatisticTabId.by_category]: {
    id: StatisticTabId.by_category,
    label: 'Статистика по категории',
    route: buildTabRoute(RouteBase, StatisticTabId.by_category)
  },
  [StatisticTabId.remaining_stock]: {
    id: StatisticTabId.remaining_stock,
    label: 'Остатки на складе',
    route: buildTabRoute(RouteBase, StatisticTabId.remaining_stock)
  },
  [StatisticTabId.sales]: {
    id: StatisticTabId.sales,
    label: 'Статистика по продажам',
    route: buildTabRoute(RouteBase, StatisticTabId.sales)
  },
  [StatisticTabId.price_dynamics]: {
    id: StatisticTabId.price_dynamics,
    label: 'Динамика по ценам',
    route: buildTabRoute(RouteBase, StatisticTabId.price_dynamics)
  },
}