import classNames from "classnames";
import React from "react";
import styles from "./PopupWrapper.module.scss";

type Props = {
  children: React.ReactNode;
  isShow: boolean;
  onClose: () => void;
  /** Callback for press out, if not set will use 'onClose' */
  onPressOut?: () => void;
  /** If u have bug with not covering all window,
   *  -> u need set transform for u'r screen */
  className?: string;
};

const PopupWrapper = ({
  children,
  onClose,
  onPressOut,
  className,
  isShow,
}: Props) => (
  <div className={classNames(styles.container, isShow && styles.show, className, )}>
    <div className={styles.backdrop} onClick={onPressOut || onClose} />
    {children}
  </div>
);

export default PopupWrapper;
