import React, { useState, useEffect } from 'react'
import { CustomTextInput } from '../../../../simples/CustomTextInput'
import { CustomRadioButtons } from '../../../../simples/CustomRadioButtons'
// import { Form } from './LawInfo'

import styles from './LawInfo.module.scss'
import { CustomTextarea } from '../../../../simples/CustomTextarea'
import classNames from 'classnames'
import { CustomHint } from '../../../../simples/CustomHint'
import { LegalCompanyInfoDTO } from '../../../../../types/swagger/llyApi_new'
import api from "../../../../../utils/api";
import ModalList from './ModalList'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { setLawData, setLawDataValue } from '../../../../../store/slices/law'

export enum Mode {
  readonly = 'readonly',
  modal = 'modal',
  embedded = 'embedded'
}

enum Form {
  ul = 'ul',
  ip = 'ip'
}
enum NDS {
  nds = 'nds',
  nonds = 'nonds'
}


type Props = {
  // data: LegalCompanyInfoDTO
  // onChange?: (data: LegalCompanyInfoDTO) => void
  mode: Mode
  isEditMode?: boolean
  onClickUpdate?: () => void
}

type FetchDataByInnResponse = {
  success: boolean
  data?: LegalCompanyInfoDTO[]
  errors?: string[]
}


function innHasError(inn: string, is_individual: boolean) {
  return (is_individual ? inn.length !== 12 : inn.length !== 10)
}


const availableKeys = ['full_name', 'short_name', 'kpp', 'manager_name', 'middle_name', 'second_name', 'first_name', 'ogrn', 'is_individual', 'vat_included', 'inn']
const errorText = "! Заполните"

export function checkErrors(data: LegalCompanyInfoDTO) {
  return {
    inn: innHasError(data.inn, data.is_individual) ? errorText : undefined,
    short_name: ((data.short_name?.length || 0) < 1) ? errorText : undefined,
    full_name: ((data.full_name?.length || 0) < 1) ? errorText : undefined,
    manager_name: (data.is_individual ? false : (data.manager_name?.length || 0) < 1) ? errorText : undefined,
    ogrn: (data.is_individual ? data.ogrn?.length !== 15 : data.ogrn?.length !== 13) ? errorText : undefined,
    kpp: (!data.is_individual && data.kpp?.length !== 9) ? errorText : undefined,
    name: ((data.name?.length || 0) < 1) ? errorText : undefined,
    first_name: (data.is_individual && (data.first_name?.length || 0) < 1) ? errorText : undefined,
    second_name: (data.is_individual && (data.second_name?.length || 0) < 1) ? errorText : undefined,
    middle_name: (data.is_individual && (data.middle_name?.length || 0) < 1) ? errorText : undefined,
  }
}

export function hasErrors(data: LegalCompanyInfoDTO) {
  // если хотябы одно поле содержити ошибку
  return Object.values(checkErrors(data)).find(e => e !== undefined) !== undefined
}



export default function LawControls({
  // data, onChange, 
  mode, onClickUpdate, isEditMode
}: Props) {

  // const [inn, setInn] = useState()
  const [loadingDataByInn, setLoadingDataByInn] = useState(false)
  const [variantsByInn, setVariantsByInn] = useState<LegalCompanyInfoDTO[] | undefined>()

  const data = useAppSelector(state => state.law.lawData)
  const dispatch = useAppDispatch()

  const lawDataDisabledFields = (mode === Mode.embedded) ? useAppSelector(state => state.law.lawDataDisabledFields) : []
  const readonly = mode === Mode.embedded && !isEditMode
  const errors = checkErrors(data)

  function fetchDataByInn(inn: string) {
    setLoadingDataByInn(true)
    api.get<FetchDataByInnResponse>('/companies/get-by-inn?inn=' + inn).then(data => {
      // console.log(data);
      if ((data.data.data?.length || 0) > 1) {
        setVariantsByInn(data.data.data?.sort((row1, row2) => (row1.full_name || '').toLowerCase().localeCompare(row2.full_name?.toLowerCase() || '')))
      } else if (data.data.data?.length === 1) {
        handleSelectVariant(data.data.data[0])
      }
    }).finally(() => {
      setLoadingDataByInn(false)
    })
  }

  const ndsHint = <>Налог на добавочную стоимость (НДС) платят компании и ИП на общей системе налогообложения, а также все, кто ввозит товары через границу, то есть занимается импортом.<br /><br />
    Ставка «без НДС» полностью исключает налог из суммы продажи. То есть организация не является плательщиком НДС или временно освобождена от уплаты налога. Организация может не платить НДС в случаях если:<br />
    - Работает на спецрежиме — УСН, НПД или патенте.<br />
    - Применяет ОСНО или ЕСХН и получил освобождение от НДС.<br />
    - Занимается бизнесом, которому по закону разрешено не платить НДС.</>

  // const [form, setForm] = useState(Forms.ip)

  const handleChange = ({ name, value }: { name: number | string, value: number | string }) => {
    if (name === 'inn') {
      const newInn = value.toString()
      if (newInn !== data.inn && !innHasError(newInn, data.is_individual)) {
        fetchDataByInn(newInn)
      }
    }
    // onChange && onChange({ ...data, [name]: value })

    dispatch(setLawDataValue({ name, value }))
  }
  const handleChangeForm = ({ value }: { value: number | string }) => {
    // onChange && onChange({ ...data, is_individual: value === Form.ip })
    dispatch(setLawData({
      ...data,
      inn: '',
      is_individual: value === Form.ip,
      // vat_included: data.vat_included,
      short_name: '',
      full_name: '',
      manager_name: '',
      ogrn: '',
      kpp: '',
      name: '',
      second_name: '',
      first_name: '',
      middle_name: '',
      // editable: data.editable,
    }))
    // onChange && onChange({
    //   ...data,
    //   inn: '',
    //   is_individual: value === Form.ip,
    //   // vat_included: data.vat_included,
    //   short_name: '',
    //   full_name: '',
    //   manager_name: '',
    //   ogrn: '',
    //   kpp: '',
    //   name: '',
    //   second_name: '',
    //   first_name: '',
    //   middle_name: '',
    //   // editable: data.editable,
    // })
  }




  const handleChangeNds = ({ value }: { value: number | string }) => {
    // onChange && onChange({ ...data, vat_included: value === NDS.nds })
    dispatch(setLawDataValue({ name: 'vat_included', value: value === NDS.nds }))
  }
  const handleChangeFulllName = (value: string) => {
    // onChange && onChange({ ...data, full_name: value })
    dispatch(setLawDataValue({ name: 'full_name', value }))
  }
  // const handleChangeInn = ({ value }: { value: number | string }) => {
  // }


  // // если хотябы одно поле содержити ошибку
  // const hasErrors = Object.values(errors).find(e => e !== undefined) !== undefined


  const reload = (mode !== Mode.modal && !isEditMode) ? < div className={classNames(styles.col, styles.reload)}>
    <CustomHint hintWidth={360} hint='Если поменялись данные Вашей компании, то их необходимо обновить. Для этого перейдите по ссылке "Обновить юридическую информацию"' />
    <div className={styles.reload_btn} onClick={onClickUpdate} >Обновить юридическую информацию</div>
  </div> : <div className={styles.col}></div>


  const shopName = <div className={styles.col}>
    <CustomTextInput name='name' value={data.name} label='Название магазина, которое увидят покупатели'
      disabled={lawDataDisabledFields.indexOf('name') !== -1}
      error={errors['name']} onChange={handleChange} warningText='! Это название увидят покупатели в карточке товара'
      readonly={readonly} required />
  </div>

  const ogrn = <div className={styles.col}>
    <CustomTextInput name='ogrn' isNumber value={data.ogrn} label={data.is_individual ? 'ОГРНИП' : 'ОГРН'}
      disabled={lawDataDisabledFields.indexOf('ogrn') !== -1}
      mask={data.is_individual ? '999999999999999' : '9999999999999'}
      error={errors['ogrn']} onChange={handleChange} readonly={readonly} required />
  </div>

  const handleCloseVariants = () => {
    setVariantsByInn(undefined)
  }


  const handleSelectVariant = (selectedData: LegalCompanyInfoDTO) => {
    setVariantsByInn(undefined)
    const newData = { ...data }
    for (const key in newData) {
      if (Object.prototype.hasOwnProperty.call(newData, key)
        && Object.prototype.hasOwnProperty.call(selectedData, key)
        && availableKeys.indexOf(key) !== -1) {
        newData[key] = selectedData[key] || ''
      }
    }
    // onChange && onChange(newData)
    dispatch(setLawData(newData))
  }

  return (
    <div>
      <ModalList list={variantsByInn} onClose={handleCloseVariants} onSelect={handleSelectVariant} />
      {!readonly && <h1>Обновить юридическую информацию</h1>}
      <div className={styles.row}>
        <div className={styles.col}>
          <CustomTextInput name='inn' isNumber
            // maxLength={data.is_individual ? 12 : 10} 
            mask={data.is_individual ? '999999999999' : '9999999999'}
            error={errors['inn']}
            value={data.inn} label='ИНН' onChange={handleChange} hintWidth={550}
            disabled={lawDataDisabledFields.indexOf('inn') !== -1}
            hint='ИНН - Идентификационный номер налогоплательщика — цифровой код, упорядочивающий учёт налогоплательщиков в Российской Федерации. Присваивается налоговой записи как юридических, так и физических лиц Федеральной налоговой службой России.'
            readonly={readonly} required
          />
          <div>
            <CustomRadioButtons label='Форма собственности' name='form' activeName={data.is_individual ? Form.ip : Form.ul}
              btns={[{ name: Form.ul, label: 'Юридическое лицо' }, { name: Form.ip, label: 'Индивидуальный предприниматель' }]}
              onChange={handleChangeForm}
              readonly={readonly}
            />
          </div>
          <div className={styles.nds}>
            <CustomRadioButtons label='Форма организации' name='nds' activeName={data.vat_included ? NDS.nds : NDS.nonds} //
              btns={[{ name: NDS.nds, label: 'с НДС' }, { name: NDS.nonds, label: 'без НДС' }]}
              onChange={handleChangeNds}
              hint={ndsHint} hintWidth={550}
              readonly={readonly}
            />
          </div>
        </div>
        <div className={styles.col}></div>
      </div>
      {!data.is_individual && <>
        <div className={styles.row}>
          <div className={styles.col}>
            <CustomTextInput name='short_name' value={data.short_name} label='Краткое наименование'
              disabled={lawDataDisabledFields.indexOf('short_name') !== -1}
              error={errors['short_name']} onChange={handleChange} readonly={readonly} required />
          </div>
          {shopName}
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <CustomTextarea onChange={handleChangeFulllName} value={data.full_name} label='Полное наименование'
              disabled={lawDataDisabledFields.indexOf('full_name') !== -1}
              error={errors['full_name']} readonly={readonly} required />
          </div>
          <div className={styles.col}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <CustomTextInput name='manager_name' value={data.manager_name} label='ФИО генерального директора'
              disabled={lawDataDisabledFields.indexOf('manager_name') !== -1}
              error={errors['manager_name']} onChange={handleChange} readonly={readonly} required />
          </div>
          <div className={styles.col}></div>
        </div>
        <div className={styles.row}>
          {ogrn}
          <div className={styles.col}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <CustomTextInput name='kpp' isNumber value={data.kpp} label='КПП'
              disabled={lawDataDisabledFields.indexOf('kpp') !== -1}
              mask={'999999999'}
              // error={(data.kpp?.length !== 9) ? errorText : undefined}
              error={errors['kpp']}
              onChange={handleChange} readonly={readonly} required />
          </div>
          {reload}
        </div>
      </>}
      {data.is_individual && <>
        <div className={styles.row}>
          <div className={styles.col}>
            <CustomTextInput name='second_name' label='Фамилия' value={data.second_name}
              disabled={lawDataDisabledFields.indexOf('second_name') !== -1}
              error={errors['second_name']} onChange={handleChange} required />
          </div>
          {shopName}
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <CustomTextInput name='first_name' label='Имя индивидуального предпринимателя' value={data.first_name}
              disabled={lawDataDisabledFields.indexOf('first_name') !== -1}
              error={errors['first_name']} onChange={handleChange} required />
          </div>
          <div className={styles.col}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <CustomTextInput name='middle_name' label='Отчество индивидуального предпринимателя' value={data.middle_name}
              disabled={lawDataDisabledFields.indexOf('middle_name') !== -1}
              error={errors['middle_name']} onChange={handleChange} required />
          </div>
          <div className={styles.col}></div>
        </div>
        <div className={styles.row}>
          {/* <div className={styles.col}>
            <CustomTextInput name='ogrn' isNumber label='ОГРНИП' value={data.ogrn} onChange={handleChange} />
          </div> */}
          {ogrn}
          {reload}
        </div>
      </>}
    </div >
  )
}
