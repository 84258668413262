import React from 'react'
import styles from './Packs.module.scss'
import PackValue from '../PackValue/PackValue'
import { IOnChange } from '../../../../../../simples/CustomTextInput_v2'
import { IconBtn, IconType } from '../../../../../../simples/IconBtn'
import { IPack, removePack, setPackSize, setPackWeight } from '../../../../../../../store/slices/fbo'
import { useAppDispatch } from '../../../../../../../hooks/store'
import classNames from 'classnames'


type Props = {
  pack: IPack
  index: number
  // onChange: ({ name, value }: IOnChange) => void
}

export default function Pack({ pack, index }: Props) {

  const dispatch = useAppDispatch()
  const handleRemovePack = () => {
    dispatch(removePack(pack.id))
  }
  const handleSetSize = ({ name, value }: IOnChange) => {
    dispatch(setPackSize({ packId: pack.id, sizeName: name.toString(), value: parseInt(value.toString()) || 1 }))
  }
  const handleSetWeight = ({ value }: IOnChange) => {
    dispatch(setPackWeight({ packId: pack.id, value: parseInt(value.toString()) || 1 }))
  }

  const hasError = !pack.sizes.count || !pack.sizes.height || !pack.sizes.length || !pack.sizes.width || !pack.weight

  return (
    <tr className={styles.pack}>
      {/* <td>{pack.packName}</td> */}
      <td className={classNames(styles.title, { [styles.fade]: hasError })}>Груз {index + 1}</td>
      <td className={styles.docs}>
        <div className={styles.wrap}>
          <div className={styles.content}>
            {hasError
              ? <div className={styles.es_info}>Укажите габариты</div>
              : pack.docs.map(doc => <a key={doc.title} href={doc.link}>{doc.title}</a>)
            }
          </div>
        </div>
      </td>
      <td className={styles.sizes}>
        <div className={styles.wrap}>
          <div>
            <PackValue label='Длина' name='width' value={pack.sizes.width} onChange={handleSetSize} />
          </div>
          <div className={styles.size}>
            <PackValue label='Высота' name='height' value={pack.sizes.height} onChange={handleSetSize} />
          </div>
          <div className={styles.size}>
            <PackValue label='Ширина' name='length' value={pack.sizes.length} onChange={handleSetSize} />
          </div>
          <div className={styles.size}>
            <PackValue label='Кол-во' name='count' value={pack.sizes.count} onChange={handleSetSize} />
          </div>
        </div>
      </td>
      <td className={styles.weight}>
        <PackValue label='кг' name='weight' value={pack.weight} onChange={handleSetWeight} />
      </td>
      <td className={styles.btns}>
        <div className={styles.wrap}><IconBtn icon={IconType.Trash} onClick={handleRemovePack} /></div>
      </td>
    </tr>
  )
}
