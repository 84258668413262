import React, { useEffect, useState } from 'react'
import { ProductSelectorSlider, ProductSelectorSliderConfig } from '../../../../../../complexes/ProductSelectorSlider'
import { formatDateForServer } from '../../../../../../../utils/action'
import api from '../../../../../../../utils/api'
import { datePickerDateToDate } from '../../../../../../complexes/CustomDatePicker'
import { ProductsStatistics } from '../../../../../../../types/swagger/llyApi_new'
import styles from './ProductStatistic.module.scss'
import classNames from 'classnames'
import PanelRow, { ValueTypes } from './PanelRow'

type ServerDataResponse = {
  success?: boolean
  data?: ProductsStatistics[]
  errors?: {
    title?: string
    errors?: string[]
  }
}

export default function ProductStatistic() {

  const [selectorSliderConfig, setSelectorSliderConfig] = useState<ProductSelectorSliderConfig>()
  const [statistic, setStatistic] = useState<ProductsStatistics>()

  useEffect(() => {
    if (selectorSliderConfig?.colorId === undefined) {
      setStatistic(undefined)
      return
    }

    const from = formatDateForServer((selectorSliderConfig?.dateFrom !== undefined)
      ? datePickerDateToDate(selectorSliderConfig?.dateFrom)
      : new Date())
    const to = formatDateForServer((selectorSliderConfig?.dateTo !== undefined)
      ? datePickerDateToDate(selectorSliderConfig?.dateTo)
      : new Date())


    const req = `/products-colors/statistic/${selectorSliderConfig?.colorId}?from_date=${from}&to_date=${to}`

    api.get<ServerDataResponse>(req).then(data => {
      setStatistic((data.data.data && data.data.data.length === 1) ? data.data.data[0] : undefined)
    }).catch(e => {
      setStatistic(undefined)
    })
  }, [selectorSliderConfig])

  return (
    <div className={styles.wrap}>
      <ProductSelectorSlider onChange={setSelectorSliderConfig} />
      {statistic?.is_unique_article && <div className={styles.isuniq}>Ваш товар уникален на нашей площадке</div>}
      {(statistic === undefined || statistic.is_unique_article) ? <></>
        : <div className={styles.statistic}>
          {statistic?.is_category && <div className={styles.iscategory}>Товар сравнивается по категории</div>}
          <h1>Статистика по товару {selectorSliderConfig?.productTitle}</h1>
          <div className={styles.panels}>
            <div className={styles.panel}>
              <div className={styles.panel_head}>Оборачиваемость</div>
              <div className={styles.panel_body}>
                <div className={styles.group}>
                  <div className={styles.panel_datarow}>Актуальная оборачиваемость: {statistic.article_turnaround.actual} дней</div>
                  <PanelRow label='Ваша оборачиваемость' value={statistic.article_turnaround.prev_period} valueType={ValueTypes.Days} isReverse />
                </div>
                <div className={styles.group}>
                  <div className={styles.panel_datarow}>Актуальная оборачиваемость: {statistic.article_turnaround.lly_actual} дней</div>
                  <PanelRow label='Оборачиваемость товара на площадке' value={statistic.article_turnaround.lly_prev_period} valueType={ValueTypes.Days} isReverse />
                </div>
              </div>
            </div>
            <div className={styles.panel}>
              <div className={styles.panel_head}>Динамика цен</div>
              <div className={styles.panel_body}>
                <div className={styles.group}>
                  <div className={styles.panel_datarow}>Актуальная цена: {statistic.consolidated_price_dynamics.actual} ₽</div>
                  <PanelRow label='Динамика по Вашему товару' value={statistic.consolidated_price_dynamics.dynamic} valueType={ValueTypes.Money} />
                </div>
                <div className={styles.group}>
                  <div className={styles.panel_datarow}>Актуальная цена по площадке: {statistic.consolidated_price_dynamics.lly_actual} ₽</div>
                  <PanelRow label='Динамика по площадке' value={statistic.consolidated_price_dynamics.lly_dynamic} valueType={ValueTypes.Money} />
                </div>
              </div>
            </div>
            <div className={styles.panel}>
              <div className={styles.panel_head}>Доля продаж</div>
              <div className={styles.panel_body}>
                <div className={styles.group}>
                  <div className={classNames(styles.panel_datarow)}>Ваши продажи: {statistic.share_of_sales.sales} шт.</div>
                  <PanelRow label='Доля от рынка' value={statistic.share_of_sales.market_share} valueType={ValueTypes.Percent} />
                </div>
                <div className={styles.group}>
                  <div className={styles.panel_datarow}>Всего на рынке продавцов: {statistic.share_of_sales.sellers_count} чел.</div>
                  <PanelRow label='Динамика' value={statistic.share_of_sales.dynamic} valueType={ValueTypes.Items} />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  )
}
