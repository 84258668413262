import classNames from 'classnames'
import React from 'react'

import styles from "./DialogCloseBtn.module.scss"

type Props = {
  className?: string
  onClick?: (evt: React.MouseEvent) => void
}

export default function DialogCloseBtn({ className, onClick }: Props) {
  return (
    <div onClick={onClick} className={classNames(styles.btn, className)} />
  )
}
