import React, { useEffect, useState } from 'react'
// import styles from './SubMenu.module.scss'
import styles from './Menu.module.scss'
import classNames from 'classnames'
import MenuIcon, { IconType } from './MenuIcon'
// import { Routes } from '../../../../../../constants/routes'
import { NavLink } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/store'
import { toggleSubmenus } from '../../../../../../store/slices/mainMenu'

export type MenuItem = {
  id: string
  title: string
  iconType?: IconType
  route?: string //Routes
  submenu?: MenuItem[]
  closable?: boolean
  isOpenByDefault?: boolean
  notificationId?: string
}


type Props = {
  isOpen?: boolean
  className?: string
  menuItems: MenuItem[]
  lvl?: number
  notifications: {
    company_info?: boolean,
    company_documents?: boolean,
    work_times?: boolean
  }
}

export default function Menu({ menuItems, className, lvl = 0, isOpen, notifications }: Props) {

  const dispatch = useAppDispatch()
  const openedSubmenus = useAppSelector(state => state.mainMenu.openedMenuItems)

  const handleOpenSubmenu = (menuItem: MenuItem, lvl: number) => () => {
    const { id, closable } = menuItem
    if (!closable) return
    dispatch(toggleSubmenus({id, lvl}))
  }

  return (<ul className={classNames(styles.menu, className, styles['lvl-' + lvl], { [styles.open]: isOpen })}>
    {menuItems.map((menuItem, i) => {
      const isActive = openedSubmenus[lvl]?.indexOf(menuItem.id) !== -1

      const headClassName = classNames(styles.item_head, {
        [styles.closable]: menuItem.closable,
        [styles.active]: isActive,
      })

      const getLinkClass = ({ isActive }: { isActive: boolean }) => isActive
        ? classNames(styles.active_link, headClassName)
        : headClassName;

      const headContent = <>
        {(lvl > 0) && <MenuIcon
          hasNotification={(menuItem.notificationId && notifications[menuItem.notificationId])}
          type={menuItem.iconType}
          active={isActive}
        />}
        <span className={styles.text}>{menuItem.title}</span>
      </>
      const head = menuItem.route
        ? <NavLink to={menuItem.route} className={getLinkClass}>{headContent}</NavLink>
        : <div className={headClassName} onClick={handleOpenSubmenu(menuItem, lvl)}>{headContent}</div>

      return <li key={i} className={classNames(styles.menu_item)}>
        {head}
        {menuItem.submenu && <Menu
          menuItems={menuItem.submenu}
          lvl={lvl + 1}
          isOpen={!menuItem.closable || isActive}
          notifications={notifications}
        />}
      </li>
    })}
  </ul>
  )
}
