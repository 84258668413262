import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OrganisationData, UserData } from "../../types/registration";
import { RootState } from "..";
import LawService, { CreateContactResponse, FetchContactsResponse, FetchLawDataAnswer, RemoveContactResponse, SaveLawDataAnswer, SaveLawDataResponse, UpdateLawDataProps, UploadDocAnswer } from "../../services/LawService";
import { DocNames, DocType, LawDoc } from "../../types/law";
import { CompaniesContactsDTO, LegalCompanyInfoDTO } from "../../types/swagger/llyApi_new";
import { toast } from "react-toastify";

type DocsGroup = { [key in DocType]?: LawDoc }

type State = {
  lawData: LegalCompanyInfoDTO
  lawDataLoading?: boolean
  lawDataDisabledFields: string[]
  ipDocs: DocsGroup
  ulDocs: DocsGroup
  contacts: CompaniesContactsDTO[]
  activeContactId?: number
  activeContactIsNew?: boolean
}


export function getEmptyLawData(): LegalCompanyInfoDTO {
  return {
    /** ИНН */
    inn: '',
    /** Форма собственности - ИП */
    is_individual: false,
    /** Форма организации - с НДС */
    vat_included: false,
    /** Краткое наименование */
    short_name: '',
    /** Полное наименование */
    full_name: '',
    /** ФИО ГД */
    manager_name: '',
    /** ОГРН */
    ogrn: '',
    /** КПП */
    kpp: '',
    /** Название магазина для пользователей */
    name: '',
    /** Фамилия ИП */
    second_name: '',
    /** Имя ИП */
    first_name: '',
    /** Отчество ИП */
    middle_name: '',
    /** Возможно редактирование */
    editable: true,
  }
}
function getNewContact(): CompaniesContactsDTO {
  return {
    id: Math.random(),
    keywords: '',
    name: '',
    phone: '',
    role: '',
  }
}

export const initialState: State = {
  lawData: getEmptyLawData(),
  lawDataDisabledFields: [],
  ipDocs: {
    [DocType.TYPE_OGRNIP]: {
      type: DocType.TYPE_OGRNIP,
      title: DocNames[DocType.TYPE_OGRNIP]// 'Свидетельство ОГРНИП'
    },
    [DocType.TYPE_INN]: {
      type: DocType.TYPE_INN,
      title: DocNames[DocType.TYPE_INN]// 'Свидетельство ИНН'
    },
    [DocType.TYPE_PASSPORT]: {
      type: DocType.TYPE_PASSPORT,
      title: DocNames[DocType.TYPE_PASSPORT]// 'Копия паспорта ИП (два разворота в одном файле - с фотографией и регистрацией)'
    },
  },
  ulDocs: {
    [DocType.TYPE_OGRN]: {
      type: DocType.TYPE_OGRN,
      title: DocNames[DocType.TYPE_OGRN]//  'Свидетельство ОГРН (или лист записи ЕГРЮЛ)'
    },
    [DocType.TYPE_INN]: {
      type: DocType.TYPE_INN,
      title: DocNames[DocType.TYPE_INN]//  'Свидетельство ИНН'
    },
    [DocType.TYPE_ORDER]: {
      type: DocType.TYPE_ORDER,
      title: DocNames[DocType.TYPE_ORDER]// 'Устав компании с отметками налогового органа о регистрации'
    },
    [DocType.TYPE_ARTICLE]: {
      type: DocType.TYPE_ARTICLE,
      title: DocNames[DocType.TYPE_ARTICLE]// 'Решение или приказ о назначении генерального директора'
    },
  },
  contacts: []
  // company: null as OrganisationData | null,
  // user: null as UserData | null,
  // isAuth: false,
  // isLoadingData: false,
};

const IpDocTypes = Object.values(initialState.ipDocs || {}).map(d => d.type)
const UlDocTypes = Object.values(initialState.ulDocs || {}).map(d => d.type)

// export const fetchUserData = createAsyncThunk("/user", async () => {
//   const data = await UserService.getUserData();
//   return data;
// });

const saveSuccessMsg = () => toast.success("Данные сохранены", { theme: "light" });
const errorMsg = () => toast.error("Ошибка", { theme: "light" });

export const uploadDoc = createAsyncThunk<any, { file: File, docType: DocType }, { state: RootState }>(
  "Law/uploadDoc", async ({ file, docType }, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {

    // const state = getState();

    // const category = getSelectedSubcategory(state);
    // if (!category) throw new Error("category not selected");

    // dispatch(setUploadingFileName(formDataFile.name));

    dispatch(setUploadingProgress({ type: docType, percent: 0 }));
    // dispatch(setFileName({ type: docType, fileName: file.name }));

    const response = await LawService.uploadDoc({
      file,
      type: docType,
      onProgress(percent) {
        dispatch(setUploadingProgress({ type: docType, percent }));
      },
    }).finally(() => {
      dispatch(setUploadingProgress({ type: docType, percent: undefined }));
    })

    if (response.success) {
      return fulfillWithValue(response);
    } else {
      return rejectWithValue(response)
    }
  }
);


export const saveLawData = createAsyncThunk<any, void, { state: RootState }>(
  "Law/saveLawData", async (_, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {

    const state = getState();
    const response = await LawService.saveLawData(state.law.lawData)

    dispatch(fetchLawData())
    if (response.success) {
      saveSuccessMsg()
      return fulfillWithValue(response);
    } else {
      errorMsg()
      return rejectWithValue(response)
    }
  }
);
export const updateLawData = createAsyncThunk<any, void, { state: RootState }>(
  "Law/updateLawData", async (_, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {

    const state = getState();

    const documents = state.law.lawData.is_individual ? state.law.ipDocs : state.law.ulDocs
    const d = Object.values(documents).map(d => ({
      type: d.type,
      id: d.id as number
    }))

    const data: UpdateLawDataProps = {
      ...state.law.lawData,
      documents: d
    }

    // console.log(data);


    const response = await LawService.updateLawData(data)

    dispatch(fetchLawData())
    if (response.success) {
      saveSuccessMsg()
      return fulfillWithValue(response);
    } else {
      errorMsg()
      return rejectWithValue(response)
    }
  }
);
export const fetchLawData = createAsyncThunk<any, void, { state: RootState }>(
  "Law/fetchLawData", async (_, { fulfillWithValue, rejectWithValue }) => {

    const response = await LawService.fetchLawData()

    if (response.success) {
      return fulfillWithValue(response);
    } else {
      return rejectWithValue(response)
    }
  }
);

export const fetchContacts = createAsyncThunk<any, void, { state: RootState }>(
  "Law/fetchContacts", async (_, { fulfillWithValue, rejectWithValue }) => {
    return await LawService.fetchContacts()
  }
);
export const removeContact = createAsyncThunk<any, { id: number }, { state: RootState }>(
  "Law/removeContact", async ({ id }, { dispatch }) => {
    const resp = await LawService.removeContact(id)
    dispatch(fetchContacts())
    if (!resp.data.success) {
      errorMsg()
    }
    return resp
  }
);
// export const createContact = createAsyncThunk<any, { data: CompaniesContactsDTO }, { state: RootState }>(
//   "Law/createContact", async ({ data }, { dispatch }) => {
//     const resp = await LawService.createContact(data)
//     dispatch(fetchContacts())
//     return resp
//   }
// );
// export const updateContact = createAsyncThunk<any, { id: number, data: CompaniesContactsDTO }, { state: RootState }>(
//   "Law/updateContact", async ({ id, data }, { dispatch }) => {
//     const resp = await LawService.updateContact(id, data)
//     dispatch(fetchContacts())
//     return resp
//   }
// );
export const saveContact = createAsyncThunk<any, void, { state: RootState }>(
  "Law/saveContact", async (_, { dispatch, getState }) => {
    const state = getState()
    const id = state.law.activeContactId
    const data = state.law.contacts.find(c => c.id === id)
    if (!data) { return }
    let resp: CreateContactResponse = { success: false }
    try {
      if (state.law.activeContactIsNew) {
        resp = await LawService.createContact(data)
      } else {
        resp = await LawService.updateContact(data.id, data)
      }
    } catch (error) {
      console.log(error);
    }
    if (resp?.success) {
      saveSuccessMsg()
    } else {
      errorMsg()
    }
    dispatch(fetchContacts())
    return resp
  }
);

export const lawSlice = createSlice({
  name: "Law",
  initialState,
  reducers: {
    setLawData: (state, action: PayloadAction<LegalCompanyInfoDTO>) => {
      state.lawData = action.payload
    },
    setLawDataValue: (state, action: PayloadAction<{ name: string | number, value: string | number | boolean }>) => {
      state.lawData[action.payload.name] = action.payload.value
    },
    removeDoc: (state, action: PayloadAction<DocType>) => {
      // const doc = state.ipDocs[action.payload] || state.ulDocs[action.payload]
      const docs = state.lawData.is_individual ? state.ipDocs : state.ulDocs
      const doc = docs[action.payload]
      if (doc) {
        doc.file = undefined
        doc.id = undefined
      }
    },
    setUploadingProgress: (state, action: PayloadAction<{ type: DocType, percent?: number }>) => {
      const docs = state.lawData.is_individual ? state.ipDocs : state.ulDocs
      const doc = docs[action.payload.type]
      if (doc) {
        doc.progress = action.payload.percent
      }
    },
    editContact: (state, action: PayloadAction<number>) => {
      // const c = state.contacts?.find(c => c.contact.id === action.payload)
      // if (c) { c.editMode = true }
      state.activeContactId = action.payload
      state.activeContactIsNew = false
    },
    updateContactValue: (state, action: PayloadAction<{ id: number, name: string | number, value: string | number }>) => {
      const c = state.contacts.find(c => c.id === action.payload.id)
      if (c) {
        c[action.payload.name] = action.payload.value
      }
    },
    createNewContact: (state) => {
      const c = getNewContact()
      state.contacts?.push(c)
      state.activeContactId = c.id
      state.activeContactIsNew = true
    },
    removeNewContact: (state) => {
      state.contacts = state.contacts.filter(c => c.id !== state.activeContactId)
      state.activeContactId = undefined
      state.activeContactIsNew = true
    },
    // setFileName: (state, action: PayloadAction<{ type: DocType, fileName: string }>) => {
    //   const docs = state.lawData.is_individual ? state.ipDocs : state.ulDocs
    //   const doc = docs[action.payload.type]
    //   if (doc) {
    //     doc.file = action.payload.fileName
    //   }
    // },
  },
  extraReducers: (builder) => {

    builder.addCase(fetchContacts.fulfilled, (state, action: PayloadAction<FetchContactsResponse>) => {
      state.activeContactId = undefined
      state.activeContactIsNew = false
      state.contacts = action.payload.data || []
    });
    builder.addCase(removeContact.fulfilled, (state, action: PayloadAction<RemoveContactResponse>) => {
      return
    });
    // builder.addCase(createContact.fulfilled, (state, action: PayloadAction<CreateContactResponse>) => {
    //   // state.activeContactId = action.payload.data?.id
    //   return
    // });
    builder.addCase(saveContact.fulfilled, (state, action: PayloadAction<CreateContactResponse>) => {
      return
    });


    builder.addCase(fetchLawData.pending, (state) => {
      state.lawDataLoading = true;
    });
    builder.addCase(fetchLawData.fulfilled, (state, action: PayloadAction<FetchLawDataAnswer>) => {

      if (action.payload.success) {
        const newLawData = action.payload.data || getEmptyLawData()
        delete newLawData.contacts // fix с бэка приходит лишнее поле
        state.lawData = newLawData
        const lawDataDisabledFields: string[] = []
        for (const key in newLawData) {
          if (Object.prototype.hasOwnProperty.call(newLawData, key)) {
            if (newLawData[key]) {
              lawDataDisabledFields.push(key)
            }
          }
        }
        state.lawDataDisabledFields = lawDataDisabledFields
      }
      state.lawDataLoading = false;
    });
    builder.addCase(fetchLawData.rejected, (state) => {
      state.lawDataLoading = false;
    });

    builder.addCase(saveLawData.fulfilled, (state, action: PayloadAction<FetchLawDataAnswer>) => {
      return
      // if (action.payload.success) {
      // }
      // state.lawDataLoading = false;
    });


    builder.addCase(uploadDoc.fulfilled, (state, action: PayloadAction<UploadDocAnswer>) => {
      if (action.payload.success) {
        const docs = state.lawData.is_individual ? state.ipDocs : state.ulDocs
        const doc = docs[action.payload.type]

        if (doc) {
          doc.progress = undefined
          doc.file = action.payload.name
          doc.id = action.payload.id
        }
      }
    });

    builder.addCase(uploadDoc.rejected, (state) => {
      errorMsg()
    });

  },
});


function allDocTypesHasId(documents: DocsGroup, types: DocType[]) {
  for (const type of types) {
    if (!docTypeHasId(documents[type])) return false
  }
  return true
}
function docTypeHasId(doc?: LawDoc) {
  return doc?.id !== undefined
}


export const isDocumentsUploaded = () => (state: RootState) => {
  return state.law.lawData.is_individual
    ? allDocTypesHasId(state.law.ipDocs, IpDocTypes)
    : allDocTypesHasId(state.law.ulDocs, UlDocTypes)
}

export const { setLawDataValue, setLawData, setUploadingProgress, removeDoc,
  createNewContact, editContact, updateContactValue, removeNewContact
} = lawSlice.actions

export default lawSlice.reducer;
