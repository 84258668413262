import React from 'react'

import { VariantSelector } from '../VariantSelector'
import { VariantColors } from '../VariantColors'
import { VariantSizes } from '../VariantSizes'
import { VariantPhotos } from '../VariantPhotos'
import { BlueButton } from '../../../../simples/BlueButton'
import { addVariant, removeVariant, selectVariant, step3HasErrors, updateProductStep3_4 } from '../../../../../store/slices/product'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import classNames from 'classnames'

import x_icon from './../../assets/x.svg'

import styles from "./Step3.module.scss"
import styles1 from "../../Product.module.scss"



export default function Step3() {
  const dispatch = useAppDispatch()
  const variants = useAppSelector(state => state.product.variants)
  const productState = useAppSelector(state => state.product)
  const { activeVariantIndex } = useAppSelector(state => state.product.variants)

  const handleSave = () => {
    dispatch(updateProductStep3_4())
  }
  const errors = variants.errors

  const isStep3HasErrors = step3HasErrors(productState)


  const handleSelectVariant = (index: number) => () => {
    dispatch(selectVariant(index))
  }
  const handleAddVariant = () => {
    dispatch(addVariant())
  }
  const handleRemoveVariant = (index: number) => (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation()
    dispatch(removeVariant(index))
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.labels}>
        {variants.values.map((v, i) => <div
          key={v.id}
          className={classNames(styles.label, {
            [styles.active]: i === activeVariantIndex
          })}
          onClick={handleSelectVariant(i)}
        >
          <div className={styles.label_text}>
            <span>{`Цвет ${i + 1}`}</span>
            {variants.values.length > 1 && <img src={x_icon} onClick={handleRemoveVariant(i)} />}
          </div>
        </div>)}
        {variants.values.length < 10 && <div className={styles.add_color} onClick={handleAddVariant}>Добавить цвет</div>}
      </div>
      <div className={styles.content}>
        <div className={styles.column}>
          <div className={styles.colors_sizes}>
            <VariantColors className={styles.colors} />
            <VariantSizes />
          </div>
          {errors.length > 0 && <div className={styles1.colors_error}>{errors.map(n => `"Цвет ${n + 1}"`).join(", ")} не заполнен{errors.length > 1 ? "ы" : ""} полностью</div>}
          <BlueButton title='Добавить цены' onClick={handleSave} disabled={isStep3HasErrors} />
        </div>
        <div className={styles.photos}>
          <VariantPhotos />
        </div>
      </div>

      {/* <VariantSelector className={styles.variant_selector} />
      <div>
        <VariantColors />
        <VariantSizes />
      </div>
      <VariantPhotos />
      {errors.length > 0 && <div className={styles.colors_error}>{errors.map(n => `"Цвет ${n + 1}"`).join(", ")} не заполнен{errors.length > 1 ? "ы" : ""} полностью</div>}
      <BlueButton title='Добавить цены' onClick={handleSave} disabled={isStep3HasErrors} /> */}
    </div>
  )
}
