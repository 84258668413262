import React, { FC } from "react";
import classNames from "classnames";
import styles from "./LogoCard.module.scss";
import { ReactComponent as EditLogo } from "../../../../../assets/edit.svg";
import { LogoType } from "../../../../../../../../types/logos";
import { useAppDispatch, useAppSelector } from "../../../../../../../../hooks/store";
import { editLogo } from "../../../../../../../../store/slices/getters/logo-list";

interface Props {
  item: LogoType;
  // selectedId: number | null;
  // gridCount: number;
  // onSelect: (id: number) => void;
  fixHint?: boolean
}

const LogoCard: FC<Props> = ({ item, fixHint }) => {

  const selectedId = useAppSelector(state => state.logoList.selectedId)

  const dispatch = useAppDispatch();

  const onSelect = (id: number) => {
    dispatch(editLogo(id));
  }

  return (
    <div
      className={classNames(
        styles.wrapper,
        item.id === selectedId && styles.selected,
      )}
      key={item.id}>
      <div className={styles.logo}>
        <img src={`${process.env.REACT_APP_API_URL}/${item.file}`} alt="logoImage" />
        <div>{item.name}</div>
        <div
          className={styles.edit}
          onClick={() => onSelect(item.id)}>
          <EditLogo className={classNames(item.id === selectedId && styles.active)} />
          <div className={classNames(styles.info, fixHint && styles.info_alignLeft)}>Редактировать</div>
        </div>
      </div>
      <button className={styles.setLogoForProduct}>Установить для товаров</button>
    </div>
  );
};

export default LogoCard;