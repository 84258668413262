import React from 'react'
import { useDroppable } from '@dnd-kit/core';
import { IItem } from '../../../../../../../../../store/slices/fbs'
import DraggableItem from './DraggableItem'
import styles from './Order.module.scss'
import { useAppSelector } from '../../../../../../../../../hooks/store';
import Item from './Item';

type Props = {
  items: IItem[]
  orderId: string
}

export default function OrderItems({ items, orderId }: Props) {
  const { activeItem } = useAppSelector(state => state.fbs)
  const { isOver, setNodeRef, over, active } = useDroppable({
    id: orderId,
    data: { accepts: [orderId] }
  });
  const isTarget = isOver && over?.data?.current?.accepts.includes(active?.data?.current?.orderId)
  const _isTarget = isTarget && activeItem
  

  return (
    <div ref={setNodeRef} className={styles.order_items}>
      {_isTarget && <Item item={activeItem} className={styles.target_place} orderId={orderId} />}
      {items.map(item => {
        // if (item.id === activeItem?.id) {
        //   return _isTarget && <DraggableItem orderId={orderId} item={activeItem} className={styles.target_place} />
        // }
        return <DraggableItem key={item.id} item={item} orderId={orderId} />
      })}
    </div>
  )
}
