import React from 'react'
import styles from "./ListCheckbox.module.scss"
import emptyIcon from "../../assets/checkbox_empty.svg"
import checkedIcon from "../../assets/checkbox_selected.svg"

type Props = {
  checked?: boolean
  onChange: (selected: boolean) => void
}

export default function ListCheckbox({ checked, onChange }: Props) {

  const handleClick = () => {
    onChange(!checked)
  }

  return (
    <div onClick={handleClick} className={styles.wrap}>
      <img src={checked ? checkedIcon : emptyIcon} />
    </div>
  )
}
