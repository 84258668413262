import { Endpoints } from "../constants/server";
import { ProductFormType } from "../store/slices/productEdit";
import { BarcodesResponseType, OtherFieldsSelect } from "../types/product";
import { CreateResponseType, Package, ReferenceBook } from "../types/product_new";
import { Products, ProductsColors, ProductsFashions } from "../types/swagger/llyApi_new";
import { Categories, Countries, Packages, ProductsDTO } from "../types/swagger/llyApi_new";
import api from "../utils/api";
import upload from "../utils/upload";


export type ProductStep1 = {
  category_id: number
  name: string
  description: string
  brand: string
  country_id: number
  package: Package
}

export type ProductStep2 = {
  category_id: number,
  collection: string,
  description: string,
  productsCompound: { compound_id: number, material_id: number, value: string | number }[]
  productsDetails: { detail_id: number, option_id: number | null, value: string }[]
  seasons: number[]
  tags: number[]
}

export type ProductsColorsStep3 = {
  colors_id: number
  color_article: number,
  // price: number
  man_article: string,
  additionalColors: { color_id: number }[],
  sizes: {
    id: number,
    man_barcode: string,
    barcode: string,
    price?: number
  }[],
  productsPhotos?: {
    main?: 0 | 1
    file_id: number
  }[]
  productsFashion: ProductsFashions// { file_id: number }
}
export type ProductStep3 = {
  productsColors: ProductsColorsStep3[]
}


interface IUploadFile {
  formDataFile: File,
  onUploadProgress?: (percent: number) => void;
}

//// const categiriesApi = "categories";
//// const parentCategoryApi = "categories/parent";
//// const colorsApi = "colors";
//// const seasonsApi = "seasons";
//// const tagsApi = "tags";
//// const packageTypesApi = "categories/packages-types";
//// const materialsApi = "categories/materials";
//// const detailsApi = "categories/details";
//// const countryApi = "countries";
//// const sizesApi = "categories/sizes";
//// const fileApi = "files/create";
//// const productsCreateApi = "products/create";
// const productsCompoundApi = "categories/compounds";
//// const productUpdateApi = "products/update/";
// const sendModerationApi = "/products/for-moderation/";

const APIUrl = {
  Categories: 'categories',
  CategoriesAll: 'categories/all',
  CategoriesActual: 'categories/actual',
  PackageTypes: 'categories/packages-types',
  Materials: 'categories/materials',
  Details: 'categories/details',
  Sizes: 'categories/sizes',
  Countries: 'countries',
  Seasons: 'seasons',
  Tags: 'tags',
  Colors: 'colors',
  Certificates: 'certificates',
  ReferenceBook: 'categories/reference-book',

  File: 'files/create',

  Create: 'products/create',
  FetchProduct: Endpoints.GET_PRODUCT,
  UpdateProduct: 'products/update',
  Moderation: '/products/for-moderation',

}

class ProductService {
  static async fetchProduct(productId: number, variantId: number): Promise<{ product: Products, referenceBook: ReferenceBook, variantId: number }> {
    const { data } = await api.get(`${APIUrl.FetchProduct}/${productId}`);
    return { product: data.data, referenceBook: data['reference-book'], variantId };
  }
  static async fetchReferenceBook(id: number): Promise<ReferenceBook> {
    const { data } = await api.get(`${APIUrl.ReferenceBook}/${id}`);
    return data.data;
  }
  static async fetchCategories(): Promise<Categories[]> {
    const { data } = await api.get(APIUrl.CategoriesAll);
    return data.data || [];
  }
  static async fetchActualCategories(): Promise<Categories[]> {
    const { data } = await api.get(APIUrl.CategoriesActual);
    return data.data || [];
  }
  static async fetchCountries(): Promise<Countries[]> {
    const { data } = await api.get(APIUrl.Countries);
    return data.data || [];
  }
  static async fetchPackageTypes() {
    const { data } = await api.get(APIUrl.PackageTypes);
    return data.data || [];
  }
  static async fetchSeasons() {
    const { data } = await api.get(APIUrl.Seasons);
    return data.data || [];
  }
  static async fetchTags() {
    const { data } = await api.get(APIUrl.Tags);
    return data.data || [];
  }
  static async fetchColors() {
    const { data } = await api.get(APIUrl.Colors);
    return data.data || [];
  }
  static async fetchCertificates() {
    const { data } = await api.get(APIUrl.Certificates);
    return data.data || [];
  }
  
  static async fetchMaterialsById(id: number) {
    const { data } = await api.get(APIUrl.Materials + `?id=${id}`);
    return data.data || [];
  }
  static async fetchDetailsById(id: number) {
    const { data } = await api.get(APIUrl.Details + `?id=${id}`);
    return data.data || [];
  }
  static async fetchSizesById(id: number) {
    const { data } = await api.get(APIUrl.Sizes + `?id=${id}`);
    return data.data || [];
  }


  static async generateBarCodes({ product_id, color_id, sizes }: { product_id: number, color_id: number, sizes: { size_id: number }[] }) {
    try {
      const data = {
        product_id,
        color_id,
        sizes
      }
      const resp = await api.post<BarcodesResponseType>(`${Endpoints.CREATE_BARCODES}`, data);

      return resp;
    } catch (error) {
      throw new Error("Generate Barcodes Failed");
    }
  }

  static async sendFile({ formDataFile, onUploadProgress }: IUploadFile) {
    const url = `${APIUrl.File}`;
    return await upload({ url, formDataFile, onUploadProgress });
  }


  static async createProduct(product: ProductStep1) {
    try {
      const data = await api.post<CreateResponseType>(APIUrl.Create, product);

      return data;
    } catch (error) {
      throw new Error("Send Failed");
    }
  }

  static async updateProduct(productId: number, reqData: ProductStep2 | ProductStep3): Promise<{ product: ProductsDTO, variantId: number }> {
    // const { data } = await api.get(`${APIUrl.UpdateProduct}/${productId}`);
    // return data.data;
    const resp = await api.post(`${APIUrl.UpdateProduct}/${productId}`, reqData);
    return resp.data
  }
  static async sendToModeration(id: number) {
    const { data } = await api.post<CreateResponseType>(`${APIUrl.Moderation}/${id}`);
    return data;
  }

  // static async getParentCategory(id: number) {
  //   const { data } = await api.get(parentCategoryApi + `?id=${id}`);
  //   return data;
  // }

  // static async getPackagesTypes() {
  //   const { data } = await api.get(packageTypesApi);
  //   return data;
  // }



  // static async getCompoundsList(id: number) {
  //   const { data } = await api.get(productsCompoundApi + `?id=${id}`);
  //   return data;
  // }

  // static async updateProduct(
  //   payload: any,
  //   id: number,
  //   otherFields?: OtherFieldsSelect[],
  // ) {
  //   let formState = payload;

  //   if (otherFields) {
  //     const correctOtherFields = otherFields.map(o => ({
  //       compound_id: o.compound_id,
  //       material_id: o.material_id,
  //       value: o.value,
  //     }));

  //     // const correctDeteals =
  //     //   formState.productsDetails.filter(d => d.option_id > 0) || [];
  //     formState = {
  //       ...formState,
  //       productsCompound: [
  //         ...formState.productsCompound,
  //         ...correctOtherFields,
  //       ],
  //     };
  //   }
  //   try {
  //     const { data } = await api.post<CreateResponseType>(
  //       productUpdateApi + id,
  //       formState,
  //     );

  //     return data;
  //   } catch (error) {
  //     throw new Error("Send Failed");
  //   }
  // }

  // static async getAllProducts(status: number) {
  //   const { data } = await api.get(
  //     `${Endpoints.GET_PRODUCTS}?status_id=${status}`,
  //   );
  //   return data;
  // }

  // static async sendModeration(id: number) {
  //   const { data } = await api.post<CreateResponseType>(sendModerationApi + id);
  //   return data;
  // }
}

export default ProductService;
