export const GoodsListTexts = {
  Warning1_title: "Загрузите фотографии",
  Warning1_text: "Карточки без фотографии не пройдут модерацию",
  Warning2_title: "Внимательно заполняйте характеристики",
  Warning2_text: "При обнаружении повторяющихся значений (слов) в характеристиках, карточка может быть помечена как спам и забанена.",
  Warning2_link_text: "Инструкция по заполнению карточки",
  GoodsSearch_defaultText: "Поиск..",
  Loader_loading: "Загрузка...",
  Loader_empty: "Список пуст",
  Pagination_rowsCount_title :"Отображать по",
  ProductStatus_instock: "В наличии",
  ProductStatus_na: "Нет в наличии",
  ProductStatus_sold: "Продано",
  GoodsListFooter_restore: "Восстановить",
  GoodsListFooter_edit: "Массовое редактирование",
  GoodsListFooter_remove: "Удалить",
  DialogDeleteGoods_q: "Удалить выбранные элементы?",
  DialogDeleteGoods_no: "Нет",
  DialogDeleteGoods_yes: "Да",
  AddGoodsBtnsMain_add_one: "Добавить товар",
  AddGoodsBtnsMain_add_many: "Добавить товар таблицей",
}