import React from 'react'
import styles from '../MainPage.module.scss'
import { BlueButton, ButtonStyle } from '../../../simples/BlueButton'

type Props = {
  content: JSX.Element | string
  onClick?: () => void
  className?: string
}

export default function NotificationsListItem({ content, className, onClick }: Props) {
  return (
    <BlueButton
      className={styles.notifications_item}
      buttonStyle={ButtonStyle.Style2}
      title={content}
      onClick={onClick}
    />
  )
}
