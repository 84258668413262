import React, { useState, useEffect } from 'react'
import { ButtonConfig, ModalDialog } from '../../simples/ModalDialog'
import { BlueButton } from '../../simples/BlueButton'
import api from "../../../utils/api";

import styles from './Employees.module.scss'
import format from 'date-fns/format'
import CodeInput from '../../simples/CodeInput/CodeInput'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import { Action, SMS_SEND_DELAY } from './const'
import { postRequest } from './utils';



type Props = {
  isActive: boolean
  onClose: () => void
  userId: number
  action: Action
}


async function sendCode(uid: number) {
  const req = '/employees/get-confirm-code/' + uid

  try {
    const resp = await api.post<{ success: boolean }>(req)
    return resp.data.success
  } catch (error) {
    console.log(error);
    alert(error)
    return false
  }
}




export default function ConfirmDialog({ isActive, onClose, userId, action }: Props) {

  const [lastSmsTS, setLastSmsTS] = useState(0)
  const [canSendSms, setCanSendSms] = useState(true)
  const [timeToNextSMS, setTimeToNextSMS] = useState(0)
  const [code, setCode] = useState(['', '', '', ''])
  const [codeHasError, setCodeHasError] = useState(false)
  const [isCodeSended, setIsCodeSended] = useState(false)

  const lockSuccess = () => toast.warn("Сотрудник заблокирован", { theme: "light" });
  // const unlockSuccess = () => toast.warn("Сотрудник разблокирован", { theme: "light" });
  const removeSuccess = () => toast.warn("Сотрудник удалён", { theme: "light" });
  const codeSended = () => toast.success("Код отправлен", { theme: "light" });
  const codeSendedError = () => toast.error("Ошибка при отправке кода", { theme: "light" });

  useEffect(() => {
    const timeout = lastSmsTS + SMS_SEND_DELAY - Date.now()

    const tid = setTimeout(() => {
      setCanSendSms(true)
    }, timeout);

    const iid = setInterval(() => {
      let timeout = lastSmsTS + SMS_SEND_DELAY - Date.now()
      if (timeout < 0) {
        timeout = 0
        clearInterval(iid)
      }
      setTimeToNextSMS(timeout)
    }, 1000)

    return () => {
      clearTimeout(tid)
      clearInterval(iid)
    }
  }, [lastSmsTS])


  const handleChangeCode = (code: string[]) => {
    setCodeHasError(false)
    setCode(code)
  }


  const handleSendCode = async () => {
    setCanSendSms(false)
    const res = await sendCode(userId)
    if (res) {
      setIsCodeSended(true)
      codeSended()
      setLastSmsTS(Date.now())
    } else {
      codeSendedError()
    }
  }

  const handleConfirm = async () => {
    const resp = await postRequest({ action, id: userId, code: code.join('') })
    if (resp.success) {
      switch (action) {
        case Action.Lock:
          lockSuccess()
          break;
        // case Action.Unlock:
        //   unlockSuccess()
        //   break;
        case Action.Remove:
          removeSuccess()
          break;
        default:
          break;
      }
      onClose()
    } else {
      if (resp.errors.codeHasError) {
        setCodeHasError(true)
      }
    }
  }


  const btns: ButtonConfig[] = [
    {
      text: 'Подтвердить',
      onClick: handleConfirm,
      disabled: code.join('').length < 4
    }
  ]

  return (
    <ModalDialog buttonsConfig={btns} isActive={isActive} width={920} hasCloseBtn handleClose={onClose}>
      <div className={styles.confirm}>
        <h1 className={styles.head}>Подтвердите действие</h1>
        <div className={classNames(styles.content)}>
          {isCodeSended && <div className={styles.code_sended}>! СМС код отправлен на основной номер</div>}
          <div className={styles.sms}>
            <div className={styles.sms_btn_wrap}>
              <span>Введите СМС-код</span>
              <BlueButton title='Отправить SMS' onClick={handleSendCode} disabled={!canSendSms} />
            </div>

            <CodeInput code={code} onChange={handleChangeCode} hasError={codeHasError} disabled={!isCodeSended} />
          </div>

          <div className={styles.info}>
            <div>Отправить код пуш-уведомлением повторно можно через {format(timeToNextSMS, 'mm:ss')}</div>
            <div>Отправить SMS-код можно через {format(timeToNextSMS, 'mm:ss')}</div>
          </div>
        </div>
      </div>
    </ModalDialog>
  )
}