enum HintTexts {
  TIN = "Налог на добавочную стоимость (НДС) платят компании и ИП на общей системе налогообложения, а также все, кто ввозит товары через границу, то есть занимается импортом." +
    "\n\nСтавка «без НДС» полностью исключает налог из суммы продажи. То есть организация не является плательщиком НДС или временно освобождена от уплаты налога. Организация может не платить НДС в случаях если: " +
    "\n- Работает на спецрежиме — УСН, НПД или патенте." +
    "\n- Применяет ОСНО или ЕСХН и получил освобождение от НДС." +
    "\n- Занимается бизнесом, которому по закону разрешено не платить НДС.",
  VAT = "Идентификационный номер налогоплательщика — цифровой" +
    "\nкод, упорядочивающий учёт налогоплательщиков в Российской" +
    "\nФедерации. Присваивается налоговой записи как юридических," +
    "\nтак и физических лиц Федеральной налоговой службой России.",
  BIC = "Банковский идентификационный код (БИК) – девятизначный" +
    "\nуникальный код кредитной организации. Он предназначен для" +
    "\nидентификации участников расчетов и является обязательным" +
    "\nэлементом реквизитов любого банка. БИК используется в" +
    "\nплатежных документах на территории России. Он позволяет" +
    "\nопределить название, корреспондентский счет и региональное" +
    "\nрасположение банка, а также подразделение ЦБ РФ," +
    "\nответственное за его регистрацию и обслуживание.",
}

export default HintTexts;
