export const PhoneRegexp = /^\+?(7)\s?\(?(\d{3})\)?\s?(\d{3})[-\s]?(\d{2})[-\s]?(\d{2})$/
export const EmailRegexp = /^[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/
 // /^[\w\d]+@[\w\d]+\.[\w\d]{2,}/
export const FioRegexp = /^[а-яА-Яa-zA-Z]+\s[а-яА-Яa-zA-Z]+\s[а-яА-Яa-zA-Z]+$/

export function parsePhone(phone: string) {
  const res = PhoneRegexp.exec(phone)
  if (!res) return ''
  return `${res[1]}${res[2]}${res[3]}${res[4]}${res[5]}`
}


export function formatPhone(phone: string) {
  let s = ''
  s += phone[0] === '7' ? '+7' : phone[0]||'_'
  s += ` (${phone[1]||'_'}${phone[2]||'_'}${phone[3]||'_'}) ${phone[4]||'_'}${phone[5]||'_'}${phone[6]||'_'}-${phone[7]||'_'}${phone[8]||'_'}-${phone[9]||'_'}${phone[10]||'_'}`
  return s
}
