import React, { useState } from "react";
import styles from "./ActiveBic.module.scss";
import { BicTextInput } from "../BicTextInput";
import { LeftRegistrationCard } from "../LeftRegistrationCard";
import { PurpleButton } from "../../../../simples/PurpleButton";
import CompanyService from "../../../../../services/CompaniesService";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../../../constants/routes";
import { PaymentInfo } from "../../../../../types/registration";
import { PinkErrorText } from "../../../../simples/PinkErrorText";
import { BlueButton } from "../../../../simples/BlueButton";

const ActiveBic = () => {
  const [error, setError] = useState(false);
  const [bic, setBic] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const goNext = async () => {
    const paymentInfo: PaymentInfo | null =
      await CompanyService.getPaymentInfoByBic(bic);
    !paymentInfo && setError(true);
    paymentInfo &&
      navigate(Routes.REGISTRATION_BIC, {
        state: {
          ...location.state,
          paymentInfo,
          bic,
        },
      });
  };
  const setBicData = (bic: string) => {
    error && setError(false);
    setBic(bic);
  };

  return (
    <LeftRegistrationCard>
      <h2 className={styles.title}>Регистрация</h2>
      <PinkErrorText
        className={styles.errorText}
        isShow={error}
        title={"Бик не найден"}
      />
      <BicTextInput className={styles.inputContainer} setBic={setBicData} />
      {/* <PurpleButton
        title={"Далее"}
        className={styles.button}
        onClick={goNext}
        disabled={bic.length < 9}
      /> */}
      <BlueButton
        title={"Далее"}
        className={styles.button}
        onClick={goNext}
        disabled={bic.length < 9}
      />
    </LeftRegistrationCard>
  );
};

export default ActiveBic;
