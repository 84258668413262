import React, { useState } from 'react'
import styles from './UserMenu.module.scss'
import classNames from 'classnames'

import iconAlert from "./assets/bell_icon.svg";
import userImg from './assets/user.svg' // image 9.png
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { loguot } from '../../../../../store/slices/auth';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../../../constants/routes';
import { AuthService } from '../../../../../services/AuthService';

type Props = {
  className?: string
}

export default function UserMenu({ className }: Props) {

  const user = useAppSelector(state => state.auth.user)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  const handleClickLink = () => {
    navigate(Routes.MAINPAGE)
    setIsOpen(false)
  }
  const handleLogout = () => {
    AuthService.logout().finally(() => {
      dispatch(loguot())
    })
  }

  const isPremium = true // user?.hasPremium

  return (
    <div className={styles.wrap}>
      {isOpen && <div className={styles.bg} onClick={handleClose}></div>}
      <div className={classNames(styles.user_menu, className, { [styles.open]: isOpen })}>
        <div className={styles.head} onClick={handleClick}>
          <div className={styles.head_content}>
            <div className={styles.alert}>
              {user?.notificationsCount && user?.notificationsCount > 0 && <div className={styles.alerts_count}>{user?.notificationsCount}</div>}
              <img className={styles.alert__icon} src={iconAlert} alt="Иконка" />
            </div>
            <div>
              <div>{user?.shortName}</div>
              {isPremium && <div className={styles.premium} >premium</div>}
            </div>
            <div>
              {/* <img className={styles.user_img} src={user?.photo?.url || userImg} /> */}
              <div className={styles.user_img} style={{ backgroundImage: `url(${user?.photo?.url || userImg})` }}></div>
            </div>
          </div>
        </div>
        <div className={classNames(styles.menu)}>
          <div className={styles.menu_content}>
            <div
              className={classNames(styles.menu_item, styles.menu_item_profile)}
              onClick={handleClickLink}
            >Редактировать профиль</div>
            <div
              className={classNames(styles.menu_item, styles.menu_item__alerts)}
              onClick={handleClickLink}>Уведомления</div>
            <div
              className={classNames(styles.menu_item, styles.menu_item__settings)}
              onClick={handleClickLink}>Настройка</div>
            <div
              className={classNames(styles.menu_item, styles.menu_item__logout)}
              onClick={handleLogout}>Выйти</div>
          </div>
        </div>
      </div >
    </div>
  )
}
