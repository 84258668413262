import React, { useEffect, useState } from 'react'
import styles from './StatisticByCategory.module.scss'
import { Categories, CategoryDynamicDTO } from '../../../../../types/swagger/llyApi_new'
import { CategoriesValue, CategoryLvl } from '../../../../../types/product_new'
import { CategorySelector } from '../../../Product/parts/Category/CategorySelector'
import ProductService from '../../../../../services/Product'
import { CalendarPeriod, CalendarPeriodSelector } from '../../../../complexes/CalendarPeriodSelector'
import { CustomDatePicker, DatePickerDate, datePickerDateToDate } from '../../../../complexes/CustomDatePicker'
import { dateToDatePickerDate } from '../../../../complexes/CustomDatePicker/CustomDatePicker'
import { sub } from 'date-fns'
import { BlueButton, ButtonStyle } from '../../../../simples/BlueButton'
import CustomChart, { SerieConfig } from '../CustomChart'
import api from '../../../../../utils/api'
import { formatDateForServer } from '../../../../../utils/action'
import { TextInput } from '../../../../simples/TextInput'
import { RadioButtons } from '../../../../simples/RadioButtons'



type ServerDataResponse = {
  success?: boolean
  data?: CategoryDynamicDTO[]
}

export default function StatisticByCategory() {

  // const [serverData, setserverData] = useState([])
  const [isLoading1, setIsLoading1] = useState(false)
  const [isLoading2, setIsLoading2] = useState(false)
  const [bcdata, setBcdata] = useState<CategoryDynamicDTO[]>()
  const [catdata, setCatdata] = useState<CategoryDynamicDTO[]>()
  const [categories, setCategories] = useState<Categories[]>([])
  const [category, setCategory] = useState<{ [key in CategoryLvl]?: number | null }>({})
  const [period, setPeriod] = useState<CalendarPeriod | undefined>(CalendarPeriod.month)
  const [dateTo, setDateTo] = useState<DatePickerDate>(dateToDatePickerDate(new Date()))
  const [dateFrom, setDateFrom] = useState<DatePickerDate>(
    dateToDatePickerDate(sub(new Date(), {
      months: 1
    }))
  )
  const [barcode, setBarcode] = useState('')
  const [catSetselectedMode, setCatSetselectedMode] = useState('Рубли')
  const [bcSetselectedMode, setBcSetselectedMode] = useState('Рубли')

  const handleSetDateFrom = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    const to = datePickerDateToDate(dateTo);
    if (d.getTime() < to.getTime()) {
      setPeriod(undefined)
      setDateFrom(date)
    }
  }
  const handleSetDateTo = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date);
    const from = datePickerDateToDate(dateFrom)
    const now = new Date()

    if (d.getTime() > from.getTime() && d.getTime() < now.getTime()) {
      setPeriod(undefined)
      setDateTo(date)
    }
  }


  useEffect(() => {
    setIsLoading1(true)
    ProductService.fetchActualCategories().then(data => {
      setIsLoading1(false)
      setCategories(data)
    })
  }, [])


  const handleBuildReportCode = () => {
    if (barcode.length > 0) {
      setIsLoading2(true)
      api.get<ServerDataResponse>(`/products-colors/dynamic?man_article=${barcode}&from_date=${formatDateForServer(datePickerDateToDate(dateFrom))}&to_date=${formatDateForServer(datePickerDateToDate(dateTo))}`).then(data => {
        if (data.data.success) {
          setBcdata(data.data.data)
        } else {
          setBcdata(undefined)
        }
        setIsLoading2(false)
      })
    }
  }

  const handleBuildReport = () => {
    setCatdata([])

    if (category.lvl4 !== undefined && category.lvl4 !== null) {
      setIsLoading1(true)
      api.get<ServerDataResponse>(`/categories/dynamic/${category.lvl4}?from_date=${formatDateForServer(datePickerDateToDate(dateFrom))}&to_date=${formatDateForServer(datePickerDateToDate(dateTo))}`).then(data => {
        if (data.data.success) {
          setCatdata(data.data.data)
        } else {
          setCatdata([])
        }
        setIsLoading1(false)
      })
    }
  }

  const handleChange = (categoryValues: CategoriesValue) => {
    setCategory(categoryValues)
  }

  const btnIsDisabled = category.lvl4 === undefined || category.lvl4 === null

  const catSeriesConfig: SerieConfig[] = [{
    id: 'Продажи по площадке',
    label: 'Продажи по площадке',
    // notSwitchable: true,
    data: catdata?.map(data => ({
      date: data.date,
      value: catSetselectedMode === 'Рубли' ? data.lly_value : data.lly_count
    })) || []
  }, {
    id: 'Ваши продажи',
    label: 'Ваши продажи',
    // notSwitchable: true,
    data: catdata?.map(data => ({
      date: data.date,
      value: catSetselectedMode === 'Рубли' ? data.value : data.count
    })) || []
  }]

  const bcSeriesConfig: SerieConfig[] = []
  if (bcdata && bcdata?.length > 0) {
    bcSeriesConfig.push({
      id: 'Продажи по площадке',
      label: 'Продажи по площадке',
      data: bcdata?.map(data => ({
        date: data.date,
        value: bcSetselectedMode === 'Рубли' ? data.lly_value : data.lly_count
      })) || []
    }, {
      id: 'Ваши продажи',
      label: 'Ваши продажи',
      data: bcdata?.map(data => ({
        date: data.date,
        value: bcSetselectedMode === 'Рубли' ? data.value : data.count
      })) || []
    })
  }

  const handleChangeBarcode = (value: string) => {
    const _v = value.toString()
    if (_v.length <= 22)
      setBarcode(value.toString())
  }
  const handleSetPeriod = (period: CalendarPeriod) => {
    setPeriod(period)
    const _to = datePickerDateToDate(dateTo)
    switch (period) {
      case CalendarPeriod.day:
        setDateFrom(
          dateToDatePickerDate(sub(_to, {
            days: 1
          }))
        )
        break;
      case CalendarPeriod.week:
        setDateFrom(
          dateToDatePickerDate(sub(_to, {
            weeks: 1
          }))
        )
        break;
      case CalendarPeriod.month:
        setDateFrom(
          dateToDatePickerDate(sub(_to, {
            months: 1
          }))
        )
        break;
      case CalendarPeriod.year:
        setDateFrom(
          dateToDatePickerDate(sub(_to, {
            years: 1
          }))
        )
        break;
      default:
        break;
    }
  }

  return (<div className={styles.statistic_by_category}>
    <CategorySelector categories={categories} category={category}
      onChange={handleChange} />
    <div className={styles.period}>
      <div>Сформировать статистику за</div>
      <div className={styles.period_selector}>
        <CalendarPeriodSelector period={period} onChange={handleSetPeriod} className={styles.calendar_period_selector} />
        <CustomDatePicker date={dateFrom} onChange={handleSetDateFrom} label="с" />
        <CustomDatePicker date={dateTo} onChange={handleSetDateTo} label="по" />
        <BlueButton buttonStyle={ButtonStyle.Style1} title='Применить' onClick={handleBuildReport} disabled={btnIsDisabled} />
      </div>
    </div>
    <BlueButton className={styles.build_report} title='Сформировать отчет' onClick={handleBuildReport} disabled={btnIsDisabled} />
    {catdata !== undefined && <div>
      <RadioButtons className={styles.mode_selector} value={catSetselectedMode} options={[{ label: 'Рубли', value: 'Рубли' }, { label: 'Штуки', value: 'Штуки' }]} onChange={setCatSetselectedMode} />
      {(catSeriesConfig.length > 0 || isLoading1)
        && <CustomChart seriesConfig={catSeriesConfig} xLabel={catSetselectedMode === 'Рубли' ? 'Руб' : 'Шт'} yLabel='' notSwitchableSeries isLoading={isLoading1} />}
      <div className={styles.statistic_by_barcode}>
        <h1>Статистика по штрихкоду производителя</h1>
        <div className={styles.barcode}>
          <TextInput onChange={handleChangeBarcode} value={barcode} placeholder='Введите штрихкод производителя...' hideTextAbove className={styles.barcode_input} />
          <BlueButton title='Сформировать отчет' onClick={handleBuildReportCode} disabled={barcode.length < 1} />
        </div>
        <RadioButtons className={styles.mode_selector} value={bcSetselectedMode} options={[{ label: 'Рубли', value: 'Рубли' }, { label: 'Штуки', value: 'Штуки' }]} onChange={setBcSetselectedMode} />
        {/* {bcSeriesConfig.length > 0 && <CustomChart seriesConfig={bcSeriesConfig} xLabel={bcSetselectedMode === 'Рубли' ? 'Руб' : 'Шт'} yLabel='' minValue={-10 * 10 ** 6} maxValue={95 * 10 ** 6} yStep={25 * 10 ** 6} notSwitchableSeries />} */}
        {(bcdata !== undefined || isLoading2) &&
          <CustomChart seriesConfig={bcSeriesConfig} xLabel={bcSetselectedMode === 'Рубли' ? 'Руб' : 'Шт'} yLabel='' notSwitchableSeries isLoading={isLoading2}
            warningText={(bcSeriesConfig.length < 2 || bcSeriesConfig[0].data.length < 1) ? '! Ваш товар уникальный и у него нет аналогов на площадке' : undefined} />
        }
      </div>
    </div>}
  </div>)
}
