import React, {FC} from "react";
import styles from "./ModalModerate.module.scss";

interface Props {
  isActive: boolean;
  handleSubmit: () => void;
  handleClose?: () => void;
  type?: string
}

const ModalModerate: FC<Props> = ({
  isActive,
  handleSubmit,
  handleClose,
  type
}) => {

  return (
    <div className={isActive ? styles.active : styles.modal}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div>{type === "delete" ? "Удалить логотип?" : "Отправлено на модерацию"}</div>
        <section className={styles.buttons}>
          <button onClick={type === "delete" ? handleSubmit : handleClose}>
            {type === "delete" ? "Да" : "Закрыть"}
          </button>
          {type === "delete" && <button className={styles.cancelBtn} onClick={handleClose}>Нет</button>}
        </section>
      </div>
    </div>
  );
};

export default ModalModerate;
