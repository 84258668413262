import React from 'react'
import styles from './Addresses.module.scss'
import AddressesPannel from './components/AddressesPannel/AddressesPannel'

export default function Addresses() {
  return (
    <div className={styles.addresses}>
      <div className={styles.addresses_group}>
        <div className={styles.row}>
          <AddressesPannel title='Юридический адрес' />
          <AddressesPannel title='Адрес склада FBS' />
        </div>
        <div>Мой почтовый адрес отличается от адреса юридического лица</div>
      </div>
      <AddressesPannel title='Почтовый адрес' />
    </div>
  )
}
