import { ListDataType } from "../../../types/productListTypes";
import { AvailableProductData } from "./ProductList";

export const DefaultHeaders = {
  /** Фото */
  [AvailableProductData.photo]: {
    name: AvailableProductData.photo,
    text: "Фото",
    type: ListDataType.image,
    minWidth: "60px",
    width: "60px"
  },
  /** Артикул <br /> (штрихкод) */
  [AvailableProductData.barcode]: {
    name: AvailableProductData.barcode,
    content: <>Артикул <br /> (штрихкод)</>,
    minWidth: "136px",
    width: "136px"
  },
  /** Наименование */
  [AvailableProductData.name]: {
    name: AvailableProductData.name,
    text: "Наименование",
    sortable: true,
    minWidth: "136px",
    width: "136px"
  },
  /** Цена */
  [AvailableProductData.retail_price]: {
    name: AvailableProductData.retail_price,
    text: "Цена",
    sortable: true,
    minWidth: "120px",
    width: "120px"
  },
  /** Цена* */
  [AvailableProductData.price]: {
    name: AvailableProductData.price,
    text: "Итоговая стоимость",
    sortable: true,
    minWidth: "143px",
    width: "143px"
  },
  /** Цена* */
  [AvailableProductData.editable_retail_price]: {
    name: AvailableProductData.editable_retail_price,
    text: "Итоговая стоимость",
    sortable: true,
    minWidth: "135px",
    width: "135px"
  },
  /** Изменение цены (₽) */
  [AvailableProductData.edited_price_diff]: {
    name: AvailableProductData.edited_price_diff,
    text: "Изменение цены (₽)",
    sortable: true,
    minWidth: "140px",
    width: "140px"
  },
  /** Изменение цены (%) */
  [AvailableProductData.edited_price_diff_percent]: {
    name: AvailableProductData.edited_price_diff_percent,
    text: "Изменение цены (%)",
    sortable: true,
    minWidth: "140px",
    width: "140px"
  },
  /** Остаток на складе */
  [AvailableProductData.stockBalance]: {
    name: AvailableProductData.stockBalance,
    text: "Остаток на складе",
    sortable: true,
    minWidth: "135px",
    width: "135px"
  },
  /** Участвуют в скидке */
  [AvailableProductData.discountItemsCount]: {
    name: AvailableProductData.discountItemsCount,
    text: "Участвуют в скидке",
    sortable: true,
    minWidth: "150px",
    width: "150px"
  },
  /** Участвуют в скидке */
  [AvailableProductData.discount_value]: {
    name: AvailableProductData.discountItemsCount,
    text: "Участвуют в скидке",
    sortable: true,
    minWidth: "150px",
    width: "150px"
  },
  /** Бренд */
  [AvailableProductData.brand]: {
    name: AvailableProductData.brand,
    text: "Бренд",
    sortable: true,
    minWidth: "106px",
    width: "106px"
  },
  /** Пол */
  [AvailableProductData.sex]: {
    name: AvailableProductData.sex,
    text: "Пол",
    sortable: true,
    minWidth: "116px",
    width: "116px"
  },
  /** Категория */
  [AvailableProductData.category]: {
    name: AvailableProductData.category,
    text: "Категория",
    sortable: true,
    minWidth: "110px",
    width: "110px"
  },
  /** Подкатегория */
  [AvailableProductData.subcategory]: {
    name: AvailableProductData.subcategory,
    text: "Подкатегория",
    sortable: true,
    minWidth: "114px",
    width: "114px"
  },
  /** Размер */
  [AvailableProductData.size]: {
    name: AvailableProductData.size,
    text: "Размер",
    minWidth: "76px",
    width: "76px"
  },
  /** Сезон */
  [AvailableProductData.season]: {
    name: AvailableProductData.season,
    text: "Сезон",
    sortable: true,
    minWidth: "76px",
    width: "76px"
  },
  /** Дата создания */
  [AvailableProductData.dateOfCreation]: {
    name: AvailableProductData.dateOfCreation,
    text: "Дата создания",
    minWidth: "110px",
    width: "110px"
  },
  /** Цвет */
  [AvailableProductData.color]: {
    name: AvailableProductData.color,
    text: "Цвет",
    minWidth: "115px",
    width: "115px"
  },
  /** Остатки FBO */
  [AvailableProductData.fbo]: {
    name: AvailableProductData.fbo,
    text: "Остатки FBO",
    sortable: true,
    minWidth: "120px",
    width: "120px"
  },
  /** Остатки FBS */
  [AvailableProductData.fbs]: {
    name: AvailableProductData.fbs,
    text: "Остатки FBS",
    sortable: true,
    minWidth: "120px",
    width: "120px"
  },
  /** Актуальная скидка */
  [AvailableProductData.discountValue]: {
    name: AvailableProductData.discountValue,
    text: "Актуальная скидка",
    sortable: true,
    minWidth: "140px",
    width: "140px"
  },
  /** Скидка % */
  [AvailableProductData.discountPercent]: {
    name: AvailableProductData.discountPercent,
    text: "Скидка %",
    sortable: true,
    minWidth: "84px",
    width: "84px"
  },
  /** Итоговая стоимость */
  [AvailableProductData.priceWithDiscount]: {
    name: AvailableProductData.priceWithDiscount,
    text: "Итоговая стоимость",
    sortable: true,
    minWidth: "140px",
    width: "140px"
  },
  // /** Участвуют в скидке */
  // [AvailableProductData.discountItemsCount]: {
  //   name: AvailableProductData.discountItemsCount,
  //   text: "Участвуют в скидке",
  //   sortable: true,
  //   minWidth: "150px",
  //   width: "150px"
  // },
  /** Тип */
  [AvailableProductData.type]: {
    name: AvailableProductData.type,
    text: "Тип",
    sortable: true,
    minWidth: "86px",
    width: "86px"
  }
}