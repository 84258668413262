import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { FeedbackTemplate } from "../../components/pages/Feedback/parts/Templates/Templates";
import { TypeFilterValue } from "../../components/pages/Feedback/types";
import FeedbackService, { GetFeedbackInterface, GetFeedbackResponseInterface } from "../../services/FeedbackService";
import { FeedbackDTO } from "../../types/swagger/llyApi_new";


export enum FeedbackSorting {
  OLD_TO_NEW = "-feedback_date",
  NEW_TO_OLD = "feedback_date",
  GOOD_TO_BAD = "-stars",
  BAD_TO_GOOD = "stars",
}

interface FeedbackState {
  conditionsIsVisible: boolean
  selectedStarsFilter: number[]
  selectedTypeFilter: TypeFilterValue
  sorting: FeedbackSorting
  feedback: FeedbackDTO[]
  feedbackTotalCount: number
  templates: FeedbackTemplate[]
  templateForDelete?: FeedbackTemplate
  selectedFeedbackId?: string
  isMassAnswerOpen: boolean
  isTemplatesOpen: boolean
  isLoadingFeedback?: boolean
  isLoadingTemplates?: boolean
  isQuestions: boolean
  activePageIndex: number
  showRowsCount: number
  updateTime: number
  availableFiltersOptionsStars: {
    /** Кол-во звезд */
    stars?: number
    /** Кол-во отзывов с этими звездами */
    count?: number
  }[]
  availableFiltersOptionsStatuses: {
    /** Название статуса */
    name?: string
    /** Кол-во отзывов с этим статусом */
    count?: number
  }[]
}

const feedback_conditions_accepted = !!localStorage.getItem('feedback_conditions_accepted')

const initialState: FeedbackState = {
  conditionsIsVisible: !feedback_conditions_accepted,
  selectedStarsFilter: [],
  templates: [],
  selectedTypeFilter: TypeFilterValue.ALL,
  sorting: FeedbackSorting.NEW_TO_OLD,
  isMassAnswerOpen: false,
  isTemplatesOpen: false,
  isLoadingFeedback: false,
  isLoadingTemplates: false,
  feedback: [],
  feedbackTotalCount: 0,
  isQuestions: true,
  activePageIndex: 0,
  showRowsCount: 10,
  updateTime: Date.now(),
  availableFiltersOptionsStars: [],
  availableFiltersOptionsStatuses: [],

};


export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    showConditions: (state) => {
      state.conditionsIsVisible = true
    },
    acceptConditions: (state) => {
      localStorage.setItem('feedback_conditions_accepted', "1")
      state.conditionsIsVisible = false
    },
    setActivePageIndex: (state, action) => {
      state.activePageIndex = action.payload
    },
    setShowRowsCount: (state, action) => {
      state.showRowsCount = action.payload
    },
    setIsQuestions: (state, action) => {
      state.isQuestions = action.payload
      state.sorting = FeedbackSorting.NEW_TO_OLD
      // state.activePageIndex = 0
    },
    openTemplatesDialog: (state) => {
      state.isTemplatesOpen = true
    },
    closeTemplatesDialog: (state) => {
      state.isTemplatesOpen = false
    },
    openMassAnswerDialog: (state) => {
      state.isMassAnswerOpen = true
    },
    closeMassAnswerDialog: (state) => {
      state.isMassAnswerOpen = false
    },
    setSelectedFeedbackId: (state, action) => {
      state.selectedFeedbackId = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setSelectedTypeFilter: (state, action) => {
      state.selectedTypeFilter = action.payload;
    },
    setSelectedStarsFilter: (state, action) => {
      state.selectedStarsFilter = action.payload;
    },
    selectStarFilter: (state, action) => {
      if (state.selectedStarsFilter.indexOf(action.payload) === -1) {
        state.selectedStarsFilter.push(action.payload)
      }
    },
    deselectStarFilter: (state, action) => {
      state.selectedStarsFilter = state.selectedStarsFilter.filter(sv => sv !== action.payload)
    },
    setTemplateForDelete: (state, action) => {
      state.templateForDelete = action.payload
    },
    setTemplates: (state, action) => {
      state.templates = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeedback.pending, (state) => {
      state.isLoadingFeedback = true;
    });
    builder.addCase(fetchFeedback.fulfilled, (state, action) => {
      if (action.payload) {
        state.feedback = action.payload.data || [];
        state.templates = action.payload.templates as FeedbackTemplate[] || [];
        state.availableFiltersOptionsStars = action.payload.availableFiltersOptions?.stars || [];
        state.availableFiltersOptionsStatuses = action.payload.availableFiltersOptions?.statuses || [];
        state.updateTime = Date.now()
        state.feedbackTotalCount = action.payload.totalCount || state.feedback.length
      }
      state.isLoadingFeedback = false;
    });
    builder.addCase(fetchFeedback.rejected, (state) => {
      state.isLoadingFeedback = false;
    });


    builder.addCase(fetchTemplates.pending, (state) => {
      state.isLoadingTemplates = true;
    });
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      if (action.payload) {
        state.templates = action.payload;
      }
      state.isLoadingTemplates = false;
    });
    builder.addCase(fetchTemplates.rejected, (state) => {
      state.isLoadingTemplates = false;
    });
  }
});


export const fetchFeedback = createAsyncThunk<GetFeedbackResponseInterface, void, { state: RootState }>(
  "feedback/fetchFeedback", async (_, { getState }) => {
    const state = getState();
    const {
      isQuestions, sorting, selectedStarsFilter, selectedTypeFilter,
      showRowsCount, activePageIndex
    } = state.feedback;

    const req: GetFeedbackInterface = {
      isQuestions,
      order_by: sorting,
      filter_status: selectedTypeFilter,
      limit: showRowsCount,
      offset: showRowsCount * activePageIndex,
    }

    if (state.feedback.isQuestions) {
      req.filter_stars = selectedStarsFilter
    }

    const data = await FeedbackService.getFeedback(req);
    return data;
  });


export const fetchTemplates = createAsyncThunk<FeedbackTemplate[], void, { state: RootState }>(
  "feedback/fetchTemplates", async (_, { getState }) => {
    const data = await FeedbackService.fetchTemplates();
    return data.data || [];
  });

export const { setSelectedStarsFilter, setSelectedTypeFilter, selectStarFilter, deselectStarFilter, setSorting,
  setSelectedFeedbackId, openMassAnswerDialog, closeMassAnswerDialog, setIsQuestions, closeTemplatesDialog, showConditions,
  openTemplatesDialog, setTemplateForDelete, setTemplates, setActivePageIndex, setShowRowsCount, acceptConditions } = feedbackSlice.actions;

export default feedbackSlice.reducer;