import React, { FC, useState } from "react";
import { FamousTags } from "./parts/FamousTags";
import { ProductCategory } from "./parts/ProductCategory";

import styles from "./SellProductContent.module.scss";
import { ITab, Tabs } from "../../../../complexes/Tabs";
import { useParams } from "react-router-dom";
import { SellProductTabConfig, SellProductTabId } from "./const";
import { ProductStatistic } from "./parts/ProductStatistic";


const SellProductContent: FC = () => {

  const params = useParams();
  const tabId = params.tabId


  const tabs: ITab[] = [{
    // label: "Категории товаров",
    ...SellProductTabConfig[SellProductTabId.categories],
    content: <ProductCategory />
  }, {
    // label: "Популярные теги",
    ...SellProductTabConfig[SellProductTabId.tags],
    content: <FamousTags />
  }, {
    // label: "Статистика по товару",
    ...SellProductTabConfig[SellProductTabId.products],
    content: <ProductStatistic />
  }]

  const activeTabIndex = tabs.findIndex(tab => tab.id === tabId)

  return (
    <div className={styles.contentWrapper}>
      <Tabs tabs={tabs} activeTabIndex={activeTabIndex} />
    </div>
  );
};

export default SellProductContent;