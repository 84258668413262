import React, { useState, useEffect } from 'react'
import styles from './PriceDynamics.module.scss'
import CustomChart, { SerieConfig } from '../CustomChart'
import { ProductSelectorSlider, ProductSelectorSliderConfig } from '../../../../complexes/ProductSelectorSlider'
import { PriceDynamicCollection } from '../../../../../types/swagger/llyApi_new'
import { formatDateForServer } from '../../../../../utils/action'
import { datePickerDateToDate } from '../../../../complexes/CustomDatePicker'
import api from '../../../../../utils/api'

// const LocalStorageKey = 'slider-lastProducts';

type ServerDataResponse = {
  success?: boolean
  data?: PriceDynamicCollection[]
  errors?: {
    title?: string
    errors?: string[]
  }
}


export default function PriceDynamics() {

  const [isLoading, setisLoading] = useState(false)
  const [customChartConfig, setCustomChartConfig] = useState<ProductSelectorSliderConfig>()
  const [serverData, setServerData] = useState<PriceDynamicCollection[]>([])

  useEffect(() => {
    if (customChartConfig === undefined || customChartConfig.colorId === undefined) {
      setServerData([])
      return
    }
    setisLoading(true)
    const req = `/products-prices/dynamic/${customChartConfig.colorId}?from_date=${formatDateForServer(datePickerDateToDate(customChartConfig.dateFrom))}&to_date=${formatDateForServer(datePickerDateToDate(customChartConfig.dateTo))}`
    api.get<ServerDataResponse>(req).then(data => {
      if (data.data.data !== undefined) {
        setServerData(data.data.data)
      }
      setisLoading(false)
    })
  }, [customChartConfig])

  const seriesConfig: SerieConfig[] = serverData.map(s => ({
    id: s.size,
    label: s.size,
    data: s.data
  }))

  // console.log(seriesConfig);


  return (
    <div className={styles.price_dynamics}>
      <ProductSelectorSlider onChange={setCustomChartConfig} />
      {/* {customChartConfig && <CustomChart colorId={customChartConfig.colorId} dateFrom={customChartConfig.dateFrom} dateTo={customChartConfig.dateTo} />} */}
      {customChartConfig && <CustomChart seriesConfig={seriesConfig} xLabel='Руб' yLabel='' isLoading={isLoading} />}
    </div>
  )
}
