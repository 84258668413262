import React, { useCallback } from "react";
import styles from "./Upload.module.scss";
import plusIcon from "../../assets/plus.svg";
import { useDropzone } from "react-dropzone";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import { clearUploadedData, getImportedData, getUploadingError, getUploadingFileName, getUploadingProgress, getUploadingStatus, selectedSubcategorySelector, uploadArch } from "../../../../../store/slices/massImport";
import classNames from "classnames";
import { BlueButton } from "../../../../simples/BlueButton";
import { ButtonSize } from "../../../../simples/BlueButton/BlueButton";
import uploadIcon from "../../assets/upload.svg";

function Upload() {

  const dispatch = useAppDispatch();
  const selectedSubcategory = useAppSelector(selectedSubcategorySelector());
  const uploadingProgress = useAppSelector(getUploadingProgress());
  const isUploadingArch = useAppSelector(getUploadingStatus());
  const uploadingError = useAppSelector(getUploadingError());
  const importedData = useAppSelector(getImportedData());
  const uploadingFileName = useAppSelector(getUploadingFileName());

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 1) {
      dispatch(uploadArch({ formDataFile: acceptedFiles[0] }));
    }
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop, maxFiles: 1, disabled: !selectedSubcategory,
    multiple: false,
    accept: { "application/zip": [".zip"], "application/vnd.rar": [".rar"], "application/x-7z-compressed": [".7z"] },
  });

  const handleClear = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(clearUploadedData());
  };

  return (
    <article className={styles.wrap}>
      <div  {...getRootProps()} className={styles.body}>
        <input {...getInputProps()} />
        <div className={classNames(styles.content, { [styles.disabled]: !selectedSubcategory })}>
          <img className={styles.icon} src={plusIcon} alt="icon" />
          <span className={styles.text}>Загрузите, либо перетащите сюда заполненный архив в формате .rar, .7z, .zip</span>
          {isUploadingArch && <span className={styles.uploading_progress}>Загрузка: {uploadingProgress}%</span>}
          {uploadingError && <span className={styles.error}>Ошибка: {uploadingError}</span>}
          {importedData && importedData.length > 0 && <span className={styles.uploaded_file}>{uploadingFileName} (товаров: {importedData.length}) <div className={styles.remove_button} onClick={handleClear} /></span>}
        </div>
      </div>
      <div className={styles.footer}>
        <BlueButton
          title="Загрузить таблицу товаров"
          size={ButtonSize.Normal}
          disabled={!selectedSubcategory}
          onClick={open}
          iconSrc={uploadIcon}
        />
      </div>
    </article>
  );
}

export default Upload;
