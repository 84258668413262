import React, { useState, useEffect, ChangeEvent } from 'react'
import shop_logo_icon from "../../assets/shop_logo.svg"
import send_icon from "../../assets/send.svg"
import styles from "./Respons.module.scss"
import { TemplateLabel } from '../TemplateLabel'
import { FeedbackDTO } from '../../../../../types/swagger/llyApi_new'
import { differenceInDays, format } from 'date-fns'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import FeedbackService from '../../../../../services/FeedbackService'
import { fetchFeedback, setSelectedFeedbackId } from '../../../../../store/slices/feedback'
import edit_icon from "../../assets/edit.svg"

interface Props {
  selected?: boolean
  feedbackData: FeedbackDTO
}

const now = Date.now()

export default function Respons({ selected, feedbackData }: Props) {

  const dispatch = useAppDispatch()

  const editMode = selected
  const text = feedbackData.answer_text
  const [editmodeText, setEditmodeText] = useState("")

  const templates = useAppSelector(state => state.feedback.templates)


  useEffect(() => {
    setEditmodeText(text || "")
  }, [editMode])

  const handleSendResponse = () => {
    FeedbackService.sendResponse({ id: feedbackData.id, text: editmodeText }).then(() => {
      dispatch(setSelectedFeedbackId(undefined))
      dispatch(fetchFeedback())
    })
  }

  let footer

  const is_editable = editMode && (feedbackData.is_editable && !feedbackData.has_answer)

  if (is_editable) {
    footer = <div className={styles.send_btn} onClick={handleSendResponse}><img src={send_icon} /><span>Отправить</span></div>
  } else {
    footer = <div className={styles.date}>{feedbackData.answer_date && `Отредактировано ${format(feedbackData.answer_date*1000, "dd.MM.yyyy")}`}</div>
  }

  const handleSelectTemplate = (id: number) => () => {
    const t = templates.find(t => t.id === id)
    setEditmodeText(t?.text || "")
  }

  const handleTextChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    setEditmodeText(target.value)
  }

  return (
    <div className={styles.card}>
      <div className={styles.head}>
        <div className={styles.row1}>
          <div className={styles.shop}>
            <img src={feedbackData.company_avatar || shop_logo_icon} alt="user" />
            <div>{feedbackData.company_name}</div>
          </div>
          <div className={styles.controls}>
            {!feedbackData.has_answer && <span>Без ответа {differenceInDays(now, feedbackData.feedback_date)} дней</span>}
            {/* {feedbackData. && <span>В архиве</span>} */}
            {feedbackData.on_moderation && <span>На модерации</span>}
            {feedbackData.moderation_failed && <span className={styles.warning} >Модерация не пройдена</span>}
            {!editMode && feedbackData.has_answer && feedbackData.is_editable && <span className={styles.edit_btn}><img src={edit_icon} /> Редактировать</span>}
          </div>
        </div>
        {is_editable && <div className={styles.tmplates}>
          <span className={styles.label}>Шаблоны</span>
          <div className={styles.list}>
            {templates.map(t => <TemplateLabel key={t.id} text={t.title} onClick={handleSelectTemplate(t.id)} />)}
          </div>
        </div>}
      </div>
      <div className={styles.body}>
        {is_editable ?
          <textarea className={styles.text} value={editmodeText} onChange={handleTextChange} maxLength={170} /> :
          <div className={styles.text}>{text}</div>
        }
      </div>
      <div className={styles.footer}>
        {footer}
      </div>
    </div>
  )
}
