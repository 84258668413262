import { AxiosProgressEvent } from "axios";
import { Endpoints } from "../constants/server";
import { DocType } from "../types/law";
import { OrganisationData } from "../types/registration";
import { CompaniesContactsDTO, Files, LegalCompanyInfoDTO } from "../types/swagger/llyApi_new";
import api from "../utils/api";
import { getEmptyLawData } from "../store/slices/law";
import { CompaniesDocumentsDTO } from "../types/swagger/llyApi_new";

type UploadResponse = {
  success?: boolean
  data?: Files
  errors?: {
    field_name?: string
  }[]
}
type FetchLawDataResponse = {
  success?: boolean
  data?: LegalCompanyInfoDTO
  errors?: string[]
}
export type SaveLawDataResponse = {
  success?: boolean
  data?: LegalCompanyInfoDTO
  errors?: string[]
}

interface IUploadDoc {
  file: File,
  type: DocType,
  onProgress?: (percent: number) => void;
}

export type UploadDocAnswer = {
  success: boolean,
  type: DocType,
  id?: number,
  name?: string,
}
export type FetchLawDataAnswer = {
  success?: boolean,
  data?: LegalCompanyInfoDTO,
}
export type SaveLawDataAnswer = {
  success?: boolean,
}


export type UpdateLawDataProps = {
  inn?: string
  is_individual?: boolean
  vat_included?: boolean
  short_name?: string
  full_name?: string
  manager_name?: string
  second_name?: string
  first_name?: string
  middle_name?: string
  ogrn?: string
  kpp?: string
  name?: string
  documents?: {
    type: DocType
    id: number
  }[]
}


export type FetchContactsResponse = {
  success?: boolean
  data?: CompaniesContactsDTO[]
  errors?: string[]
}
export type CreateContactResponse = {
  success?: boolean
  data?: CompaniesContactsDTO
  errors?: string[]
}
export type RemoveContactResponse = {
  success?: boolean
  errors?: string[]
}

class LawService {

  static async fetchContacts() {
    let answer: FetchContactsResponse = { success: false, };
    await api.get<FetchContactsResponse>('/companies/get-contacts').then(data => {
      answer.data = data.data.data
      answer.success = true
    })
    return answer
  }
  static async createContact(data: CompaniesContactsDTO) {
    let answer: CreateContactResponse = { success: false, };
    await api.post<CreateContactResponse>('/companies/add-contact', data).then(data => {
      answer.data = data.data.data
      answer.success = true
    })
    return answer
  }
  static async updateContact(id: number, data: CompaniesContactsDTO) {
    let answer: CreateContactResponse = { success: false, };
    await api.post<CreateContactResponse>('/companies/edit-contact/' + id, data).then(data => {
      answer.data = data.data.data
      answer.success = true
    })
    return answer
  }
  static async removeContact(id: number) {
    return await api.delete<RemoveContactResponse>('/companies/delete-contact/' + id)
  }


  static async fetchLawData() {
    let answer: FetchLawDataAnswer = { success: false, };
    await api.get<FetchLawDataResponse>('/companies/legal-info').then(data => {
      answer.data = data.data.data
      answer.success = true
    })
    return answer
  }

  static async saveLawData(data: LegalCompanyInfoDTO) {
    let answer: SaveLawDataAnswer = { success: false };
    await api.post<SaveLawDataResponse>('/companies/edit-legal-info', data
    ).then(data => {
      answer.success = data.data.success
    }).catch(e => {
      console.log(e);
      answer.success = false
    })
    return answer
  }

  static async updateLawData(data: UpdateLawDataProps) {
    let answer: SaveLawDataAnswer = { success: false };
    await api.post<SaveLawDataResponse>('/companies/update-legal-info', data
    ).then(data => {
      answer.success = data.data.success
    }).catch(e => {
      console.log(e);
      answer.success = false
    })
    return answer
  }

  static async uploadDoc({ file, type, onProgress }: IUploadDoc) {
    let answer: UploadDocAnswer = { success: false, type };

    const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
      const uploadPercentage = parseInt(
        Math.round((progressEvent.loaded / (progressEvent.total || -1)) * 100) as any,
      );
      onProgress && onProgress(uploadPercentage)
    }

    await api.post<UploadResponse>('/companies/upload-document',
      { file },
      { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress },
    ).then(data => {
      if (data.data.success && data.data.data) {
        answer.id = data.data.data.id
        answer.name = file.name
        answer.success = true
      }
    }).catch(e => {
      console.log(e);
      answer.success = false
    })

    return answer
  }

}

export default LawService;
