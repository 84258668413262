import React, { useState } from 'react'
import styles from './CurrentOrders.module.scss'
import { SearchInput } from '../../../../complexes/SearchInput'
import { useAppDispatch } from '../../../../../hooks/store'
import { BlueButton } from '../../../../simples/BlueButton'
import { PrintPDF } from '../../../../complexes/PrintPDF'
import { ItemsList } from '../../../../complexes/ItemsList'
import { ListHeadData } from '../../../../../types/productListTypes'


const headers: ListHeadData[] = [{
  text: 'Фото',
  name: 'foto',
  // content?: JSX.Element;
  // type?: ListDataType;
  // list?: string[];
  // dataForSorting?: string | number;
  // alignCenter?: boolean
  // sortable?: boolean;
  minWidth: '100px',
  width: '100px',
}, {
  content: <>Артикул<br /> (штрихкод)</>,
  name: 'code',
  sortable: true,
  minWidth: '150px',
  width: '150px',
}, {
  content: <>Наименование</>,
  name: 'name',
  minWidth: '150px',
  width: '150px',
}, {
  content: <>Дата<br /> оформления</>,
  name: 'date',
  sortable: true,
  minWidth: '130px',
  width: '130px',
}, {
  content: <>Адрес доставки</>,
  name: 'address',
  sortable: true,
  minWidth: '250px',
  width: '250px',
}, {
  content: <>Дата<br /> доставки</>,
  name: 'deliveryDate',
  sortable: true,
  minWidth: '110px',
  width: '110px',
}, {
  content: <>Вес в упаковке, г</>,
  name: 'packing_weight',
  sortable: true,
  minWidth: '150px',
  width: '150px',
}, {
  content: <>Вес без упаковки, г</>,
  name: 'weight',
  sortable: true,
  minWidth: '150px',
  width: '150px',
}, {
  content: <>Статус</>,
  name: 'status',
  sortable: true,
  minWidth: '100px',
  width: '100px',
}]

export default function CurrentOrders() {
  const dispatch = useAppDispatch()
  const [search, setSearch] = useState('')
  const [visibleData, setVisibleData] = useState([])

  return (
    <div className={styles.current_orders}>
      <div className={styles.controls}>
        <SearchInput onChange={setSearch} className={styles.search} />
        <div className={styles.btns}>
          <BlueButton title='Скачать таблицу' />
          <PrintPDF fileTitle='Test PDF' fileURL='/test.pdf' title='Распечатать таблицу' />
          {/* <BlueButton title='Распечатать таблицу' /> */}
        </div>
      </div>
      <div>С базовой стоимостью логистики товаров можно ознакомиться <a href="">здесь</a></div>
      <ItemsList
        // className={className}
        headers={headers}
        items={visibleData}
      // onChange={handleChange}
      // emptyText={emptyText}
      />
      <div>
        <BlueButton title='Создать заявку' />
      </div>
    </div>
  )
}
