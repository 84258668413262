const RouteBase = '/profile/'

export enum ProfileTabId {
  account = 'account',
  about_shop = 'about_shop',
  removal = 'removal'
}

type TabConfig = {
  id: ProfileTabId
  label: string
  route: string
}

type IProfileTabConfig = {
  [key in ProfileTabId]: TabConfig
}

function buildTabRoute(routeBase: string, tabId: ProfileTabId) {
  return routeBase + tabId
}

export const ProfileTabConfig: IProfileTabConfig = {
  [ProfileTabId.account]: {
    id: ProfileTabId.account,
    label: 'Аккаунт',
    route: buildTabRoute(RouteBase, ProfileTabId.account)
  },
  [ProfileTabId.about_shop]: {
    id: ProfileTabId.about_shop,
    label: 'Информация о магазине',
    route: buildTabRoute(RouteBase, ProfileTabId.about_shop)
  },
  [ProfileTabId.removal]: {
    id: ProfileTabId.removal,
    label: 'Удаление аккаунта',
    route: buildTabRoute(RouteBase, ProfileTabId.removal)
  },
}