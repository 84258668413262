import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { setDetailOption, setDetailValue } from '../../../../../store/slices/product'
import { Details } from '../../../../../types/swagger/llyApi_new'
import { CustomSelect, Option } from '../../../../simples/CustomSelect'
import { CustomTextInput } from '../../../../simples/CustomTextInput'


type Props = {
  detail: Details
}

export default function Detail({ detail }: Props) {

  const dispatch = useAppDispatch()
  const details = useAppSelector(state => state.product.details.values)
  const readonly = useAppSelector(state => state.product.isReadonlyMode)

  const options: Option[] = detail.options?.map(o => ({ label: o.name, value: o.id })) || []

  const handleChangeValue = ({ name, value }) => {
    if(readonly) return
    const detail_id = parseInt(name)
    dispatch(setDetailValue({ detail_id, value }))
  }
  const handleChangeOption = ({ name, value }) => {
    if(readonly) return
    const detail_id = parseInt(name)
    const option_id = parseInt(value)
    dispatch(setDetailOption({ detail_id, option_id }))
  }

  const _d = details.find(d => d.detail_id === detail.id)

  if (detail.options && detail.options?.length > 0) {
    return (<CustomSelect
      name={detail.id}
      // value={details[detail.id]}
      value={_d?.option_id}
      options={options}
      onChange={handleChangeOption}
      label={detail.name}
      // required={true}
      placeholder='Выберите'
      readonly={readonly}
    />)
  } else if (detail.is_area_text) {
    return (
      <CustomTextInput
        name={detail.id}
        placeholder='Укажите'
        label={detail.name}
        /*required={true}*/
        // value={details[detail.id]}
        value={_d?.value}
        onChange={handleChangeValue}
        readonly={readonly}
      />
    )
  } else {
    return (
      <CustomTextInput
        name={detail.id}
        placeholder='Укажите'
        label={detail.name}
        /*required={true}*/
        // value={details[detail.id]}
        value={_d?.value}
        onChange={handleChangeValue}
        readonly={readonly}
      />
    )
  }
}
