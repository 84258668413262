import classNames from 'classnames';
import React, { useState } from 'react'
import { FilterOption } from './FiltersPanel';
import xIcon from "../../assets/x-icon.svg";
import styles from "./FiltersPanel.module.scss";

type Props = {
  title: string,
  values?: FilterOption[],
  selectedValues?: string[],
  limit?: number;
  onSelectOption: (valueId: string) => void
}

export default function Filter({ title, values, limit, selectedValues, onSelectOption }: Props) {

  const [showAll, setShowAll] = useState(false)

  const handleOnClick = optionId => () => {
    onSelectOption(optionId)
  }
  const handleShowAll = () => {
    setShowAll(true)
  }

  const slice = (Number.isInteger(limit) && !showAll) ? values?.slice(0, limit) : values;

  return <div className={styles.filter}>
    <h2>{title}</h2>
    <div className={styles.options}>
      {(values && values.length > 0) ? slice?.map(o => <div
        className={classNames(styles.filter_option, { [styles.selectd_option]: selectedValues?.includes(o.id) })}
        key={o.id}
        onClick={handleOnClick(o.id)}
      >
        <span>{o.title}</span>
        <img className={styles.remove_option} src={xIcon} alt="X" />
      </div>) : ""}
      {(values?.length || 0) > (limit || 0) && !showAll && <span className={styles.info} onClick={handleShowAll}>... скрыто {(values?.length || 0) - (limit || 0)}</span>}
    </div>
  </div>
}
