import React, { useEffect, useState } from "react";
import { Endpoints } from "../../../../../constants/server";
import BlueButton from "../../../../simples/BlueButton/BlueButton";
import { IHint } from "../../../../simples/IHint";
import { RadioButtons } from "../../../../simples/RadioButtons";
import api from "../../../../../utils/api";

import styles from "./DiscountBannerBuilder.module.scss"

import v from "../../assets/v.svg"
import close from "../../assets/close.svg"
import demoImage from "../../assets/Rectangle 5719.png"
import polygon from "../../assets/polygon.svg"
import rect from "../../assets/rect.svg"
import elipse from "../../assets/elipse.svg"
import arrow from "../../assets/arrow.svg"

import { BannerConfig, BannerShapeType, BannerTextType } from "../../../../../types/discount";
import { DiscountBanner } from "../../../../simples/DiscountBanner";
import { DefaultBannerConfig } from "../../../../../constants/discount";
import { ProductsDTO } from "../../../../../types/swagger/llyApi_new";
import { DialogCloseBtn } from "../../../../simples/DialogCloseBtn";




type AvailableBannerValuesResponse = {
  data: {
    colors: Array<{ id: number, value: string }>
    forms: Array<{ id: number, value: BannerShapeType }>
  }
}

type Props = {
  config?: BannerConfig
  onClose: (save: boolean) => void
  onChange: (config?: BannerConfig) => void
  discountValue: number
  product?: ProductsDTO
  swToNextProduct: () => void
  swToPrevProduct: () => void
  discountInPercent: boolean
}


export default function DiscountBannerBuilder({
  config, onClose, onChange, discountValue, discountInPercent,
  product, swToNextProduct, swToPrevProduct
}: Props) {

  const isVisible = !!config;

  const [avalableColors, setAvalableColors] = useState<string[]>([])
  const [avalableShapeTypes, setAvalableShapeTypes] = useState<BannerShapeType[]>([])

  useEffect(() => {
    api.get<AvailableBannerValuesResponse>(Endpoints.FETCH_AVAILABLE_BANNER_VALUES)
      .then((res) => {
        setAvalableColors(res.data.data.colors.map(c => c.value))
        setAvalableShapeTypes(res.data.data.forms.map(f => f.value))
      })
      .catch(console.log)
  }, [])


  const handleClose = () => {
    onClose(false)
  };
  const handleSave = () => {
    onClose(true)
  };
  const handleChangeTextType = (textType) => {
    onChange({
      ...(config || DefaultBannerConfig),
      textType: textType
    })
  }
  const handleChangeShapeType = (shape) => {
    onChange({
      ...(config || DefaultBannerConfig),
      shape: shape
    })
  }
  const handleChangeColor = (color) => {
    onChange({
      ...(config || DefaultBannerConfig),
      color: color
    })
  }



  let productImg = demoImage
  let productTitle = "Product Title"
  if (product) {
    productImg = product?.url || demoImage
    productTitle = product?.name || ""
  }

  const getDiscountValue = (): number => {
    if (discountInPercent) {
      return discountValue
    } else {
      const p = Math.round(discountValue / (product?.price || 0) * 100)
      return isNaN(p) ? 0 : p
    }
  }

  return !isVisible ? null
    :
    <article className={styles.modal_container}>
      <div onClick={handleClose} className={styles.modal_underflow}></div>
      <div className={styles.content}>
        <DialogCloseBtn className={styles.close} onClick={handleClose} />
        <div className={styles.head}>
          <h1>Установка скидочного баннера <IHint className={styles.hint} hint="Скидочный баннер установится на все фото в списке" /></h1>
        </div>
        <div className={styles.body}>
          <div className={styles.preview}>
            <div className={styles.image_wrap}>
              <div className={styles.image}>
                <DiscountBanner config={config} discountValue={getDiscountValue()} />
                <img src={productImg} alt="" />
              </div>
            </div>
            <div className={styles.btns}>
              <div className={styles.arrow} onClick={swToPrevProduct}>
                <img src={arrow} className={styles.arrow_left} alt="<-" />
              </div>
              <span className={styles.product_title}>{productTitle}</span>
              <div className={styles.arrow} onClick={swToNextProduct}>
                <img src={arrow} className={styles.arrow_right} alt="->" />
              </div>
            </div>
          </div>
          <div className={styles.controls}>
            <div>
              <label>Надпись баннера:</label>
              <RadioButtons
                value={config.textType}
                onChange={handleChangeTextType}
                options={[{ label: "Процент скидки", value: BannerTextType.percent }, { label: "Текстом", value: BannerTextType.text }]}
              />
            </div>
            <div>
              <label>Форма</label>
              <RadioButtons
                value={config.shape}
                onChange={handleChangeShapeType}
                options={[
                  { label: "", value: BannerShapeType.SQUARE, icon: <img className={styles.icon_shape} src={rect} alt="" /> },
                  { label: "", value: BannerShapeType.TRIANGLE, icon: <img className={styles.icon_shape} src={polygon} alt="" /> },
                  { label: "", value: BannerShapeType.CIRCLE, icon: <img className={styles.icon_shape} src={elipse} alt="" /> },
                ]}
              />
            </div>
            <div>
              <label>Цвет</label>
              <RadioButtons
                value={config.color}
                onChange={handleChangeColor}
                options={avalableColors.map(c => ({ label: "", value: c, icon: <div className={styles.icon_color} style={{ background: `#${c}` }} /> }))}
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <BlueButton title="Применить" onClick={handleSave} />
        </div>
      </div>
    </article >
}
