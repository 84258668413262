import { getUnicID } from "../../../utils/getUnicId";
import sheet_ghost from "../../../assets/images/product/sheet_ghost.png";
import { DetailType, OneColorType, ProductsColorsType } from "../../../types/update-product";
import { MinPrice } from "../../../constants/editingProductCard";

/**
Раздел для создания и добавления новых объектов
 */

const photoArray: Array<any> = [];




export const getPhotosMock = () => photoArray.map((item, index) => ({
    main: (index === 1) ? 1 : 0,
    file_id: (index + 1) * (index + 1) * 5000,
    file: {
        id: index,
        name: "photo" + index,
        extension: "string",
        size: 0,
        path: "string",
        createdAt: "string",
        userId: 0,
        url: item
    }
}));

export const getFashionMock = () => ({
    file_id: getUnicID(),
    file: {
        id: 15545,
        name: "photo4548",
        extension: "png",
        size: 0,
        path: "string",
        createdAt: "string",
        userId: 0,
        url: ''
    }
});


export const getObjFileUploaded = (id: number, name: string, url: string) => ({
    main: 0,
    file_id: id,
    file: {
        id: id,
        name: name,
        extension: "",
        size: 0,
        path: "",
        createdAt: "",
        userId: 0,
        url: url
    }
});

export const getObjFasionUploaded = (id: number, name: string, url: string) => ({
    file_id: id,
    file: {
        id: id,
        name: name,
        extension: "",
        size: 0,
        path: "",
        createdAt: "",
        userId: 0,
        url: url
    }
});

// export const getNewColor = () => {
//     const unicId = getUnicID();
//     return {
//         id: unicId,
//         man_article: "",
//         product_id: 0,
//         colors_id: 1, // Это выбранный цвет, на бекенд
//         color_article: 0,
//         color: {
//             id: 1,
//             name: "Белый",
//             rgb: "#ffffff",
//             created_at: 0
//         },
//         additionalColors: [{
//             id: 1,
//             name: "Белый",
//             rgb: "#ffffff",
//             created_at: 0
//         }],
//         sizes: [{
//             id: 0,
//             category_id: 0,
//             name: "S(50)",
//             created_at: 0
//         }],
//         productsColorsSizes: [
//             {
//                 product_color_id: unicId,
//                 size_id: 0,
//                 price: MinPrice,
//                 man_barcode: 555555555,
//                 barcode: 555555555,
//                 size: {
//                     id: 0,
//                     category_id: 0,
//                     name: "string",
//                     created_at: 0
//                 }
//             }
//         ],
//         productsPhotos: getPhotosMock(),
//         productsFashion: {
//             file_id: 0,
//             file: {
//                 id: 0,
//                 name: "string",
//                 extension: "string",
//                 size: 0,
//                 path: "string",
//                 createdAt: "string",
//                 userId: 0,
//                 url: "",
//             }
//         }
//     };
// };

// export const getNewColor: ((color: OneColorType) => ProductsColorsType) = (color: OneColorType) => {
export const getNewColor: ((colors_id: number) => ProductsColorsType) = (colors_id: number) => {
    const c: ProductsColorsType = {
        id: getUnicID(),
        man_article: "",
        product_id: 0,
        colors_id,
        // colors_id: color.id,
        // color,
        // colors_id: 0,
        // color: {
        //     id: 1,
        //     name: "Белый",
        //     rgb: "#ffffff",
        //     created_at: 0
        // },
        color_article: "",
        additionalColors: [],
        productsPhotos: [],
        productsFashion: {
            file_id: 0,
            file: {
                id: 0,
                name: "string",
                extension: "string",
                size: 0,
                path: "string",
                createdAt: "string",
                userId: 0,
                url: "",
            }
        },
        sizes: [],
        productsColorsSizes: []
    }

    c.color_article = getNewColorArticle(c);

    return c
};

// export const getNewColorArticle = (state: any, indexColor: number) => {
//     let colorArticle = '';
//     const generalColorId = state.data.productsColors[indexColor].colors_id;
//     if (generalColorId < 10) {
//         colorArticle += '0' + generalColorId;
//     } else {
//         colorArticle += generalColorId;
//     }
//     state.data.productsColors[indexColor].additionalColors.forEach((additionalColor: any) => {
//         if (additionalColor.id < 10) {
//             colorArticle += '0' + additionalColor.id;
//         } else {
//             colorArticle += additionalColor.id;
//         }
//     });

//     return colorArticle;
// };

export const getNewColorArticle = (productsColor: ProductsColorsType) => {
    let colorArticle = '';
    const generalColorId = productsColor.colors_id;
    if (generalColorId < 10) {
        colorArticle += '0' + generalColorId;
    } else {
        colorArticle += generalColorId;
    }
    productsColor.additionalColors.forEach((additionalColor: any) => {
        if (additionalColor.id < 10) {
            colorArticle += '0' + additionalColor.id;
        } else {
            colorArticle += additionalColor.id;
        }
    });

    return colorArticle.padEnd(6, '0');
};

export const getNewProductsCompound = () => ({
    product_id: 1,
    compound_id: 1,
    material_id: 1,//Дропдаун
    value: 0,
    created_at: 0,
    updated_at: 0,
    compound: {
        id: 1,
        category_id: 4,
        name: "Материал",
        created_at: 0
    },
    material: {
        id: 1,
        name: "Хлопок",
        created_at: 0
    }
});

export const getProgressObj = () => ({
    id: 0,
    file: '',
    name: '',
    percent: 0,
});

export const getNewBarcode = (colorId: number, sizeId: number) => {
    return {
        product_color_id: colorId,
        price: MinPrice,
        size_id: sizeId,
        man_barcode: '',
        barcode: '',
        size: {
            id: 0,
            category_id: 0,
            name: "string",
            created_at: 0
        }
    }
};

export const getNewDetail = (id: number, detail: DetailType, categoryId: number, productId: number) => {
    return {
        id: id,
        product_id: productId,
        detail_id: detail.id,
        option_id: null,
        value: '',
        created_at: 0,
        updated_at: 0,
        detail: {
            id: id,
            category_id: categoryId,
            name: detail.name,
            information: detail.information,
            is_area_text: detail.is_area_text,
            has_value: detail.has_value,
            regulations: detail.regulations,
            created_at: 0,
            options: detail.options
        },
        options: {
            id: id,
            detail_id: detail.id,
            name: detail.name,
            created_at: 0
        }
    }
};