import classNames from 'classnames'
import React from 'react'

import styles from "../Product.module.scss"

type Props = {
  text: string
  className?: string
}

export default function Title({ text, className }: Props) {
  return (
    <div className={classNames(styles.title, className)}>{text}</div>
  )
}
