import React from "react";
import { Navigate, Route, Routes as RoutesDom, useLocation } from "react-router-dom";
import { Routes } from "../../../constants/routes";
import { SellProduct } from "../../pages/SellProduct";
import ProductsListMain from "../../pages/ProductsListMain/ProductsListMain";
import { BrandLogos } from "../../pages/BrandLogos";
import ProductsImportTable from "../../pages/ProductsImportTable/ProductsImportTable";
import { MassEdit } from "../../pages/MassEdit";
import { PricesAndDiscounts } from "../../pages/PricesAndDiscounts";
import { ActionsCalendar } from "../../pages/ActionsCalendar";
import { JoinInPromotion } from "../../pages/JoinInPromotion";
import { Feedback } from "../../pages/Feedback";
import { ProfileAccount } from "../../pages/ProfileAccount";
import Product from "../../pages/Product/Product";
import { Employees } from "../../pages/Employees";
import { ProfileAboutCompany } from "../../pages/ProfileAboutCompany";
import { CompanyDocuments } from "../../pages/CompanyDocuments";
import { Schedule } from "../../pages/Schedule";
import { Brands } from "../../pages/Brands";
import { MainPage } from "../../pages/MainPage";
import { UserMenu } from "../../complexes/Layout/parts/UserMenu";
import { Statistic } from "../../pages/Statistic";
import ScrollToTop from "./scrollToTop";
import { Analytics } from "../../pages/Analytics";
import { Logistics_FBO } from "../../pages/Logistics_FBO";
import { Logistics_FBS } from "../../pages/Logistics_FBS";
import Logos from "../../pages/Logos/Logos";

const MainRoutes = () => {

  return (
    <div>
      <ScrollToTop />

      <UserMenu />

      <RoutesDom>
        {/* <Route
      path={Routes.EDITING_PRODUCT_CARD}
      element={<EditingProductCard />}
    /> */}
        {/* <Route
      path={Routes.UPDATE_PRODUCT_CARD_ID}
      element={<UpdateProductCard />}
    /> */}
        {/* <Route
      path={Routes.UPDATE_PRODUCT_CARD_ID_COLOR_ID}
      element={<UpdateProductCard />}
    /> */}
        <Route
          // path={Routes.TAGS_PAGE}
          path={Routes.WHAT_TO_SELL}
          element={<SellProduct />}
        />
        <Route
          // path={Routes.TAGS_PAGE}
          path={Routes.STATISTIC}
          element={<Statistic />}
        />
        <Route
          path={Routes.INSTRUCTION_PAGE}
          element={<BrandLogos />}
        />
        <Route
          path={Routes.LOGOS}
          element={<Logos />}
        />
        <Route
          path="*"
          // element={<Navigate replace to={Routes.EDITING_PRODUCT_CARD} />}
          element={<Navigate replace to={Routes.MAINPAGE} />}
        />

        {/* Путь на комопонент списка товаров */}
        <Route path={Routes.PRODUCTS_LIST} element={<ProductsListMain />} />

        <Route path={Routes.IMPORT_TABLE} element={<ProductsImportTable />} />

        <Route path={Routes.MASS_EDIT} element={<MassEdit />} />

        <Route path={Routes.PRICE_PAGE} element={<PricesAndDiscounts />} />

        <Route path={Routes.ACTIONS_CALENDAR} element={<ActionsCalendar />} />

        <Route path={Routes.JOIN_IN_PROMOTION} element={<JoinInPromotion />} />

        <Route path={Routes.FEEDBACK} element={<Feedback />} />

        {/* <Route path={Routes.PROFILE_ACCOUNT} element={<ProfileAccount />} /> */}
        <Route path={Routes.PROFILE} element={<ProfileAccount />} />
        {/* <Route path={Routes.PROFILE_ABOUT} element={<ProfileAboutCompany />} /> */}
        <Route path={Routes.ABOUT} element={<ProfileAboutCompany />} />

        <Route path={Routes.PRODUCT} element={<Product />} />
        <Route
          path={Routes.PRODUCT_CARD_ID_COLOR_ID}
          element={<Product />}
        />

        <Route path={Routes.EMPLOYEES} element={<Employees />} />

        <Route path={Routes.DOCS} element={<CompanyDocuments />} />

        <Route path={Routes.SCHEDULE} element={<Schedule />} />

        <Route path={Routes.BRANDS} element={<Brands />} />

        <Route path={Routes.MAINPAGE} element={<MainPage />} />

        <Route path={Routes.ANALYTICS} element={<Analytics />} />


        <Route path={Routes.LOGISTICS_FBO} element={<Logistics_FBO />} />
        <Route path={Routes.LOGISTICS_FBS} element={<Logistics_FBS />} />

      </RoutesDom>
    </div>
  );
}
export default MainRoutes;
