import React from 'react'

// import x_icon from "../../assets/x.svg"
import styles from "./TemplateLabel.module.scss"

interface Props {
  text: string
  onClick?: () => void
}

export default function TemplateLabel({text, onClick}: Props) {
  return (
    <div className={styles.label} onClick={onClick}>
      <div>{text}</div>
      {/* <img src={x_icon} /> */}
    </div>
  )
}
