import classNames from 'classnames';
import React from 'react'
import xIcon from "./assets/x-icon.svg";
import styles from "./FilterBtn.module.scss"

type Props = {
  text: string,
  onClickRemove?: (fid: string) => void,
  onCheck?: (fid: string) => void,
  fid: string
  checked?: boolean
}

export default function FilterBtn({ fid, text, onClickRemove, onCheck, checked }: Props) {
  const handleClickRemove = () => {
    onClickRemove && onClickRemove(fid)
  }
  const handleCheck = () => {
    if (!checked) { onCheck && onCheck(fid) }
  }

  return (
    <div className={classNames(styles.wrap, { [styles.checked]: checked })} onClick={handleCheck}>
      <span className={styles.text}>{text}</span>
      {checked && <img onClick={handleClickRemove} className={styles.remove} src={xIcon} alt="X" />}
    </div>
  )
}
