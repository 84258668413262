import React from 'react'

import styles from '../Addresses.module.scss'
import { InfoPanel } from '../../../../../simples/InfoPanel'

export default function Info() {
  return (
    <div className={styles.info}>
      <InfoPanel title='Будьте внимательны!'>Данные фактического адреса будут учитываться при расчёте логистики по системе FBS</InfoPanel>
      <InfoPanel title='Будьте внимательны!'>Данные будут использоваться для формирования договора</InfoPanel>
    </div>
  )
}
