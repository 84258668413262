import React from "react";
import { GoodsListTexts } from "../../../../../constants/goodsListTexts";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import { RootState } from "../../../../../store";
import { deleteProducts, setGoodsToDelet, softDeleteProducts } from "../../../../../store/slices/goodsList";
import { ButtonSize, ButtonType } from "../../../../simples/BlueButton/BlueButton";
import { ModalDialog, ButtonConfig } from "../../../../simples/ModalDialog";
import { ArchiveCategoryID as ArchiveStateID } from "../../const";

export default function DeleteGoodsDialog() {
  const dispatch = useAppDispatch();
  const goodsToDelete = useAppSelector((state: RootState) => state.productsList.goodsToDelete);
  const isActive = goodsToDelete.length > 0;
  const selectedStateId = useAppSelector((state: RootState) => state.productsList.selectedCategoryID);

  const handleNo = () => {
    dispatch(setGoodsToDelet([]));
  };
  const handleYes = () => {
    if (selectedStateId !== ArchiveStateID) { // если не в архиве
      dispatch(softDeleteProducts({goodsToDelete, selectedStateId}));
    } else {
      dispatch(deleteProducts({goodsToDelete, selectedStateId}));
    }
    dispatch(setGoodsToDelet([]));
  };

  const buttonsConfig: ButtonConfig[] = [
    { text: GoodsListTexts.DialogDeleteGoods_yes, type: ButtonType.Yes, onClick: handleYes, size: ButtonSize.Big },
    { text: GoodsListTexts.DialogDeleteGoods_no, type: ButtonType.No, onClick: handleNo, size: ButtonSize.Big },
  ];
  return (
    <ModalDialog isActive={isActive} text={GoodsListTexts.DialogDeleteGoods_q} buttonsConfig={buttonsConfig} />
  );
}
