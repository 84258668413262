import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

import styles from "./Tabs.module.scss"
import { useNavigate } from 'react-router-dom'

export interface ITab {
  label: string
  content: JSX.Element
  rightSideBar?: JSX.Element | JSX.Element[]
  id?: string
  route?: string
}

type Props = {
  tabs: ITab[]
  onChange?: (index: number, id?: string) => void
  activeTabIndex?: number
  contentWidth?: string
}

export default function Tabs({ tabs, onChange, activeTabIndex, contentWidth }: Props) {

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    setSelectedTabIndex(activeTabIndex || 0)
  }, [activeTabIndex])


  const handleSelectTab = (index: number) => () => {
    if(tabs[index].route !== undefined) {
      navigate(tabs[index].route as string)
    }else{
      setSelectedTabIndex(index)
    }
    onChange && onChange(index, tabs[index].id)
  }

  return (
    <div>
      <div className={styles.labels}>
        {tabs.map((t, n) => <div
          key={n}
          className={classNames(styles.label, {
            [styles.active]: n === selectedTabIndex
          })}
          onClick={handleSelectTab(n)}
        >
          <span className={styles.label_text}>{t.label}</span>
        </div>)}
      </div>
      <div className={styles.content_wrap}>
        <div className={styles.content} style={{width: contentWidth}}>
          {tabs[selectedTabIndex]?.content}
        </div>
        {tabs[selectedTabIndex]?.rightSideBar !== undefined && <div className={styles.right_side_bar}>{tabs[selectedTabIndex]?.rightSideBar}</div>}
      </div>
    </div>
  )
}
