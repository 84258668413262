import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./Editor.module.scss";
import classNames from "classnames";
import { UploadArea } from "./components/UploadArea";
import { ModalModeration } from "./components/Modal";
import { ReactComponent as UploadIcon } from "../../assets/upload.svg";
import trash from "../../assets/trash.svg";
import { RootState } from "../../../../../store";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import { cancelEditLogo, LogosMode, removeLogo, saveNewLogo, updateLogo } from "../../../../../store/slices/getters/logo-list";



const Editor: FC = () => {

  const [title, setTitle] = useState('')
  const { logoList } = useAppSelector((state: RootState) => state.logoList);
  const [newLogoSrc, setnewLogoSrc] = useState<string>()
  const [selectedFile, setSelectedFile] = useState<undefined | File>();
  const [errorsText, setErrorsText] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const dispatch = useAppDispatch();

  const changeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };


  const { selectedId, mode } = useAppSelector(state => state.logoList)

  const selectedLogo = useMemo(() => {
    return logoList.find(logo => logo.id === selectedId);
  }, [logoList, selectedId]);

  useEffect(() => {
    if (mode === LogosMode.edit && selectedLogo) {
      setTitle(selectedLogo.name);
      // setnewLogoSrc(undefined)
    } else {
      setTitle("");
    }
    setnewLogoSrc(undefined)
    setSelectedFile(undefined)
  }, [mode, selectedId]);


  const handleUpload = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files !== null) {
      if (e.target.files.length < 1) {
        return;
      }

      let errors: string[] = []

      const file = e.target.files[0];
      const reader = new FileReader();


      if (file.size > 1048576) {
        errors.push("Размер изображения не должен превышать 1Mb")
      }
      if (file.type !== "image/png") {
        errors.push("Логотип должен быть в формате .png")
      }

      reader.readAsDataURL(file);

      reader.addEventListener("load", event => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement("img");
        if (typeof _loadedImageUrl === "string") {
          image.src = _loadedImageUrl;
        }
        image.addEventListener("load", () => {
          const { width, height, src } = image;
          if (width !== 600 || height !== 600) {
            errors.push('Логотип должен быть 600px в длину и 600px в высоту.')
          }
          if (errors.length > 0) {
            setErrorsText(['Некорректный формат изображения.', ...errors])
            setSelectedFile(undefined);
            setnewLogoSrc(undefined)
          } else {
            setnewLogoSrc(src)
            setSelectedFile(file);
            setErrorsText([])
          }
        });
      });
    }
  }, [selectedFile]);

  const handleUpdateLogo = () => {
    selectedId && dispatch(updateLogo({ id: selectedId, name: title, file: selectedFile }));
  };

  const handleCreateLogo = () => {
    title && selectedFile && dispatch(saveNewLogo({ name: title, file: selectedFile }))
  };

  const handleModal = (type) => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleRemoveLogo = () => {
    setModalType("delete");
    handleModal(modalType);
  };

  const handleRemoveNewLogo = () => {
    setnewLogoSrc(undefined)
    setSelectedFile(undefined)
  }

  const handleReset = () => {
    setTitle("");
    setnewLogoSrc(undefined)
    setSelectedFile(undefined)
    setErrorsText([]);
    dispatch(cancelEditLogo())
  };

  const handleRemove = () => {
    selectedId && dispatch(removeLogo(selectedId));
    handleCloseModal();
    handleReset();
  };

  const handleSubmit = () => {
    switch (mode) {
      case LogosMode.edit:
        handleUpdateLogo();
        break;
      case LogosMode.create:
        handleCreateLogo();
        break;
      default:
        break;
    }
    handleReset();
    setModalType("");
    handleModal(modalType);
  };

  const isValid = errorsText.length === 0
  const hasDiff = selectedFile || title !== selectedLogo?.name //|| currentUpdate
  const btnIsActive = isValid && (
    (mode === LogosMode.create && title && selectedFile) || (mode === LogosMode.edit && hasDiff)
  )


  return (
    <div className={styles.inner}>
      <input
        type="text"
        value={title}
        placeholder="Название бренда"
        onChange={changeTitle}
        disabled={mode !== LogosMode.edit && mode !== LogosMode.create}
      />


      <div
        className={classNames(
          styles.upload_form,
          mode === LogosMode.list ? styles.disabled : "",
        )}>

        <div className={styles.selectLogo}>
          {(mode === LogosMode.edit || mode === LogosMode.create && selectedFile) && <div className={styles.remove}>
            <button onClick={newLogoSrc ? handleRemoveNewLogo : handleRemoveLogo}>
              <img src={trash} alt="trash" />
            </button>
          </div>}
          {selectedLogo || newLogoSrc
            ? <img src={newLogoSrc || `${process.env.REACT_APP_API_URL}/${selectedLogo?.file}`} alt="logo" />
            : <UploadArea info={mode === LogosMode.list ? "Выберите файл из списка справа" : "Загрузите изображение логотипа"} />
          }
        </div>

        <input
          type="file"
          id="upload-btn"
          onChange={handleUpload}
          accept="image/png"
          placeholder="Загрузить изображение"
          hidden
        />

        <div>
          <label className={classNames(styles.upload, classNames(mode === LogosMode.list && styles.inactive))} htmlFor="upload-btn">
            <UploadIcon className={styles.icon} />
            <p>
              {mode === LogosMode.edit
                ? "Загрузить изображение"
                : "Загрузить новое изображение"}
            </p>
          </label>
        </div>

        <div className={styles.errorText}>
          {errorsText.length > 0 ? errorsText.map((e, i) => <div key={i}>{e}</div>) : "Размер изображения не должен превышать 1Mb"}
        </div>

        <div className={styles.buttons}>
          <button
            className={styles.submit}
            disabled={!btnIsActive}
            onClick={handleSubmit}
          >Отправить на модерацию</button>
          <button
            className={styles.cancel}
            onClick={handleReset}
          >Отмена</button>
        </div>
        <ModalModeration type={modalType} isActive={openModal} handleSubmit={handleRemove} handleClose={handleCloseModal} />
      </div>
    </div>
  );
};

export default Editor;