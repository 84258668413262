import classNames from "classnames";
import React from "react";
import styles from "./CloseButton.module.scss";

type Props = {
  onClick?: () => void;
  className?: string;
};

const CloseButton = ({ onClick, className }: Props) => (
  <button onClick={onClick} className={classNames(styles.button, className)} />
);

export default CloseButton;
