import React from 'react'
import { Layout } from '../../complexes/Layout';
import { Tabs, ITab } from '../../complexes/Tabs';
import { DiscountTab } from './parts/DiscountTab';
import { HistoryTab } from './parts/HistoryTab';
import { PriceEditing } from './parts/PriceEditing';
import styles from "./PricesAndDiscounts.module.scss";
import { useParams } from 'react-router-dom';
import { PricesAndDiscountsTabConfig } from './const';



export default function PricesAndDiscounts() {

  const params = useParams();
  const tabId = params.tabId

  const tabs: ITab[] = []

  tabs.push({
    // id: PricesAndDiscountsTabId.edit,
    // label: "Редактирование цены",
    ...PricesAndDiscountsTabConfig.edit,
    content: <PriceEditing />
  })
  tabs.push({
    // id: PricesAndDiscountsTabId.discount,
    // label: "Установка скидки",
    ...PricesAndDiscountsTabConfig.discount,
    content: <DiscountTab />
  })
  tabs.push({
    // id: PricesAndDiscountsTabId.history,
    // label: "История",
    ...PricesAndDiscountsTabConfig.history,
    content: <HistoryTab />
  })

  const activeTabIndex = tabs.findIndex(tab => tab.id === tabId)


  return (
    <Layout
      title='Цены и скидки'
      hint={<span>
        В этом разделе Вы можете установить скидки на товары, отредактировать цены и ознакомиться с историей изменения цен и скидок<br />
        <br />
        Карточки со скидкой отображаются в разделе &quot;Товары&quot;
      </span>}
    >
      <div className={styles.wrap}>
        <Tabs tabs={tabs} contentWidth='100%' activeTabIndex={activeTabIndex} />
      </div>
    </Layout>
  )
}
