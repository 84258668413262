import React, { useEffect } from 'react'
import { Layout } from '../../complexes/Layout'
import { Tabs, ITab } from '../../complexes/Tabs'
import { Reviews } from './parts/Reviews';

import styles from "./Feedback.module.scss";
import { MassAnswer } from './parts/MassAnswer';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { fetchFeedback, setIsQuestions } from '../../../store/slices/feedback';
import { Templates } from './parts/Templates';
import RemoveTemplateRequest from './parts/RemoveTemplateRequest';
import { Conditions } from './parts/Conditions';

export default function Feedback() {

  const dispatch = useAppDispatch()
  const isQuestions = useAppSelector(state => state.feedback.isQuestions)
  const sorting = useAppSelector(state => state.feedback.sorting)
  const selectedStarsFilter = useAppSelector(state => state.feedback.selectedStarsFilter)
  const selectedTypeFilter = useAppSelector(state => state.feedback.selectedTypeFilter)

  useEffect(() => {
    dispatch(fetchFeedback())
  }, [isQuestions, sorting, selectedStarsFilter, selectedTypeFilter])


  const tabs: ITab[] = []

  tabs.push({
    label: "Вопросы",
    // content: <Questions />
    content: <Reviews />
  })
  tabs.push({
    label: "Отзывы",
    content: <Reviews />
  })


  const handleChangeTab = (index: number) => {
    dispatch(setIsQuestions(index === 0))
  }

  return (
    <Layout
      title='Отзывы и вопросы'
      hint={<span>В разделе “Вопросы и Отзывы “представлены все отзывы на Ваши товары и все вопросы по Вашим товарам.<br />
        Ответить на отзыв или вопрос необходимо в течение 30 дней, по прошествии которых он будет перемещен в архив</span>}
    >
      <MassAnswer />
      <Templates />
      <RemoveTemplateRequest />
      <Conditions />
      <div className={styles.wrap}>
        <Tabs tabs={tabs} onChange={handleChangeTab} activeTabIndex={isQuestions ? 0 : 1} />
      </div>
    </Layout>
  )
}
