import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { getInfoPageText } from "../../../../../constants/infoPageTextsHashMap";
import IHint from "../../../../simples/IHint/IHint";
import { trimTrailingSlash } from "../../../../../utils/composeString";
import { UserMenu } from "../UserMenu";
import { CustomHint } from "../../../../simples/CustomHint";

type Props = {
  title: string
  hint?: JSX.Element | string
  // linkPath?: string
  hintWidth?: number
  onBack?: () => void
}

const Header = ({ hint, title, hintWidth, onBack }: Props) => {
  // const location = useLocation();
  // const [textInfoPage, setTextInfoPage] = useState<string>("");

  // useEffect(() => {
  //   const regexp = location.pathname.match(/.+\//i) as any;
  //   const text = getInfoPageText(trimTrailingSlash(regexp[0])) || "";
  //   setTextInfoPage(text);
  // }, []);

  // const navigate = useNavigate()
  // const handleNavigate = () => {
  //   navigate(linkPath || '')
  // }

  return (
    <header className={styles.header}>
      <section className={styles.left}>
        {onBack && <div className={styles.arrow} onClick={onBack}></div>}
        <div className={styles.btnBack}>{title}</div>
        {/* <IHint hint={textInfoPage} className={styles.btnAsk} /> */}
        {hint && <CustomHint hint={hint} hintWidth={hintWidth || 500} />}
      </section>

      {/* <UserMenu className={styles.user_menu} /> */}
    </header>
  );
};

export default Header;
