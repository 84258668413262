import classNames from "classnames";
import { FC, MouseEvent, useState } from "react";
import styles from "./CustomSelect.module.scss";


export type Option = {
  label?: string;
  value: string | number;
};

type Value = string | number | null

export type IOnSelectChange = { name: string | number; value: Value; }

interface SelectProps {
  value?: Value;
  label?: string;
  onChange: ({ name, value }: IOnSelectChange) => void;
  options: Option[];
  error?: string;
  name: string | number;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  hideError?: boolean;
  readonly?: boolean;
  loading?: boolean;
  listMaxHeight?: number;
}

const CustomSelect: FC<SelectProps> = ({
  label, value, onChange, options, error, name, required, placeholder, disabled,
  className, hideError, readonly, loading, listMaxHeight
}) => {

  const [isOpen, setIsOpen] = useState(false)

  const textValue = options.find(o => o.value === value)?.label || ''

  const handleChange = (v) => (e: MouseEvent<HTMLDivElement>) => {
    if (readonly) return
    // e.stopPropagation()
    !disabled && v !== value && onChange({ name, value: v });
    setIsOpen(false)
  };
  const handleToggleList = () => {
    if (readonly) return
    if (disabled) {
      setIsOpen(false)
    } else {
      setIsOpen(!isOpen)
    }
  }
  const handleCloseList = () => {
    setIsOpen(false)
  }


  return (
    <div className={classNames(styles.wrap, {
      [styles.disabled]: disabled,
      [styles.required]: required,
      [styles.open]: isOpen,
      [styles.has_error]: !!error,
      [styles.hide_error]: hideError,
      [styles.readonly]: readonly,
      [styles.loading]: loading,
    }, className)}>
      <div onClick={handleCloseList} className={styles.background}></div>
      <div className={styles.select_wrap}>
        {label && <div className={styles.label}>
          {label}
        </div>}
        <div className={styles.menu_wrap}>
          <div className={styles.select}>
            <div
              className={classNames(styles.selected_value, { [styles.empty_value]: (!value || value === '') })}
              onClick={handleToggleList}
            ><span>{textValue || placeholder}</span></div>
            <div className={styles.list} style={listMaxHeight !== undefined ? { maxHeight: `${listMaxHeight}px` } : undefined}>
              {!loading && options.sort((o1, o2) => o1.label?.toLowerCase().localeCompare(o2.label?.toLowerCase() || '') || 0).map(o => <div
                key={o.value}
                onClick={handleChange(o.value)}
                className={styles.option}
              >{o.label}</div>)}
              {loading && <div className={styles.spinner}><div className={styles.dot} /><div className={styles.dot} /><div className={styles.dot} /></div>}
              {options.length === 0 && <div className={styles.empty_list}>Список пуст</div>}
            </div>
          </div>
        </div>
      </div>

      {(!hideError && !readonly) && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default CustomSelect;
