import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../utils/api";
import { LogoType } from "../../../types/logos";
import { BrandLogosService } from "../../../services/BrandLogosService";
import { RootState } from "../..";

// export const fetchLogoList = createAsyncThunk(
//   "logos/fetchLogoList",
//   async (_, { rejectWithValue }) => {
//     const { data: response } = await api.get("logos");
//     return response.data;
//   }
// );

interface GenericResponse { success: boolean }

export const fetchLogoList = createAsyncThunk<LogoType[]>(
  "logos/fetchLogoList",
  BrandLogosService.fetchList
);


export const saveNewLogo = createAsyncThunk<boolean, {name: string, file: File}, { state: RootState }>("logos/removeLogo", async ({name, file}, { dispatch }) => {
  let response: GenericResponse = { success: false }

  await BrandLogosService.createLogo(name, file).then(data => {
    response.success = data.data.success
  }).catch(console.log)

  dispatch(fetchLogoList())
  return response.success
});

export const updateLogo = createAsyncThunk<boolean, {id: number, name: string, file?: File}, { state: RootState }>("logos/removeLogo", async ({id, name, file}, { dispatch }) => {
  let response: GenericResponse = { success: false }

  await BrandLogosService.updateLogo(id, name, file).then(data => {
    response.success = data.data.success
  }).catch(console.log)

  dispatch(fetchLogoList())
  return response.success
});

export const removeLogo = createAsyncThunk<boolean, number, { state: RootState }>("logos/removeLogo", async (lid, { dispatch }) => {
  let response: GenericResponse = { success: false }

  await BrandLogosService.removeLogo(lid).then(data => {
    response.success = data.data.success
  }).catch(console.log)

  dispatch(fetchLogoList())
  return response.success
});






export enum LogosMode {
  list = 'list',
  edit = 'edit',
  create = 'create',
}

interface LogoState {
  logoList: LogoType[]
  loading: "idle" | "pending" | "succeeded" | "failed"
  selectedId?: number
  mode: LogosMode
}


const initialState = {
  logoList: [],
  loading: "idle",
  mode: LogosMode.list
} as LogoState;

const logosSlice = createSlice({
  name: "logos",
  initialState,
  reducers: {
    editLogo: (state, action: PayloadAction<number | undefined>) => {
      state.selectedId = action.payload
      state.mode = LogosMode.edit
    },
    createLogo: (state) => {
      state.selectedId = undefined
      state.mode = LogosMode.create
    },
    cancelEditLogo: (state) => {
      state.selectedId = undefined
      state.mode = LogosMode.list
    },
    // deleteLogo: (state, action: PayloadAction<number | undefined>) => {
    //   state.selectedId = undefined
    //   state.mode = LogosMode.list
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogoList.fulfilled, (state, action) => {
        state.logoList = action.payload;
      });
  },
});

export const { editLogo, createLogo, cancelEditLogo } = logosSlice.actions
export default logosSlice;