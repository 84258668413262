import React, { FC, useEffect, useState } from "react";
import { Promotions } from "../../../../../types/swagger/llyApi";
import { BlueButton } from "../../../../simples/BlueButton";
import { ButtonSize, ButtonType } from "../../../../simples/BlueButton/BlueButton";
import api from "../../../../../utils/api";
import styles from "./ModalActionInfo.module.scss";
import { Endpoints } from "../../../../../constants/server";

import { formatPeriodMini } from "../../../../../utils/action";
import { Routes } from "../../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../hooks/store";
import { setPromotion } from "../../../../../store/slices/joinInPromotion";
import { DialogCloseBtn } from "../../../../simples/DialogCloseBtn";

interface Props {
  actionId?: number
  handleClose: () => void;
}

type Answer = {
  success?: boolean
  data?: Promotions
}

const ModalActionInfo: FC<Props> = ({ actionId, handleClose }) => {

  const navigate = useNavigate();
  const [action, setAction] = useState<Promotions>()
  const dispatch = useAppDispatch();

  useEffect(() => {
    setAction(undefined)
    if (actionId === undefined) { return }
    const req = `${Endpoints.GET_PROMOTION}${actionId}`
    api.get<Answer>(req)
      .then((res) => {
        if (res.data?.success && res.data.data) {
          setAction(res.data.data)
        }
      })
      .catch((err) => {
        setAction(undefined)
        console.log(err)
      });
  }, [actionId])


  if (actionId === undefined) {
    return null;
  }


  let content
  if (action) {
    const handleAccept = () => {
      dispatch(setPromotion(action))
      navigate(Routes.JOIN_IN_PROMOTION)
    }

    content = <>
      <h1 className={styles.title}>{action.title}</h1>
      <div className={styles.period}>
        <span>Период акции:</span> <span>{formatPeriodMini(action.start_date || "", action.finish_date || "")}</span>
      </div>
      <div className={styles.content}>

        <div className={styles.conditions}>
          <h3 className={styles.conditions_title}>Условия акции</h3>
          <div className={styles.conditions_body}>
            <p>{action.description}</p>

            {action?.categories && <div className={styles.categories}>
              <h3>Акционные категории</h3>
              <p>
                {action?.categories.map(c => c.name ).join(", ")}
              </p>
            </div>}
            <div className={styles.kind}>
              <span>Вид скидки: </span><span>{action.type}</span>
            </div>
            <div className={styles.discount_percent}>
              {action.isStandardDiscount ? <><span>Фиксированная скидка: </span><span>{action.percent}%</span></> : <span>Скидка: 2+1 </span>}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.offer}>
        <span>Принимая участие, Вы соглашаетесь с <a href="/statics/promotions-offer">условим оферты</a></span>
      </div>

      <div className={styles.buttons}>
        {action && <BlueButton
          title="Принять участие"
          onClick={handleAccept}
          className={styles.button}
          type={ButtonType.Yes}
          size={ButtonSize.Big}
        />}
      </div>
    </>
  } else {
    content = <h1 className={styles.loading}>Загрузка...</h1>
  }


  const handleCloseSearch = (list: number[]) => {
    handleClose()
  }


  return <>
    <article className={styles.modalContainer}>
      <div onClick={handleClose} className={styles.modalUnderflow}></div>
      <section className={styles.body}>
        <DialogCloseBtn className={styles.close_btn} onClick={handleClose} />
        {content}
      </section>
    </article>
  </>;
};

export default React.memo(ModalActionInfo);
