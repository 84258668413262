import { format, parse } from "date-fns"
import { ru } from 'date-fns/locale';

export function formatPeriod(from: string, to: string) {
  return `с ${format(new Date(from), 'dd.MM.yyyy')} по ${format(new Date(to), 'dd.MM.yyyy')}`
}
export function formatPeriodMini(from: string, to: string) {
  return `${format(new Date(from), 'dd.MM.yyyy')} - ${format(new Date(to), 'dd.MM.yyyy')}`
}

export const formatDateForServer = (date?: Date | number) => {
  if (!date) return ""
  return format(date, "yyyy-MM-dd")
}

export function parseDate(value: string) {
  return parse(value, 'dd.MM.yyyy', new Date(), { locale: ru });
}
export function parseServerDate(value: string) {
  return parse(value, 'yyyy-MM-dd', new Date(), { locale: ru });
}