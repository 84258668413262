import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/store'
import {
  createNewProduct, createProduct, fetchCommonData, fetchProduct, resetState, sendToModeration,
  setPriceSaved,
  setStep,
  step1HasErrors, step2HasErrors, updateProductStep2, updateProductStep3_4
} from '../../../store/slices/product'
import { Layout } from '../../complexes/Layout'
import { BlueButton } from '../../simples/BlueButton'
import { About } from './parts/About'
import { Category } from './parts/Category'
import { Steps } from './parts/Steps'
import { Package } from './parts/Package'

import styles from "./Product.module.scss"
import { Compositions } from './parts/Compositions'
import { Details } from './parts/Details'
import { Description } from './parts/Description'
import { Collection } from './parts/Collection'
import { Seasons } from './parts/Seasons'
import { TagsBlock } from './parts/TagsBlock'
import { Prices } from './parts/Prices'
import { useNavigate, useParams } from 'react-router-dom'
import { Routes } from '../../../constants/routes'
import { Step3 } from './parts/Step3'
import { ProductCard } from './parts/ProductCard'
// import { Certificates } from './parts/Certificates'

export default function Product() {
  const dispatch = useAppDispatch()

  const productState = useAppSelector(state => state.product)
  const { isLoadingProduct, isCommonDataLoading, isLoadingProductError } = productState
  const step = useAppSelector(state => state.product.step)
  const readonly = useAppSelector(state => state.product.isReadonlyMode)
  const priceIsUpdated = useAppSelector(state => state.product.priceIsUpdated)

  const [loading, setLoading] = useState(true) // TODO переделать по нормальному

  const params = useParams();
  const navigate = useNavigate();

  const isCreactionMode = (step > 0 && step < 5)
  const title = isCreactionMode ? 'Добавление нового товара' : 'Карточка товара'
  const hint = isCreactionMode
    ? 'В данном разделе можно создать карточку товара, указав его характеристики, цвет, штрихкод, стоимость'
    : 'В данном разделе содержится информация о товаре, в режиме редактирования Вы можете внести необходимые изменения в карточку товара'

  const handleBack = () => {
    if (isCreactionMode && step > 1) {
      dispatch(setStep(step - 1))
    } else {
      navigate(Routes.PRODUCTS_LIST)
    }
  }

  function loadProduct() {
    const productId = parseInt(params.id || '')
    const variantId = parseInt(params.color_id || '')
    if (productId) {
      dispatch(fetchProduct({ productId, variantId })).then(() => {
        setLoading(false)
      });
    } else {
      dispatch(createNewProduct()).then(() => {
        setLoading(false)
      });
    }
  }

  function load() {
    setLoading(true)
    dispatch(fetchCommonData()).then(() => {
      loadProduct()
    })
  }

  useEffect(() => {
    load()
  }, [params])

  useEffect(() => {
    if (readonly) load() // TODO сделать по нормальному, релоэд после отмены
  }, [readonly])

  if (isLoadingProduct || isCommonDataLoading || loading) {
    return (<Layout title={title} hint={hint} onBack={handleBack}>
      <div>Загрузка...</div>
    </Layout>
    )
  }
  if (isLoadingProductError) {
    return (<Layout title={title} hint={hint} onBack={handleBack}>
      <div>Ошибка!</div>
    </Layout>
    )
  }

  const isStep1HasErrors = step1HasErrors(productState)
  const isStep2HasErrors = step2HasErrors(productState)
  // const isStep3HasErrors = step3HasErrors(productState)
  // const isStep4HasErrors = step4HasErrors(productState)


  let content: JSX.Element
  let btn: JSX.Element


  switch (step) {
    case 1: {
      const handleSave = () => {
        dispatch(createProduct())
      }

      return (
        <Layout title={title} hint={hint} onBack={handleBack}>
          <Steps />
          <div className={styles.wrap}>
            <div className={styles.grid}>
              <Category className={styles.category} />
              <About />
              <Package />
            </div>
            <div className={styles.footer}>
              <BlueButton title='Заполнить характеристики' onClick={handleSave} disabled={isStep1HasErrors} />
            </div>
          </div>
        </Layout>
      )
    }
    case 2: {
      const handleSave = () => {
        dispatch(updateProductStep2())
      }

      return (
        <Layout title={title} hint={hint} onBack={handleBack}>
          <Steps />
          <div className={styles.wrap}>
            <div className={styles.grid}>
              <Compositions />
              <div className={styles.seasons_collection}>
                <Seasons />
                <Collection />
              </div>
              <Details />
              {/* <Certificates /> */}
              <Description />
            </div>
            <TagsBlock />
            <div className={styles.footer}>
              <BlueButton title='Добавить цвета и штрихкоды' onClick={handleSave} disabled={isStep2HasErrors} />
            </div>
          </div>
        </Layout>
      )
    }
    case 3: {
      return (
        <Layout title={title} hint={hint} onBack={handleBack}>
          <Steps />
          <Step3 />
        </Layout>)
    }
    case 4: {
      const handleSave = () => {
        dispatch(sendToModeration()).then((action) => {
          if (action.payload.success) {
            alert('Товар успешно добавлен')
            dispatch(resetState())
            navigate(Routes.PRODUCTS_LIST);
          }
        })
      }

      const applyPrice = () => {
        dispatch(updateProductStep3_4()).then(() => {
          dispatch(setPriceSaved())
        })
      }

      return (
        <Layout title={title} hint={hint} onBack={handleBack}>
          <Steps />
          <div className={styles.wrap}>
            <div className={styles.grid}>
              <Prices className={styles.prices} />
            </div>
            <div className={styles.footer}>
              <BlueButton title='Применить цену' onClick={applyPrice} disabled={!priceIsUpdated} />
              <BlueButton title='Отправить на модерацию' onClick={handleSave} disabled={productState.priceIsUpdated} />
            </div>
          </div>
        </Layout>
      )
    }

    default: {
      return (
        <Layout title={title} hint={hint} onBack={handleBack}>
          <ProductCard />
        </Layout>
      )
    }
  }
}
