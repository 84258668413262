import classNames from "classnames";
import React, { FC } from "react";
import styles from "./btnGoodsNavigate.module.scss";

interface PropsType {
  title: string;
  id: number;
  countGoods: number;
  swichOn: boolean;
  isClick: (id: number) => void;
}

const BtnGoodsNavigate: FC<PropsType> = ({ title, id, swichOn, isClick, countGoods }) => {

  function handlerClick() {
    isClick(id);
  }

  return (
    <main className={classNames(styles.wrapper, { [styles.active]: swichOn })} onClick={handlerClick}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.count_goods}> {countGoods}</div>
      </div>
    </main>
  );
};

export default BtnGoodsNavigate;
