import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from '../../utils/api';
import { CompanyCertifiedBrandsList, Files } from "../../types/swagger/llyApi_new";
import { toast } from "react-toastify";
import { AxiosProgressEvent } from "axios";
import { RootState } from "..";

const initialState = {
  isLoadingData: false,
  uploadingProgress: {} as { bid: number, progress: number },
  uploadedDocs: {} as { bid: number, files: Files },
  brandsList: new Array<CompanyCertifiedBrandsList>()
};

const saveSuccessMsg = () => toast.success("Данные сохранены", { theme: "light" });
const errorMsg = () => toast.error("Ошибка", { theme: "light" });

type FetchResponse = {
  success?: boolean
  data?: CompanyCertifiedBrandsList[]
  errors?: string[]
}

type UploadDocsResponse = {
  success?: boolean
  data?: Files[]
  errors?: string[]
}
type SendToModerationResponse = {
  success?: boolean
  data?: CompanyCertifiedBrandsList[]
  errors?: string[]
}


export const fetchBrands = createAsyncThunk<FetchResponse>("Brands/fetchBrands", async () => {
  let response: FetchResponse = { success: false }
  // await api.get<FetchResponse>('/brands/index').then(data => {
  await api.get<FetchResponse>('/brands/get-certified-brands').then(data => {
    response = data.data
  })
  return response
});


export const uploadDocs = createAsyncThunk<UploadDocsResponse, { bid: number, files: File[] }>(
  "Brands/uploadDocs", async ({ bid, files }, { getState, dispatch, fulfillWithValue, rejectWithValue }) => {
    let response: UploadDocsResponse = { success: false }
    dispatch(setUploadingProgress({ bid, progress: 0 }));

    const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
      const uploadPercentage = parseInt(
        Math.round((progressEvent.loaded / (progressEvent.total || -1)) * 100) as any,
      );
      // onProgress && onProgress(uploadPercentage)
      dispatch(setUploadingProgress({ bid, progress: uploadPercentage }));
    }

    await api.post<UploadDocsResponse>('/brands/upload-documents',
      { file: files },
      { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress },
    ).then(data => {
      if (data.data.success && data.data.data) {
        dispatch(setUploadedDocs({ bid, files: (data.data.data || []) }));
      }
      response.success = data.data.success
    }).catch(e => {
      console.log(e);
    })

    if (!response.success) {
      errorMsg()
    }
    dispatch(setUploadingProgress({ bid, progress: undefined }));

    return response
  });


export const sendToModeration = createAsyncThunk<FetchResponse, number, { state: RootState }>("Brands/sendToModeration", async (bid, { getState, dispatch }) => {
  let response: FetchResponse = { success: false }
  const state = getState()
  const uploadedDocs: Files[] | undefined = state.brands.uploadedDocs[bid]
  if (uploadedDocs && uploadedDocs.length > 0) {
    const data = {
      id: bid,
      documents: uploadedDocs.map(doc => ({ id: doc.id }))
    }
    await api.post<SendToModerationResponse>('/brands/moderation', data).then(data => {
      // response = data.data
      response.success = data.data.success
    }).catch(console.log)

    if (!response.success) {
      errorMsg()
    }

    dispatch(setUploadedDocs({ bid, files: [] }));
    dispatch(fetchBrands())
  }
  return response
});

type RemoveDocsResponse = {
  success?: boolean
  errors?: string[]
}
export const removeDocs = createAsyncThunk<FetchResponse, number, { state: RootState }>("Brands/removeDocs", async (bid, { getState, dispatch }) => {
  const state = getState()
  const brand = state.brands.brandsList[bid]
  // dispatch(clearUploaded(bid))
  dispatch(setUploadedDocs({ bid, files: [] }));

  let response: RemoveDocsResponse = { success: false }

  if (brand && brand.documents && brand.documents?.length > 0) {
    await api.delete<SendToModerationResponse>('/brands/delete/' + bid).then(data => {
      response.success = data.data.success
    }).catch(console.log)

    if (!response.success) {
      errorMsg()
    }
    dispatch(fetchBrands())
  } else {
    response.success = true
  }
  return response
});





export const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    setUploadingProgress: (state, action: PayloadAction<{ bid: number, progress: number | undefined }>) => {
      state.uploadingProgress[action.payload.bid] = action.payload.progress
    },
    setUploadedDocs: (state, action: PayloadAction<{ bid: number, files: Files[] }>) => {
      state.uploadedDocs[action.payload.bid] = action.payload.files
    },
    // clearUploaded: (state, action: PayloadAction<number>) => {
    //   state.uploadedDocs[action.payload] = []
    // },
    // setIsDeleted: (state, action: PayloadAction<boolean>) => {
    //   const companyStatus = state.user?.companyStatus
    //   if (companyStatus) { companyStatus.deleted = action.payload }
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrands.pending, (state) => {
      state.isLoadingData = true;
    });
    builder.addCase(fetchBrands.fulfilled, (state, action: PayloadAction<FetchResponse>) => {
      if (action.payload.success) {

        state.brandsList = action.payload.data || []

        // state.brandsList = action.payload.data?.map(b => {
        //   return {
        //     id: b.id,
        //     name: b.name,
        //     status: b.status,
        //     status_name: b.status_name,
        //     status_description: b.status_description,
        //     documents: b.documents
        //   }
        // })

        // state.brandsList = [{
        //   /** ID бренда */
        //   id: 1,
        //   /** Название бренда */
        //   name: 'brand 1',
        //   /** Статус модерации: null - не был отправлен, -1 - отклонен, 0 - на модерации, 1 - действующий */
        //   status: 1,
        //   /** Статус модерации (строка) */
        //   status_name: 'asdf',
        //   /** Ответ модератора (причина отклонения) */
        //   status_description: '',
        //   /** Загруженные документы */
        //   documents: []
        // }, {
        //   id: 2,
        //   name: 'brand 2',
        //   status: 1,
        //   status_name: 'asdf',
        //   status_description: '',
        //   documents: []
        // }, {
        //   id: 3,
        //   name: 'brand 3',
        //   status: 1,
        //   status_name: 'asdf',
        //   status_description: '',
        //   documents: []
        // }]
      }
      state.isLoadingData = false;
    });
    builder.addCase(fetchBrands.rejected, (state) => {
      state.isLoadingData = false;
    });
  },
});

const { setUploadingProgress, setUploadedDocs } = brandsSlice.actions
// export const { } = brandsSlice.actions

export default brandsSlice.reducer;
