import classNames from 'classnames'
import React from 'react'
import { BannerConfig, BannerShapeType, BannerTextType } from '../../../types/discount'

import styles from "./DiscountBanner.module.scss"



type Props = {
  config: BannerConfig
  // config?: DiscountsBanners
  discountValue: number | string
  className?: string
}



export default function DiscountBanner({config, discountValue, className}: Props) {
  
  let _discountValue = config?.textType === BannerTextType.percent ? `${discountValue}%` : "скидка";
  let banner
  switch (config?.shape) {
    case BannerShapeType.TRIANGLE:
      banner = <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H94L0 94V0Z" fill={`#${config?.color}`} />
      </svg>
      break;
    case BannerShapeType.CIRCLE:
      banner = <svg width="81" height="68" viewBox="0 0 81 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="36" cy="23" r="45" fill={`#${config?.color}`} />
      </svg>
      break;

    case BannerShapeType.SQUARE:
    default:
      banner = <svg width="86" height="67" viewBox="0 0 86 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H86V31.7007C86 35.1158 83.8322 38.1543 80.6029 39.2654L0 67V0Z" fill={`#${config?.color}`} />
      </svg>
      break;
  }

  return (
    <div className={classNames(
      className, 
      styles.banner,
      styles[config.textType],
      styles[config.shape.toLocaleLowerCase()],
      styles[`x${config.color}`]
    )} >
      <span className={styles.banner_text}>{_discountValue}</span>
      {banner}
    </div>
  )
}
