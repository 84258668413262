import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PLUG_VALUE } from "../../../../../constants/defaults";
import { Routes } from "../../../../../constants/routes";
import { OrganisationSendData } from "../../../../../types/registration";
import { PinkErrorText } from "../../../../simples/PinkErrorText";
import { PurpleButton } from "../../../../simples/PurpleButton";
import { SwitchTextInput } from "../../../../simples/SwitchTextInput";
import styles from "./OrganisationInfo.module.scss";
import { BlueButton } from "../../../../simples/BlueButton";

type Props = {
  data: OrganisationSendData;
  submit: (kpp: OrganisationSendData) => void;
  isTinError: boolean;
};

const defaultOrganisationData = {
  name: "",
  fullName: "",
  director: "",
  INN: "",
  OGRN: "",
  KPP: "",
  isIP: false,
};

const OrganisationInfo = ({ data, submit, isTinError }: Props) => {
  const [info, setInfo] = useState<OrganisationSendData>(
    defaultOrganisationData,
  );
  const [isError, setIsError] = useState(Boolean);
  const navigate = useNavigate();
  const cancelRegistration = () => navigate(Routes.AUTH);

  const confirm = () => {
    const isValid = isValidAll();
    !isError && isValid && submit(info);
    !isValid && setIsError(true);
  };

  const isValidAll = () => {
    const isGoodNames = info.name && info.fullName && info.director;
    const isGoodNumbers = data.isIP ? info.OGRN.length === 15 : info.OGRN.length === 13 && info.KPP.length === 9;
    return isGoodNames && isGoodNumbers;
  };

  const onTypeInfo = (infoType: keyof OrganisationSendData, value: string) => {
    const isStringType =
      infoType === "director" || infoType === "fullName" || infoType === "name";
    if (isStringType && value.length > 80) {
      return;
    }
    isError && isValidAll() && setIsError(false);
    setInfo(prev => ({ ...prev, [infoType]: value }));
  };

  useEffect(() => {
    const newData = { ...data };
    Object.keys(data).map(dataKey => {
      if (data[dataKey] === PLUG_VALUE) newData[dataKey] = "";
    });
    setInfo(newData);
  }, [data]);

  return (
    <div className={styles.container}>
      <SwitchTextInput
        isActive={data.name === PLUG_VALUE}
        value={info.name}
        title={"Краткое наименование"}
        className={styles.input}
        setValue={onTypeInfo.bind(null, "name")}
      />
      <SwitchTextInput
        isActive={data.fullName === PLUG_VALUE}
        value={info.fullName}
        title={"Полное наименование"}
        className={styles.input}
        setValue={onTypeInfo.bind(null, "fullName")}
      />
      <SwitchTextInput
        isActive={data.director === PLUG_VALUE}
        value={info.director}
        title={"ФИО руководителя"}
        className={styles.input}
        setValue={onTypeInfo.bind(null, "director")}
      />
      <SwitchTextInput
        isActive={data.OGRN === PLUG_VALUE}
        value={info.OGRN}
        title={data.isIP?"ОГРНИП":"ОГРН"}
        mask={"9999999999999"}
        className={styles.input}
        setValue={onTypeInfo.bind(null, "OGRN")}
      />
      {!data.isIP && <SwitchTextInput
        isActive={!isTinError}
        value={info.KPP}
        title={"КПП"}
        mask={"999999999"}
        className={styles.input}
        setValue={onTypeInfo.bind(null, "KPP")}
      />}
      <PinkErrorText
        isShow={isError}
        title={"Заполните все данные!"}
        className={styles.error}
      />
      <div className={styles.buttonsContainer}>
        <button onClick={cancelRegistration} className={styles.disableButton}>
          Отменить
        </button>
        {/* <PurpleButton
          disabled={isError || isTinError}
          title={"Всё верно"}
          className={styles.confirmButton}
          onClick={confirm}
        /> */}
        <BlueButton
          disabled={isError || (!data.isIP && isTinError)}
          title={"Всё верно"}
          className={styles.confirmButton}
          onClick={confirm}
        />
      </div>
    </div>
  );
};

export default OrganisationInfo;
