import { useEffect, useState } from 'react'
import styles from './RemainingStock.module.scss'
import api from '../../../../../utils/api'
import { CustomSelect } from '../../../../simples/CustomSelect'
import { Option } from '../../../../simples/Select'
import { CustomDatePicker, DatePickerDate } from '../../../../complexes/CustomDatePicker'
import { BlueButton } from '../../../../simples/BlueButton'
import { datePickerDateToDate, dateToDatePickerDate } from '../../../../complexes/CustomDatePicker/CustomDatePicker'
import { sub } from 'date-fns'
import { IHint } from '../../../../simples/IHint'
import { ProductList } from '../../../../complexes/ProductList'
import { ProductsDTO } from '../../../../../types/swagger/llyApi_new'
import { DefaultHeaders } from '../../../../complexes/ProductList/DefaultHeaders'
import { ListDataType, ListHeadData } from '../../../../../types/productListTypes'
import download from '../../../../../utils/download'
import { formatDateForServer } from '../../../../../utils/action'
import { toast } from 'react-toastify';
// import { CalendarPeriodSelector } from '../../../../complexes/CalendarPeriodSelector'

type FBOData = {
  id: number
  string?: string
}

type ServerFBOResponse = {
  success?: boolean
  data?: FBOData[]
}
type RemainingStockResponse = {
  success?: boolean
  data?: ProductsDTO[]
}


// const headers: ListHeadData[] = [
//   DefaultHeaders.barcode,
//   {
//     name: AvailableProductData.photo,
//     text: "Фото",
//     type: ListDataType.image,
//     minWidth: "60px",
//     width: "60px"
//   },
//   // {
//   //   name: AvailableProductData.id,
//   //   text: "Артикул",
//   //   minWidth: "100px",
//   //   width: "100px"
//   // },
//   {
//     name: AvailableProductData.name,
//     text: "Наименование",
//     sortable: true,
//     minWidth: "140px",
//     width: "140px"
//   }, {
//     //   name: AvailableProductData.retail_price,
//     //   text: "Цена",
//     //   sortable: true,
//     //   minWidth: "95px",
//     //   width: "95px"
//     // }, {
//     //   name: AvailableProductData.discountValue,
//     //   text: "Актуальная скидка",
//     //   sortable: true,
//     //   minWidth: "140px",
//     //   width: "140px"
//     // }, {
//     //   name: AvailableProductData.discountPercent,
//     //   text: "Скидка %",
//     //   sortable: true,
//     //   minWidth: "80px",
//     //   width: "80px"
//     // }, {
//     //   name: AvailableProductData.priceWithDiscount,
//     //   text: "Итоговая стоимость",
//     //   sortable: true,
//     //   minWidth: "140px",
//     //   width: "140px"
//     // }, {
//     //   name: AvailableProductData.stockBalance,
//     //   text: "Остаток на складе",
//     //   sortable: true,
//     //   minWidth: "135px",
//     //   width: "135px"
//     // }, {
//     //   name: AvailableProductData.discountItemsCount,
//     //   text: "Участвуют в скидке",
//     //   sortable: true,
//     //   minWidth: "150px",
//     //   width: "150px"
//     // }, {
//     name: AvailableProductData.size,
//     text: "Размер",
//     minWidth: "75px",
//     width: "75px"
//   }, {
//     name: AvailableProductData.brand,
//     text: "Бренд",
//     sortable: true,
//     minWidth: "85px",
//     width: "85px"
//   }, {
//     name: AvailableProductData.sex,
//     text: "Пол",
//     sortable: true,
//     minWidth: "55px",
//     width: "55px"
//   }, {
//     name: AvailableProductData.category,
//     text: "Категория",
//     sortable: true,
//     minWidth: "110px",
//     width: "110px"
//   }, {
//     name: AvailableProductData.subcategory,
//     text: "Подкатегория",
//     sortable: true,
//     minWidth: "130px",
//     width: "130px"
//   }, {
//     name: AvailableProductData.season,
//     text: "Сезон",
//     sortable: true,
//     minWidth: "75px",
//     width: "75px"
//   }, {
//     //   name: AvailableProductData.barcode,
//     //   text: "Штрихкод",
//     //   minWidth: "110px",
//     //   width: "110px"
//     // }, {
//     name: AvailableProductData.dateOfCreation,
//     text: "Дата создания",
//     minWidth: "110px",
//     width: "110px"
//   }];

const now = new Date()

export default function RemainingStock() {

  const [isLoading, setIsLoading] = useState(false)
  const [fbo, setFbo] = useState<FBOData[]>()
  const [selectedFbo, setSelectedFbo] = useState<number>(-1)
  const [activeFbo, setActiveFbo] = useState<number>(-1)
  const [dateTo, setDateTo] = useState<DatePickerDate>(dateToDatePickerDate(sub(new Date(), { days: 1 })))
  const [activeDateTo, setActiveDateTo] = useState<DatePickerDate>(dateToDatePickerDate(sub(new Date(), { days: 1 })))
  const [products, setProducts] = useState<ProductsDTO[]>([])


  useEffect(() => {
    setFbo(undefined)
    const req = '/storages';
    api.get<ServerFBOResponse>(req).then(data => {
      if (data.data.data !== undefined) {
        setFbo(data.data.data)
      }
    })
  }, [])


  const fboOptions: Option[] = fbo?.map(item => ({
    value: item.id,
    label: item.string || '',
  })) || []
  fboOptions.unshift({
    value: -1,
    label: 'Все склады',
  })

  const handleChangeFBO = ({ value }) => {
    setSelectedFbo(value)
  }

  const storages2String = (selectedFbo) => {
    if (selectedFbo === -1) {
      return fbo?.map(s => s.id).join(',') || ''
    }
    return [selectedFbo].join(',')
  }

  const handleBuildReport = () => {
    setActiveFbo(selectedFbo)
    setActiveDateTo(dateTo)
    // let storages = [selectedFbo].join(',')
    // if (selectedFbo === -1) {
    //   storages = fbo?.map(s => s.id).join(',') || ''
    // }
    const storages = storages2String(selectedFbo)
    setIsLoading(true)
    api.get<RemainingStockResponse>(`/storages/balance?date=${formatDateForServer(datePickerDateToDate(dateTo))}&storages=${storages}`).then(data => {
      if (data.data.data !== undefined) {
        setProducts(data.data.data)
        // console.log(data.data.data);
      }
    }).catch(e => {
      toast.error("Ошибка", { theme: "light" });
      setProducts([])
    }).finally(() => {
      setIsLoading(false)
    })
  }

  // useEffect(() => {
  //   handleBuildReport()
  // }, [selectedFbo, dateTo])

  const handleDownloadTable = () => {
    const storages = storages2String(activeFbo)
    download({
      req: `/storages/balance-to-excel?date=${formatDateForServer(datePickerDateToDate(activeDateTo))}&storages=${storages}`
    })
  }

  const handleSetDate = (date: DatePickerDate) => {
    const d = datePickerDateToDate(date)
    if (d >= now) return
    setDateTo(date)
  }

  // const products: ProductsDTO[] = []

  const storageHeaders: ListHeadData[] = []

  if (activeFbo === -1 && fbo !== undefined) {
    for (const s of fbo) {
      storageHeaders.push({
        name: `storage-${s.id}`,
        // text: fbo?.find(s => s.id === selectedFbo)?.string||'Название склада',
        content: <div className={styles.storage_title}>{s.string || 'Название склада'}</div>,
        type: ListDataType.text,
        minWidth: "120px",
        width: "120px",
        sortable: true,
        alignCenter: true
      })
    }
  } else {
    storageHeaders.push({
      name: `storage-${activeFbo}`,
      // text: fbo?.find(s => s.id === selectedFbo)?.string||'Название склада',
      content: <div className={styles.storage_title}>{fbo?.find(s => s.id === activeFbo)?.string || 'Название склада'}</div>,
      type: ListDataType.text,
      minWidth: "120px",
      width: "120px",
      sortable: true,
      alignCenter: true
    })
  }


  const headers: ListHeadData[] = [
    DefaultHeaders.barcode,
    DefaultHeaders.photo,
    // {
    //   name: AvailableProductData.photo,
    //   text: "Фото",
    //   type: ListDataType.image,
    //   minWidth: "60px",
    //   width: "60px"
    // },
    DefaultHeaders.name,
    ...storageHeaders,
    DefaultHeaders.fbo,
    DefaultHeaders.fbs,
    DefaultHeaders.size,
    DefaultHeaders.color,
    DefaultHeaders.brand,
    DefaultHeaders.sex,
    DefaultHeaders.category,
    DefaultHeaders.subcategory,
    DefaultHeaders.season,
    { ...DefaultHeaders.retail_price, text: "Розничная цена" },
    DefaultHeaders.dateOfCreation,
    // {
    //   name: AvailableProductData.name,
    //   text: "Наименование",
    //   sortable: true,
    //   minWidth: "140px",
    //   width: "140px"
    // },
    // {
    //   name: AvailableProductData.size,
    //   text: "Размер",
    //   minWidth: "75px",
    //   width: "75px"
    // },
    // {
    //   name: AvailableProductData.brand,
    //   text: "Бренд",
    //   sortable: true,
    //   minWidth: "85px",
    //   width: "85px"
    // }, {
    //   name: AvailableProductData.sex,
    //   text: "Пол",
    //   sortable: true,
    //   minWidth: "55px",
    //   width: "55px"
    // }, {
    //   name: AvailableProductData.category,
    //   text: "Категория",
    //   sortable: true,
    //   minWidth: "110px",
    //   width: "110px"
    // }, {
    //   name: AvailableProductData.subcategory,
    //   text: "Подкатегория",
    //   sortable: true,
    //   minWidth: "130px",
    //   width: "130px"
    // }, {
    //   name: AvailableProductData.season,
    //   text: "Сезон",
    //   sortable: true,
    //   minWidth: "75px",
    //   width: "75px"
    // }, 
    //   name: AvailableProductData.retail_price,
    //   text: "Розничная цена",
    //   sortable: true,
    //   minWidth: "120px",
    //   width: "4fr"
    // }, 
    // {
    //   name: AvailableProductData.dateOfCreation,
    //   text: "Дата создания",
    //   minWidth: "110px",
    //   width: "110px"
    // }
  ];


  return (
    <div className={styles.remaining_stock}>
      <div className={styles.controls}>
        <div className={styles.selects}>
          <CustomSelect className={styles.fbo_select} name='fbo' options={fboOptions} onChange={handleChangeFBO} value={selectedFbo} hideError />
          <CustomDatePicker date={dateTo} onChange={handleSetDate} label="" maxDate={now} />
        </div>
        <BlueButton title='Сформировать отчет' className={styles.build_report} onClick={handleBuildReport} />
        <IHint hint='Фото не выгружается в excel' className={styles.hint} />
        <BlueButton title='Выгрузить в excel' className={styles.export_btn} onClick={handleDownloadTable} />
      </div>
      <ProductList
        data={products} headers={headers}
        className={styles.products_list}
        isLoading={isLoading}
      />
    </div>
  )
}
