import classNames from 'classnames'
import React from 'react'
import styles from "./Progress.module.scss"

type Props = {
  value: number
  className: string
}

export default function Progress({value, className}: Props) {
  return (
    <div className={classNames(styles.wrap, className)}>
      <div className={styles.progress} style={{width: `${value}%`}}></div>
      <div className={styles.progress_over}></div>
    </div>
  )
}
