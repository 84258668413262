import React, { Dispatch, FC, SetStateAction } from "react";
import styles from "./Search.module.scss";
import loop from "../../../../../../assets/loop.svg";
import { useAppSelector } from "../../../../../../../../../hooks/store";
import classNames from "classnames";
import { LogosMode } from "../../../../../../../../../store/slices/getters/logo-list";

interface Props {
  handleSearch: Dispatch<SetStateAction<string>>;
}

const Search: FC<Props> = ({
  handleSearch,
}) => {

  const mode = useAppSelector(state => state.logoList.mode)

  return (
    <div className={classNames(styles.search, mode === LogosMode.list && styles.grow)}>
      <input
        type="text"
        placeholder="Поиск"
        onChange={e => handleSearch(e.target.value)}
      />
      <img src={loop} alt="Search Icon" />
    </div>
  );
};

export default Search;