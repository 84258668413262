import React, { useEffect } from 'react'
import { BlueButton, ButtonStyle } from '../../../../../../simples/BlueButton'
import styles from './GearSheet.module.scss'
import { ItemsList } from '../../../../../../complexes/ItemsList'
import { ListDataType, ListHeadData, ListRow } from '../../../../../../../types/productListTypes'
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/store'
import { format } from 'date-fns'
import { cancelNewDeliveryRequest, postNewOrder, reloadOrders } from '../../../../../../../store/slices/fbs'
import classNames from 'classnames'
import { ClipLoader } from 'react-spinners'
import api from '../../../../../../../utils/api'

const Headers: ListHeadData[] = [{
  name: 'id',
  text: "ID и дата заказа",
  type: ListDataType.text,
  sortable: true,
  minWidth: "140px",
  width: "140px"
}, {
  name: 'buyer',
  text: "Покупатель",
  type: ListDataType.text,
  sortable: true,
  minWidth: "220px",
  width: "220px"
}, {
  name: 'address',
  text: "Адрес доставки",
  type: ListDataType.text,
  sortable: true,
  minWidth: "280px",
  width: "280px"
}, {
  name: 'products',
  text: "Товары",
  type: ListDataType.text,
  minWidth: "220px",
  width: "220px"
}, {
  name: 'docs',
  text: "Документация",
  type: ListDataType.text,
  minWidth: "190px",
  width: "190px"
}, {
  name: 'delivery_cost',
  className: styles.delivery_cost,
  content: <>Стоимость<br />доставки</>,
  type: ListDataType.content,
  sortable: true,
  minWidth: "90px",
  width: "90px"
}, {
  name: 'cost',
  className: styles.cost,
  content: <>Стоимость<br />без доставки</>,
  type: ListDataType.content,
  sortable: true,
  minWidth: "90px",
  width: "90px",
}]


export default function GearSheet() {

  const { request, isOrdersCollected } = useAppSelector(s => s.fbs)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const i = setInterval(() => {
      if (isOrdersCollected) {
        const orders = request.orders.filter(o => !o.isReady)
        // console.log(orders);
        
        const ids = orders.map(o => o.submissionId || -1)
        // console.log(`/fbs-orders/collected-pulse?ids=${ids.join(',')}`, { orders, ids });
        if(ids.length === 0){
          clearInterval(i)
          return
        }
        dispatch(reloadOrders(ids))
        // api.get(`/fbs-orders/collected-pulse?ids=4`).then(resp => {
        //   console.log(resp);
        // })
      }
    }, 5000)

    return () => {
      clearInterval(i)
    }
  }, [request, isOrdersCollected])


  const items: ListRow[] = request.orders.map(o => ({
    key: o.id,
    data: [{
      name: 'id',
      type: ListDataType.content,
      content: <div className={styles.id}>
        <div className={styles.number}>№{o.id}</div>
        <div className={styles.date}>{format(o.date, 'dd.MM.yyyy')}</div>
      </div>
    }, {
      name: 'buyer',
      type: ListDataType.content,
      content: <div className={styles.buyer}>
        <div className={styles.consumer}>{o.consumer_name}</div>
      </div>
    }, {
      name: 'address',
      className: styles.address,
      type: ListDataType.text,
      text: o.address
    }, {
      name: 'products',
      type: ListDataType.content,
      content: <div className={styles.products}>
        {o.items.map(item => <div className={styles.product} key={item.id}>
          <div className={styles.name}>{item.name}</div>
          <div className={styles.code}>{item.code}</div>
        </div>)}
      </div>
    }, {
      name: 'docs',
      className: classNames(styles.docs, { [styles.docs_collected]: isOrdersCollected }),
      type: ListDataType.content,
      content: isOrdersCollected
        ? <>
          {o.hasErrors && <div className={styles.error}>! Ошибка</div>}
          {!o.isReady && <div className={styles.loader}><ClipLoader size={12} color='#8289A1' /> Обработка</div>}
          {o.isReady && o.documents?.map(doc => <a href={doc.url} target='_blank' rel='noreferrer' key={doc.id}>{doc.name}</a>)}
        </>
        : <>Сформируйте документацию</>
    }, {
      name: 'delivery_cost',
      type: ListDataType.text,
      className: styles.delivery_cost,
      text: ''
    }, {
      name: 'cost',
      className: styles.cost,
      type: ListDataType.text,
      text: ''
    }]
  }))


  // const items: ListRow[] = [, {
  //   key: '2',
  //   data: [{
  //     name: 'id',
  //     type: ListDataType.content,
  //     content: <div className={styles.id}>
  //       <div className={styles.number}>№2456</div>
  //       <div className={styles.date}>01.02.2023</div>
  //     </div>
  //   }, {
  //     name: 'buyer',
  //     type: ListDataType.content,
  //     content: <div className={styles.buyer}>
  //       <div className={styles.date}>Петров Пётр Петрович</div>
  //     </div>
  //   }, {
  //     name: 'address',
  //     type: ListDataType.text,
  //     text: 'г. Москва, ул. Библиотечная, д 25'
  //   }, {
  //     name: 'products',
  //     type: ListDataType.content,
  //     content: <div className={styles.products}>
  //       <div className={styles.product}>
  //         <div className={styles.name}>Футболка женская</div>
  //         <div className={styles.code}>15560B22222XS</div>
  //       </div><div className={styles.product}>
  //         <div className={styles.name}>Футболка женская</div>
  //         <div className={styles.code}>15560B22222XS</div>
  //       </div>
  //     </div>
  //   }, {
  //     name: 'docs',
  //     className: styles.docs,
  //     type: ListDataType.text,
  //     text: 'Сформируйте документацию'
  //   }, {
  //     name: 'delivery_cost',
  //     type: ListDataType.text,
  //     className: styles.delivery_cost,
  //     text: '300'
  //   }, {
  //     name: 'cost',
  //     className: styles.cost,
  //     type: ListDataType.text,
  //     text: '3 000'
  //   }]
  // }]
  const handleCreateNewOrder = () => {
    dispatch(postNewOrder())
  }
  const handleClose = () => {
    dispatch(cancelNewDeliveryRequest())
  }

  return (
    <div className={styles.gear_sheet} >
      <div className={styles.btns}>
        <BlueButton title='Распечатать термопринты' buttonStyle={ButtonStyle.Style1} disabled={!isOrdersCollected} />
        <BlueButton title='Распечатать накладную и лист отправления' buttonStyle={ButtonStyle.Style1} disabled={!isOrdersCollected} />
      </div>
      <div className={styles.list}>
        <ItemsList headers={Headers} items={items} emptyText='' />
      </div>
      <div className={styles.footer}>
        {isOrdersCollected
          ? <BlueButton title='Закрыть' onClick={handleClose} />
          : <BlueButton title='Подтвердить отправку' onClick={handleCreateNewOrder} />}
        <div className={styles.info}>Что бы продолжить, вам необходимо сформировать документацию</div>
      </div>
    </div >
  )
}
