import { CategoriesSizes, Compounds, Details, MaterialsDTO, Products } from "./swagger/llyApi_new"

export type Package = {
  type_id: number | null
  equipment: string
  // information: string
  height: number | null
  width: number | null
  depth: number | null
  gross_weight: number | null
  net_weight: number | null
}

export type CreateResponseType = {
  data: {
    data: Products;
    status: number;
    success: boolean;
  }
};

export type ReferenceBook = {
  materials: MaterialsDTO[],
  details: Details[],
  sizes: CategoriesSizes[],
  compounds: Compounds[]
}

export type MaterialState = {
  id?: number
  // name?: string
  percent?: number
}

export enum CategoryLvl {
  lvl1 = 'lvl1',
  lvl2 = 'lvl2',
  lvl3 = 'lvl3',
  lvl4 = 'lvl4',
}

export type CategoriesValue = {
  [key in CategoryLvl]?: number | null
}
