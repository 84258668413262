import React from 'react'

import styles from '../CompanyDocuments.module.scss'
import classNames from 'classnames'
import { TaxSystemType } from '../../../../types/law'
import Dropzone from 'react-dropzone'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { uploadDoc } from '../../../../store/slices/companyDocuments'


const accept = { "image/png": [".png"], 'image/jpg': ['.jpg'], 'application/pdf': ['.pdf'] }
const maxSize = 32 * 1024 ** 2

export default function USNDoc() {

  const dispatch = useAppDispatch()

  const taxSystemType = useAppSelector(state => state.companyDocuments.taxSystemType)
  const doc = useAppSelector(state => state.companyDocuments.usnDoc)


  const onDrop = (data: File[]) => {
    if (data.length === 1) {
      dispatch(uploadDoc({ file: data[0], docType: doc.type }))
    }
  }

  const disabled = taxSystemType !== TaxSystemType.USN

  return (
    <Dropzone onDrop={onDrop} multiple={false} accept={accept} maxSize={maxSize} disabled={disabled} >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={classNames(styles.usn_doc, {
          [styles.disabled]: disabled
        })} >
          <div className={classNames(styles.usn_doc_upload, styles.link)}>{doc.progress ? `Загрузка... ${doc.progress}%` : (doc.file || 'Загрузите')}</div>
          <div className={styles.usn_doc_info}>Заявление о переходе на УСН (или справка по форме 26.2-7)</div>
        </div>
      )}
    </Dropzone>
  )
}
