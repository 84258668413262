import React, { FC, useState, useEffect, ChangeEvent } from "react";
import { CategorySelectTypes } from "../../../../../types/selectOptions";
import { CategoryType } from "../../../../../types/product";
import styles from "./CategorySelector.module.scss";
import ProductEditService from "../../../../../services/ProductEdit";
import { Option, Select } from "../../../../simples/Select";
import { CustomSelect_v2, IOnSelectChange } from "../../../../simples/CustomSelect_v2";


type StateOptions = {
  [CategorySelectTypes.for]: Option[];
  [CategorySelectTypes.type]: Option[];
  [CategorySelectTypes.category]: Option[];
  [CategorySelectTypes.subcategory]: Option[];
};
type StateValues = {
  [CategorySelectTypes.for]?: number;
  [CategorySelectTypes.type]?: number;
  [CategorySelectTypes.category]?: number;
  [CategorySelectTypes.subcategory]?: number;
};

type SelectorConfig = {
  category: string;
  label: string;
  placeholder: string;
  childCategory?: CategorySelectTypes;
}

const selectorsConfig: SelectorConfig[] = [{
  category: CategorySelectTypes.for,
  label: "Для кого",
  placeholder: "Выберите категорию",
  childCategory: CategorySelectTypes.type,
}, {
  category: CategorySelectTypes.type,
  label: "Тип",
  placeholder: "Выберите тип",
  childCategory: CategorySelectTypes.category,
}, {
  category: CategorySelectTypes.category,
  label: "Категория",
  placeholder: "Выберите категорию",
  childCategory: CategorySelectTypes.subcategory,
}, {
  category: CategorySelectTypes.subcategory,
  label: "Подкатегория",
  placeholder: "Выберите категорию",
}]

export interface CategorySelectorState {
  values: StateValues;
  options: StateOptions;
}

interface CategorySelectorProps {
  state: CategorySelectorState;
  setState: (state: CategorySelectorState) => void;
}


const fetchOptions = async (cid: number): Promise<CategoryType[]> => {
  return (await ProductEditService.getCategories(cid) || []) as CategoryType[];
}

const getChildCategory = (category) => {
  return selectorsConfig.find(config => config.category === category)?.childCategory
}


const CategorySelector: FC<CategorySelectorProps> = ({ state, setState }) => {

  function handleChange({ name: category, value }: IOnSelectChange) {
    const parsedValue = parseInt((value || 0).toLocaleString());
    const values = { ...state.values, [category]: parsedValue };

    const childCategory = getChildCategory(category);
    if (childCategory) {
      fetchOptions(parsedValue).then((receivedOptions) => {
        let childCategory = getChildCategory(category);

        const newStare = {
          ...state, values, options: {
            ...state.options
          }
        }
        if (childCategory) {
          newStare.options[childCategory] = receivedOptions.map(o => ({
            label: o.name,
            value: o.id
          }))
          values[childCategory] = undefined;
        }

        childCategory = getChildCategory(childCategory);
        while (childCategory) {
          newStare.options[childCategory] = [];
          newStare.values[childCategory] = undefined;
          childCategory = getChildCategory(childCategory);
        }
        setState(newStare)
      });
    } else {
      setState({ ...state, values })
    }
  }

  useEffect(() => {
    fetchOptions(0).then((receivedOptions) => {
      setState({
        ...state,
        options: {
          ...state.options,
          [CategorySelectTypes.for]: receivedOptions.map(o => ({
            label: o.name,
            value: o.id
          }))
        }
      });
    });
  }, []);


  return (
    <article className={styles.body}>
      {selectorsConfig.map(s => <CustomSelect_v2
        key={s.category}
        name={s.category}
        value={state.values[s.category] || 0}
        onChange={handleChange}
        label={s.label}
        required={true}
        // error={null}
        placeholder={s.placeholder}
        options={state.options[s.category]}
        disabled={state.options[s.category].length === 0}
      />
      )}
      {/* {selectorsConfig.map(s => <Select
        key={s.category}
        name={s.category}
        value={state.values[s.category] || 0}
        onChange={handleChange}
        label={s.label}
        required={true}
        // error={null}
        placeholder={s.placeholder}
        options={state.options[s.category]}
        disabled={state.options[s.category].length === 0}
      />
      )} */}
    </article>
  );
}

export default CategorySelector;