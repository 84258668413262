import { createSlice } from "@reduxjs/toolkit";
import { Promotions } from "../../types/swagger/llyApi";

export interface JoinInPromotionState {
  promotion?: Promotions
}

export const initialState: JoinInPromotionState = {
  promotion: undefined
  // promotion: {
  //   "id": 1,
  //   "title": "Акция 1",
  //   "start_date": "2022-12-25",
  //   "finish_date": "2023-01-07",
  //   "percent": 10,
  //   "text": "Новогодняя распродажа",
  //   "type": "сезонная",
  //   "description": "продаем блузки",
  //   "created_at": 0,
  //   "updated_at": 0,
  //   "categories": [
  //     {
  //       "id": 4,
  //       "parent_id": 3,
  //       "name": "Блузы",
  //       "created_at": 0,
  //     }
  //   ],
  //   "banner": {
  //     "id": 1,
  //     "form_id": 1,
  //     "color_id": 1,
  //     "created_at": 1674488650,
  //     "updated_at": 1674488650,
  //     "formValue": "SQUARE",
  //     "colorValue": "FFD75E"
  //   }
  // }
};

export interface FetchGoodsRequest {
  categoryID: number;
  searchRequest?: string;
}


export const joinInPromotionSlice = createSlice({
  name: "joinInPromotion",
  initialState,
  reducers: {
    setPromotion: (state, action) => {
      state.promotion = action.payload;
    },
  }
});

export const { setPromotion } = joinInPromotionSlice.actions;

export default joinInPromotionSlice.reducer;