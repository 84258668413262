import React from 'react'
import { ButtonConfig, ModalDialog } from '../../../simples/ModalDialog'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { createCert, stopCreationCert } from '../../../../store/slices/certificates'
import CertEditor from './CertEditor'


export default function CreateCertModal() {

  const { uploadedDoc, isCertCreation } = useAppSelector(state => state.certificates)
  const { selectedRequirements, selectedDocType, title, docId, fromDate, toDate, termless } = useAppSelector(state => state.certificates.certData)

  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(stopCreationCert())
  }
  const handleCreate = () => {
    dispatch(createCert())
  }

  const formIsValid = title.length > 0
    && selectedRequirements !== undefined
    && selectedDocType !== undefined
    && docId.length > 0
    && fromDate !== undefined
    && (toDate !== undefined || termless)
    && uploadedDoc !== undefined


  const btns: ButtonConfig[] = [{
    text: 'Сохранить сертификат',
    onClick: handleCreate,
    disabled: !formIsValid
  }]


  return (
    <ModalDialog isActive={isCertCreation} buttonsConfig={btns} width={920} hasCloseBtn handleClose={handleClose}  >
      <CertEditor />
    </ModalDialog>
  )
}
