import React from 'react'
import classNames from 'classnames'
import styles from "./OptionSelector.module.scss"

export type Option<T> = {
  label: string
  value: T
}

type Props<T> = {
  options: Option<T>[]
  selectedOption?: T
  onSelect: (option: T) => void
  className?: string
}

export default function OptionSelector<T>({ selectedOption, onSelect, options, className }: Props<T>) {

  const handleClick = (m: T) => () => {
    onSelect(m)
  }

  return (
    <div className={classNames(styles.option_selector, className)}>
      {options.map((o, i) => <div key={i}
        className={classNames(styles.option, { [styles.selected]: selectedOption === o.value })}
        onClick={handleClick(o.value)}
      >{o.label}</div>)}
    </div>
  )
}
