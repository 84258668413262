import classNames from "classnames";
import React from "react";
import styles from "./AuthCardWrapper.module.scss";

type Props = {
  children: React.ReactNode;
  classes?: string;
};

const AuthCardWrapper = ({children, classes}: Props) => (
  <div className={classNames(styles.container, classes)}>
    <h2 className={styles.header}>Вход в личный кабинет</h2>
    {children}
  </div>
);

export default AuthCardWrapper;
