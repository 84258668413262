import React, { useState, useEffect } from 'react'

import Contact from './Contact'
import { BlueButton } from '../../../../simples/BlueButton'
import styles from './LawInfo.module.scss'
import { LegalCompanyInfoDTO } from '../../../../../types/swagger/llyApi_new'
import { CompaniesContactsDTO } from '../../../../../types/swagger/llyApi_new'
import LawControls, { Mode, hasErrors } from './LawControls'
import LawUpdate from './LawUpdate'
import { IOnChange } from '../../../../simples/CustomTextInput'
import api from "../../../../../utils/api";
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { createNewContact, fetchContacts, fetchLawData, saveLawData } from '../../../../../store/slices/law'

type FetchDataByInnResponse = {
  success: boolean
  data?: LegalCompanyInfoDTO
  errors?: string[]
}

// export function getEmptyLawData() {
// }

function getNewContact(): CompaniesContactsDTO {
  return {
    id: Math.random(),
    name: '',
    phone: '',
    role: '',
    keywords: '',
  }
}



export default function LawInfo() {

  const [isEditMode, setIsEditMode] = useState(false)
  // const [contacts, setContacts] = useState<CompaniesContactsDTO[]>([])
  const [isUpdateDialogActive, setIsUpdateDialogActive] = useState(false)

  const loadingData = useAppSelector(state => state.law.lawDataLoading)

  const dispatch = useAppDispatch()
  const lawData = useAppSelector(state => state.law.lawData)

  const contacts = useAppSelector(state => state.law.contacts)
  const activeContactId = useAppSelector(state => state.law.activeContactId)


  useEffect(() => {
    dispatch(fetchLawData())
    dispatch(fetchContacts())
  }, [])

  function saveData() {
    // if () {
    dispatch(saveLawData())
    // } else {
    //   dispatch(updateLawData())
    // }
  }

  const handleClickUpdate = () => {
    setIsUpdateDialogActive(true);
  }
  const handleCloseUpdate = () => {
    setIsUpdateDialogActive(false)
    dispatch(fetchLawData())
  }
  const handleEdit = () => { setIsEditMode(true) }
  const handleCancel = () => {
    setIsEditMode(false)
    dispatch(fetchLawData())
  }

  const handleSave = () => {
    saveData()
    setIsEditMode(false)
  }
  // const handleChangeContact = (id: number) => ({ name, value }: IOnChange) => {
  //   console.log({ id, name, value });

  //   const index = contacts.findIndex(_c => _c.id === id)
  //   if (index !== -1) {
  //     const _contacts = [...contacts]
  //     _contacts[index] = { ..._contacts[index], [name]: value }
  //     setContacts(_contacts)
  //   }
  // }
  const hasMasxContacts = contacts.length >= 4
  const handleAddContact = () => {
    // if (!hasMasxContacts) { setContacts([...contacts, getNewContact()]) }
    dispatch(createNewContact())
  }
  // const handleRemoveContact = (id: number) => {
  //   setContacts(contacts.filter(c => c.id !== id))
  // }

  const isHasErrors = hasErrors(lawData)

  // const handle = () => { return }

  let btns
  if (isEditMode) {
    btns = <div className={styles.btns}>
      <BlueButton title='Отправить на модерацию' onClick={handleSave} disabled={isHasErrors} />
      <BlueButton title='Отменить' onClick={handleCancel} />
    </div>
  } else if (isHasErrors && lawData.editable) {
    btns = <div className={styles.btns}>
      <BlueButton title='Редактировать' onClick={handleEdit} />
    </div>
  }

  return (
    <div className={styles.law_info}>
      <LawUpdate data={lawData} isActive={isUpdateDialogActive} onCloseUpdate={handleCloseUpdate} />
      {loadingData
        ? <div>Загрузка данных...</div>
        : <>
          <LawControls mode={Mode.embedded} onClickUpdate={handleClickUpdate} isEditMode={isEditMode} />
          {btns}
          {/* <div className={styles.btns}>
            {isEditMode
              ? <>
                <BlueButton title='Отправить на модерацию' onClick={handleSave} disabled={isHasErrors} />
                <BlueButton title='Отменить' onClick={handleCancel} />
              </>
              : isHasErrors && lawData.editable && <BlueButton title='Редактировать' onClick={handleEdit} />
            }
          </div> */}
        </>
      }


      <div className={styles.contacts}>
        <h1>Контактные лица</h1>
        <div className={styles.grid}>
          {contacts.length === 0 && <div>Список пуст</div>}
          {contacts.map(c => <div key={c.id} className={styles.cel}>
            <Contact data={c}
            // isEditMode={isEditMode}
            // onChange={handleChangeContact(c.id)} onRemove={handleRemoveContact} 
            />
          </div>)}
        </div>
      </div>
      {/* {isEditMode && !hasMasxContacts && <div className={styles.add_contact}> */}
      {!hasMasxContacts && !activeContactId && <div className={styles.add_contact}>
        <BlueButton title='Добавить контактное лицо' onClick={handleAddContact} />
      </div>}

    </div>
  )
}
