import React from 'react'


import styles from './LawInfo.module.scss'
import { CustomTextInput, IOnChange } from '../../../../simples/CustomTextInput'

import { IconBtn, IconType } from '../../../../simples/IconBtn'
import { CompaniesContactsDTO } from '../../../../../types/swagger/llyApi_new'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { editContact, removeContact, removeNewContact, saveContact, updateContactValue } from '../../../../../store/slices/law'
import { PhoneRegexp } from '../../../../../utils/employees'

type Props = {
  data: CompaniesContactsDTO
  // onChange: (data: IOnChange) => void
  // isEditMode?: boolean
  // onRemove?: (id: number) => void
}

// export default function Contact({ data, isEditMode, onChange, onRemove }: Props) {
export default function Contact({ data }: Props) {
  const isEditMode = useAppSelector(state => state.law.activeContactId) === data.id
  const isNewContact = useAppSelector(state => state.law.activeContactIsNew)
  const dispatch = useAppDispatch()

  const handleRemove = () => {
    // onRemove && onRemove(data.id)
    if (!isNewContact) {
      dispatch(removeContact({ id: data.id }))
    } else {
      dispatch(removeNewContact())
    }

  }
  const handleSave = () => {
    dispatch(saveContact())
  }
  const handleEdit = () => {
    dispatch(editContact(data.id))
  }

  const handleChange = ({ name, value }: IOnChange) => {
    dispatch(updateContactValue({ id: data.id, name, value }))
  }
  const readonly = !isEditMode

  const phoneIsValid = PhoneRegexp.test(data.phone)
  const nameIsValid = data.name.length > 1

  const isValid = nameIsValid && phoneIsValid

  return (
    <div className={styles.panel}>
      <CustomTextInput name='name' value={data.name} label='ФИО контактного лица' onChange={handleChange} readonly={readonly}
        required error={!nameIsValid ? '! Заполните' : undefined} />
      <CustomTextInput name='role' value={data.role} label='Должность' onChange={handleChange} readonly={readonly} />
      <CustomTextInput name='keywords' value={data.keywords} label='Обращаться по вопросам' onChange={handleChange} readonly={readonly} />
      <CustomTextInput name='phone' value={data.phone} label='Телефон' onChange={handleChange} readonly={readonly}
        placeholder={"+7 (___) ___-__-__"} mask={"+7 (999) 999-99-99"} required error={!phoneIsValid ? '! Заполните' : undefined} />
      {/* {isEditMode && <IconBtn icon={IconType.Trash} onClick={handleRemove} />} */}
      <div className={styles.controls}>
        {!isEditMode && <IconBtn icon={IconType.Edit} onClick={handleEdit} />}
        {isEditMode && <>
          <IconBtn icon={IconType.Save} onClick={handleSave} disabled={!isValid} />
          <IconBtn icon={IconType.Trash} onClick={handleRemove} />
        </>}
      </div>
    </div>
  )
}
