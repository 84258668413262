import React from 'react'

import styles from './LawInfo.module.scss'
import UploadRow from './UploadRow'
import { DocType, LawDoc } from '../../../../../types/law'
import { useAppSelector } from '../../../../../hooks/store'
// import { Doc, DocType } from './LawUpdate'

export type UploadConfig = { [key in DocType]?: LawDoc }


// export default function Uploads({ is_individual, config, onChange }: Props) {
export default function Uploads() {

  const lawData = useAppSelector(state => state.law.lawData)
  const config = useAppSelector(state => state.law.lawData.is_individual ? state.law.ipDocs : state.law.ulDocs)

  if (!config) return <></>

  return (
    <div className={styles.uploads}>
      {lawData.is_individual ? <h1>Индивидуальный предприниматель</h1> : <h1>юридическое лицо</h1>}
      {Object.values(config).map((item, index) => <UploadRow key={item.type} lawDoc={item} />)}
    </div>
  )

  // if (is_individual) {
  //   return (
  //     <div className={styles.uploads}>
  //       <h1>Индивидуальный предприниматель</h1>
  //       <UploadRow docType={DocType.TYPE_OGRNIP} onChange={handleUploadFile} title='Свидетельство ОГРНИП' fileName='Загружено свидетельство о постановке.jpeg' />
  //       <UploadRow title='Свидетельство ИНН' fileName='Загружено свидетельство о постановке.jpeg' />
  //       <UploadRow title='Копия паспорта ИП (два разворота в одном файле - с фотографией и регистрацией)' fileName='Загружено свидетельство о постановке.jpeg' />
  //     </div>
  //   )
  // } else {
  //   return (
  //     <div className={styles.uploads}>
  //       <h1>юридическое лицо</h1>
  //       <UploadRow title='ССвидетельство ОГРН (или лист записи ЕГРЮЛ)' fileName='Загружено свидетельство о постановке.jpeg' />
  //       <UploadRow title='Свидетельство ИНН' fileName='Загружено выписка ЕГРЮЛ.jpg' />
  //       <UploadRow title='Устав компании с отметками налогового органа о регистрации' fileName='Загружено выписка ЕГРЮЛ.jpg' />
  //       <UploadRow title='Решение или приказ о назначении генерального директора' fileName='Загружено выписка ЕГРЮЛ .jpg' />
  //     </div>
  //   )
  // }
}
