import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import styles from "./LogosList.module.scss";
import { Controls } from "./components/Controls";
import { ListView } from "./components/ListView";
import { GridView } from "./components/GridView";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/store";
import { fetchLogoList } from "../../../../../store/slices/getters/logo-list";



const LogosList: FC = () => {


  const [viewType, setViewType] = useState(false);
  // const [selectedId, setSelectedId] = useState<null | number>(null);
  // const [upload, setUpload] = useState(false);
  // const [isUpdate, setIsUpdate] = useState(false);

  // const dispatch = useAppDispatch();
  const { logoList } = useAppSelector((state) => state.logoList);

  const [search, setSearch] = useState("");

  // const onSelect = (id: number) => {
  //   setSelectedId(id);
  //   setIsUpdate(true);
  //   setUpload(false);
  // };

  // useEffect(() => {
  //   dispatch(fetchLogoList());
  // }, [dispatch, selectedId, onSelect]);


  const filtered = logoList?.filter(logo =>
    logo.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );

  return (
    <div className={styles.inner}>
      <Controls
        viewType={viewType}
        setViewType={setViewType}
        setSearch={setSearch}
      />
      <div className={styles.list}>
        {viewType ? (
          <ListView
            logoList={filtered ?? []}
          // handleSelected={onSelect}
          // setUpload={setUpload}
          // setIsUpdate={setIsUpdate}
          // selectedId={selectedId || null}
          />
        ) : (
          <GridView
            logoList={filtered ?? []}
          // onSelect={onSelect}
          // setUpload={setUpload}
          // setIsUpdate={setIsUpdate}
          // selectedId={selectedId || null}
          />
        )}
      </div>
    </div>
  );
};

export default LogosList;