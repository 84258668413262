// import classNames from 'classnames'
import React from 'react'
import { useAppSelector } from '../../../../../hooks/store'
import Title from '../Title'
import Composition from './Composition'


import styles from "./Compositions.module.scss"

export default function Compositions() {
  const compounds = useAppSelector(state => state.product.referenceBook.compounds)

  return (
    <div className={styles.composition}>
      <Title text='Состав' />
      <div className={styles.compounds}>
        {compounds.map((compound, i) => <Composition key={compound.id} compound={compound} />)}
      </div>
    </div >
  )
}
