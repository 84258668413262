import React, { useState, useEffect } from 'react'
import { RadioButtons, RadioButtonsOption } from '../../../../../../simples/RadioButtons'
import styles from './Planning.module.scss'
import Panel from './Panel'
import { BlueButton } from '../../../../../../simples/BlueButton'
import Maps from './parts/Maps/Maps'
import { IStorage } from '../../../../../../../types/fbs_fbo'
import api from '../../../../../../../utils/api'
import { nextStep, setFromLocationId, setIsCourier } from '../../../../../../../store/slices/fbs'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../../../hooks/store'
import { CdekOffice } from '../../../../../../../types/swagger/llyApi_new'

// const CDECVariants = [{
//   id: '1',
//   title: 'Отделение CDEK',
//   text: 'г. Москва, ул. Ломоносова, д 21'
// }, {
//   id: '2',
//   title: 'Отделение CDEK',
//   text: 'г. Москва, ул. Ломоносова, д 21'
// }, {
//   id: '3',
//   title: 'Отделение CDEK',
//   text: 'г. Москва, ул. Ломоносова, д 21'
// },]

type ServerStoragesResponse = {
  success?: boolean
  data?: IStorage[]
}

const options: RadioButtonsOption[] = [{
  value: '',
  label: 'Доставка в отделение CDEK'
}, {
  value: 'courier',
  label: 'Доставка курьером'
}]

interface SavedCDEKStorage {
  id: string
  address: string
  locationId: number
}

function getLastCDEKStorages(): SavedCDEKStorage[] {
  let storages: SavedCDEKStorage[] = []
  try {
    storages = JSON.parse(localStorage.getItem('last_CDEK_storages') || '')
  } catch (e) { console.log(e) }
  return storages
}
function saveLastCDEKStorages(storages: SavedCDEKStorage[]) {
  localStorage.setItem('last_CDEK_storages', JSON.stringify(storages))
}

export default function Planning() {
  const { request } = useAppSelector(s => s.fbs)
  // const [isCourier, setIsCourier] = useState(false)
  // const [selectedVariant, setSelectedVariant] = useState('')
  const [isOnMapSelection, setIsOnMapSelection] = useState(false)
  const [fbsStorages, setFbsStorages] = useState<IStorage[] | undefined>(undefined)
  const [lastCDEKStorages, setLastCDEKStorages] = useState<SavedCDEKStorage[]>([])
  const dispatch = useDispatch()
  const [costOfDelivery, setCostOfDelivery] = useState<number | undefined>()

  useEffect(() => {

    if (request.fromLocationId) {
      const data = request.orders.map(o => ({
        "id": o.id,
        "location_id": request.fromLocationId,
        "packages": o.boxes.map(b => ({
          "weight": b.weight,
          "length": b.size.l,
          "width": b.size.w,
          "height": b.size.h,
        }))
      }))

      setCostOfDelivery(undefined)

      api.post<{
        success?: boolean
        data?: {
          total?: number
        }
      }>('/fbs-orders/delivery-price', data).then(resp => {
        setCostOfDelivery(resp.data.data?.total)
      })
    }else{
      setCostOfDelivery(undefined)
    }
  }, [request])

  useEffect(() => {
    setFbsStorages(undefined)
    const req = '/storages';
    api.get<ServerStoragesResponse>(req).then(data => {
      if (data.data.data !== undefined) {
        setFbsStorages(data.data.data.filter(w => !w.is_fbo))
      }
    })

    setLastCDEKStorages(getLastCDEKStorages())
  }, [])

  const handleChangeOption = (value: string) => {
    dispatch(setIsCourier(value === 'courier'))
    dispatch(setFromLocationId(undefined))
  }

  const variants = request.isCourier
    ? (fbsStorages?.filter(s => !s.is_fbo)?.map((s, i) => ({
      id: s.id,
      locationId: s.location_id,
      title: `Ваш склад №${i + 1}`,
      text: s.string
    })) || [])
    : lastCDEKStorages.map(s => ({
      id: s.id,
      locationId: s.locationId,
      title: `Отделение CDEK "${s.id}"`,
      text: s.address
    }))

  const handleSelectAddress = (id: number) => () => {
    // console.log(id, request.fromLocationId);

    dispatch(setFromLocationId(id))
  }
  const handleSelectOnMap = () => {
    setIsOnMapSelection(true)
  }
  const handleCloseMap = (selectedDP?: CdekOffice) => {
    setIsOnMapSelection(false)
    // console.log(selectedDP);
    if (selectedDP) {
      if (!lastCDEKStorages.find(s => s.id === selectedDP.code)) {
        const list: SavedCDEKStorage[] = [{ id: selectedDP.code, address: selectedDP.location.address_full, locationId: selectedDP.location_id }, ...lastCDEKStorages]
        setLastCDEKStorages(list)
        saveLastCDEKStorages(list)
      }
      dispatch(setFromLocationId(selectedDP.location_id))
      // setSelectedVariant(selectedDP.code)
    }

    // setSelectedVariant(selectedId)
  }

  const handleNextStep = () => {
    // dispatch(buildRequest(selectedOrdersItems))
    dispatch(nextStep())
  }

  return (
    <div className={styles.planning}>
      <Maps isActive={isOnMapSelection} onClose={handleCloseMap} />
      <div className={styles.controls_row}>
        <RadioButtons className={styles.selector} options={options} value={request.isCourier ? 'courier' : ''} onChange={handleChangeOption} />
        <div className={styles.cost_of_delivery}>
          <div>Стоимость доставки</div>
          <div className={styles.cost}>{costOfDelivery ? `${costOfDelivery?.toLocaleString()} руб` : 'не рассчитана'}</div>
        </div>
      </div>
      <div>
        <div className={styles.head}>
          <h2>{request.isCourier ? 'Адрес склада FBS' : 'Адрес отделения CDEK'}</h2>
          {!request.isCourier && <div className={styles.map_btn} onClick={handleSelectOnMap}>Выбрать на карте</div>}
        </div>
        <div className={styles.variants}>
          {variants.map(v => <Panel key={v.id} title={v.title} text={v.text} onClick={handleSelectAddress(v.locationId)} selected={request.fromLocationId === v.locationId} />)}
        </div>
        <div className={styles.controls}><BlueButton title='Далее' disabled={!request.fromLocationId || request.fromLocationId === undefined} onClick={handleNextStep} /></div>
      </div>
    </div>
  )
}
