import React, {FC} from "react";
import classNames from "classnames";
import styles from "./LogoCard.module.scss";
import {ReactComponent as EditLogo} from "../../../../../assets/edit.svg";
import {LogoType} from "../../../../../../../../types/logos";

interface Props {
    item: LogoType;
    selectedId: number | null;
    gridCount: number;
    handleSelected: (id: number) => void;
}

const LogoCard: FC<Props> = ({item, selectedId, gridCount, handleSelected}) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        item.id === selectedId && styles.selected,
      )}
      key={item.id}>
      <div className={styles.logo}>
        <img src={`${process.env.REACT_APP_API_URL}/${item.file}`} alt="logoImage" />
        <div>{item.name}</div>
        <div
          className={styles.edit}
          onClick={() => handleSelected(item.id)}>
          <EditLogo className={classNames(item.id === selectedId && styles.active)} />
          <div className={classNames(styles.info, gridCount % 2 === 0 && styles.info_alignLeft)}>Редактировать</div>
        </div>
      </div>
      <button className={styles.setLogoForProduct}>
              Установить для товаров
      </button>
    </div>
  );
};

export default LogoCard;