import classNames from 'classnames'
import React from 'react'
import { MaxPrice, MinPrice } from '../../../../../constants/editingProductCard'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { setIsPricePerVariant, setIsPricePerSize, setPriceForAnySize, setPriceForAnyVariant, setPriceForVariantSize, updateProductStep3_4, setPriceSaved } from '../../../../../store/slices/product'
import { textAsIntValidator } from '../../../../../utils/validators'
import { DebouncedInput } from '../../../../complexes/DebouncedInput'
import { Checkbox } from '../../../../simples/Checkbox'
import { CustomTextInput } from '../../../../simples/CustomTextInput'

import styles from './Prices.module.scss'

interface Props {
  className?: string
}

export default function Prices({ className }: Props) {


  // const variant = useAppSelector(activeVariantSelector)
  // variant.
  const dispatch = useAppDispatch()
  const { isPricePerVariant, isPricePerSize, priceForAnyVariant } = useAppSelector(state => state.product.variants)
  const variants = useAppSelector(state => state.product.variants.values)
  const colors = useAppSelector(state => state.product.commonData.colors)
  const sizes = useAppSelector(state => state.product.referenceBook.sizes)
  // const priceIsUpdated = useAppSelector(state => state.product.priceIsUpdated)


  // const applyPrice = () => {
  //   // throw new Error("todo send to server");
  //   dispatch(updateProductStep3_4()).then(() => {
  //     dispatch(setPriceSaved())
  //   })
  // }
  const handleSetIsPricePerVariant = (v: boolean) => {
    dispatch(setIsPricePerVariant(v))
  }
  const handleSetIsPricePerSize = (v: boolean) => {
    dispatch(setIsPricePerSize(v))
  }
  const handleSetPriceForAnyVariant = ({ value }) => {
    let price = parseInt(value)// || MinPrice
    if (price > 0 && price < MinPrice) { price = MinPrice }
    if (price > MaxPrice) { price = MaxPrice }
    dispatch(setPriceForAnyVariant(price))
  }
  const handleSetPriceForAnySize = (variantIndex: number) => (value: string) => {
    let price = parseInt(value)// || MinPrice
    if (price > 0 && price < MinPrice) { price = MinPrice }
    if (price > MaxPrice) { price = MaxPrice }
    // console.log(price);

    dispatch(
      setPriceForAnySize({ variantIndex, price })
    )
  }
  const handleChangePrice = (variantIndex: number, sizeIndex: number) => (value: string) => {
    let price = parseInt(value)// || MinPrice
    if (price > 0 && price < MinPrice) { price = MinPrice }
    if (price > MaxPrice) { price = MaxPrice }
    // console.log(price);

    dispatch(
      setPriceForVariantSize({ variantIndex, sizeIndex, price })
    )
  }

  const getColorName = (cid?: number) => {
    return colors.find(c => c.id === cid)?.name || "Цвет не выбран"
  }
  const getSizeName = (sid?: number) => {
    return sizes.find(c => c.id === sid)?.name || ""
  }
  const priceValidator = (price: string) => {
    let p = parseInt(price)
    if (isNaN(p)) { p = MinPrice }
    if (p < MinPrice) { p = MinPrice }
    if (p > MaxPrice) { p = MaxPrice }
    return p.toString()
  }


  // const isPricePerSize = true
  // const isPricePerColor = true

  return (
    <div className={classNames(styles.prices, className)}>
      <div className={styles.info}>
        Цена обязательна к заполнению, должна быть целым числом без копеек, не должна быть <br />отрицательной и не может быть меньше 50 и больше 9 999 999 ₽
      </div>
      <div className={styles.price_for_any}>
        <CustomTextInput
          className={styles.big_input}
          name='price'
          label='Розничная цена'
          onChange={handleSetPriceForAnyVariant}
          value={(priceForAnyVariant !== null &&priceForAnyVariant !== undefined && priceForAnyVariant > 0) ? priceForAnyVariant?.toString() : ''}
          disabled={isPricePerVariant}
          disabledValue="-"
        />
        {/* <BlueButton title='Применить цену на все цвета' onClick={applyPrice} disabled={!priceIsUpdated} /> */}
        {/* <CustomHint hintWidth={350} hint='После установки цены она будет  применена к товарам всех цветов и размеров' /> */}
      </div>
      <div className={styles.controls}>
        <Checkbox checked={isPricePerVariant} onChange={handleSetIsPricePerVariant} label="Добавить розничную цену для цвета" />
        <Checkbox checked={isPricePerSize} onChange={handleSetIsPricePerSize} label="Использовать разделение по размерной сетке" disabled={!isPricePerVariant} />
      </div>
      <div className={classNames(styles.colors_prices, { [styles.price_per_size]: isPricePerSize })}>
        {variants.map((variant, variantIndex) => {

          const isDisabled = !isPricePerVariant || isPricePerSize

          return <div className={styles.color} key={`${variantIndex}-${variant.colors_id}`}>
            <DebouncedInput
              postfix={isDisabled ? "" : ' ₽'}
              disabled={isDisabled}
              disabledValue="-"
              value={(variant.priceForAnySize !== null &&variant.priceForAnySize !== undefined && variant.priceForAnySize > 0) ? variant.priceForAnySize?.toString() : ""}
              debounceDelay={1000}
              onChange={handleSetPriceForAnySize(variantIndex)}
              preValidator={textAsIntValidator}
              // validator={priceValidator}
              textAbove={getColorName(variant.colors_id)}
            />
            {isPricePerVariant && isPricePerSize && <div className={styles.color_sizes}>
              {variant.sizes.map((s, sizeIndex) => <div key={`${sizeIndex}-${s.size_id}`}>
                <DebouncedInput
                  postfix=' ₽'
                  value={(s.price !== undefined && s.price > 0) ? s.price?.toString() : ""}
                  debounceDelay={1000}
                  onChange={handleChangePrice(variantIndex, sizeIndex)}
                  preValidator={textAsIntValidator}
                  // validator={priceValidator}
                  textAbove={getSizeName(s.size_id)}
                />
              </div>)}
              {(variant.sizes.length === 0) && <div className={styles.empty_color_sizes}>Размеры не выбраны</div>}
            </div>}
          </div>
        })}
      </div>
    </div>
  )
}
