import { MenuItem } from "../components/complexes/Layout/parts/MainSidebar/parts/Menu";
import { IconType } from "../components/complexes/Layout/parts/MainSidebar/parts/MenuIcon";
import { AnalyticsTabConfig } from "../components/pages/Analytics/const";
import { BrandsTabConfig } from "../components/pages/Brands/const";
import { PricesAndDiscountsTabConfig } from "../components/pages/PricesAndDiscounts/const";
import { ProfileAboutCompanyTabConfig } from "../components/pages/ProfileAboutCompany/const";
import { ProfileTabConfig } from "../components/pages/ProfileAccount/const";
import { SellProductTabConfig } from "../components/pages/SellProduct/parts/SellProductContent/const";
import { StatisticTabConfig } from "../components/pages/Statistic/consts";
import { Routes } from "./routes";

const MenuConfig: MenuItem[] = [{
  id: 'main-menu',
  title: 'Основное меню',
  closable: true,
  isOpenByDefault: true,
  submenu: [{
    id: 'Главная',
    title: 'Главная',
    route: Routes.MAINPAGE,
    iconType: IconType.home,
  }, {
    id: 'products',
    title: 'Товары',
    iconType: IconType.product,
    closable: true,
    isOpenByDefault: false,
    submenu: [{
      id: 'PRODUCTS_LIST',
      title: 'Список товаров',
      route: Routes.PRODUCTS_LIST,
    }, {
      id: 'PRODUCT',
      title: 'Добавить товар',
      route: Routes.PRODUCT,
    }, {
      id: 'IMPORT_TABLE',
      title: 'Добавить товар таблицей',
      route: Routes.IMPORT_TABLE,
    }, {
      id: 'Логотипы',
      title: 'Логотипы',
      route: Routes.LOGOS,
    }]
  }, {
    id: 'Цены и скидки',
    title: 'Цены и скидки',
    iconType: IconType.price,
    closable: true,
    isOpenByDefault: false,
    submenu: [{
      id: 'Цены',
      title: 'Цены',
      // route: '/price/edit',
      route: PricesAndDiscountsTabConfig.edit.route
    }, {
      id: 'Скидки',
      title: 'Скидки',
      route: PricesAndDiscountsTabConfig.discount.route, //'/price/discount', //Routes.PRICE_PAGE,
    }, {
      id: 'История',
      title: 'История',
      route: PricesAndDiscountsTabConfig.history.route // '/price/history',
      // route: Routes.PRODUCTS_LIST,
    }, {
      id: 'Календарь акций',
      title: 'Календарь акций',
      // route: PricesAndDiscountsTabConfig.edit.route
      route: Routes.ACTIONS_CALENDAR,
    }]
  }, {
    id: 'Логистика',
    title: 'Логистика',
    iconType: IconType.logistics,
    closable: true,
    isOpenByDefault: false,
    submenu: [{
      id: 'Мой склад FBO',
      title: 'Мой склад FBO',
      route: Routes.LOGISTICS_FBO,
      // route: PricesAndDiscountsTabConfig.edit.route
    }, {
      id: 'Мой склад FBS',
      title: 'Мой склад FBS',
      route: Routes.LOGISTICS_FBS,
      // route: PricesAndDiscountsTabConfig.discount.route, //'/price/discount', //Routes.PRICE_PAGE,
    }, {
      id: 'Возвраты',
      title: 'Возвраты',
      // route: PricesAndDiscountsTabConfig.history.route // '/price/history',
      // route: Routes.PRODUCTS_LIST,
    }]
  }, {
    id: 'Что продавать',
    title: 'Что продавать',
    iconType: IconType.what_to_sell,
    closable: true,
    isOpenByDefault: false,
    submenu: [{
      id: 'Категории товаров',
      title: 'Категории товаров',
      route: SellProductTabConfig.categories.route
    }, {
      id: 'Популярные теги',
      title: 'Популярные теги',
      route: SellProductTabConfig.tags.route
      // route: PricesAndDiscountsTabConfig.edit.route
    }, {
      id: 'Статистика по товару',
      title: 'Статистика по товару',
      route: SellProductTabConfig.products.route
      // route: PricesAndDiscountsTabConfig.edit.route
    }]
  }, {
    id: 'Аналитика',
    title: 'Аналитика',
    iconType: IconType.analytics,
    closable: true,
    isOpenByDefault: false,
    submenu: [{
      id: 'Выручка',
      title: 'Выручка',
      route: AnalyticsTabConfig.Sales.route
    }, {
      id: 'Новые клиенты',
      title: 'Новые клиенты',
      route: AnalyticsTabConfig.Clients.route
    }, {
      id: 'Товар в корзине',
      title: 'Товар в корзине',
      route: AnalyticsTabConfig.ItemInCart.route
    }, {
      id: 'Оформление',
      title: 'Оформление',
      route: AnalyticsTabConfig.OrdersCount.route
    }, {
      id: 'Средний заказ',
      title: 'Средний заказ',
      route: AnalyticsTabConfig.AverageOrder.route
    }]
  }, {
    id: 'Статистика',
    title: 'Статистика',
    iconType: IconType.statistic,
    closable: true,
    isOpenByDefault: false,
    submenu: [{
      id: 'Оборачиваемость',
      title: 'Оборачиваемость',
      route: StatisticTabConfig.turnover.route
    }, {
      id: 'Статистика по категориям',
      title: 'Статистика по категориям',
      route: StatisticTabConfig.by_category.route
    }, {
      id: 'Остатки на складе',
      title: 'Остатки на складе',
      route: StatisticTabConfig.remaining_stock.route
      // route: PricesAndDiscountsTabConfig.edit.route
    }, {
      id: 'Статистика по продажам',
      title: 'Статистика по продажам',
      route: StatisticTabConfig.sales.route
      // route: PricesAndDiscountsTabConfig.edit.route
    }, {
      id: 'Динамика по ценам',
      title: 'Динамика по ценам',
      route: StatisticTabConfig.price_dynamics.route
    }]
  }, {
    id: 'Карта складов',
    title: 'Карта складов',
    iconType: IconType.warehouses,
    route: Routes.WAREHOUSES
  }, {
    id: 'Реклама',
    title: 'Реклама',
    iconType: IconType.marketing,
    closable: true,
    isOpenByDefault: false,
    submenu: [{
      id: 'Продвижение товаров',
      title: 'Продвижение товаров',
      // route: SellProductTabConfig.categories.route
    }, {
      id: 'Мои представители',
      title: 'Мои представители',
      // route: SellProductTabConfig.categories.route
    }, {
      id: 'Отзывы и вопросы',
      title: 'Отзывы и вопросы',
      route: Routes.FEEDBACK
    }]
  }]
}, {
  id: 'account-control',
  title: 'Управление аккаунтом',
  closable: true,
  isOpenByDefault: false,
  submenu: [{
    id: 'Учётная запись',
    title: 'Учётная запись',
    iconType: IconType.account,
    closable: true,
    isOpenByDefault: false,
    submenu: [{
      id: 'Аккаунт',
      title: 'Аккаунт',
      route: ProfileTabConfig.account.route
      // route: Routes.PROFILE_ACCOUNT
    }, {
      id: 'Информация о магазине',
      title: 'Информация о магазине',
      route: ProfileTabConfig.about_shop.route
      // route: Routes.PROFILE_ACCOUNT
    }, {
      id: 'Удаление аккаунта',
      title: 'Удаление аккаунта',
      route: ProfileTabConfig.removal.route
      // route: Routes.PROFILE_ACCOUNT
    }]
  }, {
    id: 'Сотрудники',
    title: 'Сотрудники',
    route: Routes.EMPLOYEES,
    iconType: IconType.employees,
  }, {
    id: 'Информация о компании',
    title: 'Информация о компании',
    iconType: IconType.about,
    notificationId: 'company_info',
    closable: true,
    isOpenByDefault: false,
    submenu: [{
      id: 'Юридическая информация',
      title: 'Юридическая информация',
      route: ProfileAboutCompanyTabConfig.lawinfo.route
      // route: Routes.PROFILE_ACCOUNT
    }, {
      id: 'Платежные реквизиты',
      title: 'Платежные реквизиты',
      route: ProfileAboutCompanyTabConfig.requisites.route
      // route: Routes.PROFILE_ACCOUNT
    }, {
      id: 'Адреса компании',
      title: 'Адреса компании',
      route: ProfileAboutCompanyTabConfig.addresses.route
      // route: Routes.PROFILE_ACCOUNT
    }]
  }, {
    id: 'Документы компании',
    title: 'Документы компании',
    route: Routes.DOCS,
    iconType: IconType.documents,
    notificationId: 'company_documents',
  }, {
    id: 'График работы',
    title: 'График работы',
    route: Routes.SCHEDULE,
    iconType: IconType.schedule,
    notificationId: 'work_times'
  }, {
    id: 'Бренды и сертификаты',
    title: 'Бренды и сертификаты',
    iconType: IconType.certificate,
    closable: true,
    isOpenByDefault: false,
    submenu: [{
      id: 'Бренды',
      title: 'Бренды',
      route: BrandsTabConfig.brands.route
    }, {
      id: 'Сертификаты',
      title: 'Сертификаты',
      route: BrandsTabConfig.certificates.route
    }]
  }]
}, {
  id: 'Look Like',
  title: 'Look Like',
  closable: true,
  isOpenByDefault: false,
  submenu: [{
    id: 'Как пользоваться маркетплейсом',
    title: 'Как пользоваться маркетплейсом',
    // route: Routes.EMPLOYEES,
    iconType: IconType.instruction,
  }, {
    id: 'Чат с LL',
    title: 'Чат с LL',
    // route: Routes.EMPLOYEES,
    iconType: IconType.chat,
  }, {
    id: 'Документы и бухгалтерия',
    title: 'Документы и бухгалтерия',
    // route: Routes.EMPLOYEES,
    iconType: IconType.accounting,
  }, {
    id: 'Уведомления от LL',
    title: 'Уведомления от LL',
    // route: Routes.EMPLOYEES,
    iconType: IconType.notifications,
  }]
}]

export default MenuConfig