import React, {FC} from "react";
import classNames from "classnames";
import styles from "./LogoCard.module.scss";
import {ReactComponent as EditLogo} from "../../../../../assets/edit.svg";
import {LogoType} from "../../../../../../../../types/logos";
import { editLogo } from "../../../../../../../../store/slices/getters/logo-list";
import { useAppDispatch, useAppSelector } from "../../../../../../../../hooks/store";

interface Props {
    item: LogoType;
    // selectedId: number | null;
    gridCount?: number;
    // handleSelected: (id: number) => void;
}

const LogoCard: FC<Props> = ({item}) => {

  const selectedId = useAppSelector(state => state.logoList.selectedId)

  const dispatch = useAppDispatch();

  const onSelect = (id: number) => {
    dispatch(editLogo(id));
  }

  return (
    <section className={styles.inner}>
      <div
        className={classNames(
          styles.wrapper,
          item.id === selectedId && styles.selected,
        )}
        key={item.id}>
        <div className={styles.logo}>
          <img src={`${process.env.REACT_APP_API_URL}/${item.file}`} alt="logo" />
          <div
            className={styles.edit}
            onClick={() => onSelect(item.id)}>
            <EditLogo className={classNames(item.id === selectedId && styles.active)} />
            <div className={classNames(styles.info)}>Редактировать</div>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.title}>
          <h3 className={styles.name}>{item.name}</h3>
          <p className={styles.setLogoForProduct}>
              Установить для товаров
          </p>
        </div>
      </div>
    </section>
  );
};

export default LogoCard;