import React, { useState } from 'react'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '../../../../hooks/store'
import { IconBtn, IconType } from '../../../simples/IconBtn'
import { CustomHint } from '../../../simples/CustomHint'
import { removeCertProduct } from '../../../../store/slices/certificates'

import styles from '../Brands.module.scss'

enum Status {
  Active = 0,
  Creacion = 1,
  Moderation = 2,
  Rejected = 3,
}

const StatusLabel = {
  [Status.Active]: 'Подтверждены',
  [Status.Moderation]: 'Ожидают проверки',
  [Status.Rejected]: 'Отклонены',
}
const ProductStatusLabel = ['Подтверждена', 'На создании', 'Модерация', 'Отклонена']
const ProductStatusColors = ['#52BA9A', '', '#CC8AFF', '#FF5980']

const FilterOptions = [Status.Active, Status.Moderation, Status.Rejected]



export default function CertProductsList() {

  const dispatch = useAppDispatch()

  const certId = useAppSelector(state => state.certificates.certData.id)
  const list = useAppSelector(state => state.certificates.certificates.find(c => c.id === certId)?.products) || []

  const [filter, setFilter] = useState<Status>()

  let _list = list

  if (filter !== undefined) {
    _list = list.filter(p => p.status === filter)
  }

  const handleSetFilter = (status: Status | undefined) => () => {
    setFilter(status)
  }

  const handleRemoveProduct = (pid: number) => () => {
    dispatch(removeCertProduct(pid))
  }

  return (
    <div className={styles.cert_products}>
      <h1>Связанные карточки</h1>
      <div className={styles.filters}>
        <tr className={classNames(styles.filter, { [styles.selected]: filter === undefined })} onClick={handleSetFilter(undefined)}
        >Все карточки <div className={styles.value}>{list.length}</div></tr>
        <>
          {FilterOptions.map(option => <div
            className={classNames(styles.filter, { [styles.selected]: filter === option })}
            key={option}
            onClick={handleSetFilter(option)}
          >{StatusLabel[option]} <div className={styles.value}>{list.filter(p => p.status === option).length}</div></div>)}
        </>
      </div>
      <table className={styles.products_list}>
        <thead>
          <th>Фото</th>
          <th>Артикул (штрихкод)</th>
          <th>Наименование</th>
          <th>Статус</th>
          <th></th>
        </thead>
        <tbody>
          {_list.map(p => <tr key={p.id}>
            <td><img src={p.url} className={styles.product_img} /></td>
            <td>{p.article}</td>
            <td>{p.name}</td>
            <td style={{ color: ProductStatusColors[p.status || 0] }}>
              <CustomHint hint={p.status_description}><>{ProductStatusLabel[p.status || 0]}</></CustomHint>
            </td>
            <td><IconBtn icon={IconType.Trash} onClick={handleRemoveProduct(p.product_id || -1)} /></td>
          </tr>)}
        </tbody>
      </table>
    </div>
  )
}
