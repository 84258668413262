import api from "../utils/api";

export class BrandLogosService {


  static async fetchList() {
    const { data: response } = await api.get("logos");
    return response.data;
  }

  static async createLogo(name: string, file?: File) {
    const logoUploadForm = new FormData();
    logoUploadForm.append("name", name);
    file && logoUploadForm.append("imageFile", file);
    const { data } = await api.post("logos/create", logoUploadForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  }

  static async updateLogo(id: number, name: string, file?: File) {
    const logoUploadForm = new FormData();
    logoUploadForm.append("name", name);
    file && logoUploadForm.append("imageFile", file);
    const { data } = await api.post(`logos/update/${id}`, logoUploadForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  }

  static async removeLogo(id: number) {
    const { data } = await api.delete(`logos/delete/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  }
}