import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { setPackageValue } from '../../../../../store/slices/product'
import { CustomHint } from '../../../../simples/CustomHint'
import { CustomSelect } from '../../../../simples/CustomSelect'
import { CustomTextInput } from '../../../../simples/CustomTextInput'
import { IHint } from '../../../../simples/IHint'
import Title from '../Title'

import styles from "./Package.module.scss"

export default function Package() {

  const dispatch = useAppDispatch()

  const { equipment, type_id, depth, width, height, gross_weight, net_weight } = useAppSelector(state => state.product.package.values)
  const packagesTypes = useAppSelector(state => state.product.commonData.packagesTypes)
  const readonly = useAppSelector(state => state.product.isReadonlyMode)

  const options = packagesTypes.map(pt => ({ label: pt.name || '', value: pt.id }))

  // const ptype = packagesTypes.find(t => t.id === type_id)?.name || ""

  const handleChangeValue = ({ name, value }) => {
    dispatch(setPackageValue({ name, value }))
  }

  return (
    <div className={styles.package}>
      {/* <div className={styles.title}>Упаковка</div> */}
      <Title text='Упаковка' />
      <div className={styles.list}>
        <CustomTextInput
          name='equipment'
          placeholder='Укажите количество'
          label='Количество в упаковке'
          value={equipment || ''}
          onChange={handleChangeValue}
          required
          readonly={readonly}
        />
        <div className={styles.package_sizes}>
          <div className={styles.package_type}>
            <CustomSelect name='type_id'
              value={type_id}
              options={options}
              onChange={handleChangeValue}
              label='Тип упаковки'
              required={true}
              placeholder='Выберите'
              readonly={readonly}
            />
            {/* <IHint hint={`Мягкая - пакеты \nТвёрдая - картонные коробки`} /> */}
            <CustomHint hint={<div>Мягкая - пакеты <br />Твёрдая - картонные коробки</div>} hintWidth={280} />
          </div>
          <CustomTextInput name='depth' placeholder='Укажите' label='Длина, см' required={true} value={depth?.toString() || ''}
            isNumber={true} max={2000} onChange={handleChangeValue} readonly={readonly} />
          <CustomTextInput name='width' placeholder='Укажите' label='Ширина, см' required={true} value={width?.toString() || ''}
            isNumber={true} max={2000} onChange={handleChangeValue} readonly={readonly} />
          <CustomTextInput name='height' placeholder='Укажите' label='Высота, см' required={true} value={height?.toString() || ''}
            isNumber={true} max={2000} onChange={handleChangeValue} readonly={readonly} />
        </div>
        <CustomTextInput name='gross_weight' placeholder='Укажите вес' label='Вес в упаковке, гр' required={true}
          value={gross_weight?.toString() || ''} isNumber={true} max={8000} onChange={handleChangeValue} readonly={readonly} />
        {/* <CustomTextInput name='gross_weight' placeholder='Укажите вес' readonly={true} label='Вес в упаковке, гр' required={true} value={gross_weight?.toString() || ''} isNumber={true} max={8000} onChange={handleChangeValue} /> */}
        <CustomTextInput name='net_weight' placeholder='Укажите вес' label='Вес без упаковки, гр' required={true}
          value={net_weight?.toString() || ''} isNumber={true} max={7990} onChange={handleChangeValue} readonly={readonly} />
        {/* <CustomTextInput name='net_weight' placeholder='Укажите вес' readonly={true} label='Вес без упаковки, гр' required={true} value={net_weight?.toString() || ''} isNumber={true} max={7990} onChange={handleChangeValue} /> */}
      </div>
    </div>
  )
}
