import React from 'react'
import { InfoPanel } from '../../../simples/InfoPanel'
import styles from '../Brands.module.scss'

export default function BrandsTabInfo() {
  return (
    <div className={styles.brands_info}>
      <InfoPanel title='Внимание'>
        <div>Документ, подтверждающий Ваше право на реализацию бренда, может быть как в формате разрешения в свободной форме, так и в форме договора. Он должен содержать:</div>
        <ul>
          <li>Дату документа</li>
          <li>Срок действия документа</li>
          <li>Бессрочные документы не принимаются</li>
          <li>Подпись или печать правообладателя на документе</li>
          <li>Право на продажу именно на территории РФ</li>
          <li>Нотариально заверенный перевод на английский или русский, если у оригинала документа другой язык</li>
        </ul>
      </InfoPanel>
    </div>
  )
}
