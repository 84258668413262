import React, { Dispatch, FC, SetStateAction } from "react";
import styles from "./GridView.module.scss";
import plus from "../../../../../../../assets/icons/addBtn.svg";

import { LogoType } from "../../../../../../../types/logos";
import { LogoCard } from "./LogoCard";
import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/store";
import { createLogo, LogosMode } from "../../../../../../../store/slices/getters/logo-list";
import classNames from "classnames";

interface Props {
  logoList: LogoType[];
  // onSelect: (id: number) => void;
  // setUpload: Dispatch<SetStateAction<boolean>>;
  // setIsUpdate: Dispatch<SetStateAction<boolean>>;
  // selectedId: number | null;
}

const GridView: FC<Props> = ({
  logoList,
  // onSelect,
  // setUpload,
  // setIsUpdate,
  // selectedId,
}) => {
  // const logoHandler = () => {
  //   setUpload(true);
  //   setIsUpdate(false);
  // };

  const { mode } = useAppSelector((state) => state.logoList);
  const dispatch = useAppDispatch();

  const handleCreateLogo = () => {
    dispatch(createLogo());
  }

  return (
    <section className={styles.container}>
      <section className={classNames(styles.gridView, mode !== LogosMode.list && styles.edit_mode)}>
        <button className={styles.create} onClick={handleCreateLogo}>
          <img src={plus} alt="Create Logo" />
          <p className={styles.title}>Создать новый логотип</p>
        </button>
        {logoList.map((item, index) => (
          <LogoCard
            key={item.id}
            item={item}
            // selectedId={selectedId || null}
            // onSelect={onSelect}
            // gridCount={index}
            fixHint={mode === LogosMode.list ? ((index + 2) % 4 === 0) : index % 2 === 0}
          />
        ))}
      </section>
    </section>
  );
};

export default GridView;
