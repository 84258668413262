import React, { FC, useEffect, useState } from 'react';
import { List, TotalConfig } from './parts/List';
import { Pagination } from '../Pagination';

import styles from "./ItemsList.module.scss";
import { ListHeadData, ListRow } from '../../../types/productListTypes';
import classNames from 'classnames';

export interface RowData {
  foto: string;
  id: string;
  name: string;
  brand: string;
}

export enum Order { ASC, DESC }

interface ProductListProps {
  items: Array<ListRow>
  selectedItems?: string[];
  headers: ListHeadData[];
  className?: string;
  isRemovable?: boolean
  selectProducts?: (selectedItems: string[]) => void
  deselectProducts?: (selectedItems: string[]) => void
  removeItemsFromList?: (selectedItems: string[]) => void
  // onChange?: (fildName: string, values: EditedValue[]) => void
  onChange?: (dataId: string, fieldName: string, newValue: string) => void
  emptyText?: string | JSX.Element
  totalConfig?: TotalConfig
  isModalDialog?: boolean
  disablePagination?: boolean
}

const ItemsList: FC<ProductListProps> = ({
  items, headers, className, isRemovable, selectedItems, emptyText, isModalDialog,
  selectProducts, deselectProducts, removeItemsFromList, onChange, totalConfig,
  disablePagination
}) => {


  const [page, setPage] = useState(0);
  const [rowsCount, setRowsCount] = useState(10);
  const [sortBy, setSortBy] = useState(-1); // header index
  const [orderBy, setOrderBy] = useState<Order>(Order.ASC);

  const pagesCount = Math.ceil(items.length / rowsCount);

  useEffect(() => {
    if (page > pagesCount - 1) {
      setPage(0)
    }
  }, [items])



  if (sortBy !== -1) {
    items = items.sort((row1Data, row2Data) => {

      // let v1 = (row1Data.data[sortBy]?.dataForSorting ? row1Data.data[sortBy]?.dataForSorting : row1Data.data[sortBy]?.text) || ""
      // let v2 = (row2Data.data[sortBy]?.dataForSorting ? row2Data.data[sortBy]?.dataForSorting : row2Data.data[sortBy]?.text) || ""
      let v1 = (row1Data.data[sortBy]?.dataForSorting ? row1Data.data[sortBy]?.dataForSorting : row1Data.data[sortBy]?.text)
      let v2 = (row2Data.data[sortBy]?.dataForSorting ? row2Data.data[sortBy]?.dataForSorting : row2Data.data[sortBy]?.text)
      // if(v1 === undefined || v1 === null) {v1 = ''}
      // if(v2 === undefined || v2 === null) {v2 = ''}
      // console.log({v1, v2});



      switch (typeof v1) {
        case 'string':
          if (orderBy === Order.ASC) {
            return v1.toLowerCase().localeCompare((v2?.toString().toLowerCase() || '')) || 0;
          } else {
            return (v2 || '').toString().toLowerCase().localeCompare(v1?.toString().toLowerCase()) || 0;
          }
        case 'number': {
          const _v2 = (typeof v2 === 'string') ? parseFloat(v2) : v2
          if (orderBy === Order.ASC) {
            return v1 - (_v2 || 0)
          } else {
            return (_v2 || 0) - v1
          }
        }
        default:
          break;
      }

      return 0
    });
  }

  // if (!deselectProducts && rowsCount !== Infinity) {
  //   items = items.slice(page * rowsCount, (page + 1) * rowsCount);
  // }

  const handleOnSelectPage = (newPageIndex: number) => {
    setPage(newPageIndex);
  };
  const handleOnSelectRowsLimit = (newLimit: number) => {
    setRowsCount(newLimit);
    setPage(0);
  };
  const handleOnClickOnHeader = (index: number) => {
    if (headers[index]?.sortable) {
      if (index !== sortBy) {
        setSortBy(index);
      } else {
        setOrderBy(orderBy === Order.DESC ? Order.ASC : Order.DESC);
      }
    }
  };

  const pageItems = items.slice(rowsCount * page, rowsCount * (page + 1))

  return (
    <div className={classNames(styles.wrap, className)}>
      <List
        headers={headers}
        data={pageItems}
        onClickOnHeader={handleOnClickOnHeader}
        sortBy={sortBy}
        orderBy={orderBy}
        isSelectableRows={!!selectedItems}
        selectProducts={selectProducts}
        deselectProducts={deselectProducts}
        isRemovable={isRemovable}
        removeItemsFromList={removeItemsFromList}
        onChange={onChange}
        emptyText={emptyText}
        totalConfig={totalConfig}
      />
      <div className={styles.footer}>
        <div className={styles.additionButtons}></div>
        {!disablePagination && <Pagination
          activePageIndex={page}
          pagesCount={pagesCount}
          showRowsCount={rowsCount}
          onSelectPage={handleOnSelectPage}
          onSelectRowsLimit={handleOnSelectRowsLimit}
          isModalDialog={isModalDialog}
        />}
      </div>
    </div>
  );
};

export default ItemsList;