import classNames from "classnames";
import React, {useRef, useState, useEffect} from "react";
import styles from "./ConfirmCodeInput.module.scss";

type Props = {
  setValue: (value: string) => void;
  onEnter: () => void;
  classes?: string;
  isError?: boolean;
};

const ConfirmCodeInput = ({setValue, onEnter, classes, isError}: Props) => {
  const firstInput = useRef<HTMLInputElement>(null);
  const secondInput = useRef<HTMLInputElement>(null);
  const thirdInput = useRef<HTMLInputElement>(null);
  const lastInput = useRef<HTMLInputElement>(null);

  const [arrValue, setArrValue] = useState<Array<string>>([]);

  const goNext = (currItem: number, values: Array<string>) => {
    //Looks much better then switch case
    currItem === 0 && secondInput.current?.focus();
    currItem === 1 && thirdInput.current?.focus();
    currItem === 2 && lastInput.current?.focus();
    // currItem === 3 && setValue(values.join(""));
  };
  const goPrev = (currItem: number) => {
    currItem === 1 && firstInput.current?.focus();
    currItem === 2 && secondInput.current?.focus();
    currItem === 3 && thirdInput.current?.focus();
  };

  const onType = (item: number, ev: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = [...arrValue];
    const value = ev.target.value;
    newValue[item] = value[value.length - 1];
    if (item !== 3) newValue[item + 1] = "";
    setArrValue(newValue);
    value && goNext(item, newValue);
    setValue(newValue.join(""));
  };

  const onKeyPress = (
    item: number,
    ev: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    switch (ev.code) {
      case "Backspace":
        item > 0 && !arrValue[item] && goPrev(item);
        break;
      case "Enter":
        lastInput && onEnter();
        break;
    }

    if (ev.code === "Backspace" && item > 0) {
      !arrValue[item] && goPrev(item);
    }
  };

  useEffect(() => {
    firstInput.current?.focus();
  }, []);

  return (
    <div className={classNames(styles.container, classes, {[styles.code_error]: isError})}>
      <input
        placeholder="0"
        className={styles.input}
        value={arrValue[0] || ""}
        onChange={onType.bind(null, 0)}
        onKeyDown={onKeyPress.bind(null, 0)}
        ref={firstInput}
      />
      <input
        placeholder="0"
        className={styles.input}
        value={arrValue[1] || ""}
        onChange={onType.bind(null, 1)}
        onKeyDown={onKeyPress.bind(null, 1)}
        ref={secondInput}
      />
      <input
        placeholder="0"
        className={styles.input}
        value={arrValue[2] || ""}
        onChange={onType.bind(null, 2)}
        onKeyDown={onKeyPress.bind(null, 2)}
        ref={thirdInput}
      />
      <input
        placeholder="0"
        className={styles.input}
        value={arrValue[3] || ""}
        onChange={onType.bind(null, 3)}
        onKeyDown={onKeyPress.bind(null, 3)}
        ref={lastInput}
      />
    </div>
  );
};

export default ConfirmCodeInput;
