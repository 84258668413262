import React, { FC } from "react";
import styles from "./GoodsListTable.module.scss";
import { ListProducts } from "./parts/ListProducts";
import { Loader } from "./parts/Loader";
import checkIcon from "../../../../../../../assets/icons/9025510_check_square_icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/store";
import { RootState } from "../../../../../../../store";
import { fetchGoods, setSelectedGoods, setSortByColumn, setSortOrder } from "../../../../../../../store/slices/goodsList";
import classNames from "classnames";
import { DISCOUNT_CATEGORY_ID, Order } from "../../../../../../../services/GoodsListService";
import { ProductsDTO } from "../../../../../../../types/swagger/llyApi_new";

interface PropsType {
  categoryID: number;
}

const GoodsList: FC<PropsType> = ({ categoryID }) => {

  const dispatch = useAppDispatch();
  const goods: Array<ProductsDTO> = useAppSelector((state: RootState) => state.productsList.goods);
  const isLoadingGoodsList = useAppSelector((state: RootState) => state.productsList.isLoadingGoodsList);
  const sortByColumn = useAppSelector((state: RootState) => state.productsList.sortByColumn);
  const order = useAppSelector((state: RootState) => state.productsList.order);

  const selectedGoods = useAppSelector((state: RootState) => state.productsList.selectedGoods);

  const isDiscountTab = (categoryID === DISCOUNT_CATEGORY_ID)

  //   Категории товаров
  let categoriesTitle = [
    { id: 1, title: "Фото" },
    { id: 2, title: "Артикул (штрихкод)", sortingAlias: "barcode" },
    { id: 3, title: "Наименование", sortingAlias: "name" },
    { id: 4, title: "Бренд", sortingAlias: "brand", className: styles.align_center },
    { id: 5, title: "Пол", sortingAlias: "gender", className: styles.align_center },
    { id: 10, title: "Тип", sortingAlias: "type" },
    { id: 6, title: "Категория", sortingAlias: "category" },
    { id: 7, title: "Подкатегория", sortingAlias: "subcategory" },
    { id: 8, title: "Размер", className: styles.align_center },
    { id: 11, title: "Цвет", className: styles.align_center },
    { id: 14, title: "Розничная цена", sortingAlias: "price", className: styles.align_center },
    { id: 9, title: "Сезон", className: styles.align_center },
    { id: 15, title: "Остатки FBO", sortingAlias: "fbo", className: styles.align_center },
    { id: 16, title: "Остатки FBS", sortingAlias: "fbs", className: styles.align_center },
    { id: 12, title: "Дата создания", sortingAlias: "created_at", className: styles.align_center },
  ];
  if (isDiscountTab) {
    categoriesTitle = [
      { id: 1, title: "Фото" },
      { id: 2, title: "Артикул (штрихкод)", sortingAlias: "barcode" },
      { id: 3, title: "Наименование", sortingAlias: "name" },
      { id: 4, title: "Бренд", sortingAlias: "brand", className: styles.align_center },
      { id: 5, title: "Пол", sortingAlias: "gender", className: styles.align_center },
      { id: 10, title: "Тип", sortingAlias: "type" },
      { id: 6, title: "Категория", sortingAlias: "category" },
      { id: 7, title: "Подкатегория", sortingAlias: "subcategory" },
      { id: 8, title: "Размер", className: styles.align_center },
      { id: 11, title: "Цвет", className: styles.align_center },
      { id: 14, title: "Розничная цена", sortingAlias: "price", className: styles.align_center },

      { id: 17, title: "Скидка %", className: styles.align_center },

      { id: 9, title: "Сезон", className: styles.align_center },
      { id: 15, title: "Остатки FBO", sortingAlias: "fbo", className: styles.align_center },
      { id: 16, title: "Остатки FBS", sortingAlias: "fbs", className: styles.align_center },
      { id: 12, title: "Дата создания", sortingAlias: "created_at", className: styles.align_center },
    ];
  }

  React.useEffect(() => {
    dispatch(fetchGoods());
  }, [categoryID, sortByColumn, order]);

  const handleSetSortByColumn = sortingAlias => () => {
    if (!sortingAlias || sortingAlias === "") return;
    if (sortByColumn === sortingAlias) {
      if (order === Order.ASC) {
        dispatch(setSortOrder(Order.DESC));
      } else {
        dispatch(setSortOrder(Order.ASC));
      }
    } else {
      dispatch(setSortByColumn(sortingAlias));
    }
  };

  const handleSelectAll = () => {
    if (selectedGoods.length > 0) {
      dispatch(setSelectedGoods([]));
    } else {
      dispatch(setSelectedGoods(goods.map(p => p.id)));
    }
  };

  return (
    <main className={styles.wrapper}>
      <table className={classNames(styles.body, { [styles.discount_tab]: isDiscountTab })}>
        <thead className={styles.table_header}>
          <tr>
            <th>
              <img onClick={handleSelectAll} className={styles.checkbox} src={checkIcon}></img>
            </th>

            {categoriesTitle.map(({ id, title, sortingAlias, className }) => {
              const canBeSorted = sortingAlias && sortingAlias !== "";
              return (
                <th
                  className={classNames(
                    className,
                    styles.title_wrap,
                    { [styles.can_be_sorted]: canBeSorted },
                    { [styles.order_desc]: (sortByColumn === sortingAlias && order === Order.DESC) },
                    { [styles.order_asc]: (sortByColumn === sortingAlias && order === Order.ASC) }
                  )}
                  key={id}
                  onClick={handleSetSortByColumn(sortingAlias)}
                >
                  <div className={styles.title}>{title}</div>
                </th>
              );
            })}
          </tr>
        </thead>


        <tbody className={styles.table_body}>
          {(!isLoadingGoodsList && goods.length > 0) ? <ListProducts categoryID={categoryID} list={goods} /> : <Loader loading={isLoadingGoodsList} />}
        </tbody>

      </table>
    </main >
  );
};

export default GoodsList;
