import React, { FC, useState } from "react";
import styles from "./PhoneAuthCard.module.scss";
import PurpleButton from "../../../../simples/PurpleButton/PurpleButton";
import LightInput from "../../../../simples/LightInput/LightInput";
import AuthCardWrapper from "../AuthCardWrapper/AuthCardWrapper";
import { AuthService } from "../../../../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../../constants/routes";
import { getOnlyNumbers } from "../../../../../utils/composeString";
import { PinkErrorText } from "../../../../simples/PinkErrorText";
import { BlueButton } from "../../../../simples/BlueButton";

const AuthCard: FC = () => {
  const [phone, setPhone] = useState<string>("");
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const onTypePhone = (ev: React.ChangeEvent<HTMLInputElement>) => {
    isError && setIsError(false);
    setPhone(ev.target.value);
  };
  const getCode = async () => {
    const onlyNumbersPhone = getOnlyNumbers(phone);
    const isGoodLength = onlyNumbersPhone.length > 10;
    if (isGoodLength) {
      const isAuth = await AuthService.login(onlyNumbersPhone, false);
      isAuth &&
        navigate(Routes.CONFIRM_CODE, {
          state: { phone },
        });
    } else {
      setIsError(true);
    }
  };


  return (
    <AuthCardWrapper classes={styles.container}>
      <LightInput
        className={styles.input}
        onChange={onTypePhone}
        placeholder={"+7 (___) ___-__-__"}
        mask={"+7 (999) 999-99-99"}
        value={phone}
      />
      <PinkErrorText
        className={styles.errorText}
        title={"Не валидный телефон"}
        isShow={isError}
      />
      <div className={styles.info}>Нажимая “Получить код”, я даю согласие на <a>обаботку персональных данных</a></div>
      {/* <PurpleButton
        className={styles.button}
        onClick={getCode}
        title={"Получить код"}
      /> */}
      <BlueButton title={"Получить код"} disabled={phone.length !== 18} onClick={getCode} />
    </AuthCardWrapper>
  );
};

export default AuthCard;
